/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@angular/cdk/overlay-prebuilt.css";
@import "~video.js/dist/video-js.min.css";
@import url("https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;600;700;800&display=swap");
app-forms-v1,
app-all-post, app-sample,
app-post-settings,
app-app-settings,
app-privacy-security,
app-change-password,
app-followers-settings,
app-edit-profile,
app-add-profile,
app-report-modal,
app-login,
app-forgot,
app-register,
app-add-product,
app-admin-login,
add-post,
app-admin-add-post,
app-allow-comments,
app-duration {
  /* ion-item*/
  /* Ion Card*/
}
app-forms-v1 ion-item,
app-all-post ion-item, app-sample ion-item,
app-post-settings ion-item,
app-app-settings ion-item,
app-privacy-security ion-item,
app-change-password ion-item,
app-followers-settings ion-item,
app-edit-profile ion-item,
app-add-profile ion-item,
app-report-modal ion-item,
app-login ion-item,
app-forgot ion-item,
app-register ion-item,
app-add-product ion-item,
app-admin-login ion-item,
add-post ion-item,
app-admin-add-post ion-item,
app-allow-comments ion-item,
app-duration ion-item {
  margin: 0px;
  --border-style: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  --ripple-color: transparent;
  --background-activated: transparent;
  --background-activated-opacity: transparent;
  --background-focused: transparent;
  --background-focused-opacity: transparent;
  --background-hover: transparent;
  --background-hover-opacity: transparent;
  /* Select */
  /* Multi Select */
  /* File Upload */
  /* Toggle */
  /* Search */
  /* Radio */
  /* Checkbox */
  /* Textarea */
}
app-forms-v1 ion-item.item-label-floating a24-label,
app-all-post ion-item.item-label-floating a24-label, app-sample ion-item.item-label-floating a24-label,
app-post-settings ion-item.item-label-floating a24-label,
app-app-settings ion-item.item-label-floating a24-label,
app-privacy-security ion-item.item-label-floating a24-label,
app-change-password ion-item.item-label-floating a24-label,
app-followers-settings ion-item.item-label-floating a24-label,
app-edit-profile ion-item.item-label-floating a24-label,
app-add-profile ion-item.item-label-floating a24-label,
app-report-modal ion-item.item-label-floating a24-label,
app-login ion-item.item-label-floating a24-label,
app-forgot ion-item.item-label-floating a24-label,
app-register ion-item.item-label-floating a24-label,
app-add-product ion-item.item-label-floating a24-label,
app-admin-login ion-item.item-label-floating a24-label,
add-post ion-item.item-label-floating a24-label,
app-admin-add-post ion-item.item-label-floating a24-label,
app-allow-comments ion-item.item-label-floating a24-label,
app-duration ion-item.item-label-floating a24-label {
  height: 0;
  visibility: visible;
}
app-forms-v1 ion-item.item-label-floating a24-label ion-label,
app-all-post ion-item.item-label-floating a24-label ion-label, app-sample ion-item.item-label-floating a24-label ion-label,
app-post-settings ion-item.item-label-floating a24-label ion-label,
app-app-settings ion-item.item-label-floating a24-label ion-label,
app-privacy-security ion-item.item-label-floating a24-label ion-label,
app-change-password ion-item.item-label-floating a24-label ion-label,
app-followers-settings ion-item.item-label-floating a24-label ion-label,
app-edit-profile ion-item.item-label-floating a24-label ion-label,
app-add-profile ion-item.item-label-floating a24-label ion-label,
app-report-modal ion-item.item-label-floating a24-label ion-label,
app-login ion-item.item-label-floating a24-label ion-label,
app-forgot ion-item.item-label-floating a24-label ion-label,
app-register ion-item.item-label-floating a24-label ion-label,
app-add-product ion-item.item-label-floating a24-label ion-label,
app-admin-login ion-item.item-label-floating a24-label ion-label,
add-post ion-item.item-label-floating a24-label ion-label,
app-admin-add-post ion-item.item-label-floating a24-label ion-label,
app-allow-comments ion-item.item-label-floating a24-label ion-label,
app-duration ion-item.item-label-floating a24-label ion-label {
  transform: translateY(125%) !important;
  padding-left: 10px;
  font-size: 15px !important;
  color: var(--ion-item-bb-text-label) !important;
  z-index: 99;
  position: relative;
  font-size: 12px !important;
  font-weight: 300;
  display: block !important;
}
app-forms-v1 ion-item.item-label-floating a24-input,
app-forms-v1 ion-item.item-label-floating app-user-name-suggestion,
app-all-post ion-item.item-label-floating a24-input,
app-all-post ion-item.item-label-floating app-user-name-suggestion, app-sample ion-item.item-label-floating a24-input,
app-sample ion-item.item-label-floating app-user-name-suggestion,
app-post-settings ion-item.item-label-floating a24-input,
app-post-settings ion-item.item-label-floating app-user-name-suggestion,
app-app-settings ion-item.item-label-floating a24-input,
app-app-settings ion-item.item-label-floating app-user-name-suggestion,
app-privacy-security ion-item.item-label-floating a24-input,
app-privacy-security ion-item.item-label-floating app-user-name-suggestion,
app-change-password ion-item.item-label-floating a24-input,
app-change-password ion-item.item-label-floating app-user-name-suggestion,
app-followers-settings ion-item.item-label-floating a24-input,
app-followers-settings ion-item.item-label-floating app-user-name-suggestion,
app-edit-profile ion-item.item-label-floating a24-input,
app-edit-profile ion-item.item-label-floating app-user-name-suggestion,
app-add-profile ion-item.item-label-floating a24-input,
app-add-profile ion-item.item-label-floating app-user-name-suggestion,
app-report-modal ion-item.item-label-floating a24-input,
app-report-modal ion-item.item-label-floating app-user-name-suggestion,
app-login ion-item.item-label-floating a24-input,
app-login ion-item.item-label-floating app-user-name-suggestion,
app-forgot ion-item.item-label-floating a24-input,
app-forgot ion-item.item-label-floating app-user-name-suggestion,
app-register ion-item.item-label-floating a24-input,
app-register ion-item.item-label-floating app-user-name-suggestion,
app-add-product ion-item.item-label-floating a24-input,
app-add-product ion-item.item-label-floating app-user-name-suggestion,
app-admin-login ion-item.item-label-floating a24-input,
app-admin-login ion-item.item-label-floating app-user-name-suggestion,
add-post ion-item.item-label-floating a24-input,
add-post ion-item.item-label-floating app-user-name-suggestion,
app-admin-add-post ion-item.item-label-floating a24-input,
app-admin-add-post ion-item.item-label-floating app-user-name-suggestion,
app-allow-comments ion-item.item-label-floating a24-input,
app-allow-comments ion-item.item-label-floating app-user-name-suggestion,
app-duration ion-item.item-label-floating a24-input,
app-duration ion-item.item-label-floating app-user-name-suggestion {
  display: block;
  width: 100%;
}
app-forms-v1 ion-item.item-label-floating a24-input ion-input,
app-forms-v1 ion-item.item-label-floating app-user-name-suggestion ion-input,
app-all-post ion-item.item-label-floating a24-input ion-input,
app-all-post ion-item.item-label-floating app-user-name-suggestion ion-input, app-sample ion-item.item-label-floating a24-input ion-input,
app-sample ion-item.item-label-floating app-user-name-suggestion ion-input,
app-post-settings ion-item.item-label-floating a24-input ion-input,
app-post-settings ion-item.item-label-floating app-user-name-suggestion ion-input,
app-app-settings ion-item.item-label-floating a24-input ion-input,
app-app-settings ion-item.item-label-floating app-user-name-suggestion ion-input,
app-privacy-security ion-item.item-label-floating a24-input ion-input,
app-privacy-security ion-item.item-label-floating app-user-name-suggestion ion-input,
app-change-password ion-item.item-label-floating a24-input ion-input,
app-change-password ion-item.item-label-floating app-user-name-suggestion ion-input,
app-followers-settings ion-item.item-label-floating a24-input ion-input,
app-followers-settings ion-item.item-label-floating app-user-name-suggestion ion-input,
app-edit-profile ion-item.item-label-floating a24-input ion-input,
app-edit-profile ion-item.item-label-floating app-user-name-suggestion ion-input,
app-add-profile ion-item.item-label-floating a24-input ion-input,
app-add-profile ion-item.item-label-floating app-user-name-suggestion ion-input,
app-report-modal ion-item.item-label-floating a24-input ion-input,
app-report-modal ion-item.item-label-floating app-user-name-suggestion ion-input,
app-login ion-item.item-label-floating a24-input ion-input,
app-login ion-item.item-label-floating app-user-name-suggestion ion-input,
app-forgot ion-item.item-label-floating a24-input ion-input,
app-forgot ion-item.item-label-floating app-user-name-suggestion ion-input,
app-register ion-item.item-label-floating a24-input ion-input,
app-register ion-item.item-label-floating app-user-name-suggestion ion-input,
app-add-product ion-item.item-label-floating a24-input ion-input,
app-add-product ion-item.item-label-floating app-user-name-suggestion ion-input,
app-admin-login ion-item.item-label-floating a24-input ion-input,
app-admin-login ion-item.item-label-floating app-user-name-suggestion ion-input,
add-post ion-item.item-label-floating a24-input ion-input,
add-post ion-item.item-label-floating app-user-name-suggestion ion-input,
app-admin-add-post ion-item.item-label-floating a24-input ion-input,
app-admin-add-post ion-item.item-label-floating app-user-name-suggestion ion-input,
app-allow-comments ion-item.item-label-floating a24-input ion-input,
app-allow-comments ion-item.item-label-floating app-user-name-suggestion ion-input,
app-duration ion-item.item-label-floating a24-input ion-input,
app-duration ion-item.item-label-floating app-user-name-suggestion ion-input {
  --background: var(--ion-item-background-black);
  border-radius: var(--border-radius-s);
  --padding-top: 30px !important;
  --padding-start: 10px !important;
  --padding-end: 20px !important;
  height: 60px;
  opacity: 1 !important;
  color: var(--ion-white-color);
  z-index: 9;
  font-size: 14px !important;
  font-weight: 300;
}
app-forms-v1 ion-item.item-label-floating a24-input ion-input.webUrl,
app-forms-v1 ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-all-post ion-item.item-label-floating a24-input ion-input.webUrl,
app-all-post ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl, app-sample ion-item.item-label-floating a24-input ion-input.webUrl,
app-sample ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-post-settings ion-item.item-label-floating a24-input ion-input.webUrl,
app-post-settings ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-app-settings ion-item.item-label-floating a24-input ion-input.webUrl,
app-app-settings ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-privacy-security ion-item.item-label-floating a24-input ion-input.webUrl,
app-privacy-security ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-change-password ion-item.item-label-floating a24-input ion-input.webUrl,
app-change-password ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-followers-settings ion-item.item-label-floating a24-input ion-input.webUrl,
app-followers-settings ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-edit-profile ion-item.item-label-floating a24-input ion-input.webUrl,
app-edit-profile ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-add-profile ion-item.item-label-floating a24-input ion-input.webUrl,
app-add-profile ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-report-modal ion-item.item-label-floating a24-input ion-input.webUrl,
app-report-modal ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-login ion-item.item-label-floating a24-input ion-input.webUrl,
app-login ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-forgot ion-item.item-label-floating a24-input ion-input.webUrl,
app-forgot ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-register ion-item.item-label-floating a24-input ion-input.webUrl,
app-register ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-add-product ion-item.item-label-floating a24-input ion-input.webUrl,
app-add-product ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-admin-login ion-item.item-label-floating a24-input ion-input.webUrl,
app-admin-login ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
add-post ion-item.item-label-floating a24-input ion-input.webUrl,
add-post ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-admin-add-post ion-item.item-label-floating a24-input ion-input.webUrl,
app-admin-add-post ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-allow-comments ion-item.item-label-floating a24-input ion-input.webUrl,
app-allow-comments ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl,
app-duration ion-item.item-label-floating a24-input ion-input.webUrl,
app-duration ion-item.item-label-floating app-user-name-suggestion ion-input.webUrl {
  text-transform: inherit !important;
}
app-forms-v1 ion-item.item-label-floating a24-input .valid_msg,
app-forms-v1 ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-all-post ion-item.item-label-floating a24-input .valid_msg,
app-all-post ion-item.item-label-floating app-user-name-suggestion .valid_msg, app-sample ion-item.item-label-floating a24-input .valid_msg,
app-sample ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-post-settings ion-item.item-label-floating a24-input .valid_msg,
app-post-settings ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-app-settings ion-item.item-label-floating a24-input .valid_msg,
app-app-settings ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-privacy-security ion-item.item-label-floating a24-input .valid_msg,
app-privacy-security ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-change-password ion-item.item-label-floating a24-input .valid_msg,
app-change-password ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-followers-settings ion-item.item-label-floating a24-input .valid_msg,
app-followers-settings ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-edit-profile ion-item.item-label-floating a24-input .valid_msg,
app-edit-profile ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-add-profile ion-item.item-label-floating a24-input .valid_msg,
app-add-profile ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-report-modal ion-item.item-label-floating a24-input .valid_msg,
app-report-modal ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-login ion-item.item-label-floating a24-input .valid_msg,
app-login ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-forgot ion-item.item-label-floating a24-input .valid_msg,
app-forgot ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-register ion-item.item-label-floating a24-input .valid_msg,
app-register ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-add-product ion-item.item-label-floating a24-input .valid_msg,
app-add-product ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-admin-login ion-item.item-label-floating a24-input .valid_msg,
app-admin-login ion-item.item-label-floating app-user-name-suggestion .valid_msg,
add-post ion-item.item-label-floating a24-input .valid_msg,
add-post ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-admin-add-post ion-item.item-label-floating a24-input .valid_msg,
app-admin-add-post ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-allow-comments ion-item.item-label-floating a24-input .valid_msg,
app-allow-comments ion-item.item-label-floating app-user-name-suggestion .valid_msg,
app-duration ion-item.item-label-floating a24-input .valid_msg,
app-duration ion-item.item-label-floating app-user-name-suggestion .valid_msg {
  position: relative;
  bottom: 0;
  left: 0px;
}
app-forms-v1 ion-item.item-label-floating.item-has-focus ion-label, app-forms-v1 ion-item.item-label-floating.item-has-value ion-label,
app-all-post ion-item.item-label-floating.item-has-focus ion-label,
app-all-post ion-item.item-label-floating.item-has-value ion-label, app-sample ion-item.item-label-floating.item-has-focus ion-label, app-sample ion-item.item-label-floating.item-has-value ion-label,
app-post-settings ion-item.item-label-floating.item-has-focus ion-label,
app-post-settings ion-item.item-label-floating.item-has-value ion-label,
app-app-settings ion-item.item-label-floating.item-has-focus ion-label,
app-app-settings ion-item.item-label-floating.item-has-value ion-label,
app-privacy-security ion-item.item-label-floating.item-has-focus ion-label,
app-privacy-security ion-item.item-label-floating.item-has-value ion-label,
app-change-password ion-item.item-label-floating.item-has-focus ion-label,
app-change-password ion-item.item-label-floating.item-has-value ion-label,
app-followers-settings ion-item.item-label-floating.item-has-focus ion-label,
app-followers-settings ion-item.item-label-floating.item-has-value ion-label,
app-edit-profile ion-item.item-label-floating.item-has-focus ion-label,
app-edit-profile ion-item.item-label-floating.item-has-value ion-label,
app-add-profile ion-item.item-label-floating.item-has-focus ion-label,
app-add-profile ion-item.item-label-floating.item-has-value ion-label,
app-report-modal ion-item.item-label-floating.item-has-focus ion-label,
app-report-modal ion-item.item-label-floating.item-has-value ion-label,
app-login ion-item.item-label-floating.item-has-focus ion-label,
app-login ion-item.item-label-floating.item-has-value ion-label,
app-forgot ion-item.item-label-floating.item-has-focus ion-label,
app-forgot ion-item.item-label-floating.item-has-value ion-label,
app-register ion-item.item-label-floating.item-has-focus ion-label,
app-register ion-item.item-label-floating.item-has-value ion-label,
app-add-product ion-item.item-label-floating.item-has-focus ion-label,
app-add-product ion-item.item-label-floating.item-has-value ion-label,
app-admin-login ion-item.item-label-floating.item-has-focus ion-label,
app-admin-login ion-item.item-label-floating.item-has-value ion-label,
add-post ion-item.item-label-floating.item-has-focus ion-label,
add-post ion-item.item-label-floating.item-has-value ion-label,
app-admin-add-post ion-item.item-label-floating.item-has-focus ion-label,
app-admin-add-post ion-item.item-label-floating.item-has-value ion-label,
app-allow-comments ion-item.item-label-floating.item-has-focus ion-label,
app-allow-comments ion-item.item-label-floating.item-has-value ion-label,
app-duration ion-item.item-label-floating.item-has-focus ion-label,
app-duration ion-item.item-label-floating.item-has-value ion-label {
  transform: translateY(50%) !important;
  --color: var(--ion-white-color);
  z-index: 99;
  position: relative;
  padding: 0px 10px;
  left: 0px;
  display: block !important;
  -webkit-transform: translateY(50%) !important;
  -moz-transform: translateY(50%) !important;
  -ms-transform: translateY(50%) !important;
  -o-transform: translateY(50%) !important;
}
app-forms-v1 ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar, app-forms-v1 ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-all-post ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-all-post ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar, app-sample ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar, app-sample ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-post-settings ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-post-settings ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-app-settings ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-app-settings ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-privacy-security ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-privacy-security ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-change-password ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-change-password ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-followers-settings ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-followers-settings ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-edit-profile ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-edit-profile ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-add-profile ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-add-profile ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-report-modal ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-report-modal ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-login ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-login ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-forgot ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-forgot ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-register ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-register ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-add-product ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-add-product ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-admin-login ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-admin-login ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
add-post ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
add-post ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-admin-add-post ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-admin-add-post ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-allow-comments ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-allow-comments ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar,
app-duration ion-item.item-label-floating.item-has-focus .with-icon.left ion-avatar,
app-duration ion-item.item-label-floating.item-has-value .with-icon.left ion-avatar {
  display: block;
}
app-forms-v1 ion-item.item-label-floating .with-icon,
app-all-post ion-item.item-label-floating .with-icon, app-sample ion-item.item-label-floating .with-icon,
app-post-settings ion-item.item-label-floating .with-icon,
app-app-settings ion-item.item-label-floating .with-icon,
app-privacy-security ion-item.item-label-floating .with-icon,
app-change-password ion-item.item-label-floating .with-icon,
app-followers-settings ion-item.item-label-floating .with-icon,
app-edit-profile ion-item.item-label-floating .with-icon,
app-add-profile ion-item.item-label-floating .with-icon,
app-report-modal ion-item.item-label-floating .with-icon,
app-login ion-item.item-label-floating .with-icon,
app-forgot ion-item.item-label-floating .with-icon,
app-register ion-item.item-label-floating .with-icon,
app-add-product ion-item.item-label-floating .with-icon,
app-admin-login ion-item.item-label-floating .with-icon,
add-post ion-item.item-label-floating .with-icon,
app-admin-add-post ion-item.item-label-floating .with-icon,
app-allow-comments ion-item.item-label-floating .with-icon,
app-duration ion-item.item-label-floating .with-icon {
  width: 100%;
  position: relative;
}
app-forms-v1 ion-item.item-label-floating .with-icon ion-avatar,
app-all-post ion-item.item-label-floating .with-icon ion-avatar, app-sample ion-item.item-label-floating .with-icon ion-avatar,
app-post-settings ion-item.item-label-floating .with-icon ion-avatar,
app-app-settings ion-item.item-label-floating .with-icon ion-avatar,
app-privacy-security ion-item.item-label-floating .with-icon ion-avatar,
app-change-password ion-item.item-label-floating .with-icon ion-avatar,
app-followers-settings ion-item.item-label-floating .with-icon ion-avatar,
app-edit-profile ion-item.item-label-floating .with-icon ion-avatar,
app-add-profile ion-item.item-label-floating .with-icon ion-avatar,
app-report-modal ion-item.item-label-floating .with-icon ion-avatar,
app-login ion-item.item-label-floating .with-icon ion-avatar,
app-forgot ion-item.item-label-floating .with-icon ion-avatar,
app-register ion-item.item-label-floating .with-icon ion-avatar,
app-add-product ion-item.item-label-floating .with-icon ion-avatar,
app-admin-login ion-item.item-label-floating .with-icon ion-avatar,
add-post ion-item.item-label-floating .with-icon ion-avatar,
app-admin-add-post ion-item.item-label-floating .with-icon ion-avatar,
app-allow-comments ion-item.item-label-floating .with-icon ion-avatar,
app-duration ion-item.item-label-floating .with-icon ion-avatar {
  position: absolute;
  width: 46px;
  height: 46px;
  font-size: 28px;
  text-align: center;
  top: 8px;
  bottom: 0;
  z-index: 99;
}
app-forms-v1 ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-all-post ion-item.item-label-floating .with-icon ion-avatar ion-icon, app-sample ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-post-settings ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-app-settings ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-privacy-security ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-change-password ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-followers-settings ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-edit-profile ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-add-profile ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-report-modal ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-login ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-forgot ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-register ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-add-product ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-admin-login ion-item.item-label-floating .with-icon ion-avatar ion-icon,
add-post ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-admin-add-post ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-allow-comments ion-item.item-label-floating .with-icon ion-avatar ion-icon,
app-duration ion-item.item-label-floating .with-icon ion-avatar ion-icon {
  color: var(--ion-input-icon-color);
}
app-forms-v1 ion-item.item-label-floating .with-icon.left ion-avatar,
app-all-post ion-item.item-label-floating .with-icon.left ion-avatar, app-sample ion-item.item-label-floating .with-icon.left ion-avatar,
app-post-settings ion-item.item-label-floating .with-icon.left ion-avatar,
app-app-settings ion-item.item-label-floating .with-icon.left ion-avatar,
app-privacy-security ion-item.item-label-floating .with-icon.left ion-avatar,
app-change-password ion-item.item-label-floating .with-icon.left ion-avatar,
app-followers-settings ion-item.item-label-floating .with-icon.left ion-avatar,
app-edit-profile ion-item.item-label-floating .with-icon.left ion-avatar,
app-add-profile ion-item.item-label-floating .with-icon.left ion-avatar,
app-report-modal ion-item.item-label-floating .with-icon.left ion-avatar,
app-login ion-item.item-label-floating .with-icon.left ion-avatar,
app-forgot ion-item.item-label-floating .with-icon.left ion-avatar,
app-register ion-item.item-label-floating .with-icon.left ion-avatar,
app-add-product ion-item.item-label-floating .with-icon.left ion-avatar,
app-admin-login ion-item.item-label-floating .with-icon.left ion-avatar,
add-post ion-item.item-label-floating .with-icon.left ion-avatar,
app-admin-add-post ion-item.item-label-floating .with-icon.left ion-avatar,
app-allow-comments ion-item.item-label-floating .with-icon.left ion-avatar,
app-duration ion-item.item-label-floating .with-icon.left ion-avatar {
  left: 10px;
  display: none;
}
app-forms-v1 ion-item.item-label-floating .with-icon.left ion-input,
app-all-post ion-item.item-label-floating .with-icon.left ion-input, app-sample ion-item.item-label-floating .with-icon.left ion-input,
app-post-settings ion-item.item-label-floating .with-icon.left ion-input,
app-app-settings ion-item.item-label-floating .with-icon.left ion-input,
app-privacy-security ion-item.item-label-floating .with-icon.left ion-input,
app-change-password ion-item.item-label-floating .with-icon.left ion-input,
app-followers-settings ion-item.item-label-floating .with-icon.left ion-input,
app-edit-profile ion-item.item-label-floating .with-icon.left ion-input,
app-add-profile ion-item.item-label-floating .with-icon.left ion-input,
app-report-modal ion-item.item-label-floating .with-icon.left ion-input,
app-login ion-item.item-label-floating .with-icon.left ion-input,
app-forgot ion-item.item-label-floating .with-icon.left ion-input,
app-register ion-item.item-label-floating .with-icon.left ion-input,
app-add-product ion-item.item-label-floating .with-icon.left ion-input,
app-admin-login ion-item.item-label-floating .with-icon.left ion-input,
add-post ion-item.item-label-floating .with-icon.left ion-input,
app-admin-add-post ion-item.item-label-floating .with-icon.left ion-input,
app-allow-comments ion-item.item-label-floating .with-icon.left ion-input,
app-duration ion-item.item-label-floating .with-icon.left ion-input {
  --padding-start: 50px !important;
  --padding-end: 20px !important;
}
app-forms-v1 ion-item.item-label-floating .with-icon.right ion-avatar,
app-all-post ion-item.item-label-floating .with-icon.right ion-avatar, app-sample ion-item.item-label-floating .with-icon.right ion-avatar,
app-post-settings ion-item.item-label-floating .with-icon.right ion-avatar,
app-app-settings ion-item.item-label-floating .with-icon.right ion-avatar,
app-privacy-security ion-item.item-label-floating .with-icon.right ion-avatar,
app-change-password ion-item.item-label-floating .with-icon.right ion-avatar,
app-followers-settings ion-item.item-label-floating .with-icon.right ion-avatar,
app-edit-profile ion-item.item-label-floating .with-icon.right ion-avatar,
app-add-profile ion-item.item-label-floating .with-icon.right ion-avatar,
app-report-modal ion-item.item-label-floating .with-icon.right ion-avatar,
app-login ion-item.item-label-floating .with-icon.right ion-avatar,
app-forgot ion-item.item-label-floating .with-icon.right ion-avatar,
app-register ion-item.item-label-floating .with-icon.right ion-avatar,
app-add-product ion-item.item-label-floating .with-icon.right ion-avatar,
app-admin-login ion-item.item-label-floating .with-icon.right ion-avatar,
add-post ion-item.item-label-floating .with-icon.right ion-avatar,
app-admin-add-post ion-item.item-label-floating .with-icon.right ion-avatar,
app-allow-comments ion-item.item-label-floating .with-icon.right ion-avatar,
app-duration ion-item.item-label-floating .with-icon.right ion-avatar {
  right: 10px;
}
app-forms-v1 ion-item.item-label-floating .with-icon.right ion-input,
app-all-post ion-item.item-label-floating .with-icon.right ion-input, app-sample ion-item.item-label-floating .with-icon.right ion-input,
app-post-settings ion-item.item-label-floating .with-icon.right ion-input,
app-app-settings ion-item.item-label-floating .with-icon.right ion-input,
app-privacy-security ion-item.item-label-floating .with-icon.right ion-input,
app-change-password ion-item.item-label-floating .with-icon.right ion-input,
app-followers-settings ion-item.item-label-floating .with-icon.right ion-input,
app-edit-profile ion-item.item-label-floating .with-icon.right ion-input,
app-add-profile ion-item.item-label-floating .with-icon.right ion-input,
app-report-modal ion-item.item-label-floating .with-icon.right ion-input,
app-login ion-item.item-label-floating .with-icon.right ion-input,
app-forgot ion-item.item-label-floating .with-icon.right ion-input,
app-register ion-item.item-label-floating .with-icon.right ion-input,
app-add-product ion-item.item-label-floating .with-icon.right ion-input,
app-admin-login ion-item.item-label-floating .with-icon.right ion-input,
add-post ion-item.item-label-floating .with-icon.right ion-input,
app-admin-add-post ion-item.item-label-floating .with-icon.right ion-input,
app-allow-comments ion-item.item-label-floating .with-icon.right ion-input,
app-duration ion-item.item-label-floating .with-icon.right ion-input {
  --padding-start: 10px !important;
  --padding-end: 50px !important;
}
app-forms-v1 ion-item.item-select.item-label-floating a24-label ion-label,
app-all-post ion-item.item-select.item-label-floating a24-label ion-label, app-sample ion-item.item-select.item-label-floating a24-label ion-label,
app-post-settings ion-item.item-select.item-label-floating a24-label ion-label,
app-app-settings ion-item.item-select.item-label-floating a24-label ion-label,
app-privacy-security ion-item.item-select.item-label-floating a24-label ion-label,
app-change-password ion-item.item-select.item-label-floating a24-label ion-label,
app-followers-settings ion-item.item-select.item-label-floating a24-label ion-label,
app-edit-profile ion-item.item-select.item-label-floating a24-label ion-label,
app-add-profile ion-item.item-select.item-label-floating a24-label ion-label,
app-report-modal ion-item.item-select.item-label-floating a24-label ion-label,
app-login ion-item.item-select.item-label-floating a24-label ion-label,
app-forgot ion-item.item-select.item-label-floating a24-label ion-label,
app-register ion-item.item-select.item-label-floating a24-label ion-label,
app-add-product ion-item.item-select.item-label-floating a24-label ion-label,
app-admin-login ion-item.item-select.item-label-floating a24-label ion-label,
add-post ion-item.item-select.item-label-floating a24-label ion-label,
app-admin-add-post ion-item.item-select.item-label-floating a24-label ion-label,
app-allow-comments ion-item.item-select.item-label-floating a24-label ion-label,
app-duration ion-item.item-select.item-label-floating a24-label ion-label {
  transform: translateY(130%) !important;
  padding-left: 10px;
  color: var(--ion-item-bb-text-label) !important;
  font-size: 12px !important;
  font-weight: 300;
  -webkit-transform: translateY(130%) !important;
  -moz-transform: translateY(130%) !important;
  -ms-transform: translateY(130%) !important;
  -o-transform: translateY(130%) !important;
}
app-forms-v1 ion-item.item-select.item-label-floating a24-select-component,
app-all-post ion-item.item-select.item-label-floating a24-select-component, app-sample ion-item.item-select.item-label-floating a24-select-component,
app-post-settings ion-item.item-select.item-label-floating a24-select-component,
app-app-settings ion-item.item-select.item-label-floating a24-select-component,
app-privacy-security ion-item.item-select.item-label-floating a24-select-component,
app-change-password ion-item.item-select.item-label-floating a24-select-component,
app-followers-settings ion-item.item-select.item-label-floating a24-select-component,
app-edit-profile ion-item.item-select.item-label-floating a24-select-component,
app-add-profile ion-item.item-select.item-label-floating a24-select-component,
app-report-modal ion-item.item-select.item-label-floating a24-select-component,
app-login ion-item.item-select.item-label-floating a24-select-component,
app-forgot ion-item.item-select.item-label-floating a24-select-component,
app-register ion-item.item-select.item-label-floating a24-select-component,
app-add-product ion-item.item-select.item-label-floating a24-select-component,
app-admin-login ion-item.item-select.item-label-floating a24-select-component,
add-post ion-item.item-select.item-label-floating a24-select-component,
app-admin-add-post ion-item.item-select.item-label-floating a24-select-component,
app-allow-comments ion-item.item-select.item-label-floating a24-select-component,
app-duration ion-item.item-select.item-label-floating a24-select-component {
  display: block;
  width: 100%;
}
app-forms-v1 ion-item.item-select.item-label-floating a24-select-component ion-select,
app-all-post ion-item.item-select.item-label-floating a24-select-component ion-select, app-sample ion-item.item-select.item-label-floating a24-select-component ion-select,
app-post-settings ion-item.item-select.item-label-floating a24-select-component ion-select,
app-app-settings ion-item.item-select.item-label-floating a24-select-component ion-select,
app-privacy-security ion-item.item-select.item-label-floating a24-select-component ion-select,
app-change-password ion-item.item-select.item-label-floating a24-select-component ion-select,
app-followers-settings ion-item.item-select.item-label-floating a24-select-component ion-select,
app-edit-profile ion-item.item-select.item-label-floating a24-select-component ion-select,
app-add-profile ion-item.item-select.item-label-floating a24-select-component ion-select,
app-report-modal ion-item.item-select.item-label-floating a24-select-component ion-select,
app-login ion-item.item-select.item-label-floating a24-select-component ion-select,
app-forgot ion-item.item-select.item-label-floating a24-select-component ion-select,
app-register ion-item.item-select.item-label-floating a24-select-component ion-select,
app-add-product ion-item.item-select.item-label-floating a24-select-component ion-select,
app-admin-login ion-item.item-select.item-label-floating a24-select-component ion-select,
add-post ion-item.item-select.item-label-floating a24-select-component ion-select,
app-admin-add-post ion-item.item-select.item-label-floating a24-select-component ion-select,
app-allow-comments ion-item.item-select.item-label-floating a24-select-component ion-select,
app-duration ion-item.item-select.item-label-floating a24-select-component ion-select {
  background: var(--ion-item-background-black);
  border-radius: var(--border-radius-s);
  --padding-top: 30px !important;
  --padding-start: 10px !important;
  --padding-end: 20px !important;
  width: 100%;
  max-width: 100%;
  height: 60px;
  opacity: 1 !important;
  color: var(--ion-white-color);
  font-size: 14px !important;
  font-weight: 300;
  position: relative;
}
app-forms-v1 ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-all-post ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon), app-sample ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-post-settings ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-app-settings ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-privacy-security ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-change-password ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-followers-settings ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-edit-profile ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-add-profile ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-report-modal ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-login ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-forgot ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-register ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-add-product ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-admin-login ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
add-post ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-admin-add-post ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-allow-comments ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon),
app-duration ion-item.item-select.item-label-floating a24-select-component ion-select::part(icon) {
  top: 0px;
  color: var(--ion-white-color);
  opacity: 1;
}
app-forms-v1 ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-all-post ion-item.item-select.item-label-floating a24-select-component .valid_msg, app-sample ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-post-settings ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-app-settings ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-privacy-security ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-change-password ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-followers-settings ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-edit-profile ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-add-profile ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-report-modal ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-login ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-forgot ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-register ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-add-product ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-admin-login ion-item.item-select.item-label-floating a24-select-component .valid_msg,
add-post ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-admin-add-post ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-allow-comments ion-item.item-select.item-label-floating a24-select-component .valid_msg,
app-duration ion-item.item-select.item-label-floating a24-select-component .valid_msg {
  position: relative;
  bottom: 0;
  left: 0px;
}
app-forms-v1 ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-all-post ion-item.item-select.item-label-floating a24-select-component.selected ion-select, app-sample ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-post-settings ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-app-settings ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-privacy-security ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-change-password ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-followers-settings ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-edit-profile ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-add-profile ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-report-modal ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-login ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-forgot ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-register ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-add-product ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-admin-login ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
add-post ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-admin-add-post ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-allow-comments ion-item.item-select.item-label-floating a24-select-component.selected ion-select,
app-duration ion-item.item-select.item-label-floating a24-select-component.selected ion-select {
  color: var(--ion-theme-color);
}
app-forms-v1 ion-item.item-select.item-label-floating.item-has-focus ion-label, app-forms-v1 ion-item.item-select.item-label-floating.item-has-value ion-label,
app-all-post ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-all-post ion-item.item-select.item-label-floating.item-has-value ion-label, app-sample ion-item.item-select.item-label-floating.item-has-focus ion-label, app-sample ion-item.item-select.item-label-floating.item-has-value ion-label,
app-post-settings ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-post-settings ion-item.item-select.item-label-floating.item-has-value ion-label,
app-app-settings ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-app-settings ion-item.item-select.item-label-floating.item-has-value ion-label,
app-privacy-security ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-privacy-security ion-item.item-select.item-label-floating.item-has-value ion-label,
app-change-password ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-change-password ion-item.item-select.item-label-floating.item-has-value ion-label,
app-followers-settings ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-followers-settings ion-item.item-select.item-label-floating.item-has-value ion-label,
app-edit-profile ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-edit-profile ion-item.item-select.item-label-floating.item-has-value ion-label,
app-add-profile ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-add-profile ion-item.item-select.item-label-floating.item-has-value ion-label,
app-report-modal ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-report-modal ion-item.item-select.item-label-floating.item-has-value ion-label,
app-login ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-login ion-item.item-select.item-label-floating.item-has-value ion-label,
app-forgot ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-forgot ion-item.item-select.item-label-floating.item-has-value ion-label,
app-register ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-register ion-item.item-select.item-label-floating.item-has-value ion-label,
app-add-product ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-add-product ion-item.item-select.item-label-floating.item-has-value ion-label,
app-admin-login ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-admin-login ion-item.item-select.item-label-floating.item-has-value ion-label,
add-post ion-item.item-select.item-label-floating.item-has-focus ion-label,
add-post ion-item.item-select.item-label-floating.item-has-value ion-label,
app-admin-add-post ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-admin-add-post ion-item.item-select.item-label-floating.item-has-value ion-label,
app-allow-comments ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-allow-comments ion-item.item-select.item-label-floating.item-has-value ion-label,
app-duration ion-item.item-select.item-label-floating.item-has-focus ion-label,
app-duration ion-item.item-select.item-label-floating.item-has-value ion-label {
  transform: translateY(50%) !important;
  z-index: 99;
  position: relative;
  padding: 0px 10px;
  left: 0px;
  -webkit-transform: translateY(50%) !important;
  -moz-transform: translateY(50%) !important;
  -ms-transform: translateY(50%) !important;
  -o-transform: translateY(50%) !important;
}
app-forms-v1 ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-all-post ion-item.item-ionic-selectable.item-label-floating a24-label ion-label, app-sample ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-post-settings ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-change-password ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-add-profile ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-report-modal ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-login ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-forgot ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-register ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-add-product ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-admin-login ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
add-post ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating a24-label ion-label,
app-duration ion-item.item-ionic-selectable.item-label-floating a24-label ion-label {
  transform: translateY(214%) !important;
  padding-left: 10px;
  font-size: 12px !important;
  font-weight: 300;
  color: var(--ion-item-bb-text-label) !important;
  -webkit-transform: translateY(214%) !important;
  -moz-transform: translateY(214%) !important;
  -ms-transform: translateY(214%) !important;
  -o-transform: translateY(214%) !important;
}
app-forms-v1 ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-all-post ion-item.item-ionic-selectable.item-label-floating a24-select-component, app-sample ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-post-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-change-password ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-add-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-report-modal ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-login ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-forgot ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-register ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-add-product ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-admin-login ion-item.item-ionic-selectable.item-label-floating a24-select-component,
add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating a24-select-component,
app-duration ion-item.item-ionic-selectable.item-label-floating a24-select-component {
  display: block;
  width: 100%;
}
app-forms-v1 ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-all-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable, app-sample ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-post-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-change-password ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-add-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-report-modal ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-forgot ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-register ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-add-product ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-admin-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable,
app-duration ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable {
  background: var(--ion-item-background-black);
  border-radius: var(--border-radius-s);
  --padding-top: 30px !important;
  --padding-start: 10px !important;
  --padding-end: 20px !important;
  width: 100%;
  max-width: 100%;
  height: 60px;
  opacity: 1 !important;
  font-size: 14px !important;
  font-weight: 300;
}
app-forms-v1 ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-all-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon, app-sample ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-post-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-change-password ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-add-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-report-modal ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-forgot ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-register ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-add-product ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-admin-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon,
app-duration ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon {
  width: 20px;
  position: absolute;
  top: 27px;
  right: 20px;
  opacity: 1;
  --icon-color: var(--ion-white-color);
}
app-forms-v1 ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-all-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item, app-sample ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-post-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-change-password ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-add-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-report-modal ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-forgot ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-register ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-add-product ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-admin-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item,
app-duration ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value-item {
  display: inline-block;
  margin: 6px 10px 6px 0px;
  padding: 5px 0px;
  border-radius: 8px;
  color: var(--ion-white-color);
}
app-forms-v1 ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-all-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value, app-sample ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-post-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-change-password ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-add-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-report-modal ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-forgot ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-register ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-add-product ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-admin-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value,
app-duration ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-value {
  padding-top: 19px !important;
}
app-forms-v1 ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-all-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner, app-sample ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-post-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-change-password ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-add-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-report-modal ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-forgot ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-register ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-add-product ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-admin-login ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner,
app-duration ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon .ionic-selectable-icon-inner {
  top: 5px !important;
}
app-forms-v1 ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-all-post ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg, app-sample ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-post-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-change-password ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-add-profile ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-report-modal ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-login ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-forgot ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-register ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-add-product ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-admin-login ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg,
app-duration ion-item.item-ionic-selectable.item-label-floating a24-select-component .valid_msg {
  position: relative;
  bottom: 0;
  left: 20px;
}
app-forms-v1 ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label, app-forms-v1 ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-all-post ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-all-post ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label, app-sample ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label, app-sample ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-post-settings ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-post-settings ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-app-settings ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-app-settings ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-privacy-security ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-change-password ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-change-password ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-followers-settings ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-edit-profile ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-add-profile ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-add-profile ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-report-modal ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-report-modal ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-login ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-login ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-forgot ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-forgot ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-register ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-register ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-add-product ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-add-product ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-admin-login ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-admin-login ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
add-post ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
add-post ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-admin-add-post ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-allow-comments ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label,
app-duration ion-item.item-ionic-selectable.item-label-floating.item-has-focus ion-label,
app-duration ion-item.item-ionic-selectable.item-label-floating.item-has-value ion-label {
  transform: translateY(50%) !important;
  z-index: 99;
  position: relative;
  padding: 0px 10px;
  left: 0px;
  -webkit-transform: translateY(50%) !important;
  -moz-transform: translateY(50%) !important;
  -ms-transform: translateY(50%) !important;
  -o-transform: translateY(50%) !important;
}
app-forms-v1 ion-item.upload_files_v2,
app-all-post ion-item.upload_files_v2, app-sample ion-item.upload_files_v2,
app-post-settings ion-item.upload_files_v2,
app-app-settings ion-item.upload_files_v2,
app-privacy-security ion-item.upload_files_v2,
app-change-password ion-item.upload_files_v2,
app-followers-settings ion-item.upload_files_v2,
app-edit-profile ion-item.upload_files_v2,
app-add-profile ion-item.upload_files_v2,
app-report-modal ion-item.upload_files_v2,
app-login ion-item.upload_files_v2,
app-forgot ion-item.upload_files_v2,
app-register ion-item.upload_files_v2,
app-add-product ion-item.upload_files_v2,
app-admin-login ion-item.upload_files_v2,
add-post ion-item.upload_files_v2,
app-admin-add-post ion-item.upload_files_v2,
app-allow-comments ion-item.upload_files_v2,
app-duration ion-item.upload_files_v2 {
  background: var(--ion-item-background-black);
  border-radius: var(--border-radius-s);
  height: 60px;
}
app-forms-v1 ion-item.upload_files_v2 a24-label,
app-all-post ion-item.upload_files_v2 a24-label, app-sample ion-item.upload_files_v2 a24-label,
app-post-settings ion-item.upload_files_v2 a24-label,
app-app-settings ion-item.upload_files_v2 a24-label,
app-privacy-security ion-item.upload_files_v2 a24-label,
app-change-password ion-item.upload_files_v2 a24-label,
app-followers-settings ion-item.upload_files_v2 a24-label,
app-edit-profile ion-item.upload_files_v2 a24-label,
app-add-profile ion-item.upload_files_v2 a24-label,
app-report-modal ion-item.upload_files_v2 a24-label,
app-login ion-item.upload_files_v2 a24-label,
app-forgot ion-item.upload_files_v2 a24-label,
app-register ion-item.upload_files_v2 a24-label,
app-add-product ion-item.upload_files_v2 a24-label,
app-admin-login ion-item.upload_files_v2 a24-label,
add-post ion-item.upload_files_v2 a24-label,
app-admin-add-post ion-item.upload_files_v2 a24-label,
app-allow-comments ion-item.upload_files_v2 a24-label,
app-duration ion-item.upload_files_v2 a24-label {
  width: 50%;
}
app-forms-v1 ion-item.upload_files_v2 a24-label ion-label,
app-all-post ion-item.upload_files_v2 a24-label ion-label, app-sample ion-item.upload_files_v2 a24-label ion-label,
app-post-settings ion-item.upload_files_v2 a24-label ion-label,
app-app-settings ion-item.upload_files_v2 a24-label ion-label,
app-privacy-security ion-item.upload_files_v2 a24-label ion-label,
app-change-password ion-item.upload_files_v2 a24-label ion-label,
app-followers-settings ion-item.upload_files_v2 a24-label ion-label,
app-edit-profile ion-item.upload_files_v2 a24-label ion-label,
app-add-profile ion-item.upload_files_v2 a24-label ion-label,
app-report-modal ion-item.upload_files_v2 a24-label ion-label,
app-login ion-item.upload_files_v2 a24-label ion-label,
app-forgot ion-item.upload_files_v2 a24-label ion-label,
app-register ion-item.upload_files_v2 a24-label ion-label,
app-add-product ion-item.upload_files_v2 a24-label ion-label,
app-admin-login ion-item.upload_files_v2 a24-label ion-label,
add-post ion-item.upload_files_v2 a24-label ion-label,
app-admin-add-post ion-item.upload_files_v2 a24-label ion-label,
app-allow-comments ion-item.upload_files_v2 a24-label ion-label,
app-duration ion-item.upload_files_v2 a24-label ion-label {
  color: var(--ion-item-bb-text-label) !important;
  width: auto !important;
  max-width: 100% !important;
  padding-left: 10px;
  z-index: 99;
  position: relative;
  font-size: 16px !important;
  font-weight: 300;
}
app-forms-v1 ion-item.upload_files_v2 app-file-upload,
app-all-post ion-item.upload_files_v2 app-file-upload, app-sample ion-item.upload_files_v2 app-file-upload,
app-post-settings ion-item.upload_files_v2 app-file-upload,
app-app-settings ion-item.upload_files_v2 app-file-upload,
app-privacy-security ion-item.upload_files_v2 app-file-upload,
app-change-password ion-item.upload_files_v2 app-file-upload,
app-followers-settings ion-item.upload_files_v2 app-file-upload,
app-edit-profile ion-item.upload_files_v2 app-file-upload,
app-add-profile ion-item.upload_files_v2 app-file-upload,
app-report-modal ion-item.upload_files_v2 app-file-upload,
app-login ion-item.upload_files_v2 app-file-upload,
app-forgot ion-item.upload_files_v2 app-file-upload,
app-register ion-item.upload_files_v2 app-file-upload,
app-add-product ion-item.upload_files_v2 app-file-upload,
app-admin-login ion-item.upload_files_v2 app-file-upload,
add-post ion-item.upload_files_v2 app-file-upload,
app-admin-add-post ion-item.upload_files_v2 app-file-upload,
app-allow-comments ion-item.upload_files_v2 app-file-upload,
app-duration ion-item.upload_files_v2 app-file-upload {
  width: 50%;
}
app-forms-v1 ion-item.upload_files_v2 app-file-upload ion-button,
app-all-post ion-item.upload_files_v2 app-file-upload ion-button, app-sample ion-item.upload_files_v2 app-file-upload ion-button,
app-post-settings ion-item.upload_files_v2 app-file-upload ion-button,
app-app-settings ion-item.upload_files_v2 app-file-upload ion-button,
app-privacy-security ion-item.upload_files_v2 app-file-upload ion-button,
app-change-password ion-item.upload_files_v2 app-file-upload ion-button,
app-followers-settings ion-item.upload_files_v2 app-file-upload ion-button,
app-edit-profile ion-item.upload_files_v2 app-file-upload ion-button,
app-add-profile ion-item.upload_files_v2 app-file-upload ion-button,
app-report-modal ion-item.upload_files_v2 app-file-upload ion-button,
app-login ion-item.upload_files_v2 app-file-upload ion-button,
app-forgot ion-item.upload_files_v2 app-file-upload ion-button,
app-register ion-item.upload_files_v2 app-file-upload ion-button,
app-add-product ion-item.upload_files_v2 app-file-upload ion-button,
app-admin-login ion-item.upload_files_v2 app-file-upload ion-button,
add-post ion-item.upload_files_v2 app-file-upload ion-button,
app-admin-add-post ion-item.upload_files_v2 app-file-upload ion-button,
app-allow-comments ion-item.upload_files_v2 app-file-upload ion-button,
app-duration ion-item.upload_files_v2 app-file-upload ion-button {
  --background: transparent;
  --box-shadow: unset;
  border-radius: var(--border-radius-l);
  border: 1px solid var(--ion-white-color);
  font-size: 12px;
  font-weight: 400;
  width: 96px;
  height: 32px;
  text-align: center;
  text-transform: capitalize;
}
app-forms-v1 ion-item.upload_files_v2::part(native),
app-all-post ion-item.upload_files_v2::part(native), app-sample ion-item.upload_files_v2::part(native),
app-post-settings ion-item.upload_files_v2::part(native),
app-app-settings ion-item.upload_files_v2::part(native),
app-privacy-security ion-item.upload_files_v2::part(native),
app-change-password ion-item.upload_files_v2::part(native),
app-followers-settings ion-item.upload_files_v2::part(native),
app-edit-profile ion-item.upload_files_v2::part(native),
app-add-profile ion-item.upload_files_v2::part(native),
app-report-modal ion-item.upload_files_v2::part(native),
app-login ion-item.upload_files_v2::part(native),
app-forgot ion-item.upload_files_v2::part(native),
app-register ion-item.upload_files_v2::part(native),
app-add-product ion-item.upload_files_v2::part(native),
app-admin-login ion-item.upload_files_v2::part(native),
add-post ion-item.upload_files_v2::part(native),
app-admin-add-post ion-item.upload_files_v2::part(native),
app-allow-comments ion-item.upload_files_v2::part(native),
app-duration ion-item.upload_files_v2::part(native) {
  height: 60px;
}
app-forms-v1 ion-item ion-toggle,
app-all-post ion-item ion-toggle, app-sample ion-item ion-toggle,
app-post-settings ion-item ion-toggle,
app-app-settings ion-item ion-toggle,
app-privacy-security ion-item ion-toggle,
app-change-password ion-item ion-toggle,
app-followers-settings ion-item ion-toggle,
app-edit-profile ion-item ion-toggle,
app-add-profile ion-item ion-toggle,
app-report-modal ion-item ion-toggle,
app-login ion-item ion-toggle,
app-forgot ion-item ion-toggle,
app-register ion-item ion-toggle,
app-add-product ion-item ion-toggle,
app-admin-login ion-item ion-toggle,
add-post ion-item ion-toggle,
app-admin-add-post ion-item ion-toggle,
app-allow-comments ion-item ion-toggle,
app-duration ion-item ion-toggle {
  --background: var(--ion-white-color);
}
app-forms-v1 ion-item ion-toggle::part(track),
app-all-post ion-item ion-toggle::part(track), app-sample ion-item ion-toggle::part(track),
app-post-settings ion-item ion-toggle::part(track),
app-app-settings ion-item ion-toggle::part(track),
app-privacy-security ion-item ion-toggle::part(track),
app-change-password ion-item ion-toggle::part(track),
app-followers-settings ion-item ion-toggle::part(track),
app-edit-profile ion-item ion-toggle::part(track),
app-add-profile ion-item ion-toggle::part(track),
app-report-modal ion-item ion-toggle::part(track),
app-login ion-item ion-toggle::part(track),
app-forgot ion-item ion-toggle::part(track),
app-register ion-item ion-toggle::part(track),
app-add-product ion-item ion-toggle::part(track),
app-admin-login ion-item ion-toggle::part(track),
add-post ion-item ion-toggle::part(track),
app-admin-add-post ion-item ion-toggle::part(track),
app-allow-comments ion-item ion-toggle::part(track),
app-duration ion-item ion-toggle::part(track) {
  --background-checked: var(--ion-white-color);
}
app-forms-v1 ion-item ion-toggle::part(handle),
app-all-post ion-item ion-toggle::part(handle), app-sample ion-item ion-toggle::part(handle),
app-post-settings ion-item ion-toggle::part(handle),
app-app-settings ion-item ion-toggle::part(handle),
app-privacy-security ion-item ion-toggle::part(handle),
app-change-password ion-item ion-toggle::part(handle),
app-followers-settings ion-item ion-toggle::part(handle),
app-edit-profile ion-item ion-toggle::part(handle),
app-add-profile ion-item ion-toggle::part(handle),
app-report-modal ion-item ion-toggle::part(handle),
app-login ion-item ion-toggle::part(handle),
app-forgot ion-item ion-toggle::part(handle),
app-register ion-item ion-toggle::part(handle),
app-add-product ion-item ion-toggle::part(handle),
app-admin-login ion-item ion-toggle::part(handle),
add-post ion-item ion-toggle::part(handle),
app-admin-add-post ion-item ion-toggle::part(handle),
app-allow-comments ion-item ion-toggle::part(handle),
app-duration ion-item ion-toggle::part(handle) {
  background-color: #ff9b20;
}
app-forms-v1 ion-item ion-toggle.toggle-checked::part(track),
app-all-post ion-item ion-toggle.toggle-checked::part(track), app-sample ion-item ion-toggle.toggle-checked::part(track),
app-post-settings ion-item ion-toggle.toggle-checked::part(track),
app-app-settings ion-item ion-toggle.toggle-checked::part(track),
app-privacy-security ion-item ion-toggle.toggle-checked::part(track),
app-change-password ion-item ion-toggle.toggle-checked::part(track),
app-followers-settings ion-item ion-toggle.toggle-checked::part(track),
app-edit-profile ion-item ion-toggle.toggle-checked::part(track),
app-add-profile ion-item ion-toggle.toggle-checked::part(track),
app-report-modal ion-item ion-toggle.toggle-checked::part(track),
app-login ion-item ion-toggle.toggle-checked::part(track),
app-forgot ion-item ion-toggle.toggle-checked::part(track),
app-register ion-item ion-toggle.toggle-checked::part(track),
app-add-product ion-item ion-toggle.toggle-checked::part(track),
app-admin-login ion-item ion-toggle.toggle-checked::part(track),
add-post ion-item ion-toggle.toggle-checked::part(track),
app-admin-add-post ion-item ion-toggle.toggle-checked::part(track),
app-allow-comments ion-item ion-toggle.toggle-checked::part(track),
app-duration ion-item ion-toggle.toggle-checked::part(track) {
  --background-checked: #ff9b20;
}
app-forms-v1 ion-item ion-toggle.toggle-checked::part(handle),
app-all-post ion-item ion-toggle.toggle-checked::part(handle), app-sample ion-item ion-toggle.toggle-checked::part(handle),
app-post-settings ion-item ion-toggle.toggle-checked::part(handle),
app-app-settings ion-item ion-toggle.toggle-checked::part(handle),
app-privacy-security ion-item ion-toggle.toggle-checked::part(handle),
app-change-password ion-item ion-toggle.toggle-checked::part(handle),
app-followers-settings ion-item ion-toggle.toggle-checked::part(handle),
app-edit-profile ion-item ion-toggle.toggle-checked::part(handle),
app-add-profile ion-item ion-toggle.toggle-checked::part(handle),
app-report-modal ion-item ion-toggle.toggle-checked::part(handle),
app-login ion-item ion-toggle.toggle-checked::part(handle),
app-forgot ion-item ion-toggle.toggle-checked::part(handle),
app-register ion-item ion-toggle.toggle-checked::part(handle),
app-add-product ion-item ion-toggle.toggle-checked::part(handle),
app-admin-login ion-item ion-toggle.toggle-checked::part(handle),
add-post ion-item ion-toggle.toggle-checked::part(handle),
app-admin-add-post ion-item ion-toggle.toggle-checked::part(handle),
app-allow-comments ion-item ion-toggle.toggle-checked::part(handle),
app-duration ion-item ion-toggle.toggle-checked::part(handle) {
  background-color: var(--ion-white-color);
}
app-forms-v1 ion-item.item-searchbar a24-search,
app-all-post ion-item.item-searchbar a24-search, app-sample ion-item.item-searchbar a24-search,
app-post-settings ion-item.item-searchbar a24-search,
app-app-settings ion-item.item-searchbar a24-search,
app-privacy-security ion-item.item-searchbar a24-search,
app-change-password ion-item.item-searchbar a24-search,
app-followers-settings ion-item.item-searchbar a24-search,
app-edit-profile ion-item.item-searchbar a24-search,
app-add-profile ion-item.item-searchbar a24-search,
app-report-modal ion-item.item-searchbar a24-search,
app-login ion-item.item-searchbar a24-search,
app-forgot ion-item.item-searchbar a24-search,
app-register ion-item.item-searchbar a24-search,
app-add-product ion-item.item-searchbar a24-search,
app-admin-login ion-item.item-searchbar a24-search,
add-post ion-item.item-searchbar a24-search,
app-admin-add-post ion-item.item-searchbar a24-search,
app-allow-comments ion-item.item-searchbar a24-search,
app-duration ion-item.item-searchbar a24-search {
  width: 100%;
}
app-forms-v1 ion-item.item-searchbar a24-search ion-searchbar,
app-all-post ion-item.item-searchbar a24-search ion-searchbar, app-sample ion-item.item-searchbar a24-search ion-searchbar,
app-post-settings ion-item.item-searchbar a24-search ion-searchbar,
app-app-settings ion-item.item-searchbar a24-search ion-searchbar,
app-privacy-security ion-item.item-searchbar a24-search ion-searchbar,
app-change-password ion-item.item-searchbar a24-search ion-searchbar,
app-followers-settings ion-item.item-searchbar a24-search ion-searchbar,
app-edit-profile ion-item.item-searchbar a24-search ion-searchbar,
app-add-profile ion-item.item-searchbar a24-search ion-searchbar,
app-report-modal ion-item.item-searchbar a24-search ion-searchbar,
app-login ion-item.item-searchbar a24-search ion-searchbar,
app-forgot ion-item.item-searchbar a24-search ion-searchbar,
app-register ion-item.item-searchbar a24-search ion-searchbar,
app-add-product ion-item.item-searchbar a24-search ion-searchbar,
app-admin-login ion-item.item-searchbar a24-search ion-searchbar,
add-post ion-item.item-searchbar a24-search ion-searchbar,
app-admin-add-post ion-item.item-searchbar a24-search ion-searchbar,
app-allow-comments ion-item.item-searchbar a24-search ion-searchbar,
app-duration ion-item.item-searchbar a24-search ion-searchbar {
  --background: var(--ion-item-background-black) !important;
  --border-radius: var(--border-radius-s) !important;
  --box-shadow: unset !important;
  --color: var(--ion-white-color) !important;
  height: 40px;
  padding-inline-start: 0;
}
app-forms-v1 ion-item.item-searchbar a24-search ion-searchbar input,
app-all-post ion-item.item-searchbar a24-search ion-searchbar input, app-sample ion-item.item-searchbar a24-search ion-searchbar input,
app-post-settings ion-item.item-searchbar a24-search ion-searchbar input,
app-app-settings ion-item.item-searchbar a24-search ion-searchbar input,
app-privacy-security ion-item.item-searchbar a24-search ion-searchbar input,
app-change-password ion-item.item-searchbar a24-search ion-searchbar input,
app-followers-settings ion-item.item-searchbar a24-search ion-searchbar input,
app-edit-profile ion-item.item-searchbar a24-search ion-searchbar input,
app-add-profile ion-item.item-searchbar a24-search ion-searchbar input,
app-report-modal ion-item.item-searchbar a24-search ion-searchbar input,
app-login ion-item.item-searchbar a24-search ion-searchbar input,
app-forgot ion-item.item-searchbar a24-search ion-searchbar input,
app-register ion-item.item-searchbar a24-search ion-searchbar input,
app-add-product ion-item.item-searchbar a24-search ion-searchbar input,
app-admin-login ion-item.item-searchbar a24-search ion-searchbar input,
add-post ion-item.item-searchbar a24-search ion-searchbar input,
app-admin-add-post ion-item.item-searchbar a24-search ion-searchbar input,
app-allow-comments ion-item.item-searchbar a24-search ion-searchbar input,
app-duration ion-item.item-searchbar a24-search ion-searchbar input {
  padding-inline-start: 40px !important;
}
app-forms-v1 ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-all-post ion-item.item-searchbar a24-search ion-searchbar ion-icon, app-sample ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-post-settings ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-app-settings ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-privacy-security ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-change-password ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-followers-settings ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-edit-profile ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-add-profile ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-report-modal ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-login ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-forgot ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-register ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-add-product ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-admin-login ion-item.item-searchbar a24-search ion-searchbar ion-icon,
add-post ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-admin-add-post ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-allow-comments ion-item.item-searchbar a24-search ion-searchbar ion-icon,
app-duration ion-item.item-searchbar a24-search ion-searchbar ion-icon {
  --icon-color: var(--ion-icon-color) !important;
  left: 10px !important;
}
app-forms-v1 ion-item.item-searchbar a24-label,
app-all-post ion-item.item-searchbar a24-label, app-sample ion-item.item-searchbar a24-label,
app-post-settings ion-item.item-searchbar a24-label,
app-app-settings ion-item.item-searchbar a24-label,
app-privacy-security ion-item.item-searchbar a24-label,
app-change-password ion-item.item-searchbar a24-label,
app-followers-settings ion-item.item-searchbar a24-label,
app-edit-profile ion-item.item-searchbar a24-label,
app-add-profile ion-item.item-searchbar a24-label,
app-report-modal ion-item.item-searchbar a24-label,
app-login ion-item.item-searchbar a24-label,
app-forgot ion-item.item-searchbar a24-label,
app-register ion-item.item-searchbar a24-label,
app-add-product ion-item.item-searchbar a24-label,
app-admin-login ion-item.item-searchbar a24-label,
add-post ion-item.item-searchbar a24-label,
app-admin-add-post ion-item.item-searchbar a24-label,
app-allow-comments ion-item.item-searchbar a24-label,
app-duration ion-item.item-searchbar a24-label {
  margin-bottom: 12px;
  color: var(--ion-white-color);
}
app-forms-v1 ion-item.without_icon a24-search ion-searchbar input,
app-all-post ion-item.without_icon a24-search ion-searchbar input, app-sample ion-item.without_icon a24-search ion-searchbar input,
app-post-settings ion-item.without_icon a24-search ion-searchbar input,
app-app-settings ion-item.without_icon a24-search ion-searchbar input,
app-privacy-security ion-item.without_icon a24-search ion-searchbar input,
app-change-password ion-item.without_icon a24-search ion-searchbar input,
app-followers-settings ion-item.without_icon a24-search ion-searchbar input,
app-edit-profile ion-item.without_icon a24-search ion-searchbar input,
app-add-profile ion-item.without_icon a24-search ion-searchbar input,
app-report-modal ion-item.without_icon a24-search ion-searchbar input,
app-login ion-item.without_icon a24-search ion-searchbar input,
app-forgot ion-item.without_icon a24-search ion-searchbar input,
app-register ion-item.without_icon a24-search ion-searchbar input,
app-add-product ion-item.without_icon a24-search ion-searchbar input,
app-admin-login ion-item.without_icon a24-search ion-searchbar input,
add-post ion-item.without_icon a24-search ion-searchbar input,
app-admin-add-post ion-item.without_icon a24-search ion-searchbar input,
app-allow-comments ion-item.without_icon a24-search ion-searchbar input,
app-duration ion-item.without_icon a24-search ion-searchbar input {
  padding-inline-start: 15px !important;
}
app-forms-v1 ion-item.radio_btns a24-label,
app-all-post ion-item.radio_btns a24-label, app-sample ion-item.radio_btns a24-label,
app-post-settings ion-item.radio_btns a24-label,
app-app-settings ion-item.radio_btns a24-label,
app-privacy-security ion-item.radio_btns a24-label,
app-change-password ion-item.radio_btns a24-label,
app-followers-settings ion-item.radio_btns a24-label,
app-edit-profile ion-item.radio_btns a24-label,
app-add-profile ion-item.radio_btns a24-label,
app-report-modal ion-item.radio_btns a24-label,
app-login ion-item.radio_btns a24-label,
app-forgot ion-item.radio_btns a24-label,
app-register ion-item.radio_btns a24-label,
app-add-product ion-item.radio_btns a24-label,
app-admin-login ion-item.radio_btns a24-label,
add-post ion-item.radio_btns a24-label,
app-admin-add-post ion-item.radio_btns a24-label,
app-allow-comments ion-item.radio_btns a24-label,
app-duration ion-item.radio_btns a24-label {
  color: var(--ion-white-color);
}
app-forms-v1 ion-item.radio_btns a24-radio,
app-all-post ion-item.radio_btns a24-radio, app-sample ion-item.radio_btns a24-radio,
app-post-settings ion-item.radio_btns a24-radio,
app-app-settings ion-item.radio_btns a24-radio,
app-privacy-security ion-item.radio_btns a24-radio,
app-change-password ion-item.radio_btns a24-radio,
app-followers-settings ion-item.radio_btns a24-radio,
app-edit-profile ion-item.radio_btns a24-radio,
app-add-profile ion-item.radio_btns a24-radio,
app-report-modal ion-item.radio_btns a24-radio,
app-login ion-item.radio_btns a24-radio,
app-forgot ion-item.radio_btns a24-radio,
app-register ion-item.radio_btns a24-radio,
app-add-product ion-item.radio_btns a24-radio,
app-admin-login ion-item.radio_btns a24-radio,
add-post ion-item.radio_btns a24-radio,
app-admin-add-post ion-item.radio_btns a24-radio,
app-allow-comments ion-item.radio_btns a24-radio,
app-duration ion-item.radio_btns a24-radio {
  width: 100%;
}
app-forms-v1 ion-item.radio_btns a24-radio ion-item ion-label,
app-all-post ion-item.radio_btns a24-radio ion-item ion-label, app-sample ion-item.radio_btns a24-radio ion-item ion-label,
app-post-settings ion-item.radio_btns a24-radio ion-item ion-label,
app-app-settings ion-item.radio_btns a24-radio ion-item ion-label,
app-privacy-security ion-item.radio_btns a24-radio ion-item ion-label,
app-change-password ion-item.radio_btns a24-radio ion-item ion-label,
app-followers-settings ion-item.radio_btns a24-radio ion-item ion-label,
app-edit-profile ion-item.radio_btns a24-radio ion-item ion-label,
app-add-profile ion-item.radio_btns a24-radio ion-item ion-label,
app-report-modal ion-item.radio_btns a24-radio ion-item ion-label,
app-login ion-item.radio_btns a24-radio ion-item ion-label,
app-forgot ion-item.radio_btns a24-radio ion-item ion-label,
app-register ion-item.radio_btns a24-radio ion-item ion-label,
app-add-product ion-item.radio_btns a24-radio ion-item ion-label,
app-admin-login ion-item.radio_btns a24-radio ion-item ion-label,
add-post ion-item.radio_btns a24-radio ion-item ion-label,
app-admin-add-post ion-item.radio_btns a24-radio ion-item ion-label,
app-allow-comments ion-item.radio_btns a24-radio ion-item ion-label,
app-duration ion-item.radio_btns a24-radio ion-item ion-label {
  --color: var(--ion-white-color) !important;
}
app-forms-v1 ion-item.radio_btns a24-radio ion-item ion-radio,
app-all-post ion-item.radio_btns a24-radio ion-item ion-radio, app-sample ion-item.radio_btns a24-radio ion-item ion-radio,
app-post-settings ion-item.radio_btns a24-radio ion-item ion-radio,
app-app-settings ion-item.radio_btns a24-radio ion-item ion-radio,
app-privacy-security ion-item.radio_btns a24-radio ion-item ion-radio,
app-change-password ion-item.radio_btns a24-radio ion-item ion-radio,
app-followers-settings ion-item.radio_btns a24-radio ion-item ion-radio,
app-edit-profile ion-item.radio_btns a24-radio ion-item ion-radio,
app-add-profile ion-item.radio_btns a24-radio ion-item ion-radio,
app-report-modal ion-item.radio_btns a24-radio ion-item ion-radio,
app-login ion-item.radio_btns a24-radio ion-item ion-radio,
app-forgot ion-item.radio_btns a24-radio ion-item ion-radio,
app-register ion-item.radio_btns a24-radio ion-item ion-radio,
app-add-product ion-item.radio_btns a24-radio ion-item ion-radio,
app-admin-login ion-item.radio_btns a24-radio ion-item ion-radio,
add-post ion-item.radio_btns a24-radio ion-item ion-radio,
app-admin-add-post ion-item.radio_btns a24-radio ion-item ion-radio,
app-allow-comments ion-item.radio_btns a24-radio ion-item ion-radio,
app-duration ion-item.radio_btns a24-radio ion-item ion-radio {
  --ion-color-base: var(--ion-theme-color) !important;
  --color: var(--ion-theme-color) !important;
}
app-forms-v1 ion-item.checkbox_styles a24-label,
app-all-post ion-item.checkbox_styles a24-label, app-sample ion-item.checkbox_styles a24-label,
app-post-settings ion-item.checkbox_styles a24-label,
app-app-settings ion-item.checkbox_styles a24-label,
app-privacy-security ion-item.checkbox_styles a24-label,
app-change-password ion-item.checkbox_styles a24-label,
app-followers-settings ion-item.checkbox_styles a24-label,
app-edit-profile ion-item.checkbox_styles a24-label,
app-add-profile ion-item.checkbox_styles a24-label,
app-report-modal ion-item.checkbox_styles a24-label,
app-login ion-item.checkbox_styles a24-label,
app-forgot ion-item.checkbox_styles a24-label,
app-register ion-item.checkbox_styles a24-label,
app-add-product ion-item.checkbox_styles a24-label,
app-admin-login ion-item.checkbox_styles a24-label,
add-post ion-item.checkbox_styles a24-label,
app-admin-add-post ion-item.checkbox_styles a24-label,
app-allow-comments ion-item.checkbox_styles a24-label,
app-duration ion-item.checkbox_styles a24-label {
  color: var(--ion-white-color);
}
app-forms-v1 ion-item.checkbox_styles app-check-box-component,
app-all-post ion-item.checkbox_styles app-check-box-component, app-sample ion-item.checkbox_styles app-check-box-component,
app-post-settings ion-item.checkbox_styles app-check-box-component,
app-app-settings ion-item.checkbox_styles app-check-box-component,
app-privacy-security ion-item.checkbox_styles app-check-box-component,
app-change-password ion-item.checkbox_styles app-check-box-component,
app-followers-settings ion-item.checkbox_styles app-check-box-component,
app-edit-profile ion-item.checkbox_styles app-check-box-component,
app-add-profile ion-item.checkbox_styles app-check-box-component,
app-report-modal ion-item.checkbox_styles app-check-box-component,
app-login ion-item.checkbox_styles app-check-box-component,
app-forgot ion-item.checkbox_styles app-check-box-component,
app-register ion-item.checkbox_styles app-check-box-component,
app-add-product ion-item.checkbox_styles app-check-box-component,
app-admin-login ion-item.checkbox_styles app-check-box-component,
add-post ion-item.checkbox_styles app-check-box-component,
app-admin-add-post ion-item.checkbox_styles app-check-box-component,
app-allow-comments ion-item.checkbox_styles app-check-box-component,
app-duration ion-item.checkbox_styles app-check-box-component {
  width: 100%;
}
app-forms-v1 ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-all-post ion-item.checkbox_styles app-check-box-component ion-item ion-label, app-sample ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-post-settings ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-app-settings ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-privacy-security ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-change-password ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-followers-settings ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-edit-profile ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-add-profile ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-report-modal ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-login ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-forgot ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-register ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-add-product ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-admin-login ion-item.checkbox_styles app-check-box-component ion-item ion-label,
add-post ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-admin-add-post ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-allow-comments ion-item.checkbox_styles app-check-box-component ion-item ion-label,
app-duration ion-item.checkbox_styles app-check-box-component ion-item ion-label {
  --color: var(--ion-white-color) !important;
}
app-forms-v1 ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-all-post ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox, app-sample ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-post-settings ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-app-settings ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-privacy-security ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-change-password ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-followers-settings ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-edit-profile ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-add-profile ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-report-modal ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-login ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-forgot ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-register ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-add-product ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-admin-login ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
add-post ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-admin-add-post ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-allow-comments ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox,
app-duration ion-item.checkbox_styles app-check-box-component ion-item ion-checkbox {
  --border-color: var(--ion-theme-color) !important;
}
app-forms-v1 ion-item.checkbox_styles app-check-box-component a24-label,
app-all-post ion-item.checkbox_styles app-check-box-component a24-label, app-sample ion-item.checkbox_styles app-check-box-component a24-label,
app-post-settings ion-item.checkbox_styles app-check-box-component a24-label,
app-app-settings ion-item.checkbox_styles app-check-box-component a24-label,
app-privacy-security ion-item.checkbox_styles app-check-box-component a24-label,
app-change-password ion-item.checkbox_styles app-check-box-component a24-label,
app-followers-settings ion-item.checkbox_styles app-check-box-component a24-label,
app-edit-profile ion-item.checkbox_styles app-check-box-component a24-label,
app-add-profile ion-item.checkbox_styles app-check-box-component a24-label,
app-report-modal ion-item.checkbox_styles app-check-box-component a24-label,
app-login ion-item.checkbox_styles app-check-box-component a24-label,
app-forgot ion-item.checkbox_styles app-check-box-component a24-label,
app-register ion-item.checkbox_styles app-check-box-component a24-label,
app-add-product ion-item.checkbox_styles app-check-box-component a24-label,
app-admin-login ion-item.checkbox_styles app-check-box-component a24-label,
add-post ion-item.checkbox_styles app-check-box-component a24-label,
app-admin-add-post ion-item.checkbox_styles app-check-box-component a24-label,
app-allow-comments ion-item.checkbox_styles app-check-box-component a24-label,
app-duration ion-item.checkbox_styles app-check-box-component a24-label {
  margin-left: 15px;
}
app-forms-v1 ion-item.item-textarea ion-textarea,
app-all-post ion-item.item-textarea ion-textarea, app-sample ion-item.item-textarea ion-textarea,
app-post-settings ion-item.item-textarea ion-textarea,
app-app-settings ion-item.item-textarea ion-textarea,
app-privacy-security ion-item.item-textarea ion-textarea,
app-change-password ion-item.item-textarea ion-textarea,
app-followers-settings ion-item.item-textarea ion-textarea,
app-edit-profile ion-item.item-textarea ion-textarea,
app-add-profile ion-item.item-textarea ion-textarea,
app-report-modal ion-item.item-textarea ion-textarea,
app-login ion-item.item-textarea ion-textarea,
app-forgot ion-item.item-textarea ion-textarea,
app-register ion-item.item-textarea ion-textarea,
app-add-product ion-item.item-textarea ion-textarea,
app-admin-login ion-item.item-textarea ion-textarea,
add-post ion-item.item-textarea ion-textarea,
app-admin-add-post ion-item.item-textarea ion-textarea,
app-allow-comments ion-item.item-textarea ion-textarea,
app-duration ion-item.item-textarea ion-textarea {
  margin-top: 0;
  --background: var(--ion-item-background-black);
  color: var(--ion-white-color);
  --padding-top: 30px;
  border-radius: var(--border-radius-s);
  opacity: 1 !important;
  min-height: 60px;
  height: auto;
  --padding-top: 30px !important;
  --padding-start: 10px !important;
}
app-forms-v1 ion-item.item-textarea ion-textarea textarea,
app-all-post ion-item.item-textarea ion-textarea textarea, app-sample ion-item.item-textarea ion-textarea textarea,
app-post-settings ion-item.item-textarea ion-textarea textarea,
app-app-settings ion-item.item-textarea ion-textarea textarea,
app-privacy-security ion-item.item-textarea ion-textarea textarea,
app-change-password ion-item.item-textarea ion-textarea textarea,
app-followers-settings ion-item.item-textarea ion-textarea textarea,
app-edit-profile ion-item.item-textarea ion-textarea textarea,
app-add-profile ion-item.item-textarea ion-textarea textarea,
app-report-modal ion-item.item-textarea ion-textarea textarea,
app-login ion-item.item-textarea ion-textarea textarea,
app-forgot ion-item.item-textarea ion-textarea textarea,
app-register ion-item.item-textarea ion-textarea textarea,
app-add-product ion-item.item-textarea ion-textarea textarea,
app-admin-login ion-item.item-textarea ion-textarea textarea,
add-post ion-item.item-textarea ion-textarea textarea,
app-admin-add-post ion-item.item-textarea ion-textarea textarea,
app-allow-comments ion-item.item-textarea ion-textarea textarea,
app-duration ion-item.item-textarea ion-textarea textarea {
  height: auto;
}
app-forms-v1 ion-card,
app-all-post ion-card, app-sample ion-card,
app-post-settings ion-card,
app-app-settings ion-card,
app-privacy-security ion-card,
app-change-password ion-card,
app-followers-settings ion-card,
app-edit-profile ion-card,
app-add-profile ion-card,
app-report-modal ion-card,
app-login ion-card,
app-forgot ion-card,
app-register ion-card,
app-add-product ion-card,
app-admin-login ion-card,
add-post ion-card,
app-admin-add-post ion-card,
app-allow-comments ion-card,
app-duration ion-card {
  background: var(--ion-background-color);
  margin-bottom: 40px;
}
app-forms-v1 ion-card ion-card-header,
app-all-post ion-card ion-card-header, app-sample ion-card ion-card-header,
app-post-settings ion-card ion-card-header,
app-app-settings ion-card ion-card-header,
app-privacy-security ion-card ion-card-header,
app-change-password ion-card ion-card-header,
app-followers-settings ion-card ion-card-header,
app-edit-profile ion-card ion-card-header,
app-add-profile ion-card ion-card-header,
app-report-modal ion-card ion-card-header,
app-login ion-card ion-card-header,
app-forgot ion-card ion-card-header,
app-register ion-card ion-card-header,
app-add-product ion-card ion-card-header,
app-admin-login ion-card ion-card-header,
add-post ion-card ion-card-header,
app-admin-add-post ion-card ion-card-header,
app-allow-comments ion-card ion-card-header,
app-duration ion-card ion-card-header {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

* {
  font-family: "Baloo Paaji 2", cursive !important;
}

body {
  font-family: "Roboto", sans-serif !important;
  background-color: var(--ion-web-background-color) !important;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
}

.intro_content {
  --offset-bottom: 0px !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: var(--ion-web-background-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--ion-web-background-color);
  border-radius: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ion-progress-bar {
  top: 0;
  position: absolute;
}

:root {
  --btn-animation-time: 0.75s;
  --ion-background-color: #343434;
  --ion-web-background-color: #212121;
  --ion-text-color: #ffffff;
  --ion-backdrop-color: #000000;
  --ion-backdrop-opacity: 0.7;
  --ion-border-color: #5a5a5a;
  --ion-item-border-color: #d6d6d6;
  --ion-toolbar-background: transparent;
  --ion-toolbar-color: #fff;
  --ion-toolbar-segment-color: #e73814;
  --ion-item-background: transparent;
  --ion-item-color: #1b1b1b;
  --ion-placeholder-color: #1b1b1b;
  --ion-hover-color: #f7cfc7;
  --ion-sidemenu-color: #fff;
  --ion-white-color: #fff;
  --ion-theme-color: #ff9b20;
  --ion-input-icon-color: #c9c6c6;
  --ion-input-bg-color: #f5f5f5;
  --border-focus-color: #e73814;
  --ion-active-label-color: #9b9494;
  --ion-active-label-bg-color: #fdeeee;
  --ion-multi-select-bg-color: #f2f2f2;
  --ion-item-background-black: #4e4e4e;
  --ion-item-bb-text-label: #c4c4c4;
  --ion-icon-color: #9f9f9f;
  --ion-card-background-color: #4e4e4e;
  --ion-color-base: #fff;
  --ion-transparent-color: #40404033;
  --ion-post-icon-bg: #c9c9c93d;
  --ion-blur-color: #0000008a;
  --cropper-overlay-color: #404040 !important;
  --cropper-outline-color: #4040409d !important;
  --border-radius-xl: 20px;
  --border-radius-l: 16px;
  --border-radius-md: 12px;
  --border-radius-s: 5px;
  --border-radius-xs: 4px;
  --bar: linear-gradient(90deg, rgba(238, 29, 82, 1) 0%, rgba(255, 255, 255, 1) 34%, rgba(105, 201, 208, 1) 65%);
}

a.filepond--credits {
  display: none;
}

ion-content.feedpage {
  --offset-bottom: 0px !important;
}

ion-item.item-has-start-slot.item.md.item-fill-none.in-list.hydrated.item-label.item-label-stackedl {
  cursor: pointer;
}

app-c-avatar {
  cursor: pointer;
}

.post_feature {
  position: absolute;
  background: transparent;
  width: 75px;
  height: 75px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

ion-modal.short_modal::part(content) {
  height: 80% !important;
}

.feature_footer {
  width: 75px;
  height: 75px;
  background: transparent;
  z-index: 99999;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.custom_feature {
  width: 100%;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-65%, -78%);
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Common Styles CSS */
.add_post_toast {
  --start: 0;
  --end: 0;
  --ion-safe-area-bottom: -8px;
}
.add_post_toast::part(container) {
  background: var(--ion-theme-color);
  --ion-color-contrast: var(--ion-white-color) !important;
  color: var(--ion-white-color);
  --start: 0;
  --end: 0;
}
.add_post_toast::part(button) {
  border-radius: 50%;
  padding: 9px;
  width: 40px;
  height: 40px;
  background-color: #00c766;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add_post_toast::part(button) .toast-button-inner ion-icon {
  font-size: 26px;
}

.header_icon {
  display: flex;
}

.wrapper {
  padding: 30px;
}

.no-border {
  --border-style: 0;
}

.no-pading {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
}

.content_box {
  background: var(--ion-white-color);
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  padding: 30px;
}

.no-flex {
  display: unset !important;
}

.inner_title {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}
.inner_title span {
  color: var(--ion-theme-color);
}

.border-radius-xl {
  border-radius: var(--border-radius-xl);
}

.border-radius-l {
  border-radius: var(--border-radius-l);
}

.border-radius-md {
  border-radius: var(--border-radius-md);
}

.border-radius-s {
  border-radius: var(--border-radius-s);
}

.border-radius-xs {
  border-radius: var(--border-radius-xs);
}

.hide-err {
  font-size: 14px;
  color: var(--ion-color-danger);
  margin: 0 !important;
}

.hide-err {
  position: relative;
}

ion-icon {
  font-size: 22px !important;
  color: var(--ion-white-color);
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
  cursor: pointer;
}

.arrow_icon {
  width: 12px;
  height: 12px;
}

.action-sheet-selected.sc-ion-action-sheet-md {
  background-color: var(--ion-theme-color);
}

a24-label ion-label {
  font-size: 16px !important;
}

app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon {
  right: 42px !important;
}

app-login ion-content ion-grid ion-row ion-col .logo,
app-register ion-content ion-grid ion-row ion-col .logo {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-login ion-content ion-grid ion-row ion-col .logo ion-icon,
app-register ion-content ion-grid ion-row ion-col .logo ion-icon {
  font-size: 55px !important;
}

.diy_feature fiv-feature-discovery .backdrop {
  pointer-events: none !important;
}
.diy_feature fiv-feature-discovery .circle .center {
  z-index: 99999 !important;
  pointer-events: none !important;
}

.curve fiv-feature-discovery {
  display: block;
  position: relative;
}
.curve fiv-feature-discovery .content {
  position: absolute;
  right: 0 !important;
  left: auto !important;
  margin: auto;
  display: block;
  width: 100% !important;
  text-align: center;
  bottom: 28% !important;
}
.curve fiv-feature-discovery .content ion-text h1 {
  margin-top: 5px;
}
.curve fiv-feature-discovery .content ion-text p {
  margin-bottom: 10px;
  margin-top: 0;
}
.curve fiv-feature-discovery .content ion-button {
  margin: 0 auto;
}

.curve.post fiv-feature-discovery .content {
  bottom: 0% !important;
}

app-all-post ion-footer ion-toolbar,
app-sample ion-footer ion-toolbar {
  padding: 5px 0 !important;
}

ion-footer ion-toolbar .footer_main_icon ion-buttons ion-button {
  background-color: var(--ion-card-background-color);
  width: 60px;
  height: 50px !important;
  border-radius: 24px 0 0 24px;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons ion-button:last-child() {
  border-radius: 0 24px 24px 0;
  -webkit-border-radius: 0 24px 24px 0;
  -moz-border-radius: 0 24px 24px 0;
  -ms-border-radius: 0 24px 24px 0;
  -o-border-radius: 0 24px 24px 0;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons .placement {
  background-color: var(--ion-card-background-color);
  width: 60px;
  height: 50px !important;
  border-radius: 0 24px 24px 0;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons .placement .heart {
  margin-top: -13px;
  margin-left: -16px;
  height: 78px;
  width: 90px;
  background-size: 2900%;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons .placement .heart.is-active {
  margin-left: -16px !important;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons .footer_post_page_heart {
  background-color: var(--ion-card-background-color);
  width: 60px;
  height: 50px !important;
  border-radius: 0 24px 24px 0;
  -webkit-border-radius: 0 24px 24px 0;
  -moz-border-radius: 0 24px 24px 0;
  -ms-border-radius: 0 24px 24px 0;
  -o-border-radius: 0 24px 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons .footer_post_page_heart a24-button-heart {
  z-index: 99999;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons .footer_post_page_heart a24-button-heart ion-icon {
  filter: unset;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons .footer_post_page_heart a24-button-heart .button {
  position: relative;
  top: unset;
  left: unset;
  margin-right: unset;
  transform: unset;
  width: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  transition: 0.4s;
  outline: 0;
  text-align: center;
  color: #fff;
  font-family: unset;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons .footer_post_page_heart a24-button-heart .button ion-icon {
  font-size: 35px !important;
}
ion-footer ion-toolbar .footer_main_icon ion-buttons .footer_post_page_heart a24-button-heart .button:hover {
  transform: unset;
}

ion-content {
  --background: var(--ion-web-background-color) !important;
  z-index: 999;
  border: unset;
}
ion-content ion-list ion-item-group ion-item {
  --inner-border-width: 0 !important;
}
ion-content .mainpage_heading {
  padding: 15px 0 15px 10px;
  border-bottom: 1px solid var(--ion-border-color);
}
ion-content .mainpage_heading a24-slides .slider_section {
  padding: 0px;
}
ion-content .mainpage_heading a24-slides .slider_section .swiper-container .swiper-wrapper ion-slide {
  flex-direction: column;
}
ion-content .mainpage_heading a24-slides .slider_section .swiper-container .swiper-wrapper ion-slide p {
  padding: 0;
  margin: 0;
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 74px;
}

app-all-post ion-content,
app-sample ion-content {
  --background: var(--ion-web-background-color) !important;
  z-index: 999;
  border: unset;
}
app-all-post ion-content follower-suggestion .follower-suggestion-page,
app-sample ion-content follower-suggestion .follower-suggestion-page {
  margin: 15px auto 15px 30.5%;
  width: 40%;
}
app-all-post ion-content follower-suggestion .follower-suggestion-page .follower_page_input,
app-sample ion-content follower-suggestion .follower-suggestion-page .follower_page_input {
  width: 100% !important;
  margin: 0 0 30px !important;
}
app-all-post ion-content .cdk-virtual-scroll-content-wrapper,
app-sample ion-content .cdk-virtual-scroll-content-wrapper {
  position: relative;
  contain: content;
  height: 300%;
}
app-all-post ion-content .cdk-virtual-scroll-content-wrapper .mainpage_heading h4,
app-sample ion-content .cdk-virtual-scroll-content-wrapper .mainpage_heading h4 {
  margin: 0px 15px 0;
}
app-all-post ion-content .cdk-virtual-scroll-content-wrapper .mainpage_heading a24-slides,
app-sample ion-content .cdk-virtual-scroll-content-wrapper .mainpage_heading a24-slides {
  margin: 0px 15px 0px;
}
app-all-post ion-content .cdk-virtual-scroll-content-wrapper .mainpage_heading a24-slides .slider_section .swiper-container .swiper-wrapper ion-slide,
app-sample ion-content .cdk-virtual-scroll-content-wrapper .mainpage_heading a24-slides .slider_section .swiper-container .swiper-wrapper ion-slide {
  max-width: 30% !important;
  width: 20% !important;
  flex-direction: column;
  margin-right: 0 !important;
}
app-all-post ion-content .cdk-virtual-scroll-content-wrapper .mainpage_heading a24-slides .slider_section .swiper-container .swiper-wrapper ion-slide p,
app-sample ion-content .cdk-virtual-scroll-content-wrapper .mainpage_heading a24-slides .slider_section .swiper-container .swiper-wrapper ion-slide p {
  padding: 0;
  margin: 0;
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 74px;
}

:root ion-img {
  /** 
   * Bug fix for:
   * https://github.com/ionic-team/ionic/issues/18734
   */
  min-height: 1px !important;
}

@font-face {
  font-family: "BalooPaaji2";
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-Regular.ttf");
}
@font-face {
  font-family: "BalooPaaji2";
  font-style: Bold;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-Medium.ttf");
}
@font-face {
  font-family: "BalooPaaji2";
  font-style: Bold;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-SemiBold.ttf");
}
@font-face {
  font-family: "BalooPaaji2";
  font-style: Bold;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-Bold.ttf");
}
@font-face {
  font-family: "BalooPaaji2";
  font-style: Bold;
  font-weight: 800;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-ExtraBold.ttf");
}
ion-card {
  box-shadow: unset;
}

p {
  font-size: 14px;
  line-height: 24px;
}

a {
  font-weight: 500;
  color: #afafaf;
  font-size: 16px;
}

h1 {
  font-size: 28px;
  font-weight: 400;
}

h3 {
  font-weight: 600;
  font-size: 18px;
  color: var(--ion-white-color);
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.invalid_msg {
  color: #ff0000;
  margin-top: 5px;
}

.input-box {
  outline: none;
  padding: 10px;
  border-radius: 6px;
  transition: 0.4s;
  display: flex;
  justify-content: start;
  align-items: center;
  border: 2px solid black;
}

.example-viewport {
  height: 100vh;
  width: 60%;
  margin: 0 auto;
  overflow-x: hidden;
}

.filepond--item-panel {
  background-color: transparent !important;
  box-shadow: none !important;
}

.post-settings.web .modal-wrapper {
  top: 62% !important;
  bottom: unset;
}

.post-settings {
  background: rgba(0, 0, 0, 0.2509803922);
}
.post-settings .modal-wrapper {
  position: absolute;
  height: 380px !important;
  max-width: 100%;
  top: unset !important;
  bottom: 0;
}

app-post-action-sheet ion-card {
  padding: 0;
  margin: 0;
}
app-post-action-sheet ion-card .post-settings-row {
  border-bottom: 1px solid var(--ion-border-color);
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 18px;
}
app-post-action-sheet ion-card .post-settings-row .post-popup .post-settings-col {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid var(--ion-border-color);
}
app-post-action-sheet ion-card .post-settings-row .post-popup p {
  color: var(--ion-white-color);
  padding: 0;
  margin: 10px 0px;
  font-size: 16px;
  line-height: 20px;
}
app-post-action-sheet ion-card .post-settings-texts {
  color: var(--ion-white-color);
  font-size: 16px;
  font-weight: 100;
}
app-post-action-sheet ion-card .post-settings-texts .disable_follow {
  pointer-events: none !important;
}
app-post-action-sheet ion-card .post-settings-texts .disable_follow p {
  color: #666 !important;
}
app-post-action-sheet ion-card .post-settings-texts p {
  font-size: 16px;
  line-height: 20px;
  padding: 0 0px 10px;
  margin: 20px 15px 0;
  cursor: pointer;
}

.post-settings-col {
  display: inline-block;
}

.userProfile {
  padding-top: 10%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.user_postlist {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

button.vjs-fullscreen-control.vjs-control.vjs-button,
.vjs-progress-control.vjs-control,
button.vjs-play-control.vjs-control.vjs-button.vjs-paused,
button.vjs-play-control.vjs-control.vjs-button.vjs-playing {
  display: none !important;
}

.remove_cancel .action-sheet-group-cancel {
  display: none;
}

.detail-width {
  width: 30%;
}

.details {
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  width: 30% !important;
}
.details .required-details-list {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  width: max-content;
  overflow-x: hidden;
  overflow-y: hidden;
  transform: scale(0.98);
}
.details .required-details-list .required-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 1.25rem;
  position: relative;
}

.frame_img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.button-view ion-icon {
  width: 25px;
  height: 25px;
  padding-left: 15px;
}

.category-group {
  color: #fff;
  text-transform: capitalize;
}
.category-group .selected {
  color: var(--ion-theme-color) !important;
}

img {
  display: block;
}

.container {
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 15px;
}

/* Slider */
.circle_top {
  background: #ff9b20;
  width: 200px;
  height: 200px;
  position: fixed;
  top: -15%;
  right: -25%;
  border-radius: 50%;
  z-index: 99999;
}

.slider_section .top_position {
  position: absolute;
  z-index: 99999;
  margin-top: 50px;
  text-transform: capitalize;
  font-size: 18px !important;
}
.slider_section .slide_images {
  display: block;
  width: 100%;
}
.slider_section .bottom_content {
  position: relative;
  bottom: 0;
  width: 100%;
}
.slider_section .slide_content {
  background: #4e4e4e;
  display: block;
  width: 100%;
  padding: 40px 30px;
  text-align: left;
}
.slider_section .slide_content h2 {
  margin: 0;
}
.slider_section .slide_content h6 {
  font-size: 20px;
  font-weight: 500;
  margin: 0px 0px 15px 0px;
}
.slider_section .slide_content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #d1d1d1;
  margin-bottom: 50px;
}
.slider_section .slide_img {
  margin-top: 0;
  margin-bottom: 15px;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.slider_section .slide_button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 7px;
  z-index: 999;
  right: 0;
}
.slider_section .slide_button ion-button {
  margin-top: 0;
  text-transform: capitalize;
  font-size: 18px !important;
}
.slider_section .slide_button ion-button::part(native) {
  line-height: 25px;
}
.slider_section .slide_button ion-button ion-icon {
  font-size: 16px !important;
  color: var(--ion-theme-color) !important;
}

.ion-color-orange-text {
  color: #ff9b20;
}
.ion-color-orange-text::part(native) {
  color: unset;
}

ion-footer .grey_bg {
  background: #4e4e4e;
}

ion-searchbar {
  padding: 0px 15px !important;
}
ion-searchbar .searchbar-input-container .searchbar-input {
  box-shadow: unset;
  background-color: var(--ion-item-background-black);
  color: var(--ion-text-color);
  border-radius: 5px;
  height: 40px;
  width: 100%;
}
ion-searchbar .searchbar-input-container .searchbar-search-icon {
  color: var(--ion-icon-color) !important;
}

ion-modal ionic-selectable-modal ion-header {
  padding: 15px 0px !important;
}
ion-modal ionic-selectable-modal ion-header ion-toolbar {
  padding: 0px 0px 15px;
}
ion-modal ionic-selectable-modal ion-header ion-toolbar .popup_header {
  display: flex;
  align-items: center;
}
ion-modal ionic-selectable-modal ion-header .header {
  padding: 0 15px 15px !important;
}
ion-modal ionic-selectable-modal ion-header .header ion-title {
  float: right;
  text-align: left;
  width: 88%;
  text-transform: capitalize;
}
ion-modal ionic-selectable-modal ion-content ion-list ion-item-group .ionic-selectable-item-is-selected {
  background-color: var(--ion-theme-color);
}
ion-modal ionic-selectable-modal ion-content ion-list ion-item-group .ionic-selectable-item-is-selected ion-icon {
  fill: var(--ion-text-color);
}
ion-modal app-message-detail ion-header app-page-header .header_btns ion-row .backButton {
  max-width: 15% !important;
}
ion-modal app-message-detail ion-content {
  --padding-start: 16px;
  --padding-end: 16px;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row {
  height: auto;
  align-items: flex-start;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row.comment_footer {
  align-items: center !important;
  color: #8f8f8f;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row.user_text {
  flex-direction: column;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row .header_title {
  text-align: end;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row .message_text {
  margin-top: 10px;
  width: 100% !important;
  max-width: 100% !important;
  padding: 0;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row .cmt_options {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-bottom: auto;
  margin-top: auto;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col.menu_icon_comment {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3px;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col.profile_time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  padding: 0;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col.profile_time .time {
  line-height: 20px;
  width: 30px;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col ion-icon {
  font-size: 16px !important;
  color: #8f8f8f;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .heart_icon {
  position: relative;
  transform: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .heart_icon a24-button-heart .button {
  margin-right: 0;
  line-height: 0;
  top: 48%;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .heart_icon a24-button-heart .button.dead {
  top: 50% !important;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .heart_icon p {
  margin: 0;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .heart_like_section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  flex-direction: column;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .heart_like_section label {
  height: 38px;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col app-c-avatar {
  -webkit-margin-end: unset !important;
  margin-inline-end: unset !important;
  width: 100% !important;
  height: 100% !important;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .profile_comment_message {
  width: 100%;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .profile_comment_message .profile_name_time p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: var(--ion-theme-color);
  line-height: 5px;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .profile_comment_message .profile_name_time .profile_time span {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #9d9d9d;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .profile_comment_message .comment_message {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .profile_comment_message .comment_message .message-container {
  background: unset !important;
  padding: 0 !important;
  max-width: 100% !important;
  margin-top: 5px;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .profile_comment_message .comment_message .message-container span {
  display: block;
  text-align: justify;
  line-height: 20px;
}
ion-modal app-message-detail ion-content .mesage_detail_page_comment app-user-message .message-user ion-row ion-col .profile_comment_message .comment_message .message-container span::after {
  content: unset;
}

app-image-edit {
  height: 100vh;
}
app-image-edit ion-content .edit_cancel_btn {
  position: absolute;
  z-index: 9999;
  top: 4%;
  right: 7%;
}
app-image-edit ion-content .edit_cancel_btn ion-icon {
  font-size: 35px !important;
}
app-image-edit ion-content .croppie-container {
  height: auto !important;
}
app-image-edit ion-content .croppie-container .cr-boundary {
  width: 100% !important;
  height: 75vh !important;
}
app-image-edit ion-content .croppie-container .cr-boundary .cr-viewport.cr-vp-square {
  height: 100% !important;
  border: 2px solid #404040 !important;
}
app-image-edit ion-content .croppie-container .cr-slider-wrap {
  width: 100% !important;
  padding: 17px 5px 20px;
  margin: 0 !important;
  text-align: center;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-image-edit ion-content .action_filed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px 10px;
}
app-image-edit ion-content .image_action_btn ion-row {
  margin-bottom: 15px;
  padding: 0 20px;
}
app-image-edit ion-content .image_action_btn ion-row ion-col ion-button {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
app-image-edit ion-content .image_action_btn ion-row ion-col ion-button::part(native) {
  box-shadow: none !important;
  text-transform: capitalize;
  height: 50px;
  font-size: 16px;
}

app-message-detail ion-footer ion-toolbar {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 5px 10px 20px !important;
}
app-message-detail ion-footer ion-toolbar ion-textarea {
  background-color: var(--ion-background-color) !important;
  height: 50px;
  border-radius: 70px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}
app-message-detail ion-footer ion-toolbar ion-textarea .textarea-wrapper {
  width: 100%;
  height: 50px !important;
}
app-message-detail ion-footer ion-toolbar ion-textarea .textarea-wrapper textarea {
  height: 100% !important;
  color: var(--ion-color-base);
  padding: 0 60px !important;
  line-height: 50px;
}
app-message-detail ion-footer ion-toolbar app-c-avatar {
  position: absolute;
  z-index: 999;
  bottom: 3px;
  left: 4px;
}
app-message-detail ion-footer ion-toolbar app-c-avatar .user_profile ion-avatar {
  width: 40px;
  height: 40px;
}
app-message-detail ion-footer ion-toolbar .send_icon {
  position: absolute;
  z-index: 999;
  right: 45px;
  bottom: 10px;
  font-size: 30px;
  color: var(--ion-theme-color);
  width: auto;
  height: 25px;
}

.user_profile ion-avatar {
  box-shadow: -3px 3px 6px 1px rgba(12, 12, 12, 0.0901960784);
  width: 40px;
  height: 40px;
  position: relative;
  background: #ff9b20;
}
.user_profile ion-avatar ion-thumbnail ion-img::part(image) {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  object-fit: cover;
  object-position: top center;
}
.user_profile ion-avatar span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 24px;
  font-weight: 400;
}

.page_content {
  padding: 25px 40px;
  height: calc(100vh - 94px);
  overflow-y: scroll;
  margin-top: 94px;
}

.page_content.admin_all_post {
  margin-left: 0 !important;
}

ng2-smart-table .custom-smart-table .top-pagination .pagination-bottom {
  bottom: -75px !important;
  right: 8% !important;
}

ng2-smart-table .custom-smart-table {
  padding: 35px 35px 10px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 2px 0 rgba(12, 12, 12, 0.0784313725);
  z-index: 99;
  margin: auto 25px;
}
ng2-smart-table .custom-smart-table table thead {
  margin: 10px;
  border-width: 5px;
  border-radius: 5px;
}
ng2-smart-table .custom-smart-table table tbody tr td {
  word-break: break-word;
  text-align: left !important;
  text-transform: capitalize !important;
}
ng2-smart-table .custom-smart-table .top-pagination .pagination-top {
  display: none;
}
ng2-smart-table .custom-smart-table .top-pagination .pagination-bottom {
  position: absolute;
  bottom: -86px;
  right: 0px;
  background: #fff;
  height: 44px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 99999;
}
ng2-smart-table .custom-smart-table .top-pagination .pagination-bottom ul {
  padding: 0;
}
ng2-smart-table .custom-smart-table .top-pagination .pagination-bottom ul span {
  padding-right: 5px;
  padding-left: 5px;
}
ng2-smart-table .custom-smart-table .top-pagination .pagination-bottom .page-item {
  display: inline-block !important;
  margin: 0 5px;
  width: 24px;
  height: 24px;
  position: relative;
}
ng2-smart-table .custom-smart-table .top-pagination .pagination-bottom .page-item.active {
  background: #00ca80;
  border-radius: 50%;
}
ng2-smart-table .custom-smart-table .top-pagination .pagination-bottom .page-item.active .page-link {
  color: #fff;
}

.theme_green_btn {
  --background: #00ca80;
  color: #f3fdf9;
  border-radius: 6px;
  border: unset;
}

.search_bar {
  position: relative;
  padding: 0px;
}
.search_bar ion-searchbar {
  padding: 0;
  height: 44px;
  --border-radius: 5px;
  --border-top-right-radius: 0 !important;
  --border-bottom-right-radius: 0 !important;
  --box-shadow: -1px 2px 5px 0 #0c0c0c14;
}
.search_bar ion-searchbar input {
  padding-inline-start: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
  width: 90%;
}
.search_bar ion-searchbar .searchbar-search-icon {
  position: absolute;
  right: 14px !important;
  left: auto;
  z-index: 9998;
  color: #fff;
}
.search_bar ion-searchbar .searchbar-clear-button {
  right: 55px !important;
}
.search_bar .button-holder {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #af0000;
  width: 48px;
  height: 44px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  --box-shadow: -1px 2px 5px 0 #0c0c0c14;
}
.search_bar .button-holder ion-icon {
  display: none;
}

.user-list {
  height: 60px;
  padding: 5px;
  display: block;
}

.vid-close {
  position: relative;
  top: 40px;
  left: 12px;
  z-index: 999;
}

.vid_mute {
  position: relative;
  top: 40px;
  left: 88%;
  z-index: 999;
}

/* Gallery Section */
.gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 80px;
  justify-content: flex-start;
}
.gallery .gallery-item {
  position: relative;
  width: 22%;
  margin: 11px;
}
.gallery .gallery-item app-post-slides ion-card {
  padding: 0;
  margin: 0 10px;
}
.gallery .gallery-item app-post-slides ion-card .meanu_icon {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--ion-post-icon-bg);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
  z-index: 9999;
}
.gallery .gallery-item app-post-slides ion-card .meanu_icon ion-buttons ion-button ion-icon {
  font-size: 20px !important;
}
.gallery .gallery-item .meanu_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--ion-post-icon-bg);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
}
.gallery .gallery-item .meanu_icon ion-buttons ion-button ion-icon {
  font-size: 20px !important;
}
.gallery .gallery-item .icons {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
}
.gallery .gallery-item .icons ion-icon {
  color: var(--ion-color-base) !important;
}
.gallery .gallery-item .icons ion-icon:first-child {
  margin-right: 10px;
}
.gallery .gallery-item .media {
  width: 100%;
  height: 100%;
}
.gallery .gallery-item .media app-video-play {
  width: 100%;
}
.gallery .gallery-item .media app-video-play .video-js {
  border-radius: 13px;
  height: 100% !important;
}
.gallery .gallery-item .media app-video-play .video-js .vjs-poster {
  border-radius: 13px;
}
.gallery .gallery-item .media app-video-play .video-js video {
  border-radius: 13px;
  object-fit: cover;
}
.gallery .gallery-item .media ion-img {
  height: 100%;
}
.gallery .gallery-item .media ion-img::before {
  content: "";
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 13px !important;
  margin: 1px;
  background: linear-gradient(to right, #f1f0f0, #c1c0c0, #f1f0f0);
  background-size: 200%;
  background-position: left;
}
@keyframes load {
  0%, 100% {
    background-position: right;
  }
  50% {
    background-position: left;
  }
}
.gallery .gallery-item .media ion-img::part(image) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 13px;
}
.gallery .gallery-item .media .video_uploading {
  width: auto;
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff9b21;
  border-radius: 10px;
}
.gallery .gallery-item .media .video_uploading ion-icon {
  height: 55px;
  width: 55px;
}

/* Loader */
.loader {
  width: 5rem;
  height: 5rem;
  border: 0.6rem solid #999;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin: 0 auto;
  animation: loader 500ms linear infinite;
}

ion-action-sheet .action-sheet-wrapper .action-sheet-container .action-sheet-group {
  background: var(--ion-item-background-black);
}
ion-action-sheet .action-sheet-wrapper .action-sheet-container .action-sheet-group button span {
  color: var(--ion-text-color);
}
ion-action-sheet .action-sheet-wrapper .action-sheet-container .action-sheet-group button span ion-icon {
  color: var(--ion-text-color);
}

.toast-custom-class {
  --ion-color-base: var(--ion-theme-color) !important;
}

ion-action-sheet.source_list .action-sheet-wrapper .action-sheet-container .action-sheet-group {
  background: var(--ion-item-background-black);
}
ion-action-sheet.source_list .action-sheet-wrapper .action-sheet-container .action-sheet-group button span {
  color: var(--ion-text-color);
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}
ion-action-sheet.source_list .action-sheet-wrapper .action-sheet-container .action-sheet-group button.flipkart_icon span ion-icon {
  content: url(/assets/svg/settings/flipkart.svg);
}
ion-action-sheet.source_list .action-sheet-wrapper .action-sheet-container .action-sheet-group button.walmart_icon span ion-icon {
  content: url(/assets/svg/settings/walmart.svg);
}
ion-action-sheet.source_list .action-sheet-wrapper .action-sheet-container .action-sheet-group button.is_selected {
  background-color: var(--ion-theme-color);
}

ion-content ion-datetime {
  --background: var(--ion-background-color) !important;
}

ion-modal ion-content ion-list ion-item::part(native) {
  color: #fff;
}
ion-modal ion-content ion-list ion-item::part(native):hover {
  color: #fff;
}
ion-modal ion-footer ion-button::part(native) {
  --ion-toolbar-color: var(--ion-theme-color);
  --ion-toolbar-background: var(--ion-text-color);
}

add-post ion-item,
app-app-settings ion-item,
app-change-password ion-item,
app-add-profile ion-item,
app-edit-profile ion-item {
  margin: 15px 0 !important;
}

add-post .custom_time_filed {
  margin: 0 !important;
}

app-add-product ion-item {
  margin: 0 !important;
}
app-add-product ion-item.custom_time_filed {
  --background: var(--ion-item-background-black);
  border-radius: var(--border-radius-s);
  --padding-top: 5px !important;
  --padding-start: 5px !important;
  opacity: 1 !important;
  color: var(--ion-white-color);
  z-index: 9;
  font-size: 14px !important;
  font-weight: 300;
  --min-height: 55px !important;
  cursor: pointer;
}
app-add-product ion-item.custom_time_filed .form_group.set_time .set_time_info .time_info p {
  margin-top: 15px;
  margin-left: 10px;
}
app-add-product ion-item.custom_time_filed .form_group.set_time .set_time_info .up_text ion-label {
  margin-top: -15px;
}
app-add-product ion-item.custom_time_filed .form_group.set_time ion-icon {
  position: absolute;
  right: 10px;
  top: 17px;
  font-size: 15px !important;
}

/* Login & Register Page */
ion-modal app-login ion-grid {
  overflow: scroll;
}
ion-modal app-login ion-grid ion-row {
  margin: 15px 0;
}
ion-modal app-login ion-grid ion-row ion-col .logo {
  justify-content: center !important;
}

.login_page,
app-register {
  position: relative;
}
.login_page ion-grid,
app-register ion-grid {
  --ion-margin: 0 !important;
  height: 100%;
  padding: 0px 15px !important;
}
.login_page ion-grid ion-row,
app-register ion-grid ion-row {
  height: 100%;
  display: block;
  position: relative;
}
.login_page ion-grid ion-row ion-col,
app-register ion-grid ion-row ion-col {
  padding: 0;
}
.login_page ion-grid ion-row ion-col .logo,
app-register ion-grid ion-row ion-col .logo {
  margin: 0px auto 10px;
  width: 130px;
}
.login_page ion-grid ion-row ion-col .logo ion-icon,
app-register ion-grid ion-row ion-col .logo ion-icon {
  font-size: 35px !important;
  height: 65px;
}
.login_page ion-grid ion-row ion-col .logo p,
app-register ion-grid ion-row ion-col .logo p {
  color: var(--ion-theme-color);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.login_page ion-card,
app-register ion-card {
  margin-top: 20px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  box-shadow: none;
  background: unset !important;
}
.login_page ion-card ion-item,
app-register ion-card ion-item {
  margin: 10px 0 15px;
  border-radius: 5px;
  border-bottom: none !important;
  --border-color: $light-grey;
  --inner-border-width: 0 !important;
}
.login_page ion-card ion-item::part(native),
app-register ion-card ion-item::part(native) {
  --border-color: $light-grey;
}
.login_page ion-card ion-item ion-icon,
app-register ion-card ion-item ion-icon {
  color: #afafaf;
  font-size: 18px !important;
}
.login_page ion-card ion-item a24-input,
app-register ion-card ion-item a24-input {
  width: 100%;
}
.login_page ion-card ion-item a24-input ion-input input,
app-register ion-card ion-item a24-input ion-input input {
  height: 60px;
  padding: 0;
  color: #afafaf !important;
}
.login_page .forgot_pw,
app-register .forgot_pw {
  margin: 15px 0px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.login_page ion-button,
app-register ion-button {
  text-transform: capitalize;
  margin: 0px;
  font-size: 14px;
}
.login_page ion-button.sign_btns,
app-register ion-button.sign_btns {
  height: 60px;
  margin: 20px 0px 0 !important;
  box-shadow: none;
}
.login_page ion-button.sign_btns::part(native),
app-register ion-button.sign_btns::part(native) {
  box-shadow: none;
}
.login_page ion-button.guest_btn,
app-register ion-button.guest_btn {
  --background: var(--ion-active-label-color);
  height: 60px;
  margin: 20px auto 0 !important;
}
.login_page ion-button.guest_btn::part(native),
app-register ion-button.guest_btn::part(native) {
  box-shadow: none;
}
.login_page .social_login span,
app-register .social_login span {
  font-size: 25px;
  display: inline-block;
  margin: 0px 10px;
  color: #d6d6d6;
}
.login_page .or_continue,
app-register .or_continue {
  margin: 20px 0px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login_page .or_continue p,
app-register .or_continue p {
  margin: 0;
  font-size: 16px;
}
.login_page .or_continue .sideline,
app-register .or_continue .sideline {
  width: 110px;
  display: block;
}
.login_page .or_continue .sideline .left,
app-register .or_continue .sideline .left {
  float: left;
}
.login_page .or_continue .sideline .right,
app-register .or_continue .sideline .right {
  float: right;
}
.login_page .social_login,
app-register .social_login {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 25px;
  cursor: pointer;
}
.login_page .social_login span,
app-register .social_login span {
  display: flex;
  align-items: center;
  width: 180px;
  justify-content: center;
  padding: 8px 0px;
  border: 1px solid var(--ion-white-color);
  border-radius: 4px;
}
.login_page .social_login span p,
app-register .social_login span p {
  margin: 0;
  margin-left: 15px;
}
.login_page .spacer,
app-register .spacer {
  height: 25px;
  display: block;
}
.login_page .sign_bottom,
app-register .sign_bottom {
  padding-bottom: 30px;
  position: relative;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}
.login_page .sign_bottom p,
app-register .sign_bottom p {
  margin: 0;
  font-size: 16px;
}
.login_page .sign_bottom .transparent_txt_btn,
app-register .sign_bottom .transparent_txt_btn {
  color: #ff9b20;
}

app-forgot {
  padding: 0px 15px;
}
app-forgot ion-header ion-toolbar {
  padding: 0;
}
app-forgot ion-content ion-card {
  margin: 0px !important;
}
app-forgot ion-content ion-button {
  text-transform: capitalize;
  margin: 20px 0px !important;
}
app-forgot ion-content ion-button.sign_btns {
  width: 100%;
  height: 60px;
}

ion-buttons.back_btns ion-back-button {
  border: 1px solid var(--ion-white-color);
  color: #fff;
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 50%;
}

app-web-header {
  position: relative;
  z-index: 9999;
  top: 0;
  width: 100%;
  margin: 0 auto;
}
app-web-header .header_row {
  margin: 8px 0 0;
}
app-web-header .header_row .profile_btn {
  padding: 5px 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-web-header .header_row .home_icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
app-web-header .header_row .all_icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
app-web-header .header_row .logo_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-web-header .header_row .logo_icon ion-icon {
  height: 40px;
  width: 100%;
}

.header_home_post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

ion-split-pane {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 15px !important;
}
ion-split-pane.has_header {
  margin-top: 80px !important;
}

.split-pane-side {
  z-index: 999999;
  position: absolute;
  right: 0;
  left: 10%;
  width: 400px;
  border: 0;
}
.split-pane-side div {
  width: 100%;
}
.split-pane-side.has_not_side_pane {
  display: none;
}

ion-router-outlet {
  width: 100%;
  margin: 0 auto;
}

/* inner header */
ion-header {
  padding: 15px 15px 3px 15px;
  z-index: 99999;
  --background: var(--ion-web-background-color) !important;
  border: unset;
}
ion-header:after {
  display: none;
}
ion-header ion-toolbar {
  padding: 0px;
  --min-height: 40px;
  --border-style: unset !important ;
}
ion-header ion-toolbar ion-searchbar {
  --box-shadow: unset !important;
}
ion-header ion-toolbar ion-icon {
  font-size: 33px !important;
}
ion-header ion-toolbar.toolbar-segment {
  display: none !important;
}
ion-header ion-toolbar .input-box {
  width: 0%;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
ion-header ion-toolbar .search_box {
  padding: 0;
}
ion-header ion-toolbar .search_box .search_extend {
  position: relative;
}
ion-header ion-toolbar .search_box .search_extend .input-box {
  --padding-top: 0px;
  --padding-bottom: 0px;
  padding: 0 !important;
  border: 0px !important;
  width: 100%;
}
ion-header ion-toolbar .search_box .search_extend .input-box ion-input {
  --background: var(--ion-card-background-color);
  border-radius: 6px;
  --padding-start: 40px;
}
ion-header ion-toolbar .search_box .search_extend .input-box ion-input ion-icon {
  position: absolute;
  top: 8px;
  left: 5px;
}
ion-header ion-toolbar .search_box .search_extend ion-button {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 3px;
  width: 33px;
  height: 33px;
  z-index: 99;
}
ion-header ion-toolbar .search_box .search_extend ion-button::part(native) {
  padding: 0 !important;
}

cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
  margin: 0 auto;
  width: 54% !important;
  min-width: 54% !important;
  top: 0;
  left: 0;
  contain: content;
  right: 0;
}
cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper app-feed-post ion-card {
  margin: 0 !important;
}
cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper app-feed-post ion-card ion-slides {
  height: 406px;
}
cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper app-feed-post ion-card ion-slides .video-js.vjs-1-1:not(.vjs-audio-only-mode),
cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper app-feed-post ion-card ion-slides .video-js.vjs-16-9:not(.vjs-audio-only-mode),
cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper app-feed-post ion-card ion-slides .video-js.vjs-4-3:not(.vjs-audio-only-mode),
cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper app-feed-post ion-card ion-slides .video-js.vjs-9-16:not(.vjs-audio-only-mode),
cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper app-feed-post ion-card ion-slides .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 0 !important;
  height: 100% !important;
}

.feed_scroll {
  height: auto;
  width: 100%;
  margin: 0 auto;
}

/* Card Lists */
ion-card.card_lists {
  box-shadow: unset !important;
  position: relative;
  margin-top: 0 !important;
  padding: 0px;
  background-color: var(--ion-web-background-color) !important;
}
ion-card.card_lists ion-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: var(--ion-white-color);
  width: 100%;
  margin: 0 auto;
  --min-height: 32px;
  padding: 5px 4px 10px 16px;
}
ion-card.card_lists ion-item ion-buttons .heart_like_section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
ion-card.card_lists ion-item ion-buttons .heart_like_section label ion-icon {
  font-size: 40px !important;
  padding-top: 5px;
}
ion-card.card_lists ion-item ion-avatar {
  width: 42px;
  height: 42px;
  border: 1px solid var(--ion-white-color);
  color: var(--ion-white-color);
  text-align: center;
  line-height: 43px;
}
ion-card.card_lists ion-item .post_details {
  width: 90%;
  padding-left: 14px;
}
ion-card.card_lists ion-item .post_details .post_title {
  font-size: 14px;
  padding: 0;
  margin: 0;
  line-height: 20px;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.card_lists ion-item .post_details .post_texts {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
ion-card.card_lists ion-item .post_details .post_texts .post_user {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.card_lists ion-item .post_details .post_texts .views {
  margin-left: 5px;
}
ion-card.card_lists ion-item ion-button::part(native) {
  color: var(--ion-white-color);
  padding: 0px 10px;
}
ion-card.card_lists ion-card {
  margin: 0;
  box-shadow: unset !important;
  border-radius: unset;
}
ion-card.card_lists ion-card ion-slides ion-slide {
  width: 100% !important;
  margin: 0 auto !important;
}
ion-card.card_lists ion-card ion-slides ion-slide ion-img::part(image) {
  height: 210px !important;
  object-fit: cover !important;
}
ion-card.card_lists ion-card ion-slides .swiper-pagination.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  right: 20px;
  margin: 0 auto;
  width: 92%;
  text-align: right;
}
ion-card.card_lists .bottom_btns {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--ion-border-color);
  padding: 0 10px !important;
  margin-left: 0 !important;
}
ion-card.card_lists .bottom_btns ion-buttons.right_side_icon {
  margin-left: 0;
}
ion-card.card_lists .bottom_btns ion-buttons .bookmark ion-icon {
  font-size: 19px !important;
}
ion-card.card_lists .bottom_btns ion-buttons ion-button {
  height: 50px;
}
ion-card.card_lists .bottom_btns ion-buttons ion-button ion-icon {
  font-size: 22px;
  margin-inline-end: 0;
}
ion-card.card_lists .bottom_btns ion-buttons ion-button.post_footer_right {
  width: 100%;
}
ion-card.card_lists .bottom_btns ion-buttons .heart_icon {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-card.card_lists .bottom_btns ion-buttons .heart_icon a24-button-heart .button {
  position: relative;
  top: unset;
  left: unset;
  margin-right: unset;
  transform: unset;
  width: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  transition: 0.4s;
  outline: 0;
  text-align: center;
  color: #fff;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
ion-card.card_lists .bottom_btns ion-buttons .heart_icon a24-button-heart .button.button.dead .sw {
  left: 50% !important;
  margin-right: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}
ion-card.card_lists .bottom_btns ion-buttons .heart_icon p {
  margin-left: 4px !important;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
}
ion-card.card_lists .bottom_btns ion-buttons p {
  font-weight: normal;
}
ion-card.card_lists .bottom_btns ion-buttons .placement {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50px;
  width: auto;
}
ion-card.card_lists .bottom_btns ion-buttons .placement .heart_count {
  display: flex;
  height: 100%;
}
ion-card.card_lists .bottom_btns ion-buttons .placement .heart_count p {
  position: absolute;
  right: 5px;
  margin-left: 5px;
}

.feed_scroll_first {
  height: 150px;
  width: auto;
}
.feed_scroll_first ion-slide {
  height: auto;
  object-fit: contain !important;
}
.feed_scroll_first ion-slide img {
  height: 392px !important;
  object-fit: contain !important;
}

.feed_scroll {
  height: 520px;
  width: auto;
  margin: 0px auto;
}
.feed_scroll app-feed-post ion-card {
  margin: 0 !important;
  width: 100vw;
}
.feed_scroll app-feed-post ion-card ion-item ion-buttons ion-button::part(native) {
  padding: 0;
}
.feed_scroll app-feed-post ion-card ion-item ion-buttons ion-button ion-icon {
  float: left;
  width: 24px;
}
.feed_scroll app-feed-post ion-card ion-item ion-buttons ion-button p {
  margin-left: 4px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feed_scroll ion-slide {
  height: auto;
  object-fit: contain !important;
}
.feed_scroll ion-slide img {
  height: 406px !important;
  object-fit: contain !important;
  cursor: pointer;
}

.people_suggestion {
  height: 330px;
  width: auto;
  display: flex;
  align-items: center;
}

app-feed-post ion-card {
  margin: 0 !important;
  width: 100%;
}
app-feed-post ion-card ion-item ion-buttons ion-button::part(native) {
  padding: 0;
}
app-feed-post ion-card ion-item ion-buttons ion-button ion-icon {
  float: left;
  width: 24px;
}
app-feed-post ion-card ion-item ion-buttons ion-button p {
  margin-left: 4px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

app-project-detail-modal ion-content {
  --offset-bottom: 0px !important;
}
app-project-detail-modal ion-content .project_transparent_text .header ion-icon {
  margin-bottom: 15px;
}
app-project-detail-modal ion-content .project_transparent_text h3 {
  margin: 15px 0;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
}
app-project-detail-modal ion-content .project_transparent_text .space {
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
}
app-project-detail-modal ion-content .project_transparent_text .space::after {
  content: "";
  width: 200px;
  height: 10px;
  border-bottom: 1px solid #5c5c58;
  position: absolute;
  right: 0;
}
app-project-detail-modal ion-content .project_transparent_text .space h4 {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 8px;
}
app-project-detail-modal ion-content .project_transparent_text .space .detail_box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
app-project-detail-modal ion-content .project_transparent_text .space .detail_box p {
  margin: 0;
  line-height: 30px;
}
app-project-detail-modal ion-content .project_transparent_text .space .detail_box ion-icon {
  margin-left: 10px;
  font-size: 18px !important;
  pointer-events: none;
}
app-project-detail-modal ion-content .project_transparent_text .space .text_capital {
  font-size: 14px;
  font-weight: 100;
}
app-project-detail-modal ion-content .project_transparent_text .non-sapce {
  padding-bottom: 10px;
  margin-bottom: 20px;
}
app-project-detail-modal ion-content .project_transparent_text .non-sapce h4 {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 8px;
}
app-project-detail-modal ion-content .project_transparent_text .non-sapce .detail_box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 100;
  cursor: pointer;
}
app-project-detail-modal ion-content .project_transparent_text .non-sapce .detail_box ion-icon {
  margin-left: 10px;
  font-size: 18px !important;
}

app-post-detail cdk-virtual-scroll-viewport .list-group-item .post_detail_main_page app-post-header ion-item {
  padding: 5px 0px 10px 0px !important;
  --padding-start: 10px;
}
app-post-detail cdk-virtual-scroll-viewport .list-group-item .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide {
  min-width: 70px !important;
}
/*app-post-detail*/
.project_details_modal {
  --background: transparent !important;
  --width: 100%;
}
.project_details_modal ion-content {
  --background: transparent !important;
}
.project_details_modal ion-content .project_transparent_text {
  padding: 0;
  text-align: right;
  position: relative;
  top: 15%;
  right: 7%;
  width: 46%;
  margin: 0 auto;
}
.project_details_modal ion-content .header {
  text-align: center;
  padding: 30px 0;
  position: absolute;
  bottom: 5%;
  cursor: pointer;
  width: 100%;
}

app-post-detail ion-header {
  padding-bottom: 15px;
}
app-post-detail ion-content .full_post_detail_page ion-row {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0px 15px;
  align-items: flex-end;
}
app-post-detail ion-content .full_post_detail_page ion-row ion-col .heart_like_section label svg {
  width: 50px;
  margin-top: 6px;
  margin-left: -8px;
}
app-post-detail ion-content .full_post_detail_page ion-row ion-col .heart_like_section span {
  margin-left: -10px;
}
app-post-detail ion-content .full_post_detail_page ion-row ion-col p {
  font-size: 20px;
  line-height: 30px;
  padding: 0;
  margin: 8px 0;
}
app-post-detail ion-content .full_post_detail_page ion-row ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide {
  justify-content: flex-start;
  max-width: 35% !important;
  width: 35% !important;
}
app-post-detail ion-content .full_post_detail_page ion-row ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img {
  margin-top: 0;
  margin-bottom: 0;
}
app-post-detail ion-content .full_post_detail_page ion-row ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img img {
  width: 75px;
  height: 75px;
}
app-post-detail ion-content .full_post_detail_page ion-row ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 {
  border-radius: 3px;
  padding-left: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-post-detail ion-content .full_post_detail_page ion-row ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 span {
  height: 30px;
  width: 30px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}
app-post-detail ion-footer ion-toolbar {
  background-color: var(--ion-background-color);
}
app-post-detail ion-footer ion-toolbar ion-buttons .heart_like_section label svg {
  width: 65px;
}

/* Header */
.header_btns .backButton {
  max-width: 100% !important;
  padding: 0;
  width: 100%;
}
.header_btns .centerButton {
  text-align: right;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-text-color);
}
.header_btns .centerButton ion-icon {
  width: 30px;
  height: 30px;
}
.header_btns .centerButton.skip {
  color: var(--ion-theme-color);
  font-size: 18px;
  text-transform: capitalize;
  z-index: 99999;
}
.header_btns .centerButton.options ion-icon {
  transform: rotate(-90deg);
}
.header_btns .rightButton {
  text-align: right;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--ion-text-color);
}
.header_btns .rightButton ion-icon {
  width: 35px;
  height: 35px;
  margin-left: 10px;
}
.header_btns .rightButton .fit_screen {
  width: 30px;
  height: 30px;
}
.header_btns .rightButton.skip {
  color: var(--ion-theme-color);
  font-size: 18px;
  text-transform: capitalize;
  z-index: 99999;
}
.header_btns .rightButton.options ion-icon {
  transform: rotate(-90deg);
}

.back_btns {
  border: 1px solid var(--ion-white-color);
  color: #fff;
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.back_btns ion-icon {
  display: block;
  margin: auto;
  width: 100%;
  position: absolute;
  font-size: 15px !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Footer */
ion-footer {
  z-index: 99999;
}
ion-footer .d-flex {
  width: 90%;
  margin: 0 auto;
}
ion-footer .d-flex .addPost ion-button {
  margin: 0;
  width: 50px;
  height: 50px;
}
ion-footer .d-flex .addPost ion-button::part(native) {
  --padding-start: 0;
  --padding-end: 0;
}
ion-footer .foo_logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
ion-footer .foo_logo ion-icon {
  font-size: 40px !important;
}
ion-footer ion-toolbar {
  padding: 5px 0px !important;
  --background: var(--ion-background-color) !important;
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.4);
}

button.vjs-big-play-button {
  display: none !important;
}

/* Category */
app-category {
  width: 100%;
  padding: 0;
}
app-category ion-content {
  height: 80%;
}
app-category .header_btns {
  margin: 20px 15px;
}
app-category .categories {
  padding: 0 15px;
}
app-category .categories .categories_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-category .categories .category_row {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 0px 15px;
}
app-category .categories .category_row .selected .category_box {
  background-color: #212121 !important;
  border: 1px solid var(--ion-theme-color) !important;
}
app-category .categories .category_row .selected .category_box ion-icon {
  color: var(--ion-white-color) !important;
  display: none;
}
app-category .categories .category_row .selected .category_box p {
  color: var(--ion-white-color) !important;
}
app-category .categories .category_row .selected .category_box p::before {
  content: "";
  border-left: 0.5px solid var(--ion-white-color) !important;
  padding-left: 10px !important;
  display: none;
}
app-category .categories .category_row .category-col {
  text-align: center;
  margin: 8px 0;
  padding: 0;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
}
app-category .categories .category_row .category-col .category_box {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #5a5a5a;
  margin: 0;
  padding: 10px;
  height: 50px;
  border-radius: 4px;
  width: auto;
}
app-category .categories .category_row .category-col .category_box ion-icon {
  font-size: 24px !important;
  margin-right: 10px;
  display: none;
}
app-category .categories .category_row .category-col .category_box .category-group {
  font-size: 14px;
  margin: 0;
  line-height: 18px;
}
app-category .categories .category_row .category-col .category_box .category-group::before {
  content: "";
  border-left: 0.5px solid #717171;
  padding-left: 10px;
  display: none;
}
app-category .category_btn ion-icon {
  font-size: 16px !important;
  color: var(--ion-theme-color) !important;
}

ion-modal {
  --ion-safe-area-top: 0px;
  --height: 100%;
  --background: var(--ion-web-background-color) !important;
}
ion-modal app-category .header_btns {
  margin-top: 35px;
}
ion-modal app-category .categories {
  padding: 0 15px;
  position: relative;
  margin-bottom: 50px;
}
ion-modal app-category .categories .categories_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ion-modal app-category .categories .category_row {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 0px 15px;
}
ion-modal app-category .categories .category_row .selected .category_box {
  background-color: var(--ion-theme-color) !important;
}
ion-modal app-category .categories .category_row .selected .category_box ion-icon {
  color: var(--ion-white-color) !important;
  display: none;
}
ion-modal app-category .categories .category_row .selected .category_box p {
  color: var(--ion-white-color) !important;
}
ion-modal app-category .categories .category_row .selected .category_box p::before {
  content: "";
  border-left: 0.5px solid var(--ion-white-color) !important;
  padding-left: 10px !important;
  display: none;
}
ion-modal app-category .categories .category_row .category-col {
  text-align: center;
  margin: 8px 0;
  padding: 0;
  height: 50px;
  border-radius: 4px;
}
ion-modal app-category .categories .category_row .category-col .category_box {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--ion-item-background-black);
  margin: 0;
  padding: 10px;
  height: 50px;
  border-radius: 4px;
  width: auto;
}
ion-modal app-category .categories .category_row .category-col .category_box ion-icon {
  font-size: 24px !important;
  margin-right: 10px;
  display: none;
}
ion-modal app-category .categories .category_row .category-col .category_box .category-group {
  font-size: 14px;
  margin: 0;
  line-height: 18px;
}
ion-modal app-category .categories .category_row .category-col .category_box .category-group::before {
  content: "";
  border-left: 0.5px solid #717171;
  padding-left: 10px;
  display: none;
}
ion-modal .category_btn {
  color: var(--ion-theme-color);
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
  margin-right: 20px;
}

.inner_title {
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  margin: 20px 0px;
  padding: 0px 5px;
}

.transparent_txt_btn {
  --background: transparent;
  margin-left: 5px !important;
  height: auto;
  color: var(--ion-theme-color);
  text-transform: capitalize;
  font-size: 18px;
}
.transparent_txt_btn::part(native) {
  height: 24px !important;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: unset;
}

.filepond--media-preview audio,
.filepond--media-preview video {
  width: 100%;
  will-change: transform;
  height: 100%;
  object-position: center;
}

app-login ion-item.item-label-floating a24-label ion-label,
app-register ion-item.item-label-floating a24-label ion-label,
app-forgot ion-item.item-label-floating a24-label ion-label,
app-report-modal ion-item.item-label-floating a24-label ion-label {
  transform: translateY(96%) !important;
}

/* User Profile */
.profile {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
}
.profile app-profile-upload {
  position: absolute;
  top: 20px;
  right: 25px;
  display: block;
  margin: auto;
}
.profile app-profile-upload .filepond--root .filepond--drop-label label .user_picture_icon {
  margin-top: 0px;
  background-color: var(--ion-white-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.profile app-profile-upload .filepond--root .filepond--drop-label label .user_picture_icon ion-icon {
  color: #404040;
  font-size: 18px !important;
  margin-left: 2px;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item {
  top: 28px !important;
  left: -120px !important;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper legend {
  display: none;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--media-preview-wrapper .filepond--media-preview audio,
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--media-preview-wrapper .filepond--media-preview video {
  width: 100%;
  height: 20vw;
  position: relative;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--file-action-button {
  cursor: auto;
  color: #404040;
  border-radius: 50%;
  background-color: #ffffff;
  background-image: none;
  box-shadow: 0 0 rgba(255, 255, 255, 0);
  transition: box-shadow 0.25s ease-in;
  cursor: pointer;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--load-indicator {
  top: -35px;
  position: absolute;
  left: 65px;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--action-remove-item {
  margin-left: 10px;
  margin-top: 0px;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--file-info .filepond--file-info-sub {
  display: none;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--file-info .filepond--file-info-main {
  text-indent: 100%;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--file-info .filepond--file-info-main .filepond--action-edit-item-alt {
  cursor: pointer;
  color: #404040;
  border-radius: 50%;
  background-color: #fff;
  background-image: none;
  box-shadow: 0 0 rgba(255, 255, 255, 0);
  transition: box-shadow 0.25s ease-in;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1px;
  left: 48px;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--file-info .filepond--file-info-main .filepond--action-edit-item-alt svg {
  width: 25px;
  height: 25px;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--action-abort-item-load {
  display: none;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--action-retry-item-load {
  display: none;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--processing-complete-indicator {
  display: none;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .filepond--file-status {
  display: none;
}
.profile app-profile-upload .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--file-wrapper .filepond--file .ilepond--load-indicator {
  display: none;
}
.profile .profile-image {
  width: 110px;
  height: 110px;
  margin: 0 auto 10px;
  position: relative;
}
.profile .profile-image ion-icon {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: unset;
}
.profile .profile-image ion-img {
  width: 110px;
  height: 110px;
}
.profile .profile-image ion-img::part(image) {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
  border: unset;
}
.profile .profile-image img {
  width: 110px;
  border-radius: 50%;
  height: 110px;
  object-fit: cover;
  object-position: top center;
}
.profile .profile-user-name {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: var(--ion-text-color);
  margin: 5px 0px;
}
.profile .account-user-name {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: var(--ion-theme-color);
  margin: 5px 0px;
  justify-content: center;
}
.profile .profile-bio {
  text-align: center;
  height: 50px;
  overflow: scroll;
  width: 80%;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
}
.profile .profile-bio:empty {
  display: none;
}
.profile .profile-stats {
  background: var(--ion-card-background-color);
  padding: 10px;
  border-radius: 7px;
  margin: 20px 0px;
}
.profile .profile-stats ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.profile .profile-stats ul li {
  width: auto;
  text-align: left;
  margin: 0 auto;
  cursor: pointer;
  line-height: 18px;
}
.profile .profile-stats ul li span {
  display: block;
}
.profile .profile-stats ul li.follow span {
  font-weight: 700;
  font-size: 18px;
  line-height: 10px;
}
.profile .profile-stats ul li.follow p {
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  text-align: left;
}
.profile .profile-stats ul li.add_follow .profile-stat-count {
  background: var(--ion-background-color);
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.profile .profile-stats ul li.add_follow .profile-stat-count span {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  width: 100%;
}
.profile .profile-stats ul li.add_follow .profile-stat-count ion-icon {
  font-size: 20px;
  margin-left: 5px;
}
.profile .profile-stats ul li.add_follow .profile-stat-count.disable_follow {
  pointer-events: none !important;
  color: #666 !important;
}
.profile .profile-stats ul li.add_follow .profile-stat-count.disable_follow ion-icon {
  color: #666 !important;
}
.profile .profile-stats ul li.profile_menu_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
}
.profile .profile-stats ul.blocked_account li li.add_follow {
  margin: 0 auto;
}
.profile .profile-stats ul.people_account li.add_follow {
  margin-right: 0;
}
.profile .profile-stats ul.my_profile li.add_follow, .profile .profile-stats ul.my_profile li.profile_menu_btn {
  display: none;
}
.profile .profile-stats ul.private_account .add_follow {
  margin-right: 13%;
}

app-post-detail ion-header {
  background: unset !important;
}
app-post-detail ion-content app-post-slides {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: -9;
  cursor: pointer;
}
app-post-detail ion-content app-post-slides ion-card {
  margin: 0 !important;
  border-radius: unset !important;
}
app-post-detail ion-content app-post-slides ion-card ion-slides .swiper-wrapper ion-slide {
  position: relative;
  overflow: hidden;
}
app-post-detail ion-content app-post-slides ion-card ion-slides .swiper-wrapper ion-slide ion-img::part(image) {
  height: 100vh !important;
  width: 100% !important;
  object-fit: cover !important;
  aspect-ratio: 1/2;
}

.slide_img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  border: 2px solid #ffffff;
  background-color: var(--ion-color-base);
}
.slide_img h4 {
  border-radius: 10px;
  align-items: center;
  padding-left: 5px;
  background-color: #4e4e4e;
}

.uppercase {
  text-transform: uppercase;
}

.trending_skeleton {
  margin-bottom: 20px;
}
.trending_skeleton .container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.trending_skeleton .container .box ion-avatar {
  height: 55px;
  width: 55px;
  margin: 10px;
  border-radius: 50%;
}

.heart {
  height: 66px;
  width: 56px;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/66955/web_heart_animation.png");
  background-size: cover;
  background-position: left;
  margin: 0 auto;
  cursor: pointer;
  margin-left: -9px;
}
.heart.is-active {
  animation: heart-burst 0.8s steps(28) forwards;
  margin-right: -20px;
}

.is-active {
  animation: heart-burst 0.8s steps(28) forwards;
}

@keyframes heart-burst {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
a24-select-component {
  width: 100%;
}

ion-select,
ionic-selectable {
  width: 100%;
  max-width: 100%;
  --padding-start: 0;
  display: block !important;
}
ion-select::part(icon),
ionic-selectable::part(icon) {
  top: 50% !important;
  right: 6%;
  left: auto;
  position: absolute;
}
ion-select.ionic-selectable,
ionic-selectable.ionic-selectable {
  max-width: 100%;
}

a.filepond--credits {
  display: none;
}

.author_name {
  background: turquoise;
  padding: 11px;
  width: 100%;
  text-align: center;
}

.text_capital {
  text-transform: capitalize;
}

app-empty-message {
  min-width: 200px;
  margin: auto;
  color: #fff;
  color: var(--ion-white-color);
  z-index: 999;
  min-height: 300px;
  pointer-events: none;
}
app-empty-message .empty_post_container .empty_post_content {
  margin: auto;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
app-empty-message.empty_profile .empty_post_container .empty_post_content {
  top: 60%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.custom_alert_modal .alert-message {
  color: var(--ion-white-color) !important;
}

.alert-message {
  color: var(--ion-white-color) !important;
}

.follower-suggestion-page .slider {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  align-items: center;
}
.follower-suggestion-page .slider .slide.user_action_list {
  margin: auto 20px;
}

.custom_skeleton .custom_skeleton_square .skeleton_square {
  break-inside: avoid-column;
  width: 250px;
  height: 250px;
}
.custom_skeleton .custom_skeleton_rectangle .skeleton_user_dp {
  border-radius: 50%;
}
.custom_skeleton .custom_skeleton_rectangle div {
  height: 50px;
  width: 90%;
}
.custom_skeleton .custom_skeleton_profile {
  height: 250px;
}
.custom_skeleton .custom_skeleton_profile .skeleton_user_profile_dp {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.custom_skeleton .custom_skeleton_profile div {
  margin-left: 100px;
  height: 150px;
  width: 50%;
}

.pin-container .pin-item {
  margin: 4px;
  margin-bottom: 24px;
}

.profile .display-image {
  width: 100px;
  height: 100px;
  margin: auto;
}
.profile .bio {
  height: 30px;
  border-radius: 8px;
  margin: 20px auto;
  width: 100px;
}
.profile .followers {
  height: 75px;
  border-radius: 8px;
  margin: 20px auto;
  width: 300px;
}

.horizontal_view {
  display: flex;
}

app-all-post ion-header ion-toolbar .search_content,
app-sample ion-header ion-toolbar .search_content {
  display: flex;
  height: 30px;
  align-items: center;
  margin: 10px 0;
  padding: 10px 0;
}
app-all-post ion-header ion-toolbar .search_content .post_box,
app-all-post ion-header ion-toolbar .search_content .people_box,
app-sample ion-header ion-toolbar .search_content .post_box,
app-sample ion-header ion-toolbar .search_content .people_box {
  text-align: center;
  width: 100%;
  cursor: pointer;
}
app-all-post ion-header ion-toolbar .search_content .post_box p,
app-all-post ion-header ion-toolbar .search_content .people_box p,
app-sample ion-header ion-toolbar .search_content .post_box p,
app-sample ion-header ion-toolbar .search_content .people_box p {
  margin: 0;
  position: relative;
  padding: 5px 0;
}
app-all-post ion-header ion-toolbar .search_content .post_box .is_active::after,
app-all-post ion-header ion-toolbar .search_content .people_box .is_active::after,
app-sample ion-header ion-toolbar .search_content .post_box .is_active::after,
app-sample ion-header ion-toolbar .search_content .people_box .is_active::after {
  content: "";
  border-bottom: 2px solid var(--ion-theme-color);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
app-all-post ion-header ion-toolbar .category_section,
app-sample ion-header ion-toolbar .category_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
app-all-post ion-header ion-toolbar .category_section ion-col,
app-sample ion-header ion-toolbar .category_section ion-col {
  margin: 5px 0 20px;
}
app-all-post ion-header ion-toolbar .category_section .category_explore,
app-sample ion-header ion-toolbar .category_section .category_explore {
  width: 30%;
}
app-all-post ion-header ion-toolbar .category_section .category_explore ion-card,
app-sample ion-header ion-toolbar .category_section .category_explore ion-card {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px;
  height: 30px;
  box-shadow: none;
  color: var(--ion-text-color);
  padding: 0;
}
app-all-post ion-header ion-toolbar .category_section .category_explore ion-card ion-icon,
app-sample ion-header ion-toolbar .category_section .category_explore ion-card ion-icon {
  --ion-color-base: #ff9b20 !important;
}
app-all-post ion-header ion-toolbar .category_section .category_explore ion-card .bottom_btns,
app-sample ion-header ion-toolbar .category_section .category_explore ion-card .bottom_btns {
  padding-bottom: unset;
  border-bottom: unset;
}
app-all-post ion-header ion-toolbar .category_section .category_explore::after,
app-sample ion-header ion-toolbar .category_section .category_explore::after {
  border-right: 1.5px solid #fff;
  content: "";
}
app-all-post ion-header ion-toolbar .category_section .category_slider,
app-sample ion-header ion-toolbar .category_section .category_slider {
  width: 70%;
}
app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide:first-child::after,
app-sample ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide:first-child::after {
  content: "";
  width: 0;
  height: 95%;
  position: absolute;
  border: 1px solid var(--ion-white-color);
  top: 0;
  right: -10px;
}
app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .category-group,
app-sample ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .category-group {
  width: 100% !important;
  cursor: pointer;
}
app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .category-group ion-card,
app-sample ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .category-group ion-card {
  margin: 0;
  padding: 0;
  box-shadow: unset;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--ion-item-background-black);
  border-radius: 4px;
}
app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .category-group ion-card .explore_icon,
app-sample ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .category-group ion-card .explore_icon {
  font-size: 24px !important;
  margin-left: 6px;
}
app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .category-group ion-card ion-card-content,
app-sample ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .category-group ion-card ion-card-content {
  height: auto;
  width: max-content;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px;
  color: var(--ion-text-color);
  font-size: 15px;
  padding: 5px;
}
app-all-post ion-header ion-toolbar ion-row,
app-sample ion-header ion-toolbar ion-row {
  align-items: center;
  line-height: 18px;
}
app-all-post ion-header ion-toolbar ion-row ion-col,
app-sample ion-header ion-toolbar ion-row ion-col {
  margin: 0;
  padding: 0;
}
app-all-post ion-header ion-toolbar ion-row ion-col a24-search ion-searchbar,
app-sample ion-header ion-toolbar ion-row ion-col a24-search ion-searchbar {
  padding: 0 !important;
}
app-all-post ion-header ion-toolbar ion-row ion-col .center_text p,
app-sample ion-header ion-toolbar ion-row ion-col .center_text p {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-all-post ion-header ion-toolbar ion-row .post_page_logo,
app-sample ion-header ion-toolbar ion-row .post_page_logo {
  width: 70%;
}
app-all-post ion-header ion-toolbar ion-row .header_home_post,
app-sample ion-header ion-toolbar ion-row .header_home_post {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
app-all-post ion-header ion-toolbar ion-row .header_home_post .profile_btn,
app-sample ion-header ion-toolbar ion-row .header_home_post .profile_btn {
  margin-left: 10px;
}
app-all-post ion-content .mainpage_heading a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img ion-img,
app-sample ion-content .mainpage_heading a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img ion-img {
  position: relative;
  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 50px;
  margin-top: 10px;
}
app-all-post ion-content .mainpage_heading a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img ion-img::before,
app-sample ion-content .mainpage_heading a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img ion-img::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px; /* same as border width */
  border-radius: inherit; /* inherit container box's radius */
  background: linear-gradient(207deg, #ffcd1d 0%, #fc7823 25%, #da181a 100%);
}
app-all-post ion-content .mainpage_heading a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img ion-img::part(image),
app-sample ion-content .mainpage_heading a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img ion-img::part(image) {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid var(--ion-white-color);
}
app-all-post ion-content app-feed-post ion-card,
app-sample ion-content app-feed-post ion-card {
  padding: 0;
  margin: 0;
}

app-guest-user-profile ion-content {
  position: fixed !important;
}
app-guest-user-profile ion-content .user_container .gallery {
  padding: 0 0px 0 8px !important;
}
app-guest-user-profile ion-content .user_container .gallery .gallery-item {
  width: 22% !important;
  margin: 8px !important;
}

app-profile user-profile,
app-guest-user-profile user-profile {
  width: 100%;
  position: relative;
  height: 100%;
  margin: 0 auto;
}
app-profile user-profile ion-header,
app-guest-user-profile user-profile ion-header {
  padding: 15px !important;
  background: unset !important;
  width: 40%;
  margin: 0 auto;
}
app-profile user-profile ion-content app-page-not-found .pagenot_found_container,
app-guest-user-profile user-profile ion-content app-page-not-found .pagenot_found_container {
  width: 40%;
  margin: 0 auto;
}
app-profile user-profile ion-content .user_container,
app-guest-user-profile user-profile ion-content .user_container {
  width: 40%;
  margin: 0 auto;
}
app-profile user-profile ion-content .user_container .gallery,
app-guest-user-profile user-profile ion-content .user_container .gallery {
  padding: 0 0px 0 10px;
  justify-content: flex-start;
}
app-profile user-profile ion-content .user_container .gallery .gallery-item,
app-guest-user-profile user-profile ion-content .user_container .gallery .gallery-item {
  position: relative;
  width: 23%;
  margin: 7px;
}

app-guest-user-profile ion-content user-profile ion-header {
  padding: 0;
}

app-settings ion-content ion-list ion-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0px;
  --min-height: unset !important;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --inner-padding-end: 0 !important;
  --padding-start: 0;
}
app-settings ion-content ion-list ion-item a24-label {
  width: 100%;
  margin-left: 100px;
}

app-privacy-security ion-content ion-card {
  margin: 0 !important;
  box-shadow: unset !important;
  background: unset !important;
}
app-privacy-security ion-content ion-card ion-card-header {
  color: var(--ion-theme-color);
  padding: 0;
}
app-privacy-security ion-content ion-card ion-card-content {
  padding-bottom: 0 !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
}
app-privacy-security ion-content ion-card ion-card-content ion-list ion-item {
  align-items: center !important;
  margin: 0 !important;
}

app-post-settings ion-content ion-card ion-card-content,
app-allow-comments ion-content ion-card ion-card-content {
  padding-bottom: 0 !important;
}
app-post-settings ion-content ion-card ion-card-content ion-item,
app-allow-comments ion-content ion-card ion-card-content ion-item {
  margin: 0 !important;
  --min-height: 48px;
}

app-followers-settings ion-content ion-card ion-card-header {
  padding: 20px 20px;
}
app-followers-settings ion-content ion-card ion-card-content {
  padding: 0 20px 0px !important;
}
app-followers-settings ion-content ion-card ion-card-content ion-item {
  margin: 0 !important;
  --min-height: 48px;
}

app-followers-settings ion-card,
app-post-settings ion-card,
app-privacy-security ion-card,
app-allow-comments ion-card {
  background: var(--ion-card-background-color) !important;
}

app-app-settings ion-content ion-card ion-card-content {
  margin: 0;
  padding: 0 !important;
}

ion-button::part(native) {
  box-shadow: unset;
  text-transform: capitalize;
  font-size: 16px;
}

app-about ion-content .about_page {
  margin: 30px auto;
  text-align: center;
  width: 40%;
}
app-about ion-content .about_page .share_btn ion-button {
  width: 150px;
}
app-about ion-content .about_page .share_btn ion-button ion-icon {
  font-size: 24px !important;
}
app-about ion-content .about_page ion-icon {
  width: 160px;
  margin-left: -25px;
  font-size: 67px !important;
}
app-about ion-content .about_page ion-img {
  width: 160px;
}
app-about ion-content .about_page h4 {
  font-size: 12px !important;
  margin: 0;
}
app-about ion-content .about_page P {
  font-size: 16px;
  text-align: justify;
}
app-about ion-content .about_page ion-button::part(native) {
  box-shadow: unset;
  text-transform: capitalize;
}
app-about ion-content .about_page ion-button ion-icon {
  margin-left: 30px;
}

app-settings ion-content,
app-followers-settings ion-content,
app-notification ion-content,
app-privacy-security ion-content,
app-help ion-content,
app-about ion-content,
app-edit-profile ion-content,
app-app-settings ion-content,
app-post-settings ion-content,
app-comments ion-content,
app-add-product ion-content,
app-add-profile ion-content,
app-forgot ion-content,
app-blocked-user ion-content,
app-muted-user ion-content,
app-change-password ion-content,
app-allow-comments ion-content {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 15px;
  --padding-end: 15px;
}
app-settings ion-content ion-card,
app-followers-settings ion-content ion-card,
app-notification ion-content ion-card,
app-privacy-security ion-content ion-card,
app-help ion-content ion-card,
app-about ion-content ion-card,
app-edit-profile ion-content ion-card,
app-app-settings ion-content ion-card,
app-post-settings ion-content ion-card,
app-comments ion-content ion-card,
app-add-product ion-content ion-card,
app-add-profile ion-content ion-card,
app-forgot ion-content ion-card,
app-blocked-user ion-content ion-card,
app-muted-user ion-content ion-card,
app-change-password ion-content ion-card,
app-allow-comments ion-content ion-card {
  padding: 0;
  margin: 15px 0 20px !important;
  background: unset;
}
app-settings ion-content ion-title,
app-followers-settings ion-content ion-title,
app-notification ion-content ion-title,
app-privacy-security ion-content ion-title,
app-help ion-content ion-title,
app-about ion-content ion-title,
app-edit-profile ion-content ion-title,
app-app-settings ion-content ion-title,
app-post-settings ion-content ion-title,
app-comments ion-content ion-title,
app-add-product ion-content ion-title,
app-add-profile ion-content ion-title,
app-forgot ion-content ion-title,
app-blocked-user ion-content ion-title,
app-muted-user ion-content ion-title,
app-change-password ion-content ion-title,
app-allow-comments ion-content ion-title {
  padding: 0;
  color: var(--ion-text-color) !important;
  font-size: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 85%;
  white-space: nowrap;
  position: relative;
  margin: 15px 0 0;
  padding: 0;
  text-align: left;
  height: auto;
}
app-settings ion-content ion-list ion-item a24-label ion-label,
app-followers-settings ion-content ion-list ion-item a24-label ion-label,
app-notification ion-content ion-list ion-item a24-label ion-label,
app-privacy-security ion-content ion-list ion-item a24-label ion-label,
app-help ion-content ion-list ion-item a24-label ion-label,
app-about ion-content ion-list ion-item a24-label ion-label,
app-edit-profile ion-content ion-list ion-item a24-label ion-label,
app-app-settings ion-content ion-list ion-item a24-label ion-label,
app-post-settings ion-content ion-list ion-item a24-label ion-label,
app-comments ion-content ion-list ion-item a24-label ion-label,
app-add-product ion-content ion-list ion-item a24-label ion-label,
app-add-profile ion-content ion-list ion-item a24-label ion-label,
app-forgot ion-content ion-list ion-item a24-label ion-label,
app-blocked-user ion-content ion-list ion-item a24-label ion-label,
app-muted-user ion-content ion-list ion-item a24-label ion-label,
app-change-password ion-content ion-list ion-item a24-label ion-label,
app-allow-comments ion-content ion-list ion-item a24-label ion-label {
  font-size: 16px !important;
}
app-settings ion-content ion-grid,
app-followers-settings ion-content ion-grid,
app-notification ion-content ion-grid,
app-privacy-security ion-content ion-grid,
app-help ion-content ion-grid,
app-about ion-content ion-grid,
app-edit-profile ion-content ion-grid,
app-app-settings ion-content ion-grid,
app-post-settings ion-content ion-grid,
app-comments ion-content ion-grid,
app-add-product ion-content ion-grid,
app-add-profile ion-content ion-grid,
app-forgot ion-content ion-grid,
app-blocked-user ion-content ion-grid,
app-muted-user ion-content ion-grid,
app-change-password ion-content ion-grid,
app-allow-comments ion-content ion-grid {
  padding-inline-start: unset;
  padding-inline-end: unset;
}
app-settings ion-content ion-grid ion-row ion-col,
app-followers-settings ion-content ion-grid ion-row ion-col,
app-notification ion-content ion-grid ion-row ion-col,
app-privacy-security ion-content ion-grid ion-row ion-col,
app-help ion-content ion-grid ion-row ion-col,
app-about ion-content ion-grid ion-row ion-col,
app-edit-profile ion-content ion-grid ion-row ion-col,
app-app-settings ion-content ion-grid ion-row ion-col,
app-post-settings ion-content ion-grid ion-row ion-col,
app-comments ion-content ion-grid ion-row ion-col,
app-add-product ion-content ion-grid ion-row ion-col,
app-add-profile ion-content ion-grid ion-row ion-col,
app-forgot ion-content ion-grid ion-row ion-col,
app-blocked-user ion-content ion-grid ion-row ion-col,
app-muted-user ion-content ion-grid ion-row ion-col,
app-change-password ion-content ion-grid ion-row ion-col,
app-allow-comments ion-content ion-grid ion-row ion-col {
  padding-inline-start: unset;
  padding-inline-end: unset;
}
app-settings ion-content ion-grid ion-row ion-col ion-card,
app-followers-settings ion-content ion-grid ion-row ion-col ion-card,
app-notification ion-content ion-grid ion-row ion-col ion-card,
app-privacy-security ion-content ion-grid ion-row ion-col ion-card,
app-help ion-content ion-grid ion-row ion-col ion-card,
app-about ion-content ion-grid ion-row ion-col ion-card,
app-edit-profile ion-content ion-grid ion-row ion-col ion-card,
app-app-settings ion-content ion-grid ion-row ion-col ion-card,
app-post-settings ion-content ion-grid ion-row ion-col ion-card,
app-comments ion-content ion-grid ion-row ion-col ion-card,
app-add-product ion-content ion-grid ion-row ion-col ion-card,
app-add-profile ion-content ion-grid ion-row ion-col ion-card,
app-forgot ion-content ion-grid ion-row ion-col ion-card,
app-blocked-user ion-content ion-grid ion-row ion-col ion-card,
app-muted-user ion-content ion-grid ion-row ion-col ion-card,
app-change-password ion-content ion-grid ion-row ion-col ion-card,
app-allow-comments ion-content ion-grid ion-row ion-col ion-card {
  --padding-start: unset;
  --padding-end: unset;
  background: transparent;
  box-shadow: none;
}
app-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-followers-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-notification ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-privacy-security ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-help ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-about ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-edit-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-app-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-post-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-comments ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-add-product ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-add-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-forgot ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-blocked-user ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-muted-user ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-change-password ion-content ion-grid ion-row ion-col ion-card ion-card-content,
app-allow-comments ion-content ion-grid ion-row ion-col ion-card ion-card-content {
  padding: 0 !important;
}
app-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-followers-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-notification ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-privacy-security ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-help ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-about ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-edit-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-app-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-post-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-comments ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-add-product ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-add-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-forgot ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-blocked-user ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-muted-user ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-change-password ion-content ion-grid ion-row ion-col ion-card ion-card-content form,
app-allow-comments ion-content ion-grid ion-row ion-col ion-card ion-card-content form {
  position: relative;
}
app-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-followers-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-notification ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-privacy-security ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-help ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-about ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-edit-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-app-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-post-settings ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-comments ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-add-product ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-add-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-forgot ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-blocked-user ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-muted-user ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-change-password ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button,
app-allow-comments ion-content ion-grid ion-row ion-col ion-card ion-card-content form ion-button {
  z-index: 99999;
  width: 100%;
  height: 40px;
  bottom: 0;
  left: 0;
  margin: 20px auto;
  padding: 0px 15px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: unset !important;
  margin-inline-start: unset !important;
}
app-settings ion-content ion-card-content ion-row h5,
app-followers-settings ion-content ion-card-content ion-row h5,
app-notification ion-content ion-card-content ion-row h5,
app-privacy-security ion-content ion-card-content ion-row h5,
app-help ion-content ion-card-content ion-row h5,
app-about ion-content ion-card-content ion-row h5,
app-edit-profile ion-content ion-card-content ion-row h5,
app-app-settings ion-content ion-card-content ion-row h5,
app-post-settings ion-content ion-card-content ion-row h5,
app-comments ion-content ion-card-content ion-row h5,
app-add-product ion-content ion-card-content ion-row h5,
app-add-profile ion-content ion-card-content ion-row h5,
app-forgot ion-content ion-card-content ion-row h5,
app-blocked-user ion-content ion-card-content ion-row h5,
app-muted-user ion-content ion-card-content ion-row h5,
app-change-password ion-content ion-card-content ion-row h5,
app-allow-comments ion-content ion-card-content ion-row h5 {
  font-size: 24px !important;
  color: var(--ion-text-color);
  margin: 20px 0 !important;
}
app-settings ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-followers-settings ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-notification ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-privacy-security ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-help ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-about ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-edit-profile ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-app-settings ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-post-settings ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-comments ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-add-product ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-add-profile ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-forgot ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-blocked-user ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-muted-user ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-change-password ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native),
app-allow-comments ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item::part(native) {
  flex-direction: row-reverse;
}
app-settings ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-followers-settings ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-notification ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-privacy-security ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-help ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-about ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-edit-profile ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-app-settings ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-post-settings ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-comments ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-add-product ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-add-profile ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-forgot ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-blocked-user ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-muted-user ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-change-password ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio,
app-allow-comments ion-content ion-card-content ion-item a24-radio ion-radio-group ion-item ion-radio {
  margin: 0 36px 0px 4px !important;
}
app-settings ion-content ion-card-content ion-item a24-label ion-label,
app-followers-settings ion-content ion-card-content ion-item a24-label ion-label,
app-notification ion-content ion-card-content ion-item a24-label ion-label,
app-privacy-security ion-content ion-card-content ion-item a24-label ion-label,
app-help ion-content ion-card-content ion-item a24-label ion-label,
app-about ion-content ion-card-content ion-item a24-label ion-label,
app-edit-profile ion-content ion-card-content ion-item a24-label ion-label,
app-app-settings ion-content ion-card-content ion-item a24-label ion-label,
app-post-settings ion-content ion-card-content ion-item a24-label ion-label,
app-comments ion-content ion-card-content ion-item a24-label ion-label,
app-add-product ion-content ion-card-content ion-item a24-label ion-label,
app-add-profile ion-content ion-card-content ion-item a24-label ion-label,
app-forgot ion-content ion-card-content ion-item a24-label ion-label,
app-blocked-user ion-content ion-card-content ion-item a24-label ion-label,
app-muted-user ion-content ion-card-content ion-item a24-label ion-label,
app-change-password ion-content ion-card-content ion-item a24-label ion-label,
app-allow-comments ion-content ion-card-content ion-item a24-label ion-label {
  font-size: 16px !important;
  color: var(--ion-text-color) !important;
  display: unset !important;
}
app-settings ion-content ion-card-content ion-item a24-toggle,
app-followers-settings ion-content ion-card-content ion-item a24-toggle,
app-notification ion-content ion-card-content ion-item a24-toggle,
app-privacy-security ion-content ion-card-content ion-item a24-toggle,
app-help ion-content ion-card-content ion-item a24-toggle,
app-about ion-content ion-card-content ion-item a24-toggle,
app-edit-profile ion-content ion-card-content ion-item a24-toggle,
app-app-settings ion-content ion-card-content ion-item a24-toggle,
app-post-settings ion-content ion-card-content ion-item a24-toggle,
app-comments ion-content ion-card-content ion-item a24-toggle,
app-add-product ion-content ion-card-content ion-item a24-toggle,
app-add-profile ion-content ion-card-content ion-item a24-toggle,
app-forgot ion-content ion-card-content ion-item a24-toggle,
app-blocked-user ion-content ion-card-content ion-item a24-toggle,
app-muted-user ion-content ion-card-content ion-item a24-toggle,
app-change-password ion-content ion-card-content ion-item a24-toggle,
app-allow-comments ion-content ion-card-content ion-item a24-toggle {
  margin: 0;
  padding: 0;
}
app-settings ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-followers-settings ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-notification ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-privacy-security ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-help ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-about ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-edit-profile ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-app-settings ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-post-settings ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-comments ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-add-product ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-add-profile ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-forgot ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-blocked-user ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-muted-user ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-change-password ion-content ion-card-content ion-item a24-toggle ion-toggle,
app-allow-comments ion-content ion-card-content ion-item a24-toggle ion-toggle {
  margin: 0;
  padding: 5px;
}
app-settings ion-content.app-followers-page ion-card,
app-followers-settings ion-content.app-followers-page ion-card,
app-notification ion-content.app-followers-page ion-card,
app-privacy-security ion-content.app-followers-page ion-card,
app-help ion-content.app-followers-page ion-card,
app-about ion-content.app-followers-page ion-card,
app-edit-profile ion-content.app-followers-page ion-card,
app-app-settings ion-content.app-followers-page ion-card,
app-post-settings ion-content.app-followers-page ion-card,
app-comments ion-content.app-followers-page ion-card,
app-add-product ion-content.app-followers-page ion-card,
app-add-profile ion-content.app-followers-page ion-card,
app-forgot ion-content.app-followers-page ion-card,
app-blocked-user ion-content.app-followers-page ion-card,
app-muted-user ion-content.app-followers-page ion-card,
app-change-password ion-content.app-followers-page ion-card,
app-allow-comments ion-content.app-followers-page ion-card {
  padding: 0;
  margin: 15px 0 40px;
  box-shadow: unset;
}
app-settings ion-content.post_setting_Page ion-card,
app-followers-settings ion-content.post_setting_Page ion-card,
app-notification ion-content.post_setting_Page ion-card,
app-privacy-security ion-content.post_setting_Page ion-card,
app-help ion-content.post_setting_Page ion-card,
app-about ion-content.post_setting_Page ion-card,
app-edit-profile ion-content.post_setting_Page ion-card,
app-app-settings ion-content.post_setting_Page ion-card,
app-post-settings ion-content.post_setting_Page ion-card,
app-comments ion-content.post_setting_Page ion-card,
app-add-product ion-content.post_setting_Page ion-card,
app-add-profile ion-content.post_setting_Page ion-card,
app-forgot ion-content.post_setting_Page ion-card,
app-blocked-user ion-content.post_setting_Page ion-card,
app-muted-user ion-content.post_setting_Page ion-card,
app-change-password ion-content.post_setting_Page ion-card,
app-allow-comments ion-content.post_setting_Page ion-card {
  padding: 0;
  margin: 15px 0 40px;
  box-shadow: unset !important;
}
app-settings ion-content.privacy_page_section ion-card,
app-followers-settings ion-content.privacy_page_section ion-card,
app-notification ion-content.privacy_page_section ion-card,
app-privacy-security ion-content.privacy_page_section ion-card,
app-help ion-content.privacy_page_section ion-card,
app-about ion-content.privacy_page_section ion-card,
app-edit-profile ion-content.privacy_page_section ion-card,
app-app-settings ion-content.privacy_page_section ion-card,
app-post-settings ion-content.privacy_page_section ion-card,
app-comments ion-content.privacy_page_section ion-card,
app-add-product ion-content.privacy_page_section ion-card,
app-add-profile ion-content.privacy_page_section ion-card,
app-forgot ion-content.privacy_page_section ion-card,
app-blocked-user ion-content.privacy_page_section ion-card,
app-muted-user ion-content.privacy_page_section ion-card,
app-change-password ion-content.privacy_page_section ion-card,
app-allow-comments ion-content.privacy_page_section ion-card {
  padding: 0;
  margin: 10px 0 15px;
  box-shadow: unset;
}
app-settings ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-followers-settings ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-notification ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-privacy-security ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-help ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-about ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-edit-profile ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-app-settings ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-post-settings ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-comments ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-add-product ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-add-profile ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-forgot ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-blocked-user ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-muted-user ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-change-password ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-allow-comments ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item a24-label ion-label {
  font-size: 16px !important;
  color: var(--ion-text-color) !important;
}
app-settings ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-followers-settings ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-notification ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-privacy-security ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-help ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-about ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-edit-profile ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-app-settings ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-post-settings ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-comments ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-add-product ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-add-profile ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-forgot ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-blocked-user ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-muted-user ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-change-password ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon,
app-allow-comments ion-content.privacy_page_section ion-card ion-card-content ion-list ion-item ion-icon {
  color: var(--ion-text-color);
}
app-settings ion-content.app_notification_section ion-card, app-settings ion-content.comment_section ion-card,
app-followers-settings ion-content.app_notification_section ion-card,
app-followers-settings ion-content.comment_section ion-card,
app-notification ion-content.app_notification_section ion-card,
app-notification ion-content.comment_section ion-card,
app-privacy-security ion-content.app_notification_section ion-card,
app-privacy-security ion-content.comment_section ion-card,
app-help ion-content.app_notification_section ion-card,
app-help ion-content.comment_section ion-card,
app-about ion-content.app_notification_section ion-card,
app-about ion-content.comment_section ion-card,
app-edit-profile ion-content.app_notification_section ion-card,
app-edit-profile ion-content.comment_section ion-card,
app-app-settings ion-content.app_notification_section ion-card,
app-app-settings ion-content.comment_section ion-card,
app-post-settings ion-content.app_notification_section ion-card,
app-post-settings ion-content.comment_section ion-card,
app-comments ion-content.app_notification_section ion-card,
app-comments ion-content.comment_section ion-card,
app-add-product ion-content.app_notification_section ion-card,
app-add-product ion-content.comment_section ion-card,
app-add-profile ion-content.app_notification_section ion-card,
app-add-profile ion-content.comment_section ion-card,
app-forgot ion-content.app_notification_section ion-card,
app-forgot ion-content.comment_section ion-card,
app-blocked-user ion-content.app_notification_section ion-card,
app-blocked-user ion-content.comment_section ion-card,
app-muted-user ion-content.app_notification_section ion-card,
app-muted-user ion-content.comment_section ion-card,
app-change-password ion-content.app_notification_section ion-card,
app-change-password ion-content.comment_section ion-card,
app-allow-comments ion-content.app_notification_section ion-card,
app-allow-comments ion-content.comment_section ion-card {
  padding: 0;
  margin: 10px 0 15px;
  box-shadow: unset;
}
app-settings ion-content.app_notification_section ion-card ion-card-content, app-settings ion-content.comment_section ion-card ion-card-content,
app-followers-settings ion-content.app_notification_section ion-card ion-card-content,
app-followers-settings ion-content.comment_section ion-card ion-card-content,
app-notification ion-content.app_notification_section ion-card ion-card-content,
app-notification ion-content.comment_section ion-card ion-card-content,
app-privacy-security ion-content.app_notification_section ion-card ion-card-content,
app-privacy-security ion-content.comment_section ion-card ion-card-content,
app-help ion-content.app_notification_section ion-card ion-card-content,
app-help ion-content.comment_section ion-card ion-card-content,
app-about ion-content.app_notification_section ion-card ion-card-content,
app-about ion-content.comment_section ion-card ion-card-content,
app-edit-profile ion-content.app_notification_section ion-card ion-card-content,
app-edit-profile ion-content.comment_section ion-card ion-card-content,
app-app-settings ion-content.app_notification_section ion-card ion-card-content,
app-app-settings ion-content.comment_section ion-card ion-card-content,
app-post-settings ion-content.app_notification_section ion-card ion-card-content,
app-post-settings ion-content.comment_section ion-card ion-card-content,
app-comments ion-content.app_notification_section ion-card ion-card-content,
app-comments ion-content.comment_section ion-card ion-card-content,
app-add-product ion-content.app_notification_section ion-card ion-card-content,
app-add-product ion-content.comment_section ion-card ion-card-content,
app-add-profile ion-content.app_notification_section ion-card ion-card-content,
app-add-profile ion-content.comment_section ion-card ion-card-content,
app-forgot ion-content.app_notification_section ion-card ion-card-content,
app-forgot ion-content.comment_section ion-card ion-card-content,
app-blocked-user ion-content.app_notification_section ion-card ion-card-content,
app-blocked-user ion-content.comment_section ion-card ion-card-content,
app-muted-user ion-content.app_notification_section ion-card ion-card-content,
app-muted-user ion-content.comment_section ion-card ion-card-content,
app-change-password ion-content.app_notification_section ion-card ion-card-content,
app-change-password ion-content.comment_section ion-card ion-card-content,
app-allow-comments ion-content.app_notification_section ion-card ion-card-content,
app-allow-comments ion-content.comment_section ion-card ion-card-content {
  padding: 0;
  margin: 0;
}
app-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native), app-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-followers-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-followers-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-notification ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-notification ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-privacy-security ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-privacy-security ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-help ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-help ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-about ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-about ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-edit-profile ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-edit-profile ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-app-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-app-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-post-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-post-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-comments ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-comments ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-add-product ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-add-product ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-add-profile ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-add-profile ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-forgot ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-forgot ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-blocked-user ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-blocked-user ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-muted-user ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-muted-user ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-change-password ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-change-password ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native),
app-allow-comments ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native),
app-allow-comments ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) {
  margin: 0;
  padding: 0;
  --border-style: 0 !important;
  padding-inline-end: unset !important;
  padding-inline-start: unset !important;
  --inner-padding-end: 0 !important;
}
app-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner, app-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-followers-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-followers-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-notification ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-notification ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-privacy-security ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-privacy-security ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-help ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-help ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-about ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-about ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-edit-profile ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-edit-profile ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-app-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-app-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-post-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-post-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-comments ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-comments ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-add-product ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-add-product ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-add-profile ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-add-profile ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-forgot ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-forgot ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-blocked-user ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-blocked-user ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-muted-user ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-muted-user ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-change-password ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-change-password ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-allow-comments ion-content.app_notification_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner,
app-allow-comments ion-content.comment_section ion-card ion-card-content ion-list ion-item::part(native) .item-inner {
  padding-inline-end: unset !important;
  padding-inline-start: unset !important;
}
app-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label, app-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-followers-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-followers-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-notification ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-notification ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-privacy-security ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-privacy-security ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-help ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-help ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-about ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-about ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-edit-profile ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-edit-profile ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-app-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-app-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-post-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-post-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-comments ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-comments ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-add-product ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-add-product ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-add-profile ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-add-profile ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-forgot ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-forgot ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-blocked-user ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-blocked-user ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-muted-user ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-muted-user ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-change-password ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-change-password ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-allow-comments ion-content.app_notification_section ion-card ion-card-content ion-list ion-item a24-label ion-label,
app-allow-comments ion-content.comment_section ion-card ion-card-content ion-list ion-item a24-label ion-label {
  font-size: 16px !important;
  color: var(--ion-text-color) !important;
}
app-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon, app-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-followers-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-followers-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-notification ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-notification ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-privacy-security ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-privacy-security ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-help ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-help ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-about ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-about ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-edit-profile ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-edit-profile ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-app-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-app-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-post-settings ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-post-settings ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-comments ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-comments ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-add-product ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-add-product ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-add-profile ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-add-profile ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-forgot ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-forgot ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-blocked-user ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-blocked-user ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-muted-user ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-muted-user ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-change-password ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-change-password ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon,
app-allow-comments ion-content.app_notification_section ion-card ion-card-content ion-list ion-item ion-icon,
app-allow-comments ion-content.comment_section ion-card ion-card-content ion-list ion-item ion-icon {
  color: var(--ion-text-color);
}

app-page-not-found .pagenot_found_container p {
  font-size: 16px;
  margin: 10px 0;
}
app-page-not-found .pagenot_found_container .logo_container {
  margin: 20px 15px 0;
}
app-page-not-found .pagenot_found_container .logo_container .foo_logo ion-icon {
  height: 45px;
  width: 100%;
}
app-page-not-found .pagenot_found_container .page_not_found_box {
  margin: auto 15px;
}
app-page-not-found .pagenot_found_container .page_not_found_box .page_not_found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 5px 0 45px;
  text-align: center;
}
app-page-not-found .pagenot_found_container .page_not_found_box .page_not_found p > span {
  color: var(--ion-theme-color);
  cursor: pointer;
}
app-page-not-found .pagenot_found_container .page_not_found_box .page_not_foung_img {
  margin: 110px 0;
}
app-page-not-found .pagenot_found_container .page_not_found_box .page_not_foung_img ion-icon {
  width: 100%;
  height: 236px;
}
app-page-not-found .pagenot_found_container .not_found_footer {
  position: absolute;
  bottom: 30px;
  padding: 15px;
  background-color: var(--ion-item-background-black);
  width: 40%;
}
app-page-not-found .pagenot_found_container .not_found_footer .foo_logo ion-icon {
  width: 90px;
  height: 30px;
}
app-page-not-found .pagenot_found_container .not_found_footer .footer_box .text_box,
app-page-not-found .pagenot_found_container .not_found_footer .footer_box .btn_box {
  width: 100%;
  text-align: center;
}
app-page-not-found .pagenot_found_container .not_found_footer .footer_box .btn_box {
  display: flex;
  align-items: center;
  padding: 20px;
}
app-page-not-found .pagenot_found_container .not_found_footer .footer_box .btn_box ion-button {
  width: 100%;
  text-transform: capitalize;
}
app-page-not-found .pagenot_found_container .not_found_footer .footer_box .btn_box ion-button::part(native) {
  box-shadow: unset;
}
app-page-not-found .pagenot_found_container .not_found_footer .footer_box .btn_box ion-button:last-child()::part(native) {
  background-color: unset;
  color: var(--ion-theme-color);
}

app-settings ion-header {
  background: unset !important;
}
app-settings ion-content ion-list ion-item {
  padding: 10px 0;
}
app-settings ion-content ion-list ion-item ion-icon {
  color: var(--ion-color-base) !important;
  margin-inline-end: 0px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-inline-start: 0;
}
app-settings ion-content ion-list ion-item ion-label {
  font-size: 24px !important;
  color: var(--ion-text-color) !important;
  display: unset !important;
}

.intro_container .intro_slide {
  justify-content: flex-end !important;
}
.intro_container .intro_slide .intro_image_container {
  margin: auto 0;
}
.intro_container .intro_slide .intro_image_container ion-img::part(image) {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: 0 auto -100px;
}
.intro_container .swiper-pagination-bullets {
  bottom: 15px;
  left: 30px;
  width: 100%;
  text-align: start;
}

.login_form_container .header {
  font-size: 28px;
  font-weight: 500;
  color: var(--ion-text-color);
  margin-top: 40px;
}
.login_form_container .sub_header {
  font-size: 16px;
  font-weight: 400;
  color: #d1d1d1;
  line-height: 10px;
}

.real_comment_model {
  --background: #00000059 !important;
}
.real_comment_model ion-header {
  background: transparent !important;
}
.real_comment_model ion-content {
  --background: transparent !important ;
}
.real_comment_model ion-footer app-messages-keyboard ion-toolbar {
  --background: none !important;
  box-shadow: none;
}
.real_comment_model ion-footer app-messages-keyboard ion-toolbar .editer {
  position: absolute;
  right: 60px;
  z-index: 99;
  bottom: 10px;
}
.real_comment_model ion-footer app-messages-keyboard ion-toolbar .editer .edit_text p {
  display: none;
}
.real_comment_model ion-footer app-messages-keyboard ion-toolbar .editer .editer_icon {
  text-align: center;
  margin-right: 15px;
}
.real_comment_model ion-footer app-messages-keyboard ion-toolbar .editer .editer_icon span {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.real_comment_model ion-footer app-messages-keyboard ion-toolbar .message_box ion-item {
  --border-width: 0 0 0 0 !important;
  --border-color: unset !important;
  --highlight-color-focused: unset !important;
  --highlight-color-valid: unset !important;
  --highlight-background: unset !important;
  --highlight-color-invalid: unset !important;
  --inner-border-width: 0px 0px 0px 0px !important;
  --padding-start: 8px !important;
}
.real_comment_model ion-footer app-messages-keyboard ion-toolbar .message_box textarea {
  color: var(--ion-white-color) !important;
  --highlight-color-focused: unset !important;
  --highlight-color-valid: unset !important;
  --highlight-background: unset !important;
}
.real_comment_model ion-footer app-messages-keyboard ion-toolbar .message_box .icon_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 2px;
  margin: 0px 3px;
}

.comment_modal {
  --background: transparent !important;
}
.comment_modal app-message-detail ion-header {
  background: transparent !important;
}
.comment_modal app-message-detail ion-header app-page-header .header_btns ion-row .header_title h3 {
  text-align: end;
}
.comment_modal app-message-detail ion-content {
  --background: transparent !important ;
  --padding-start: 5px !important;
  --padding-end: 10px !important;
}
.comment_modal app-message-detail ion-content app-user-message {
  --background: transparent !important;
}
.comment_modal app-message-detail ion-content app-user-message .message-user ion-item {
  --inner-border-width: 0 !important;
}
.comment_modal app-message-detail ion-content app-user-message .message-user ion-row .message-container {
  background: unset !important;
  position: relative;
  border-radius: unset;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
  width: 95%;
  max-width: 100%;
  margin-top: 0;
}
.comment_modal app-message-detail ion-content app-user-message .message-user ion-row .message-container span::after {
  display: none !important;
}

app-help ion-content a24-accordion ion-accordion-group {
  margin: 25px 0px;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion {
  background: var(--ion-card-background-color) !important;
  margin: 15px 0 20px !important;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion ion-item {
  display: block;
  align-items: center;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion ion-item::part(native) {
  margin: 0;
  padding: 0;
  color: var(--ion-text-color);
  border: unset !important;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion ion-item a24-label {
  --min-height: unset !important;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  height: 55px;
  line-height: 25px;
  padding: 15px;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion ion-item a24-label ion-label {
  display: unset !important;
  font-size: 16px !important;
  color: var(--ion-text-color);
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion ion-item ion-icon {
  padding: 0;
  margin: 0;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion ion-list ion-item {
  --inner-border-width: 0;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion ion-list ion-item a24-label {
  --min-height: unset !important;
  text-overflow: unset;
  overflow: unset;
  width: 100%;
  white-space: unset;
  text-align: justify;
  height: auto;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion ion-list ion-item a24-label ion-label {
  display: unset !important;
  font-size: 16px !important;
  color: var(--ion-text-color);
  white-space: unset !important;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion.accordion-expanded .header a24-label ion-label {
  color: var(--ion-theme-color) !important;
}
app-help ion-content a24-accordion ion-accordion-group ion-accordion.accordion-expanded .header ion-icon {
  color: var(--ion-theme-color) !important;
}

app-notifications ion-header app-page-header .header_btns ion-row .backButton {
  max-width: 15% !important;
}
app-notifications ion-content ion-card {
  margin: 0 !important;
}
app-notifications ion-content ion-card ion-card-content {
  padding: 0 !important;
}
app-notifications ion-content ion-card ion-card-content .notification_btn {
  position: absolute;
  right: 16px;
  top: 10px;
}
app-notifications ion-content ion-card ion-card-content .notification_box {
  margin-bottom: 20px;
}
app-notifications ion-content ion-card ion-card-content .notification_box .head_text {
  color: var(--ion-white-color);
  font-size: 18px;
  margin: 0 0 10px;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row {
  align-items: center;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row {
  align-items: center;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row ion-col .text {
  color: var(--ion-white-color);
  line-height: 14px;
  margin: 0;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row ion-col .text .username {
  color: var(--ion-theme-color);
  margin-right: 3px;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row ion-col .date_sec {
  color: var(--ion-icon-color);
  font-weight: 600;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row ion-col app-c-avatar .user_profile {
  position: relative;
  width: 43px !important;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row ion-col app-c-avatar .user_profile .info_icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  font-size: 15px !important;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row ion-col app-c-avatar .user_profile ion-avatar {
  box-shadow: -3px 3px 6px 1px rgba(12, 12, 12, 0.0901960784);
  width: 39px;
  height: 39px;
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(207deg, #ffcd1d 0%, #fc7823 25%, #da181a 100%);
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row ion-col app-c-avatar .user_profile ion-avatar ion-thumbnail {
  --size: 35px !important;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row ion-col app-c-avatar .user_profile ion-avatar ion-thumbnail ion-img {
  border: 0.5px solid white;
  border-radius: 50%;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col ion-row ion-col app-c-avatar .user_profile ion-avatar ion-thumbnail ion-img::part(image) {
  width: 35px;
  height: 35px;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col.avatar {
  padding: 5px 0 !important;
  width: 100% !important;
  max-width: 8% !important;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col.info_text {
  flex: unset !important;
  width: 100% !important;
  max-width: 75% !important;
}
app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col.date_col {
  text-align: center;
}

ion-modal app-duration {
  overflow-y: scroll !important;
  padding: 0 15px;
}
ion-modal app-duration ion-header app-page-header .header_btns ion-row .backButton {
  max-width: 15% !important;
}
ion-modal app-duration ion-grid {
  padding: 0;
}
ion-modal app-duration ion-grid .back_btns {
  margin: 10px 0px !important;
}
ion-modal app-duration ion-grid a24-label ion-label {
  padding: 0;
  color: var(--ion-text-color) !important;
  font-size: 20px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 210px;
  white-space: nowrap;
}
ion-modal app-duration ion-grid ion-row {
  margin: 10px auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-modal app-duration ion-grid ion-row ion-col {
  margin: 0px;
}
ion-modal app-duration ion-grid ion-row ion-col ion-card {
  text-align: center;
  color: var(--ion-white-color);
  font-size: 14px;
  padding: 0;
  margin: 0;
  height: 50px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-item-background-black);
  cursor: pointer;
}
ion-modal app-duration ion-grid ion-row ion-col ion-card.selected {
  background-color: var(--ion-theme-color);
  cursor: pointer;
}
ion-modal app-duration ion-grid .duration_btn {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 0px;
}
ion-modal app-duration ion-grid .duration_btn::part(native) {
  --box-shadow: unset;
  height: 40px;
  margin-top: 24px;
  width: 280px;
}
ion-modal app-following-list ion-content .following_list a24-search ion-searchbar {
  padding: 0 !important;
}
ion-modal app-report-modal {
  padding: 0px 10px;
}
ion-modal app-report-modal ion-header {
  padding: 15px 0 0 !important;
}
ion-modal app-report-modal ion-header ion-title {
  padding: 0;
  margin: 0;
  color: var(--ion-color-base);
  position: relative;
  text-align: start;
  height: 30px;
}
ion-modal app-report-modal ion-header ion-card-subtitle {
  color: var(--ion-color-base) !important;
}
ion-modal app-report-modal ion-header ion-card-subtitle p {
  margin: 0;
  font-weight: 300;
  text-transform: capitalize;
}
ion-modal app-report-modal ion-content form {
  width: 100% !important;
  padding: 0 10px;
}
ion-modal app-report-modal ion-content form ion-item.item-input {
  margin-top: 10px;
}
ion-modal app-report-modal ion-content form ion-radio-group ion-item {
  height: 38px;
}
ion-modal app-report-modal ion-content form ion-radio-group ion-item a24-label {
  line-height: 25px;
}
ion-modal app-report-modal ion-content form ion-radio-group ion-item a24-label ion-label {
  color: var(--ion-color-base) !important;
}
ion-modal app-report-modal ion-content ion-button {
  margin: 15px 0px;
}

app-follower-suggestion ion-content .follower_head_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-follower-suggestion ion-content .follower_head_text .header_text {
  padding: 0;
  color: var(--ion-text-color) !important;
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 210px;
  white-space: nowrap;
}
app-follower-suggestion ion-content .follower_head_text ion-button::part(native) {
  background-color: transparent;
  box-shadow: unset;
  text-transform: capitalize;
  font-size: 18px !important;
  color: var(--ion-theme-color);
}

app-following-list ion-content .following_list,
app-followers-list ion-content .following_list {
  padding: 0px 15px;
}
app-following-list ion-content .following_list ion-toolbar a24-search ion-searchbar,
app-followers-list ion-content .following_list ion-toolbar a24-search ion-searchbar {
  padding: 0 !important;
}
app-following-list ion-content .following_list ion-card,
app-followers-list ion-content .following_list ion-card {
  margin: 4% 0 0;
}
app-following-list ion-content .following_list .following_page,
app-followers-list ion-content .following_list .following_page {
  margin: 20px 0 0 !important;
}
app-following-list ion-content .following_list .following_page ion-row ion-col h4,
app-followers-list ion-content .following_list .following_page ion-row ion-col h4 {
  padding: 0;
  margin: 0;
  color: var(--ion-white-color);
}
app-following-list ion-content .following_list .following_page ion-row ion-col p,
app-followers-list ion-content .following_list .following_page ion-row ion-col p {
  padding: 0;
  margin: 0;
  color: var(--ion-white-color);
}
app-following-list ion-content .following_list .following_page ion-row ion-col.user_profile,
app-followers-list ion-content .following_list .following_page ion-row ion-col.user_profile {
  padding: 0;
  display: flex;
  align-items: center;
}

app-following-list ion-content .user_action_list,
app-following-list ion-content .account-list,
app-blocked-user ion-content .user_action_list,
app-blocked-user ion-content .account-list,
app-all-post ion-content .user_action_list,
app-all-post ion-content .account-list,
app-following-list ion-content .user_action_list,
app-following-list ion-content .account-list,
app-follow-request ion-content .user_action_list,
app-follow-request ion-content .account-list,
app-follow-request-sent ion-content .user_action_list,
app-follow-request-sent ion-content .account-list,
app-no-network ion-content .user_action_list,
app-no-network ion-content .account-list,
app-muted-user ion-content .user_action_list,
app-muted-user ion-content .account-list,
app-follower-suggestion ion-content .user_action_list,
app-follower-suggestion ion-content .account-list,
app-followers-list ion-content .user_action_list,
app-followers-list ion-content .account-list {
  padding: 0px;
  height: 65px;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button {
  margin: 0;
  height: auto !important;
  --border-radius: 4px;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native) {
  border: 1px solid var(--ion-theme-color);
  background: transparent;
  font-size: 12px;
  color: var(--ion-color-base);
  width: auto;
  padding: 2px 5px !important;
  font-weight: 300 !important;
  cursor: pointer;
  display: block;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: capitalize;
  line-height: 18px;
  text-transform: capitalize;
  box-shadow: none !important;
  height: auto;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn {
  margin: 0;
  height: auto !important;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native),
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type .active_btn::part(native) {
  border: 1px solid var(--ion-theme-color);
  background: transparent;
  background-color: var(--ion-theme-color) !important;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native) {
  border: unset !important;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text {
  margin-left: 10px;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p {
  padding: 0;
  margin: 0;
  line-height: 18px;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new {
  font-size: 14px;
  color: var(--ion-white-color);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username {
  font-size: 16px;
  color: var(--ion-white-color);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar {
  width: 40px;
  height: 40px;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn {
  flex-direction: row !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-blocked-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-blocked-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-all-post ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-all-post ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-following-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-following-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-follow-request ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-follow-request ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-follow-request-sent ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-follow-request-sent ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-no-network ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-no-network ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-muted-user ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-muted-user ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-follower-suggestion ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-follower-suggestion ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-followers-list ion-content .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-followers-list ion-content .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button {
  color: var(--ion-white-color);
}

app-guest-user-model .guest_page {
  width: 365px;
  background-color: var(--ion-background-color);
  padding: 25px 20px;
  border-radius: 10px;
}
app-guest-user-model .guest_page .head p {
  padding: 0;
  margin: 0;
  text-align: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: var(--ion-white-color);
}
app-guest-user-model .guest_page ion-button {
  margin: 0;
  padding: 0px 5px;
}
app-guest-user-model .guest_page ion-button a {
  text-decoration: none;
}
app-guest-user-model .guest_page .sub_text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}
app-guest-user-model .guest_page .sub_text ul {
  list-style: none;
  padding: 0;
}
app-guest-user-model .guest_page .sub_text ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
app-guest-user-model .guest_page .sub_text ul li ion-icon {
  font-size: 16px !important;
  margin-right: 10px;
  color: var(--ion-item-bb-text-label) !important;
}
app-guest-user-model .guest_page .sub_text ul li span {
  font-size: 14px;
  font-weight: 100;
  color: var(--ion-item-bb-text-label) !important;
}
app-guest-user-model .guest_page .login_btns .explore_btn::part(native) {
  color: var(--ion-white-color);
  border: 1px solid #797979;
  background: transparent;
  padding: 15px;
  margin: 0;
  height: 45px;
  width: 120px;
  font-size: 14px;
}
app-guest-user-model .guest_page .login_btns .sing_new::part(native) {
  --box-shadow: unset;
  height: 45px;
  width: 120px;
  font-size: 14px;
}

app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
  width: 40% !important;
  min-width: 40% !important;
  margin: 0 25% 0 auto;
}

app-post-detail {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
app-post-detail .video-js.vjs-1-1,
app-post-detail .video-js.vjs-16-9,
app-post-detail .video-js.vjs-4-3,
app-post-detail .video-js.vjs-9-16,
app-post-detail .video-js.vjs-fluid {
  overflow: hidden;
  width: 100%;
  max-width: 80%;
  height: 100% !important;
}
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
  top: 0;
  left: 0;
  contain: content;
  right: 0;
}
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item app-post-slides {
  cursor: pointer;
}
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item app-post-slides ion-card ion-slides ion-slide img {
  width: 80% !important;
}

app-all-post .follower_page_input,
app-sample .follower_page_input {
  padding: 0;
}
app-all-post .follower_page_input .suggestion_list,
app-sample .follower_page_input .suggestion_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 25px;
}
app-all-post .follower_page_input .suggestion_list ion-title,
app-sample .follower_page_input .suggestion_list ion-title {
  padding: 0;
  color: var(--ion-white-color);
}
app-all-post .follower_page_input .slider,
app-sample .follower_page_input .slider {
  margin: 5px 0px;
}
app-all-post .follower_page_input .slider .user_action_list,
app-all-post .follower_page_input .slider .account-list,
app-sample .follower_page_input .slider .user_action_list,
app-sample .follower_page_input .slider .account-list {
  padding: 0px;
  background-color: var(--ion-item-background-black);
  margin: 0 0 0 10px;
  border-radius: 8px;
}
app-all-post .follower_page_input .slider .user_action_list:last-child(),
app-all-post .follower_page_input .slider .account-list:last-child(),
app-sample .follower_page_input .slider .user_action_list:last-child(),
app-sample .follower_page_input .slider .account-list:last-child() {
  margin-right: 10px;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list,
app-all-post .follower_page_input .slider .account-list app-account-list,
app-sample .follower_page_input .slider .user_action_list app-account-list,
app-sample .follower_page_input .slider .account-list app-account-list {
  display: flex;
  align-items: center;
  padding: 25px 0px !important;
  height: 100% !important;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content {
  margin: 0 !important;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button {
  margin: 0;
  height: auto !important;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native),
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-button::part(native) {
  border: 1px solid var(--ion-theme-color);
  background: transparent;
  font-size: 14px;
  color: var(--ion-color-base);
  width: auto;
  padding: 5px 9px;
  cursor: pointer;
  display: block;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: capitalize;
  line-height: 18px;
  text-transform: capitalize;
  box-shadow: none !important;
  height: auto;
  font-weight: 100;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native),
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .account_btn_type ion-buttons ion-button::part(native) {
  border: unset !important;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text {
  text-align: center;
  margin: 10px;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text p {
  padding: 0;
  margin: 0;
  line-height: 18px;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username_new {
  font-size: 15px;
  color: var(--ion-white-color);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username {
  font-size: 18px;
  color: var(--ion-white-color);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 125px;
  padding: 0;
  height: 1.2em;
  white-space: nowrap;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_detail app-c-avatar .user_profile ion-avatar {
  width: 40px;
  height: 40px;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_btn,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_btn {
  flex-direction: row !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-all-post .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-all-post .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-sample .follower_page_input .slider .user_action_list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button,
app-sample .follower_page_input .slider .account-list app-account-list .acct-list-content .card-modal .user_request_btn ion-buttons ion-button {
  color: var(--ion-white-color);
}

.public_policy_popup {
  padding: 10px;
}
.public_policy_popup p {
  text-align: justify;
}
.public_policy_popup p .read_more {
  color: var(--ion-theme-color);
  cursor: pointer;
}

app-add-profile ion-content ion-grid .add_profile_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
}
app-add-profile ion-content .category_btn {
  float: right;
  opacity: 1;
}
app-add-profile ion-content .category_btn ion-icon {
  font-size: 16px !important;
  color: var(--ion-theme-color) !important;
}

app-add-product ion-header,
app-add-post ion-header,
app-save-posts ion-header {
  padding: 0px 15px;
}
app-add-product ion-header app-page-header .header_btns .backButton,
app-add-post ion-header app-page-header .header_btns .backButton,
app-save-posts ion-header app-page-header .header_btns .backButton {
  max-width: 8% !important;
  padding: 0;
  width: 100%;
}
app-add-product ion-header app-page-header .header_btns ion-row ion-col h3,
app-add-post ion-header app-page-header .header_btns ion-row ion-col h3,
app-save-posts ion-header app-page-header .header_btns ion-row ion-col h3 {
  color: var(--ion-text-color) !important;
  font-size: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 210px;
  white-space: nowrap;
  text-align: left;
  font-weight: 500;
}

app-admin-add-post ion-content {
  max-width: 964px;
  left: 50%;
  transform: translate(-50%, -10%);
}

app-add-post add-post ion-content ion-row form .add_poast_card {
  border-bottom: 1px solid var(--ion-border-color);
  padding-bottom: 20px;
}
app-add-post add-post ion-content ion-row form .submit_btn ion-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 0px;
}

app-add-post add-post ion-header,
app-admin-add-post add-post ion-header {
  padding: 0px 15px;
  width: 40%;
  margin: 0 auto;
  background-color: var(--ion-web-background-color) !important;
}
app-add-post add-post ion-header ion-toolbar app-page-header .header_btns ion-row ion-col h3,
app-admin-add-post add-post ion-header ion-toolbar app-page-header .header_btns ion-row ion-col h3 {
  color: var(--ion-text-color) !important;
  font-size: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 210px;
  white-space: nowrap;
  text-align: left;
  font-weight: 500;
}
app-add-post add-post ion-content,
app-admin-add-post add-post ion-content {
  width: 100%;
}
app-add-post add-post ion-content ion-row,
app-admin-add-post add-post ion-content ion-row {
  padding-bottom: 30px;
  height: 100%;
  width: 40%;
  margin: 0 auto;
}
app-add-post add-post ion-content ion-row form,
app-admin-add-post add-post ion-content ion-row form {
  width: 100%;
}
app-add-post add-post ion-content ion-row form .submit_btn,
app-admin-add-post add-post ion-content ion-row form .submit_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px 50px;
}
app-add-post add-post ion-content ion-row form .submit_btn ion-button,
app-admin-add-post add-post ion-content ion-row form .submit_btn ion-button {
  width: 100%;
}
app-add-post add-post ion-content ion-row form .submit_btn .pub_policy,
app-admin-add-post add-post ion-content ion-row form .submit_btn .pub_policy {
  margin: 4px 2px;
  height: 40px;
  align-items: flex-end;
}
app-add-post add-post ion-content ion-row form .submit_btn .pub_policy app-public-policy ion-card-content,
app-admin-add-post add-post ion-content ion-row form .submit_btn .pub_policy app-public-policy ion-card-content {
  padding: 0;
}
app-add-post add-post ion-content ion-row form .submit_btn .pub_policy app-public-policy ion-card-content span,
app-admin-add-post add-post ion-content ion-row form .submit_btn .pub_policy app-public-policy ion-card-content span {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-add-post add-post ion-content ion-row form ion-card,
app-admin-add-post add-post ion-content ion-row form ion-card {
  background-color: unset !important;
  margin: 20px 15px !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content {
  padding: 0 !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root {
  margin-bottom: 0px !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label {
  background-color: var(--ion-item-background-black);
  color: var(--ion-white-color);
  border-radius: 5px;
  min-height: 3.75em !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label {
  width: 100%;
  padding: 0 10px;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn .left,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn .left {
  text-align: start;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn .left .tittle,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn .left .tittle {
  font-weight: 300;
  display: block !important;
  color: var(--ion-item-bb-text-label) !important;
  z-index: 99;
  position: relative;
  transform: none !important;
  font-size: 12px !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn .left .sub_text,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn .left .sub_text {
  font-size: 14px !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn .right.upload_btn,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--drop-label label .img_upload_btn .right.upload_btn {
  padding: 4px 35px;
  border: 1px solid #fff;
  border-radius: 25px;
  font-size: 14px;
  margin-right: 1%;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--list-scroller,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--list-scroller {
  margin-top: 60px;
  transform: unset !important;
  -webkit-transform: unset !important;
  -moz-transform: unset !important;
  -ms-transform: unset !important;
  -o-transform: unset !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--list-scroller .filepond--list,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--list-scroller .filepond--list {
  width: 100%;
  left: 0 !important;
  right: 0 !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--list-scroller .filepond--list .filepond--item,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--list-scroller .filepond--list .filepond--item {
  margin: 15px 0 !important;
  transform: unset !important;
  position: relative;
  height: 310px !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--list-scroller .filepond--list .filepond--item fieldset .filepond--file button,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--list-scroller .filepond--list .filepond--item fieldset .filepond--file button {
  cursor: pointer !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--panel-root,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content .filepond--root .filepond--panel-root {
  background-color: transparent !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content app-video-play,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content app-video-play {
  position: relative;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content app-video-play .video-js,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content app-video-play .video-js {
  padding-top: 56.25% !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item .active_sub_category ion-chip,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item .active_sub_category ion-chip {
  background-color: var(--ion-theme-color);
  color: var(--ion-white-color);
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item .active_sub_category ion-chip ion-icon,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item .active_sub_category ion-chip ion-icon {
  font-size: 20px !important;
  color: var(--ion-white-color);
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time {
  width: 100%;
  background: var(--ion-item-background-black);
  border-radius: var(--border-radius-s);
  --padding-top: 30px !important;
  --padding-start: 10px !important;
  --padding-end: 20px !important;
  height: 60px;
  opacity: 1 !important;
  color: var(--ion-white-color);
  z-index: 9;
  font-size: 14px !important;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time a24-label ion-label,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time a24-label ion-label {
  transform: none !important;
  font-size: 12px !important;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time .set_time_info .time_info,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time .set_time_info .time_info {
  padding-left: 10px;
  padding-top: 3px;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time .set_time_info .time_info p span,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time .set_time_info .time_info p span {
  margin: 0 10px 0 0;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time ion-icon,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time ion-icon {
  font-size: 14px !important;
  padding-right: 6%;
}
app-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time .add_btn,
app-admin-add-post add-post ion-content ion-row form ion-card ion-card-content ion-item.custom_time_filed .set_time .add_btn {
  padding: 4px 35px;
  border: 1px solid #fff;
  border-radius: 25px;
  font-size: 14px;
  margin-right: 4%;
  color: #fff;
  font-weight: 400;
}
app-add-post add-post ion-content ion-row form ion-card.required_filed a24-label ion-label,
app-admin-add-post add-post ion-content ion-row form ion-card.required_filed a24-label ion-label {
  color: var(--ion-white-color);
  font-size: 20px !important;
}
app-add-post add-post ion-content ion-row form ion-card.required_filed h5,
app-admin-add-post add-post ion-content ion-row form ion-card.required_filed h5 {
  color: var(--ion-white-color);
}
app-add-post add-post ion-content ion-row form .product_submit,
app-admin-add-post add-post ion-content ion-row form .product_submit {
  --box-shadow: unset;
}
app-add-post add-post ion-content ion-row form .product_submit::part(native),
app-admin-add-post add-post ion-content ion-row form .product_submit::part(native) {
  --box-shadow: unset;
  height: 40px;
  margin-top: 24px;
  width: 280px;
}

.popover-class .popover-viewport ion-list ion-item {
  --border-color: unset;
  --inner-border-width: 0;
}
.popover-class .popover-viewport ion-list ion-item .popover_cotainer_text {
  width: 100%;
}
.popover-class .popover-viewport ion-list ion-item .popover_cotainer_text .popup_box,
.popover-class .popover-viewport ion-list ion-item .popover_cotainer_text div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--ion-white-color);
}

app-add-product ion-content ion-button {
  margin-bottom: 30px;
  margin-top: 30px;
}
app-add-product ion-content ion-button::part(native) {
  box-shadow: none !important;
}
app-add-product ion-content ion-card ion-card-content {
  padding: 0 !important;
}
app-add-product ion-content ion-card ion-card-content .price_input {
  justify-content: space-between;
  align-items: center;
}
app-add-product ion-content ion-card ion-card-content .price_input ion-col {
  padding: 15px 5px !important;
  position: relative;
}
app-add-product ion-content ion-card ion-card-content .price_input ion-col:first-child() {
  padding-left: 0 !important;
}
app-add-product ion-content ion-card ion-card-content .price_input ion-col:last-child() {
  padding-right: 0 !important;
}
app-add-product ion-content ion-card ion-card-content .price_input ion-col .price_sign {
  position: absolute;
  z-index: 999;
  top: 36px;
  right: 15px;
}
app-add-product ion-content ion-card ion-card-content .error_msg .valid_msg .form-con-errors .hide-err {
  margin-top: -14px !important;
  padding-bottom: 5px;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box {
  justify-content: space-between;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col {
  padding: 0 5px !important;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col ion-item a24-select-component .custom_select {
  position: relative;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:first-child() {
  padding-left: 0 !important;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() {
  padding-right: 0 !important;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper button.filepond--file-action-button.filepond--action-edit-item {
  display: none;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--file-info {
  display: none;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--action-remove-item {
  width: 20px;
  height: 20px;
  left: 8px;
  top: 5px;
  margin-left: 13px;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root {
  margin-bottom: 0 !important;
  height: auto !important;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--drop-label {
  background-color: var(--ion-theme-color);
  color: var(--ion-white-color);
  padding: 0;
  margin: 0;
  border: unset;
  width: 100%;
  position: relative;
  min-height: 100% !important;
  height: 60px;
  border-radius: 8px;
  font-size: 12px;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--drop-label label .product_img_box {
  color: var(--ion-white-color);
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--drop-label label .product_img_box .product_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--drop-label label .product_img_box .product_icon ion-icon {
  color: var(--ion-white-color);
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--list-scroller {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--list-scroller .filepond--list .filepond--item {
  height: 60px !important;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--list-scroller .filepond--list .filepond--item .filepond--panel .filepond--item-panel .filepond--panel-center {
  height: auto !important;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--panel-center {
  height: 30px !important;
  border-radius: 8px !important;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--panel-root {
  background-color: var(--ion-background-color) !important;
}
app-add-product ion-content ion-card ion-card-content .producat_image_box ion-col:last-child() a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--panel-root {
  background-color: var(--ion-background-color) !important;
  padding: 0;
  margin: 0 !important;
}
app-add-product ion-content ion-card ion-card-content .source_link ion-col {
  padding: 0 !important;
}
app-add-product ion-content ion-card ion-card-content .add_remove_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-add-product ion-content ion-card ion-card-content .add_remove_sec .add_link,
app-add-product ion-content ion-card ion-card-content .add_remove_sec .control_link,
app-add-product ion-content ion-card ion-card-content .add_remove_sec .rm_link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--ion-theme-color);
  font-size: 18px;
  font-weight: 400;
  margin: 15px 0;
  float: right;
  width: 100%;
  cursor: pointer;
}
app-add-product ion-content ion-card ion-card-content .add_remove_sec .add_link ion-icon,
app-add-product ion-content ion-card ion-card-content .add_remove_sec .control_link ion-icon,
app-add-product ion-content ion-card ion-card-content .add_remove_sec .rm_link ion-icon {
  margin-right: 10px;
  color: var(--ion-theme-color);
}
app-add-product ion-content ion-card ion-card-content ion-row ion-col a24-label ion-label {
  color: var(--ion-white-color);
}

app-no-network ion-content .network_container {
  width: 40%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
app-no-network ion-content .network_container .no_network_icon {
  width: 100%;
  margin: 0 auto;
}
app-no-network ion-content .network_container .no_network_icon svg {
  width: 80%;
  padding: 20px;
  height: 100%;
  padding-top: 200px;
  margin: 0 auto;
  display: block;
}
app-no-network ion-content .network_container .no_network_footer {
  bottom: 0;
  left: 0;
  background-color: var(--ion-item-background-black);
  width: 100%;
  margin: 0 auto;
  padding: 35px;
}
app-no-network ion-content .network_container .no_network_footer .text_box {
  padding-bottom: 15px;
}
app-no-network ion-content .network_container .no_network_footer .text_box h4 {
  font-size: 24px;
}
app-no-network ion-content .network_container .no_network_footer .text_box p {
  font-size: 18px;
  line-height: 30px;
  padding: 0;
  margin: 0;
}
app-no-network ion-content .network_container .no_network_footer ion-button {
  width: 158px;
  height: 45px;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
}
app-no-network ion-content .network_container .no_network_footer ion-button::part(native) {
  box-shadow: none;
}

.pub_policy {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pub_content {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: inherit;
  display: block;
  position: relative;
  line-height: 1.5;
}
.pub_content p {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

app-sample-post-detail ion-header,
app-post-detail ion-header {
  position: absolute;
  background: transparent !important;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .fit app-post-slides,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .fit app-post-slides {
  cursor: pointer;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .fit app-post-slides ion-card ion-slides .swiper-wrapper ion-slide img,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .fit app-post-slides ion-card ion-slides .swiper-wrapper ion-slide img {
  object-fit: contain !important;
  object-position: 50% 40%;
  cursor: pointer;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item {
  padding: 0 !important;
  position: relative;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item.fit app-post-slides ion-card ion-slides ion-slide app-video-play video,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item.fit app-post-slides ion-card ion-slides ion-slide app-video-play video {
  object-fit: contain !important;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item app-post-slides ion-card ion-slides .swiper-wrapper ion-slide ion-img::part(image),
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item app-post-slides ion-card ion-slides .swiper-wrapper ion-slide ion-img::part(image) {
  height: var(--height-viewport);
  object-fit: cover;
  object-position: center center;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100% !important;
  max-width: 80% !important;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .info,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--ion-blur-color);
  margin: 0;
  height: 25px;
  border-radius: 50px;
  padding: 0 10px;
  width: fit-content;
  margin-left: 8px;
  cursor: pointer;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .info p,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .info p {
  margin: 0;
  margin-left: 5px;
  font-size: 14px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .post_product_link,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .post_product_link {
  margin-left: 5px;
  z-index: 998;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
  margin-left: 8px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar {
  border: solid 2px transparent;
  width: 40px;
  height: 40px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar ion-thumbnail,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar ion-thumbnail {
  position: relative;
  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 50px;
  margin: -5px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar ion-thumbnail ion-img,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar ion-thumbnail ion-img {
  position: relative;
  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 50px;
  margin: 0px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar ion-thumbnail ion-img::before,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar ion-thumbnail ion-img::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px; /* same as border width */
  border-radius: inherit; /* inherit container box's radius */
  background: linear-gradient(207deg, #ffcd1d 0%, #fc7823 25%, #da181a 100%);
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar ion-thumbnail ion-img::part(image),
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details app-c-avatar .user_profile ion-avatar ion-thumbnail ion-img::part(image) {
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--ion-white-color);
  margin: 1px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details .profile_img,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details .profile_img {
  margin-left: 10px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details .profile_img p,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .user_details .profile_img p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 5px;
  z-index: 999;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .comment_icon,
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .share_icon,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .comment_icon,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .share_icon {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 58px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .comment_icon ion-icon,
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .share_icon ion-icon,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .comment_icon ion-icon,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .share_icon ion-icon {
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
  font-size: 29px !important;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .comment_icon p,
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .share_icon p,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .comment_icon p,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .share_icon p {
  padding: 0;
  margin: 0;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .comment_icon span,
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .share_icon span,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .comment_icon span,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .share_icon span {
  color: var(--ion-white-color);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .arrow_icon,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .arrow_icon {
  width: auto;
  height: 20px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .arrow_icon ion-icon,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .arrow_icon ion-icon {
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
  font-size: 29px !important;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .volume_icon,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .volume_icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 45px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .volume_icon ion-icon,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .volume_icon ion-icon {
  width: 25px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 60px;
  width: 100%;
  position: relative;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart a24-button-heart,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart a24-button-heart {
  height: 30px;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart a24-button-heart .button,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart a24-button-heart .button {
  position: relative;
  top: unset;
  left: unset;
  margin-right: unset;
  transform: unset;
  width: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  transition: 0.4s;
  outline: 0;
  text-align: center;
  color: var(--ion-white-color);
  font-family: unset;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 14px;
  text-transform: uppercase;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart a24-button-heart .button ion-icon,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart a24-button-heart .button ion-icon {
  font-size: 29px !important;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart a24-button-heart .button:hover,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart a24-button-heart .button:hover {
  transform: unset;
}
app-sample-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart p,
app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .right_detail_box .post_page_heart p {
  color: var(--ion-white-color);
  padding: 0;
  margin: 0;
}

app-sample-post-detail ion-content,
app-post-detail ion-content {
  position: fixed;
  z-index: 99999;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper {
  width: 100%;
  height: 100vh;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  scroll-snap-align: center;
  transform: translateY(100vh);
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides {
  height: 100vh;
  position: fixed;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides ion-card,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides ion-card {
  height: 100vh;
  margin: 0;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides ion-card ion-slides,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides ion-card ion-slides {
  height: 100%;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides ion-card ion-slides ion-slide,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides ion-card ion-slides ion-slide {
  margin: 0;
  width: 100% !important;
  margin: 0 auto !important;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides ion-card ion-slides ion-slide img,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page app-post-slides ion-card ion-slides ion-slide img {
  object-fit: cover !important;
  aspect-ratio: 9/18;
  height: 100vh;
  width: 100%;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page {
  position: absolute;
  bottom: 150px;
  width: 100%;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col p,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col p {
  font-size: 16px;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide {
  margin-right: 0;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img {
  flex-direction: column;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 {
  border-radius: 3px;
  padding: 0 0 0 15px;
  background-color: #4e4e4e;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 span,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 span {
  line-height: 0;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 span ion-icon,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 span ion-icon {
  padding: 5px 10px;
  margin-left: 15px;
  background-color: #e45826;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_page_footer,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_page_footer {
  position: fixed;
  bottom: 0;
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_page_footer ion-toolbar,
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_page_footer ion-toolbar {
  background-color: var(--ion-background-color);
}
app-sample-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_page_footer::before(),
app-post-detail ion-content .cdk-virtual-scroll-content-wrapper .full_post_detail_page .post_detail_page_footer::before() {
  display: none;
}

.detail-viewport {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  width: 100%;
  height: var(--height-viewport); /* updated from 100vh */
}

.list-group-item {
  height: var(--height-viewport);
  width: 100%;
  scroll-snap-align: start;
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
}

/*.cdk-virtual-scroll-content-wrapper {
  overflow-y: scroll !important; 
  scroll-snap-type: y mandatory !important; //anim
}*/
app-add-product .product_box {
  border-bottom: 1px solid var(--ion-border-color);
}
app-add-product .product_box .requirement_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-add-product .product_box .requirement_sec .head_txt {
  width: 100%;
}
app-add-product .product_box .requirement_sec .head_txt p {
  color: var(--ion-white-color);
  font-size: 20px !important;
}
app-add-product .product_box .requirement_sec .control_link.rm_link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--ion-theme-color);
  font-size: 18px;
  font-weight: 400;
  margin: 15px 0;
  float: right;
  width: 100%;
  cursor: pointer;
}
app-add-product .product_box .requirement_sec .control_link.rm_link ion-icon {
  margin-right: 10px;
  color: var(--ion-theme-color);
}
app-add-product .product_box ion-item.custom_unit_field .set_unit {
  width: 100%;
  background: var(--ion-item-background-black);
  border-radius: var(--border-radius-s);
  --padding-top: 30px !important;
  --padding-start: 10px !important;
  --padding-end: 20px !important;
  height: 60px;
  opacity: 1 !important;
  color: var(--ion-white-color);
  z-index: 9;
  font-size: 14px !important;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-add-product .product_box ion-item.custom_unit_field .set_unit s a24-label ion-label {
  transform: none !important;
  font-size: 12px !important;
}
app-add-product .product_box ion-item.custom_unit_field .set_unit .set_unit_info a24-label .label-floating {
  transform: translateY(0%) !important;
  -webkit-transform: translateY(0%) !important;
  -moz-transform: translateY(0%) !important;
  -ms-transform: translateY(0%) !important;
  -o-transform: translateY(0%) !important;
}
app-add-product .product_box ion-item.custom_unit_field .set_unit .set_unit_info .unit_info {
  padding-left: 10px;
  padding-top: 3px;
}
app-add-product .product_box ion-item.custom_unit_field .set_unit .set_unit_info .unit_info p span {
  margin: 0 10px 0 0;
}
app-add-product .product_box ion-item.custom_unit_field .set_unit ion-icon {
  font-size: 14px !important;
  padding-right: 6%;
}
app-add-product .product_box ion-item.custom_unit_field .set_unit .add_btn {
  padding: 4px 35px;
  border: 1px solid #fff;
  border-radius: 25px;
  font-size: 14px;
  margin-right: 4%;
  color: #fff;
  font-weight: 400;
}

.list-group-item app-post-slides {
  height: var(--height-viewport);
  position: fixed;
  width: 100%;
}
.list-group-item app-post-slides ion-card {
  height: var(--height-viewport);
  margin: 0;
  border-radius: unset;
}
.list-group-item app-post-slides ion-card ion-slides {
  height: var(--height-viewport);
}
.list-group-item app-post-slides ion-card ion-slides ion-slide {
  margin: 0;
  width: 100% !important;
  margin: 0 auto !important;
}
.list-group-item app-post-slides ion-card ion-slides ion-slide img {
  object-fit: cover !important;
  aspect-ratio: 9/18;
  height: var(--height-viewport) !important;
  width: 100%;
  border-radius: 10px;
}
.list-group-item .post_detail_main_page {
  position: absolute;
  bottom: 10px;
  width: 100%;
  z-index: 99;
}
.list-group-item .post_detail_main_page ion-col p {
  font-size: 16px;
  color: var(--ion-white-color);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 15px 0px 15px 15px;
}
.list-group-item .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper {
  margin-bottom: 5px;
}
.list-group-item .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide {
  margin-right: 0;
}
.list-group-item .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img {
  flex-direction: column;
  margin-bottom: 0;
  width: auto;
  height: 100%;
}
.list-group-item .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img ion-thumbnail {
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  width: 60px;
  height: 60px;
}
.list-group-item .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 {
  border-radius: 3px;
  padding: 0 0 0 4px;
  background-color: var(--ion-blur-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--ion-white-color);
  font-weight: 100;
  font-size: 12px;
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
  margin-bottom: 0;
  width: 68px;
  height: 22px;
  line-height: 15px;
}
.list-group-item .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 span {
  line-height: 0;
}
.list-group-item .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img h4 span ion-icon {
  padding: 5px;
  margin-left: 5px;
  background-color: #e45826;
  font-size: 10px !important;
  border-radius: 0 2px 2px 0;
  border: 0.8px solid #e45826;
}
.list-group-item .post_detail_main_page ion-col a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .slide_img ion-thumbnail ion-img::part(image) {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-position: center;
}
.list-group-item .post_detail_page_footer {
  position: fixed;
  bottom: 0;
}
.list-group-item .post_detail_page_footer ion-toolbar {
  background-color: var(--ion-background-color);
}
.list-group-item .post_detail_page_footer::before() {
  display: none;
}

app-add-profile ion-footer,
app-category ion-footer,
app-follower-suggestion ion-footer {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  width: 100%;
  box-shadow: 0px 8px 67px rgba(0, 0, 0, 0.3);
}
app-add-profile ion-footer .category_btn,
app-category ion-footer .category_btn,
app-follower-suggestion ion-footer .category_btn {
  margin-right: 20px;
}

app-follower-suggestion ion-footer {
  width: 40%;
  margin: 0 auto;
}

.link_clipboard {
  opacity: 0 !important;
  width: 100% !important;
  height: 1px !important;
  position: absolute;
}

.video-item app-post-slides {
  position: relative !important;
}
.video-item app-post-slides ion-card ion-slides ion-slide app-video-play .post-video-dimensions {
  height: 100vh !important;
}
.video-item app-post-slides ion-card ion-slides ion-slide app-video-play video {
  width: 100% !important;
  height: var(--height-viewport) !important;
  object-fit: cover;
  border-radius: 10px;
}
.video-item app-post-slides ion-card ion-slides ion-slide app-video-play .video-js {
  border-radius: 10px;
  background: transparent !important;
}

app-all-post .post_search_header {
  width: 40%;
  padding: 0 !important;
  margin: 0 auto;
}
app-all-post .post_search_viewport {
  height: 100vh;
  width: 40%;
  margin: 0 auto;
  overflow-x: hidden;
}
app-all-post .post_search_viewport .cdk-virtual-scroll-content-wrapper {
  margin: 0 auto;
  width: 100% !important;
  min-width: 100% !important;
  top: 0;
  left: 0;
  contain: content;
  right: 0;
}

app-add-post add-post {
  width: 100%;
  margin: 0 auto;
}
app-add-post add-post .add_post {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
}

ion-modal app-login ion-header,
ion-modal app-register ion-header,
ion-modal app-forgot ion-header {
  width: 100%;
  margin: 0 auto;
  padding: 15px 0 20px;
}
ion-modal app-login ion-content ion-grid,
ion-modal app-register ion-content ion-grid,
ion-modal app-forgot ion-content ion-grid {
  width: 100%;
  margin: 0 auto !important;
  padding: 0 !important;
}
ion-modal app-guest-user-model .guest_page {
  margin: 15px auto;
  background-color: var(--ion-item-background-black);
}
ion-modal app-guest-user-model .guest_page .head p {
  padding: 0;
  margin: 0;
  text-align: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
}
ion-modal app-guest-user-model .guest_page .sub_text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
ion-modal app-guest-user-model .guest_page .sub_text ul {
  list-style: none;
  padding: 0;
}
ion-modal app-guest-user-model .guest_page .sub_text ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
ion-modal app-guest-user-model .guest_page .sub_text ul li ion-icon {
  font-size: 16px !important;
  margin-right: 10px;
  color: var(--ion-item-bb-text-label) !important;
}
ion-modal app-guest-user-model .guest_page .sub_text ul li span {
  font-size: 14px;
  font-weight: 100;
  color: var(--ion-item-bb-text-label) !important;
}

.slider_container {
  margin: 0 auto;
  width: 40%;
}
.slider_container ion-slide {
  width: 100% !important;
}

app-forgot ion-content {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}

app-login ion-header,
app-register ion-header,
app-forgot ion-header {
  width: 40%;
  margin: 0 auto;
  padding: 15px 0 20px;
}
app-login ion-content ion-grid,
app-register ion-content ion-grid,
app-forgot ion-content ion-grid {
  width: 40%;
  margin: 0 auto !important;
  padding: 0 !important;
}

app-edit-profile .profile,
app-add-profile .profile {
  margin: 0 auto;
  position: relative;
  width: 110px !important;
  height: 110px !important;
}
app-edit-profile ion-footer,
app-add-profile ion-footer {
  width: 40%;
  margin: 0 auto;
}
app-edit-profile ion-header,
app-add-profile ion-header {
  width: 40%;
  margin: 0 auto;
  padding: 15px 0 20px;
  background-color: var(--ion-web-background-color) !important;
}
app-edit-profile ion-content,
app-add-profile ion-content {
  --padding-start: 0;
  --padding-end: 0;
}
app-edit-profile ion-content ion-title,
app-add-profile ion-content ion-title {
  width: 40%;
  margin: 0 auto;
  padding: 0 10px;
}
app-edit-profile ion-content ion-list,
app-add-profile ion-content ion-list {
  width: 40%;
  margin: 0 auto !important;
}
app-edit-profile ion-content ion-grid,
app-add-profile ion-content ion-grid {
  width: 40%;
  margin: 0 auto !important;
  padding: 0 10px;
}
app-edit-profile ion-content ion-grid ion-title,
app-add-profile ion-content ion-grid ion-title {
  width: 100%;
  margin: 0 !important;
}
app-edit-profile ion-content ion-grid ion-row ion-col,
app-add-profile ion-content ion-grid ion-row ion-col {
  width: 100% !important;
  max-width: 100% !important;
  flex: unset !important;
}
app-edit-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content ion-button,
app-add-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content ion-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 0px !important;
}
app-edit-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content ion-button::part(native),
app-add-profile ion-content ion-grid ion-row ion-col ion-card ion-card-content ion-button::part(native) {
  --box-shadow: unset;
  height: 40px;
  margin-top: 24px;
  width: 280px;
}

app-policies ion-header {
  width: 40%;
  margin: 0 auto;
  padding: 15px 0 20px;
}
app-policies ion-content .pub_content {
  width: 40%;
  margin: 25px auto !important;
}

app-notifications ion-header {
  width: 40%;
  margin: 0 auto;
  padding: 15px 0 20px;
}
app-notifications ion-content ion-card {
  width: 40%;
  margin: 25px auto !important;
}

app-change-password ion-header {
  width: 40%;
  margin: 0 auto;
}
app-change-password ion-content ion-title {
  width: 40%;
  margin: 15px auto 0;
}
app-change-password ion-content ion-grid {
  width: 40%;
  margin: 0 auto !important;
}

app-settings ion-header,
app-followers-settings ion-header,
app-post-settings ion-header,
app-allow-comments ion-header {
  width: 40%;
  margin: 0 auto;
  padding: 15px 0 20px;
}
app-settings ion-content ion-title,
app-followers-settings ion-content ion-title,
app-post-settings ion-content ion-title,
app-allow-comments ion-content ion-title {
  width: 40%;
  margin: 0 auto;
}
app-settings ion-content ion-card,
app-followers-settings ion-content ion-card,
app-post-settings ion-content ion-card,
app-allow-comments ion-content ion-card {
  width: 40%;
  margin: 25px auto !important;
}
app-settings ion-content ion-list,
app-followers-settings ion-content ion-list,
app-post-settings ion-content ion-list,
app-allow-comments ion-content ion-list {
  width: 40%;
  margin: 0 auto !important;
}
app-settings ion-content ion-grid,
app-followers-settings ion-content ion-grid,
app-post-settings ion-content ion-grid,
app-allow-comments ion-content ion-grid {
  width: 40%;
  margin: 0 auto !important;
}
app-settings ion-content ion-grid ion-row ion-col,
app-followers-settings ion-content ion-grid ion-row ion-col,
app-post-settings ion-content ion-grid ion-row ion-col,
app-allow-comments ion-content ion-grid ion-row ion-col {
  width: 100% !important;
  max-width: 100% !important;
  flex: unset !important;
}

app-blocked-user ion-header,
app-following-list ion-header,
app-followers-list ion-header,
app-muted-user ion-header,
app-about ion-header,
app-follow-request ion-header,
app-follow-request-sent ion-header,
app-no-network ion-header {
  width: 40%;
  margin: 0 auto;
  padding: 15px 0 20px;
}
app-blocked-user ion-content ion-title,
app-following-list ion-content ion-title,
app-followers-list ion-content ion-title,
app-muted-user ion-content ion-title,
app-about ion-content ion-title,
app-follow-request ion-content ion-title,
app-follow-request-sent ion-content ion-title,
app-no-network ion-content ion-title {
  width: 40%;
  margin: 0 auto;
  height: auto;
  position: relative;
  padding-inline: 18px;
  text-align: start;
}
app-blocked-user ion-content .user_action_list,
app-following-list ion-content .user_action_list,
app-followers-list ion-content .user_action_list,
app-muted-user ion-content .user_action_list,
app-about ion-content .user_action_list,
app-follow-request ion-content .user_action_list,
app-follow-request-sent ion-content .user_action_list,
app-no-network ion-content .user_action_list {
  width: 40%;
  margin: 0 auto !important;
}
app-blocked-user ion-content .following_list,
app-following-list ion-content .following_list,
app-followers-list ion-content .following_list,
app-muted-user ion-content .following_list,
app-about ion-content .following_list,
app-follow-request ion-content .following_list,
app-follow-request-sent ion-content .following_list,
app-no-network ion-content .following_list {
  width: 40%;
  margin: 0 auto !important;
}
app-blocked-user ion-content .following_list ion-card .account-list app-account-list,
app-following-list ion-content .following_list ion-card .account-list app-account-list,
app-followers-list ion-content .following_list ion-card .account-list app-account-list,
app-muted-user ion-content .following_list ion-card .account-list app-account-list,
app-about ion-content .following_list ion-card .account-list app-account-list,
app-follow-request ion-content .following_list ion-card .account-list app-account-list,
app-follow-request-sent ion-content .following_list ion-card .account-list app-account-list,
app-no-network ion-content .following_list ion-card .account-list app-account-list {
  padding: 0;
}
app-blocked-user ion-content .following_list ion-card .account-list app-account-list .acct-list-content .card-modal .user_request_btn .account_btn_type,
app-following-list ion-content .following_list ion-card .account-list app-account-list .acct-list-content .card-modal .user_request_btn .account_btn_type,
app-followers-list ion-content .following_list ion-card .account-list app-account-list .acct-list-content .card-modal .user_request_btn .account_btn_type,
app-muted-user ion-content .following_list ion-card .account-list app-account-list .acct-list-content .card-modal .user_request_btn .account_btn_type,
app-about ion-content .following_list ion-card .account-list app-account-list .acct-list-content .card-modal .user_request_btn .account_btn_type,
app-follow-request ion-content .following_list ion-card .account-list app-account-list .acct-list-content .card-modal .user_request_btn .account_btn_type,
app-follow-request-sent ion-content .following_list ion-card .account-list app-account-list .acct-list-content .card-modal .user_request_btn .account_btn_type,
app-no-network ion-content .following_list ion-card .account-list app-account-list .acct-list-content .card-modal .user_request_btn .account_btn_type {
  margin-left: 15px;
}

app-follower-suggestion ion-content .follower_head_text {
  margin: 0 auto;
  width: 40%;
}
app-follower-suggestion ion-content follower-suggestion .follower-suggestion-page {
  margin: 0 auto;
  width: 40%;
}

app-save-posts ion-header {
  width: 40%;
  margin: 0 auto;
  padding: 15px 0 20px;
}
app-save-posts .user_postlist {
  width: 40%;
  margin: 0 auto;
}

app-notification ion-header,
app-privacy-security ion-header,
app-app-settings ion-header,
app-help ion-header {
  width: 40%;
  margin: 0 auto;
  padding: 15px 0 20px;
}
app-notification ion-content ion-title,
app-privacy-security ion-content ion-title,
app-app-settings ion-content ion-title,
app-help ion-content ion-title {
  width: 40%;
  margin: 0 auto;
}
app-notification ion-content a24-accordion ion-accordion-group,
app-privacy-security ion-content a24-accordion ion-accordion-group,
app-app-settings ion-content a24-accordion ion-accordion-group,
app-help ion-content a24-accordion ion-accordion-group {
  width: 40%;
  margin: 0 auto;
}
app-notification ion-content ion-card,
app-privacy-security ion-content ion-card,
app-app-settings ion-content ion-card,
app-help ion-content ion-card {
  width: 40%;
  margin: 0 auto !important;
}

app-add-product ion-content .product_box ion-card ion-card-content ion-row ion-col .custom_time_filed .form_group .set_time_info a24-label ion-label {
  transform: translateY(88%) !important;
}

.post_end {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin: 45px 0px;
  color: var(--ion-white-color);
}
.post_end ion-img::part(image) {
  width: 100%;
  height: 66px;
  padding-bottom: 15px;
  margin: 0 auto;
  padding-left: 6px;
}
.post_end P {
  margin: 0;
  line-height: 30px;
}

.search_content {
  display: flex;
  justify-content: space-evenly;
}
.search_content .is_active {
  color: var(--ion-theme-color) !important;
  font-weight: bold;
}

app-post-slides > ion-card > ion-slides > div.swiper-wrapper {
  height: var(--height-viewport) !important;
}

app-all-post cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper app-feed-post ion-card app-post-slides ion-card ion-slides .swiper-wrapper {
  height: 100% !important;
}

app-custom-skeleton {
  width: 40%;
  margin: 0 auto;
}

app-category .intro_category .categories {
  width: 40%;
  margin: 0 auto;
}
app-category .intro_footer {
  width: 40%;
  margin: 0 auto;
}

.greaterthan_4 .right_detail_box {
  position: absolute;
  right: 0;
  bottom: 115px;
}

.normal .right_detail_box {
  position: absolute;
  right: 0;
  bottom: 0;
}

.normal .right_detail_box {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 400px) {
  .normal .right_detail_box {
    position: absolute;
    right: 0;
    bottom: 115px !important;
  }
}
@media only screen and (max-width: 1600px) {
  .split-pane-side {
    left: 3%;
  }
  app-web-header .header_row .profile_btn {
    padding: 5px 20px;
  }
  app-profile user-profile ion-content .user_container .gallery .gallery-item {
    width: 22%;
  }
  app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide:first-child::after {
    right: -8px;
  }
}
@media only screen and (max-width: 1280px) {
  .split-pane-side {
    left: 1%;
  }
  app-follower-suggestion ion-footer {
    width: 60%;
    margin: 0 auto;
  }
  app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide:first-child::after {
    right: -8px;
  }
  app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
    width: 56% !important;
    min-width: 56% !important;
    margin: 0 15% 0 auto;
  }
  .example-viewport {
    width: 70%;
  }
  app-no-network ion-content .network_container {
    width: 60%;
  }
  app-category .intro_category .categories {
    width: 60%;
    margin: 0 auto;
  }
  app-category .intro_footer {
    width: 60%;
    margin: 0 auto;
  }
  .project_details_modal ion-content {
    --background: transparent !important;
  }
  .project_details_modal ion-content .project_transparent_text {
    width: 60%;
  }
  app-add-post add-post ion-header,
app-admin-add-post add-post ion-header {
    width: 60%;
  }
  app-add-post add-post ion-content ion-row,
app-admin-add-post add-post ion-content ion-row {
    width: 60%;
  }
  app-add-post add-post ion-content ion-row form .submit_btn,
app-admin-add-post add-post ion-content ion-row form .submit_btn {
    margin: 0 16px 80px;
  }
  app-category ion-content {
    height: 100%;
  }
  app-change-password ion-header {
    width: 60%;
    margin: 0 auto;
  }
  app-change-password ion-content ion-title {
    width: 60%;
    margin: 15px auto 0;
  }
  app-change-password ion-content ion-grid {
    width: 60%;
    margin: 0 auto !important;
  }
  .slider_container {
    margin: 0 auto;
    width: 60%;
  }
  .slider_container ion-slide {
    width: 100% !important;
  }
  app-login ion-header,
app-register ion-header,
app-forgot ion-header {
    width: 60%;
  }
  app-login ion-content ion-grid,
app-register ion-content ion-grid,
app-forgot ion-content ion-grid {
    width: 60%;
  }
  app-edit-profile .profile,
app-add-profile .profile {
    margin: 0 auto;
    position: relative;
    width: 110px !important;
    height: 110px !important;
  }
  app-edit-profile ion-footer,
app-add-profile ion-footer {
    width: 60%;
  }
  app-edit-profile ion-header,
app-add-profile ion-header {
    width: 60%;
  }
  app-edit-profile ion-content ion-title,
app-add-profile ion-content ion-title {
    width: 60%;
  }
  app-edit-profile ion-content ion-list,
app-add-profile ion-content ion-list {
    width: 60%;
  }
  app-edit-profile ion-content ion-grid,
app-add-profile ion-content ion-grid {
    width: 60%;
  }
  app-edit-profile ion-content ion-grid ion-row ion-col,
app-add-profile ion-content ion-grid ion-row ion-col {
    width: 100% !important;
    max-width: 100% !important;
    flex: unset !important;
  }
  app-policies ion-header {
    width: 60%;
  }
  app-policies ion-content .pub_content {
    width: 60%;
  }
  app-notifications ion-header {
    width: 60%;
  }
  app-notifications ion-content ion-card {
    width: 60%;
  }
  app-settings ion-header,
app-followers-settings ion-header,
app-post-settings ion-header,
app-allow-comments ion-header {
    width: 60%;
  }
  app-settings ion-content ion-title,
app-followers-settings ion-content ion-title,
app-post-settings ion-content ion-title,
app-allow-comments ion-content ion-title {
    width: 60%;
  }
  app-settings ion-content ion-card,
app-followers-settings ion-content ion-card,
app-post-settings ion-content ion-card,
app-allow-comments ion-content ion-card {
    width: 60%;
  }
  app-settings ion-content ion-list,
app-followers-settings ion-content ion-list,
app-post-settings ion-content ion-list,
app-allow-comments ion-content ion-list {
    width: 60%;
  }
  app-settings ion-content ion-grid,
app-followers-settings ion-content ion-grid,
app-post-settings ion-content ion-grid,
app-allow-comments ion-content ion-grid {
    width: 60%;
  }
  app-settings ion-content ion-grid ion-row ion-col,
app-followers-settings ion-content ion-grid ion-row ion-col,
app-post-settings ion-content ion-grid ion-row ion-col,
app-allow-comments ion-content ion-grid ion-row ion-col {
    width: 100% !important;
    max-width: 100% !important;
    flex: unset !important;
  }
  app-blocked-user ion-header,
app-following-list ion-header,
app-followers-list ion-header,
app-muted-user ion-header,
app-about ion-header,
app-follow-request ion-header,
app-follow-request-sent ion-header,
app-no-network ion-header {
    width: 60%;
  }
  app-blocked-user ion-content ion-title,
app-following-list ion-content ion-title,
app-followers-list ion-content ion-title,
app-muted-user ion-content ion-title,
app-about ion-content ion-title,
app-follow-request ion-content ion-title,
app-follow-request-sent ion-content ion-title,
app-no-network ion-content ion-title {
    width: 60%;
  }
  app-blocked-user ion-content .user_action_list,
app-following-list ion-content .user_action_list,
app-followers-list ion-content .user_action_list,
app-muted-user ion-content .user_action_list,
app-about ion-content .user_action_list,
app-follow-request ion-content .user_action_list,
app-follow-request-sent ion-content .user_action_list,
app-no-network ion-content .user_action_list {
    width: 60%;
  }
  app-blocked-user ion-content .following_list,
app-following-list ion-content .following_list,
app-followers-list ion-content .following_list,
app-muted-user ion-content .following_list,
app-about ion-content .following_list,
app-follow-request ion-content .following_list,
app-follow-request-sent ion-content .following_list,
app-no-network ion-content .following_list {
    width: 60%;
  }
  app-follower-suggestion ion-content .follower_head_text {
    width: 60%;
  }
  app-follower-suggestion ion-content follower-suggestion .follower-suggestion-page {
    width: 60%;
  }
  app-save-posts ion-header {
    width: 60%;
  }
  app-save-posts .user_postlist {
    width: 60%;
  }
  app-notification ion-header,
app-privacy-security ion-header,
app-app-settings ion-header,
app-help ion-header {
    width: 60%;
  }
  app-notification ion-content ion-title,
app-privacy-security ion-content ion-title,
app-app-settings ion-content ion-title,
app-help ion-content ion-title {
    width: 60%;
  }
  app-notification ion-content a24-accordion ion-accordion-group,
app-privacy-security ion-content a24-accordion ion-accordion-group,
app-app-settings ion-content a24-accordion ion-accordion-group,
app-help ion-content a24-accordion ion-accordion-group {
    width: 60%;
  }
  app-notification ion-content ion-card,
app-privacy-security ion-content ion-card,
app-app-settings ion-content ion-card,
app-help ion-content ion-card {
    width: 60%;
  }
  app-all-post .post_search_header {
    width: 60%;
  }
  app-all-post .post_search_viewport {
    width: 60%;
  }
  app-all-post ion-content follower-suggestion .follower-suggestion-page,
app-sample ion-content follower-suggestion .follower-suggestion-page {
    width: 60%;
    margin: 15px auto 15px 21%;
  }
  app-page-not-found .pagenot_found_container .not_found_footer {
    width: 60%;
  }
  app-custom-skeleton {
    width: 60%;
    margin: 0 auto;
  }
  app-profile user-profile ion-header,
app-guest-user-profile user-profile ion-header {
    width: 60%;
  }
  app-profile user-profile ion-content app-page-not-found .pagenot_found_container,
app-guest-user-profile user-profile ion-content app-page-not-found .pagenot_found_container {
    width: 60%;
    margin: 0 auto;
  }
  app-profile user-profile ion-content .user_container,
app-guest-user-profile user-profile ion-content .user_container {
    width: 60%;
  }
  app-guest-user-model .guest_page {
    width: auto;
  }
}
@media only screen and (max-width: 1279px) {
  .split-pane-side {
    left: 1%;
  }
  app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide:first-child::after {
    right: -7px;
  }
  app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
    width: 56% !important;
    min-width: 56% !important;
    margin: 0 17% 0 auto;
  }
  .example-viewport {
    width: 100%;
    margin: 0 auto 0 10%;
  }
  app-category ion-content {
    height: 100%;
  }
  app-category ion-content .categories .category_row .category-col .category_box {
    padding: 5px;
  }
  app-category ion-content .categories .category_row .category-col .category_box p {
    font-size: 12px !important;
  }
  app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon {
    right: 42px !important;
  }
  app-custom-skeleton {
    width: 60%;
    margin: 0 auto;
  }
  app-about ion-content .about_page {
    margin: 30px auto 0;
    width: 60%;
  }
  app-web-header .header_row .profile_btn {
    padding: 5px 10px;
  }
}
@media only screen and (max-width: 820px) {
  .example-viewport {
    margin: 0px auto 0 17%;
  }
  app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide:first-child::after {
    right: -6px;
  }
  app-custom-skeleton {
    width: 70%;
  }
  app-no-network ion-content .network_container {
    width: 70%;
  }
  app-follower-suggestion ion-footer {
    width: 70%;
    margin: 0 auto;
  }
  app-profile user-profile ion-content .user_container .gallery .gallery-item {
    width: 21%;
    margin: 8px;
  }
  app-change-password ion-header {
    width: 70%;
  }
  app-change-password ion-content ion-title {
    width: 70%;
  }
  app-change-password ion-content ion-grid {
    width: 70%;
  }
  app-category .intro_category .categories {
    width: 70%;
    margin: 0 auto;
  }
  app-category .intro_footer {
    width: 70%;
    margin: 0 auto;
  }
  app-add-post add-post ion-header,
app-admin-add-post add-post ion-header {
    width: 70%;
  }
  app-add-post add-post ion-content ion-row,
app-admin-add-post add-post ion-content ion-row {
    width: 70%;
  }
  app-add-post add-post ion-content ion-row form .submit_btn,
app-admin-add-post add-post ion-content ion-row form .submit_btn {
    margin: 0 16px 80px;
  }
  .slider_container {
    width: 70%;
  }
  app-login ion-header,
app-register ion-header,
app-forgot ion-header {
    width: 70%;
  }
  app-login ion-content ion-grid,
app-register ion-content ion-grid,
app-forgot ion-content ion-grid {
    width: 70%;
  }
  app-edit-profile .profile,
app-add-profile .profile {
    margin: 0 auto;
    position: relative;
    width: 110px !important;
    height: 110px !important;
  }
  app-edit-profile ion-header,
app-add-profile ion-header {
    width: 70%;
  }
  app-edit-profile ion-footer,
app-add-profile ion-footer {
    width: 70%;
  }
  app-edit-profile ion-content ion-title,
app-add-profile ion-content ion-title {
    width: 70%;
  }
  app-edit-profile ion-content ion-list,
app-add-profile ion-content ion-list {
    width: 70%;
  }
  app-edit-profile ion-content ion-grid,
app-add-profile ion-content ion-grid {
    width: 70%;
  }
  app-policies ion-header {
    width: 70%;
  }
  app-policies ion-content .pub_content {
    width: 70%;
  }
  app-notifications ion-header {
    width: 70%;
  }
  app-notifications ion-content ion-card {
    width: 70%;
  }
  app-settings ion-header,
app-followers-settings ion-header,
app-post-settings ion-header,
app-allow-comments ion-header {
    width: 70%;
  }
  app-settings ion-content ion-title,
app-followers-settings ion-content ion-title,
app-post-settings ion-content ion-title,
app-allow-comments ion-content ion-title {
    width: 70%;
  }
  app-settings ion-content ion-card,
app-followers-settings ion-content ion-card,
app-post-settings ion-content ion-card,
app-allow-comments ion-content ion-card {
    width: 70%;
  }
  app-settings ion-content ion-list,
app-followers-settings ion-content ion-list,
app-post-settings ion-content ion-list,
app-allow-comments ion-content ion-list {
    width: 70%;
  }
  app-settings ion-content ion-grid,
app-followers-settings ion-content ion-grid,
app-post-settings ion-content ion-grid,
app-allow-comments ion-content ion-grid {
    width: 70%;
  }
  app-blocked-user ion-header,
app-following-list ion-header,
app-followers-list ion-header,
app-muted-user ion-header,
app-about ion-header,
app-follow-request ion-header,
app-follow-request-sent ion-header,
app-no-network ion-header {
    width: 70%;
  }
  app-blocked-user ion-content ion-title,
app-following-list ion-content ion-title,
app-followers-list ion-content ion-title,
app-muted-user ion-content ion-title,
app-about ion-content ion-title,
app-follow-request ion-content ion-title,
app-follow-request-sent ion-content ion-title,
app-no-network ion-content ion-title {
    width: 70%;
  }
  app-blocked-user ion-content .user_action_list,
app-following-list ion-content .user_action_list,
app-followers-list ion-content .user_action_list,
app-muted-user ion-content .user_action_list,
app-about ion-content .user_action_list,
app-follow-request ion-content .user_action_list,
app-follow-request-sent ion-content .user_action_list,
app-no-network ion-content .user_action_list {
    width: 70%;
  }
  app-blocked-user ion-content .following_list,
app-following-list ion-content .following_list,
app-followers-list ion-content .following_list,
app-muted-user ion-content .following_list,
app-about ion-content .following_list,
app-follow-request ion-content .following_list,
app-follow-request-sent ion-content .following_list,
app-no-network ion-content .following_list {
    width: 70%;
  }
  app-follower-suggestion ion-content .follower_head_text {
    width: 70%;
  }
  app-follower-suggestion ion-content follower-suggestion .follower-suggestion-page {
    width: 70%;
  }
  app-save-posts ion-header {
    width: 70%;
  }
  app-save-posts .user_postlist {
    width: 70%;
  }
  app-notification ion-header,
app-privacy-security ion-header,
app-app-settings ion-header,
app-help ion-header {
    width: 70%;
  }
  app-notification ion-content ion-title,
app-privacy-security ion-content ion-title,
app-app-settings ion-content ion-title,
app-help ion-content ion-title {
    width: 70%;
  }
  app-notification ion-content a24-accordion ion-accordion-group,
app-privacy-security ion-content a24-accordion ion-accordion-group,
app-app-settings ion-content a24-accordion ion-accordion-group,
app-help ion-content a24-accordion ion-accordion-group {
    width: 70%;
  }
  app-notification ion-content ion-card,
app-privacy-security ion-content ion-card,
app-app-settings ion-content ion-card,
app-help ion-content ion-card {
    width: 70%;
  }
  app-all-post .post_search_viewport {
    width: 70%;
  }
  app-all-post ion-content follower-suggestion .follower-suggestion-page,
app-sample ion-content follower-suggestion .follower-suggestion-page {
    width: 70%;
  }
  app-page-not-found .pagenot_found_container .not_found_footer {
    width: 70%;
  }
  app-profile user-profile ion-header,
app-guest-user-profile user-profile ion-header {
    width: 70%;
  }
  app-profile user-profile ion-content app-page-not-found .pagenot_found_container,
app-guest-user-profile user-profile ion-content app-page-not-found .pagenot_found_container {
    width: 70%;
    margin: 0 auto;
  }
  app-profile user-profile ion-content .user_container,
app-guest-user-profile user-profile ion-content .user_container {
    width: 70%;
  }
  app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
    width: 75% !important;
    min-width: 75% !important;
    margin: 0 8% 0 auto;
  }
  app-web-header .header_row .profile_btn {
    padding: 5px 8px;
  }
  app-all-post .post_search_header {
    width: 70%;
  }
  .gallery .gallery-item {
    position: relative;
    width: 20%;
    margin: 11px;
  }
  app-all-post ion-content follower-suggestion .follower-suggestion-page {
    margin: 15px auto 15px 3%;
  }
  app-category .categories .category_row .category-col {
    margin: 7px 0;
  }
  app-category .categories .category_row .category-col .category_box {
    height: 40px;
  }
  app-category .categories .category_row .category-col .category_box p {
    line-height: 14px;
  }
}
@media only screen and (max-width: 767px) {
  ion-router-outlet {
    width: 100%;
  }
  .page_not_found_box .page_not_foung_img {
    margin: 25px 0;
  }
  app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide:first-child::after {
    right: -8px;
  }
  app-category .intro_category .categories {
    width: 95%;
    margin: 0 auto;
  }
  app-category .intro_footer {
    width: 95%;
    margin: 0 auto;
  }
  app-no-network ion-content .network_container {
    width: 100%;
  }
  app-follower-suggestion ion-footer {
    width: 100%;
    margin: 0 auto;
  }
  .cdk-virtual-scroll-content-wrapper .fit app-post-slides {
    cursor: pointer;
  }
  .cdk-virtual-scroll-content-wrapper .fit app-post-slides ion-card ion-slides .swiper-wrapper ion-slide img {
    object-position: 50% 35%;
    cursor: pointer;
  }
  app-page-not-found .pagenot_found_container .not_found_footer {
    width: 100%;
    left: 0;
  }
  ion-split-pane {
    margin-top: 0 !important;
  }
  .example-viewport {
    margin: 0;
  }
  cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
    width: 100% !important;
    min-width: 100% !important;
  }
  .project_details_modal ion-content {
    --background: transparent !important;
  }
  .project_details_modal ion-content .project_transparent_text {
    width: 90%;
  }
  app-all-post ion-header ion-toolbar .category_section .category_slider a24-slides .slider_section ion-slides .swiper-wrapper ion-slide .category-group ion-card ion-card-content {
    font-size: 12px;
    padding: 5px 0;
  }
  app-web-header {
    margin: 15px 0 0;
  }
  app-web-header .header_row ion-col:nth-child(1) {
    order: 2;
    justify-content: flex-end !important;
  }
  app-web-header .header_row ion-col:nth-child(2) {
    order: 1;
  }
  app-web-header .header_row ion-col:nth-child(3) {
    order: 3;
    flex-wrap: nowrap;
  }
  app-web-header .header_row ion-col.home_icon_detail {
    justify-content: flex-start;
  }
  app-profile user-profile ion-content .user_container .gallery .gallery-item {
    width: 45%;
    margin: 8px;
  }
  app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0;
  }
  app-add-post add-post ion-header,
app-admin-add-post add-post ion-header {
    width: 95%;
  }
  app-add-post add-post ion-content ion-row,
app-admin-add-post add-post ion-content ion-row {
    width: 95%;
  }
  app-add-post add-post ion-content ion-row form .submit_btn,
app-admin-add-post add-post ion-content ion-row form .submit_btn {
    margin: 0 16px 80px;
  }
  app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item app-post-slides ion-card ion-slides ion-slide img {
    width: 100% !important;
  }
  app-about ion-content .about_page {
    width: 100%;
  }
  app-change-password ion-header {
    width: 95%;
  }
  app-change-password ion-content ion-title {
    width: 95%;
    margin: 15px auto 0;
  }
  app-change-password ion-content ion-grid {
    width: 95%;
  }
  .slider_container {
    width: 100%;
  }
  app-login ion-header,
app-register ion-header,
app-forgot ion-header {
    width: 95%;
  }
  app-login ion-content ion-grid,
app-register ion-content ion-grid,
app-forgot ion-content ion-grid {
    width: 95%;
  }
  app-edit-profile ion-header,
app-add-profile ion-header {
    width: 95%;
  }
  app-edit-profile ion-footer,
app-add-profile ion-footer {
    width: 95%;
  }
  app-edit-profile ion-content ion-title,
app-add-profile ion-content ion-title {
    width: 95%;
  }
  app-edit-profile ion-content ion-list,
app-add-profile ion-content ion-list {
    width: 95%;
  }
  app-edit-profile ion-content ion-grid,
app-add-profile ion-content ion-grid {
    width: 95%;
  }
  app-policies ion-header {
    width: 95%;
  }
  app-policies ion-content .pub_content {
    width: 95%;
  }
  app-notifications ion-header {
    width: 95%;
  }
  app-notifications ion-content ion-card {
    width: 95%;
  }
  app-settings ion-header,
app-followers-settings ion-header,
app-post-settings ion-header,
app-allow-comments ion-header {
    width: 95%;
  }
  app-settings ion-content ion-title,
app-followers-settings ion-content ion-title,
app-post-settings ion-content ion-title,
app-allow-comments ion-content ion-title {
    width: 95%;
  }
  app-settings ion-content ion-card,
app-followers-settings ion-content ion-card,
app-post-settings ion-content ion-card,
app-allow-comments ion-content ion-card {
    width: 95%;
  }
  app-settings ion-content ion-list,
app-followers-settings ion-content ion-list,
app-post-settings ion-content ion-list,
app-allow-comments ion-content ion-list {
    width: 95%;
  }
  app-settings ion-content ion-grid,
app-followers-settings ion-content ion-grid,
app-post-settings ion-content ion-grid,
app-allow-comments ion-content ion-grid {
    width: 95%;
  }
  app-blocked-user ion-header,
app-following-list ion-header,
app-followers-list ion-header,
app-muted-user ion-header,
app-about ion-header,
app-follow-request ion-header,
app-follow-request-sent ion-header,
app-no-network ion-header {
    width: 95%;
  }
  app-blocked-user ion-content ion-title,
app-following-list ion-content ion-title,
app-followers-list ion-content ion-title,
app-muted-user ion-content ion-title,
app-about ion-content ion-title,
app-follow-request ion-content ion-title,
app-follow-request-sent ion-content ion-title,
app-no-network ion-content ion-title {
    width: 100%;
  }
  app-blocked-user ion-content .user_action_list,
app-following-list ion-content .user_action_list,
app-followers-list ion-content .user_action_list,
app-muted-user ion-content .user_action_list,
app-about ion-content .user_action_list,
app-follow-request ion-content .user_action_list,
app-follow-request-sent ion-content .user_action_list,
app-no-network ion-content .user_action_list {
    width: 100%;
  }
  app-blocked-user ion-content .following_list,
app-following-list ion-content .following_list,
app-followers-list ion-content .following_list,
app-muted-user ion-content .following_list,
app-about ion-content .following_list,
app-follow-request ion-content .following_list,
app-follow-request-sent ion-content .following_list,
app-no-network ion-content .following_list {
    width: 100%;
  }
  app-follower-suggestion ion-content .follower_head_text {
    width: 95%;
  }
  app-follower-suggestion ion-content follower-suggestion .follower-suggestion-page {
    width: 95%;
  }
  app-save-posts ion-header {
    width: 95%;
  }
  app-save-posts .user_postlist {
    width: 95%;
  }
  app-notification ion-header,
app-privacy-security ion-header,
app-app-settings ion-header,
app-help ion-header {
    width: 95%;
  }
  app-notification ion-content ion-title,
app-privacy-security ion-content ion-title,
app-app-settings ion-content ion-title,
app-help ion-content ion-title {
    width: 95%;
  }
  app-notification ion-content a24-accordion ion-accordion-group,
app-privacy-security ion-content a24-accordion ion-accordion-group,
app-app-settings ion-content a24-accordion ion-accordion-group,
app-help ion-content a24-accordion ion-accordion-group {
    width: 95%;
  }
  app-notification ion-content ion-card,
app-privacy-security ion-content ion-card,
app-app-settings ion-content ion-card,
app-help ion-content ion-card {
    width: 95%;
  }
  app-all-post .post_search_header {
    width: 95%;
  }
  app-all-post .post_search_viewport {
    width: 95%;
  }
  app-all-post ion-content follower-suggestion .follower-suggestion-page,
app-sample ion-content follower-suggestion .follower-suggestion-page {
    width: 95%;
    margin: 15px auto 0 10px;
  }
  app-profile user-profile ion-header,
app-guest-user-profile user-profile ion-header {
    width: 100%;
  }
  app-profile user-profile ion-content app-page-not-found .pagenot_found_container,
app-guest-user-profile user-profile ion-content app-page-not-found .pagenot_found_container {
    width: 95%;
    margin: 0 auto;
  }
  app-profile user-profile ion-content .user_container,
app-guest-user-profile user-profile ion-content .user_container {
    width: 95%;
  }
  app-post-detail .video-js.vjs-1-1,
app-post-detail .video-js.vjs-16-9,
app-post-detail .video-js.vjs-4-3,
app-post-detail .video-js.vjs-9-16,
app-post-detail .video-js.vjs-fluid {
    max-width: 100% !important;
  }
  app-add-post add-post ion-header,
app-admin-add-post add-post ion-header {
    padding: 0 !important;
  }
  app-add-post ion-header app-page-header .header_btns .backButton,
app-save-posts ion-header app-page-header .header_btns .backButton,
app-add-product ion-header app-page-header .header_btns .backButton {
    max-width: 15% !important;
  }
  app-add-post add-post ion-content ion-row form ion-card,
app-admin-add-post add-post ion-content ion-row form ion-card {
    margin: 20px 5px !important;
  }
  ::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  ::-webkit-scrollbar-track {
    background-color: var(--ion-web-background-color);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--ion-web-background-color);
    border-radius: 10px;
  }
  app-web-header .header_row {
    margin: 0 !important;
  }
  app-all-post ion-header ion-toolbar ion-row,
app-sample ion-header ion-toolbar ion-row {
    margin: 15px 0 0;
  }
  ion-header {
    padding: 15px 0 !important;
  }
  app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col.avatar {
    max-width: 15% !important;
  }
  app-notifications ion-content ion-card ion-card-content .notification_box ion-row ion-col.info_text {
    max-width: 65% !important;
  }
  .real_comment_model ion-header {
    width: 95%;
    margin: 0 auto;
  }
  app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box {
    width: calc(10 / var(--ion-grid-columns, 12) * 100%) !important;
    max-width: calc(10 / var(--ion-grid-columns, 12) * 100%) !important;
  }
  app-post-detail cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper .list-group-item .post_detail_main_page .left_detail_box .post_product_link {
    width: 100vw;
    margin-left: 0;
  }
  .gallery .gallery-item {
    position: relative;
    width: 45%;
    margin: 8px;
  }
  app-add-product ion-header {
    margin: 0 auto;
    width: 95%;
  }
  app-app-settings ion-item.item-ionic-selectable.item-label-floating a24-select-component ionic-selectable .ionic-selectable-icon {
    right: 18px !important;
  }
  app-custom-skeleton {
    width: 98%;
  }
  app-web-header .header_row .all_icon {
    justify-content: flex-end;
  }
  app-web-header .header_row .profile_btn {
    padding: 5px 8px !important;
  }
  app-add-product ion-item.custom_time_filed .form_group.set_time .set_time_info .time_info p {
    margin-top: 22px;
    margin-left: 6px;
    width: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  app-add-product a24-select-component .custom_select ion-select::part(icon) {
    top: 65% !important;
  }
  app-add-product ion-item.custom_time_filed .form_group.set_time ion-icon {
    position: absolute;
    right: 5px;
    top: 28px;
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 375px) {
  .profile a24-filepond file-pond .filepond--wrapper .filepond--root .filepond--drop-label label .user_picture_icon {
    left: 95px !important;
  }
  app-add-product ion-item.custom_time_filed .form_group.set_time .set_time_info .time_info p {
    margin-top: 22px;
    margin-left: 6px;
    width: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  app-web-header .header_row ion-col:nth-child(1) {
    order: 2;
    justify-content: flex-start !important;
  }
  app-profile user-profile ion-content .user_container .gallery .gallery-item {
    width: 43%;
    margin: 8px;
  }
  app-custom-skeleton {
    width: 98%;
  }
  app-add-post add-post ion-header,
app-admin-add-post add-post ion-header {
    width: 98%;
  }
  app-add-post add-post ion-content ion-row,
app-admin-add-post add-post ion-content ion-row {
    width: 98%;
  }
  app-web-header .header_row .profile_btn {
    padding: 5px 6px !important;
  }
  .profile .profile-stats ul li {
    font-size: 14px;
    width: auto;
  }
  .profile .profile-stats ul li p {
    font-size: 15px;
  }
  .profile .profile-stats ul li.add_follow .profile-stat-count {
    width: 88px;
    font-size: 11px;
  }
  .login_page .or_continue p {
    font-size: 14px;
  }
  .login_page .or_continue .sideline {
    width: 90px;
  }
  .login_page .or_continue ion-text {
    width: 45%;
  }
  app-add-product ion-content .product_box ion-card ion-card-content ion-row ion-col .custom_time_filed .form_group .set_time_info a24-label ion-label {
    padding: 0 7px;
  }
}
@media only screen and (max-width: 240px) {
  .login_form_container .header {
    font-size: 18px;
    margin-top: 10px;
  }
  .login_form_container .sub_header {
    font-size: 10px;
    line-height: 10px;
  }
  .login_page .sign_bottom p,
app-register .sign_bottom p {
    font-size: 12px;
  }
  .login_page .sign_bottom ion-button::part(native),
app-register .sign_bottom ion-button::part(native) {
    font-size: 12px;
  }
  .login_page .social_login,
app-register .social_login {
    margin-top: 20px;
  }
  .login_page .spacer,
app-register .spacer {
    height: 35px;
  }
  .login_page .or_continue .sideline,
app-register .or_continue .sideline {
    width: 50px;
  }
  .login_page .or_continue p,
app-register .or_continue p {
    font-size: 12px;
  }
  .login_page ion-card ion-item,
app-register ion-card ion-item {
    margin: 0px 0 10px;
  }
  .login_page ion-card ion-item ion-icon,
app-register ion-card ion-item ion-icon {
    color: #afafaf;
    font-size: 14px !important;
  }
  .login_page ion-card ion-item ion-avatar,
app-register ion-card ion-item ion-avatar {
    top: -3px !important;
    right: 0 !important;
  }
  .login_page ion-card ion-item a24-label ion-label,
app-register ion-card ion-item a24-label ion-label {
    transform: translateY(76%) !important;
    font-size: 10px !important;
  }
  .login_page ion-card ion-item a24-input ion-input,
app-register ion-card ion-item a24-input ion-input {
    height: 45px;
  }
  .login_page ion-card ion-item a24-input ion-input input,
app-register ion-card ion-item a24-input ion-input input {
    height: 45px;
    font-size: 10px !important;
  }
  .login_page ion-button.sign_btns,
app-register ion-button.sign_btns {
    height: 45px;
    margin: 10px 0 !important;
  }
  .login_page ion-button.sign_btns::part(native),
app-register ion-button.sign_btns::part(native) {
    font-size: 14px !important;
  }
}
.login_btns {
  display: flex;
  justify-content: space-evenly;
}

.verify_tick {
  align-items: center;
  display: flex;
}
.verify_tick ion-icon {
  font-size: 14px !important;
  margin-left: 4px;
  color: #00fff8;
  border: 0px;
  pointer-events: none;
}

.toLowercase {
  text-transform: lowercase;
}

.innerHtml_desc {
  display: flex;
  justify-content: center;
}
.innerHtml_desc ion-icon {
  padding: 0px 5px;
}

app-image-crop .edit_cancel_btn {
  position: absolute;
  top: 20px;
  left: 13px;
}
app-image-crop .canvas_edit {
  height: 80%;
}
app-image-crop .canvas_edit image-cropper img {
  width: 80%;
  margin: 0 auto;
}
app-image-crop .action_filed {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  width: 100%;
  bottom: 10%;
}
app-image-crop .image_action_btn {
  z-index: 99999;
  position: absolute;
  width: 100%;
  bottom: 0;
}
app-image-crop .image_action_btn ion-row ion-col ion-button {
  width: 100%;
}

.disabled {
  pointer-events: none;
}
.disabled.text {
  color: rgba(198, 198, 198, 0.2509803922) !important;
}
.disabled.popup {
  border: 1px solid var(--ion-border-color);
}
.disabled span {
  color: rgba(198, 198, 198, 0.2509803922) !important;
}
.disabled ion-icon {
  color: rgba(198, 198, 198, 0.7607843137) !important;
}

app-post-header ion-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: var(--ion-white-color);
  width: 100%;
  margin: 0 auto;
  --min-height: 32px;
  padding: 5px 4px 10px 16px;
}
app-post-header ion-item ion-buttons .heart_like_section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
app-post-header ion-item ion-buttons .heart_like_section label ion-icon {
  font-size: 40px !important;
  padding-top: 5px;
}
app-post-header ion-item ion-avatar {
  width: 42px;
  height: 42px;
  border: 1px solid var(--ion-white-color);
  color: var(--ion-white-color);
  text-align: center;
  line-height: 43px;
}
app-post-header ion-item .post_details {
  width: 90%;
  padding-left: 14px;
}
app-post-header ion-item .post_details .post_title {
  font-size: 14px;
  padding: 0;
  margin: 0;
  line-height: 20px;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-post-header ion-item .post_details .post_texts {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
app-post-header ion-item .post_details .post_texts .post_user {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-post-header ion-item .post_details .post_texts .views {
  margin-left: 5px;
}
app-post-header ion-item ion-button::part(native) {
  color: var(--ion-white-color);
  padding: 0px 10px;
}

app-account-list .acct-list-content .card-modal .user_request_detail .user_detail_text .username.verify_tick {
  width: 90% !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  display: block;
}
app-account-list .acct-list-content .card-modal .account_btn_type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0 !important;
}
app-account-list .acct-list-content .card-modal .account_btn_type ion-button.follow::part(native) {
  border: unset !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 12px !important;
  color: var(--ion-theme-color) !important;
  font-weight: bold !important;
}
app-account-list .acct-list-content .card-modal .account_btn_type ion-button.remove {
  margin-left: 10px !important;
}
app-account-list .acct-list-content .card-modal .account_btn_type ion-button.remove::part(native) {
  padding: 2px 5px !important;
  font-weight: 300 !important;
}
app-account-list .acct-list-content .card-modal .account_btn_type ion-button.btn_common::part(native) {
  padding: 2px 5px !important;
  font-weight: 300 !important;
}