// @import "/src/assets/components/input/v1/app/input-styles.scss";

app-forms-v1,
app-all-post, app-sample,
app-post-settings,
app-app-settings,
app-privacy-security,
app-change-password,
app-followers-settings,
app-edit-profile,
app-add-profile,
app-report-modal,
app-login,
app-forgot,
app-register,
app-add-product,
app-admin-login,
add-post,
app-admin-add-post,
app-allow-comments,
app-duration {
    ion-item {
        margin:0px;
        --border-style: 0;
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
        // --background: var(--ion-item-background-black);
        --ripple-color: transparent;
        --background-activated: transparent;
        --background-activated-opacity: transparent;
        --background-focused: transparent;
        --background-focused-opacity: transparent;
        --background-hover: transparent;
        --background-hover-opacity: transparent;

        &.item-label-floating {
            a24-label {
                height: 0;
                visibility: visible;
                ion-label {
                    transform: translateY(125%) !important;
                    padding-left: 10px;
                    font-size: 15px !important;
                    color: var(--ion-item-bb-text-label) !important;
                    z-index: 99;
                    position: relative;
                    font-size: 12px !important;
                    font-weight: 300;
                    display: block !important;
                  
}
            }
            a24-input,
            app-user-name-suggestion {
                display: block;
                width: 100%;
                ion-input {
                    --background: var(--ion-item-background-black);
                    border-radius: var(--border-radius-s);
                    --padding-top: 30px !important;
                    --padding-start: 10px !important;
                    --padding-end: 20px !important;
                    height: 60px;
                    opacity: 1 !important;
                    color: var(--ion-white-color);
                    z-index: 9;
                    font-size: 14px !important;
                    font-weight: 300;
                    // text-transform: lowercase;
                    &.webUrl{
                        text-transform: inherit !important;
                    }
                }
                .valid_msg {
                    position: relative;
                    bottom: 0;
                    left: 0px;
                }
            }
            &.item-has-focus,
            &.item-has-value {
                ion-label {
                    transform: translateY(50%) !important;
                    --color: var(--ion-white-color);
                    z-index: 99;
                    position: relative;
                    padding: 0px 10px;
                    left: 0px;
                    display: block !important;
                    -webkit-transform: translateY(50%) !important;
                    -moz-transform: translateY(50%) !important;
                    -ms-transform: translateY(50%) !important;
                    -o-transform: translateY(50%) !important;
}
                .with-icon {
                    &.left {
                        ion-avatar {
                            display: block;
                        }
                    }
                }
            }
            .with-icon {
                width: 100%;
                position: relative;
                ion-avatar {
                    position: absolute;
                    width: 46px;
                    height: 46px;
                    font-size: 28px;
                    text-align: center;
                    top: 8px;
                    bottom: 0;
                    z-index: 99;
                    ion-icon {
                        color: var(--ion-input-icon-color);
                    }
                }
                &.left {
                    ion-avatar {
                        left: 10px;
                        display: none;
                    }
                    ion-input {
                        --padding-start: 50px !important;
                        --padding-end: 20px !important;
                    }
                }
                &.right {
                    ion-avatar {
                        right: 10px;
                    }
                    ion-input {
                        --padding-start: 10px !important;
                        --padding-end: 50px !important;
                    }
                }
            }
        }

        /* Select */
        &.item-select.item-label-floating {
            a24-label {
                ion-label {
                    transform: translateY(130%) !important;
                    padding-left: 10px;
                    color: var(--ion-item-bb-text-label) !important;
                    font-size: 12px !important;
                    font-weight: 300;
                    -webkit-transform: translateY(130%) !important;
                    -moz-transform: translateY(130%) !important;
                    -ms-transform: translateY(130%) !important;
                    -o-transform: translateY(130%) !important;
}
            }
            a24-select-component {
                display: block;
                width: 100%;
                ion-select {
                    background: var(--ion-item-background-black);
                    border-radius: var(--border-radius-s);
                    --padding-top: 30px !important;
                    --padding-start: 10px !important;
                    --padding-end: 20px !important;
                    width: 100%;
                    max-width: 100%;
                    height: 60px;
                    opacity: 1 !important;
                    color: var(--ion-white-color);
                    font-size: 14px !important;
                    font-weight: 300;
                    position: relative;
                    &::part(icon) {
                        top: 0px;
                        color: var(--ion-white-color);
                        opacity: 1;
                    }
                }
                .valid_msg {
                    position: relative;
                    bottom: 0;
                    left: 0px;
                }
                &.selected {
                    ion-select {
                        color: var(--ion-theme-color);
                    }
                }
            }

            &.item-has-focus,
            &.item-has-value {
                ion-label {
                    transform: translateY(50%) !important;
                    z-index: 99;
                    position: relative;
                    padding: 0px 10px;
                    left: 0px;
                    -webkit-transform: translateY(50%) !important;
                    -moz-transform: translateY(50%) !important;
                    -ms-transform: translateY(50%) !important;
                    -o-transform: translateY(50%) !important;
}
            }
        }

        /* Multi Select */

        &.item-ionic-selectable.item-label-floating {
            a24-label {
                ion-label {
                    transform: translateY(214%) !important;
                    padding-left: 10px;
                    font-size: 12px !important;
                    font-weight: 300;
                    color: var(--ion-item-bb-text-label) !important;
                    -webkit-transform: translateY(214%) !important;
                    -moz-transform: translateY(214%) !important;
                    -ms-transform: translateY(214%) !important;
                    -o-transform: translateY(214%) !important;
}
            }
            a24-select-component {
                display: block;
                width: 100%;
                ionic-selectable {
                    background: var(--ion-item-background-black);
                    border-radius: var(--border-radius-s);
                    --padding-top: 30px !important;
                    --padding-start: 10px !important;
                    --padding-end: 20px !important;
                    width: 100%;
                    max-width: 100%;
                    height: 60px;
                    opacity: 1 !important;
                    font-size: 14px !important;
                    font-weight: 300;
                    .ionic-selectable-icon {
                        width: 20px;
                        position: absolute;
                        top: 27px;
                        right: 20px;
                        opacity: 1;
                        --icon-color: var(--ion-white-color);
                    }
                    .ionic-selectable-value-item {
                        display: inline-block;
                        margin: 6px 10px 6px 0px;
                        padding: 5px 0px;
                        border-radius: 8px;
                        color: var(--ion-white-color);
                    }
                    .ionic-selectable-value {
                        padding-top: 19px !important;
                    }
                    .ionic-selectable-icon{
                        .ionic-selectable-icon-inner{
                            top: 5px !important ;
                         }
                    }
                }
                .valid_msg {
                    position: relative;
                    bottom: 0;
                    left: 20px;
                }
            }

            &.item-has-focus,
            &.item-has-value {
                ion-label {
                    transform: translateY(50%) !important;
                    z-index: 99;
                    position: relative;
                    padding: 0px 10px;
                    left: 0px;
                    -webkit-transform: translateY(50%) !important;
                    -moz-transform: translateY(50%) !important;
                    -ms-transform: translateY(50%) !important;
                    -o-transform: translateY(50%) !important;
}
            }
        }

        /* File Upload */

        &.upload_files_v2 {
            background: var(--ion-item-background-black);
            border-radius: var(--border-radius-s);
            height: 60px;
            a24-label {
                width: 50%;
                ion-label {
                    color: var(--ion-item-bb-text-label) !important;
                    width: auto !important;
                    max-width: 100% !important;
                    padding-left: 10px;
                    z-index: 99;
                    position: relative;
                    font-size: 16px !important;
                    font-weight: 300;
                }
            }
            app-file-upload {
                width: 50%;
                ion-button {
                    --background: transparent;
                    --box-shadow: unset;
                    border-radius: var(--border-radius-l);
                    border: 1px solid var(--ion-white-color);
                    font-size: 12px;
                    font-weight: 400;
                    width: 96px;
                    height: 32px;
                    text-align: center;
                    text-transform: capitalize;
                }
            }
            &::part(native) {
                height: 60px;
            }
        }

        /* Toggle */
        ion-toggle {
            --background: var(--ion-white-color);
            &::part(track) {
                --background-checked: var(--ion-white-color);
            }
            &::part(handle) {
                background-color: #ff9b20;
            }

            &.toggle-checked {
                &::part(track) {
                    --background-checked: #ff9b20;
                }
                &::part(handle) {
                    background-color: var(--ion-white-color);
                }
            }
        }

        /* Search */
        &.item-searchbar {
            a24-search {
                width: 100%;
                ion-searchbar {
                    --background: var(--ion-item-background-black) !important;
                    --border-radius: var(--border-radius-s) !important;
                    --box-shadow: unset !important;
                    --color: var(--ion-white-color) !important;
                    height: 40px;
                    padding-inline-start: 0;
                    input {
                        padding-inline-start: 40px !important;
                    }
                    ion-icon {
                        --icon-color: var(--ion-icon-color) !important;
                        left: 10px !important;
                    }
                }
            }
            a24-label {
                margin-bottom: 12px;
                color: var(--ion-white-color);
            }
        }

        &.without_icon {
            a24-search {
                ion-searchbar {
                    input {
                        padding-inline-start: 15px !important;
                    }
                }
            }
        }

        /* Radio */
        &.radio_btns {
            a24-label {
                color: var(--ion-white-color);
            }
            a24-radio {
                width: 100%;
                ion-item {
                    ion-label {
                        --color: var(--ion-white-color) !important;
                    }
                    ion-radio {
                        --ion-color-base: var(--ion-theme-color) !important;
                        --color: var(--ion-theme-color) !important;
                    }
                }
            }
        }

        /* Checkbox */
        &.checkbox_styles {
            a24-label {
                color: var(--ion-white-color);
            }
            app-check-box-component {
                width: 100%;
                ion-item {
                    ion-label {
                        --color: var(--ion-white-color) !important;
                    }
                    ion-checkbox {
                        --border-color: var(--ion-theme-color) !important;
                    }
                }
                a24-label {
                    margin-left: 15px;
                }
            }
        }

        /* Textarea */
        &.item-textarea {
            ion-textarea {
                margin-top: 0;
                --background: var(--ion-item-background-black);
                color: var(--ion-white-color);
                --padding-top: 30px;
                border-radius: var(--border-radius-s);
                opacity: 1 !important;
                min-height: 60px;
                height: auto;
                --padding-top: 30px !important;
                --padding-start: 10px !important;
                textarea{
                    height: auto;
                }
            }
        }
    } /* ion-item*/

    /* Ion Card*/
    ion-card {
        background: var(--ion-background-color);
        margin-bottom: 40px;
        ion-card-header {
            font-size: 18px;
            font-weight: 500;
            color: #fff;
        }
    }
}
