/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//cdk styles
@import "~@angular/cdk/overlay-prebuilt.css";

// @import "./scss/horizontal-scroll.scss";
// @import "./scss/auth.scss";

@import "~video.js/dist/video-js.min.css";

@import "/src/assets/components/form-v2-style.scss";

// app font

@import url("https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;600;700;800&display=swap");

// table style

// @import "~@swimlane/ngx-datatable/index.css";
// @import "~@swimlane/ngx-datatable/themes/material.scss";
// @import "~@swimlane/ngx-datatable/assets/icons.css";

* {
  font-family: "Baloo Paaji 2", cursive !important;
}

// /commented - sd

// :root[mode="ios"] .Roboto,
// :root[mode="md"] .Roboto {
//   --ion-font-family: "Roboto", sans-serif !important;
//   font-family: "Roboto", sans-serif !important;
// }

// COMMON STYLES

body {
  font-family: "Roboto", sans-serif !important;
  background-color: var(--ion-web-background-color) !important;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
}

.intro_content {
  --offset-bottom: 0px !important;
}

// ion-content {
//   --offset-bottom: unset !important;
//   --overflow: hidden;
//   overflow: auto;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-track {
  background-color: var(--ion-web-background-color);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--ion-web-background-color);
  border-radius: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ion-progress-bar {
  top: 0;
  position: absolute;
}

:root {
  --btn-animation-time: 0.75s;
  --ion-background-color: #343434;
  --ion-web-background-color: #212121;
  --ion-text-color: #ffffff;
  --ion-backdrop-color: #000000;
  --ion-backdrop-opacity: 0.7;
  --ion-border-color: #5a5a5a;
  --ion-item-border-color: #d6d6d6;
  --ion-toolbar-background: transparent;
  --ion-toolbar-color: #fff;
  --ion-toolbar-segment-color: #e73814;
  --ion-item-background: transparent;
  --ion-item-color: #1b1b1b;
  --ion-placeholder-color: #1b1b1b;
  --ion-hover-color: #f7cfc7;
  --ion-sidemenu-color: #fff;
  --ion-white-color: #fff;
  --ion-theme-color: #ff9b20;
  --ion-input-icon-color: #c9c6c6;
  --ion-input-bg-color: #f5f5f5;
  --border-focus-color: #e73814;
  --ion-active-label-color: #9b9494;
  --ion-active-label-bg-color: #fdeeee;
  --ion-multi-select-bg-color: #f2f2f2;
  --ion-item-background-black: #4e4e4e;
  --ion-item-bb-text-label: #c4c4c4;
  --ion-icon-color: #9f9f9f;
  --ion-card-background-color: #4e4e4e;
  --ion-color-base: #fff;
  --ion-transparent-color: #40404033;
  --ion-post-icon-bg: #c9c9c93d;
  --ion-blur-color: #0000008a;

  --cropper-overlay-color: #404040 !important;
  --cropper-outline-color: #4040409d !important;

  // /commented end - sd

  // //   /* border-radius */
  --border-radius-xl: 20px;
  --border-radius-l: 16px;
  --border-radius-md: 12px;
  --border-radius-s: 5px;
  --border-radius-xs: 4px;

  --bar: linear-gradient(90deg, rgba(238, 29, 82, 1) 0%, rgba(255, 255, 255, 1) 34%, rgba(105, 201, 208, 1) 65%);
}
a.filepond--credits {
  display: none;
}
ion-content.feedpage {
  --offset-bottom: 0px !important;
}
ion-item.item-has-start-slot.item.md.item-fill-none.in-list.hydrated.item-label.item-label-stackedl {
  cursor: pointer;
}
app-c-avatar {
  cursor: pointer;
}

.post_feature {
  position: absolute;
  background: transparent;
  width: 75px;
  height: 75px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

ion-modal{

  &.short_modal{
   &::part(content){
      height: 80% !important;
   }
  }
  
}

.feature_footer {
  width: 75px;
  height: 75px;
  background: transparent;
  z-index: 99999;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.custom_feature {
  width: 100%;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-65%, -78%);
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Common Styles CSS */

.add_post_toast {
  --start: 0;
  --end: 0;
  --ion-safe-area-bottom: -8px;
  &::part(container) {
    background: var(--ion-theme-color);
    --ion-color-contrast: var(--ion-white-color) !important;
    color: var(--ion-white-color);
    --start: 0;
    --end: 0;
  }

  &::part(button) {
    border-radius: 50%;
    padding: 9px;
    width: 40px;
    height: 40px;
    background-color: #00c766;
    display: flex;
    align-items: center;
    justify-content: center;
    .toast-button-inner {
      ion-icon {
        font-size: 26px;
      }
    }
  }
}
.header_icon {
  display: flex;
}
.wrapper {
  padding: 30px;
}
.no-border {
  --border-style: 0;
}
.no-pading {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
}
.content_box {
  background: var(--ion-white-color);
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  padding: 30px;
}
.no-flex {
  display: unset !important;
}
.inner_title {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  span {
    color: var(--ion-theme-color);
  }
}
.border-radius-xl {
  border-radius: var(--border-radius-xl);
}
.border-radius-l {
  border-radius: var(--border-radius-l);
}
.border-radius-md {
  border-radius: var(--border-radius-md);
}
.border-radius-s {
  border-radius: var(--border-radius-s);
}
.border-radius-xs {
  border-radius: var(--border-radius-xs);
}
.hide-err {
  font-size: 14px;
  color: var(--ion-color-danger);
  margin: 0 !important;
}
.hide-err {
  position: relative;

  // &::after {
  //   content: "\26A0";
  //   position: absolute;
  //   left: -16px;
  //   font-size: 16px;
  //   bottom: 0;
  // }
}

ion-icon {
  font-size: 22px !important;
  color: var(--ion-white-color);
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
  cursor: pointer;
}
.arrow_icon {
  width: 12px;
  height: 12px;
}

.action-sheet-selected.sc-ion-action-sheet-md {
  background-color: var(--ion-theme-color);
}

a24-label {
  ion-label {
    // display: unset !important;
    font-size: 16px !important;
  }
}

app-app-settings {
  ion-item.item-ionic-selectable.item-label-floating {
    a24-select-component {
      ionic-selectable {
        .ionic-selectable-icon {
          right: 42px !important;
        }
      }
    }
  }
}

app-login,
app-register {
  ion-content {
    ion-grid {
      ion-row {
        ion-col {
          .logo {
            width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            ion-icon {
              font-size: 55px !important;
            }
          }
        }
      }
    }
  }
}

.diy_feature fiv-feature-discovery {
  .backdrop {
    pointer-events: none !important;
  }
  .circle .center {
    z-index: 99999 !important;
    pointer-events: none !important;
  }
}

.curve fiv-feature-discovery {
  display: block;
  position: relative;

  .content {
    position: absolute;
    right: 0 !important;
    left: auto !important;
    margin: auto;
    display: block;
    width: 100% !important;
    text-align: center;
    bottom: 28% !important;
    ion-text {
      h1 {
        margin-top: 5px;
      }
      p {
        margin-bottom: 10px;
        margin-top: 0;
      }
    }
    ion-button {
      margin: 0 auto;
    }
  }
}

.curve.post fiv-feature-discovery {
  .content {
    bottom: 0% !important;
  }
}
// footer design

app-all-post,
app-sample {
  ion-footer {
    ion-toolbar {
      padding: 5px 0 !important;
    }
  }
}

ion-footer {
  ion-toolbar {
    .footer_main_icon {
      ion-buttons {
        ion-button {
          background-color: var(--ion-card-background-color);
          width: 60px;
          height: 50px !important ;
          border-radius: 24px 0 0 24px;
          &:last-child() {
            border-radius: 0 24px 24px 0;
            -webkit-border-radius: 0 24px 24px 0;
            -moz-border-radius: 0 24px 24px 0;
            -ms-border-radius: 0 24px 24px 0;
            -o-border-radius: 0 24px 24px 0;
          }
        }
        .placement {
          background-color: var(--ion-card-background-color);
          width: 60px;
          height: 50px !important;
          border-radius: 0 24px 24px 0;
          .heart {
            margin-top: -13px;
            margin-left: -16px;
            height: 78px;
            width: 90px;
            background-size: 2900%;
            &.is-active {
              margin-left: -16px !important;
            }
          }
        }
        .footer_post_page_heart {
          background-color: var(--ion-card-background-color);
          width: 60px;
          height: 50px !important ;
          border-radius: 0 24px 24px 0;
          -webkit-border-radius: 0 24px 24px 0;
          -moz-border-radius: 0 24px 24px 0;
          -ms-border-radius: 0 24px 24px 0;
          -o-border-radius: 0 24px 24px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          a24-button-heart {
            z-index: 99999;
            ion-icon {
              filter: unset;
            }
          }
          a24-button-heart {
            .button {
              position: relative;
              top: unset;
              left: unset;
              margin-right: unset;
              transform: unset;
              width: 100%;
              cursor: pointer;
              background: transparent;
              border: none;
              transition: 0.4s;
              outline: 0;
              text-align: center;
              color: #fff;
              font-family: unset;
              font-weight: 100;
              letter-spacing: 2px;
              font-size: 14px;
              text-transform: uppercase;
              padding: 0;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              ion-icon {
                font-size: 35px !important;
              }
              &:hover {
                transform: unset;
              }
            }
          }
        }
      }
    }
  }
}

ion-content {
  --background: var(--ion-web-background-color) !important;
  z-index: 999;
  border: unset;
  ion-list {
    ion-item-group {
      ion-item {
        --inner-border-width: 0 !important;
      }
    }
  }
  .mainpage_heading {
    padding: 15px 0 15px 10px;
    border-bottom: 1px solid var(--ion-border-color);
    a24-slides {
      // margin: 0px 15px 0px;
      .slider_section {
        padding: 0px;
        .swiper-container {
          .swiper-wrapper {
            ion-slide {
              flex-direction: column;
              p {
                padding: 0;
                margin: 0;
                display: block;
                overflow: hidden;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 74px;
              }
            }
          }
        }
      }
    }
  }
}

app-all-post,
app-sample {
  ion-content {
    --background: var(--ion-web-background-color) !important;
    z-index: 999;
    border: unset;
    follower-suggestion {
      .follower-suggestion-page {
        margin: 15px auto 15px 30.5%;
        width: 40%;
        .follower_page_input {
          width: 100% !important;
          margin: 0 0 30px !important;
        }
      }
    }
    .cdk-virtual-scroll-content-wrapper {
      position: relative;
      // top: 85%;
      // left: 0;
      contain: content;
      height: 300%;
      .mainpage_heading {
        h4 {
          margin: 0px 15px 0;
        }
        a24-slides {
          margin: 0px 15px 0px;
          .slider_section {
            .swiper-container {
              .swiper-wrapper {
                ion-slide {
                  max-width: 30% !important;
                  width: 20% !important;
                  flex-direction: column;
                  margin-right: 0 !important;
                  p {
                    padding: 0;
                    margin: 0;
                    display: block;
                    overflow: hidden;
                    text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 74px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

:root {
  ion-img {
    /** 
     * Bug fix for:
     * https://github.com/ionic-team/ionic/issues/18734
     */
    min-height: 1px !important;
  }
}

$theme-color: #ff9b20;
$orange-text: #ff9b20;
$light-grey: #4e4e4e;
$link-color: #afafaf;
$intro-secondary-color: #d1d1d1;
$input-text-color: #afafaf;

@font-face {
  font-family: "BalooPaaji2";
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-Regular.ttf");
}

@font-face {
  font-family: "BalooPaaji2";
  font-style: Bold;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-Medium.ttf");
}

@font-face {
  font-family: "BalooPaaji2";
  font-style: Bold;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-SemiBold.ttf");
}

@font-face {
  font-family: "BalooPaaji2";
  font-style: Bold;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-Bold.ttf");
}

@font-face {
  font-family: "BalooPaaji2";
  font-style: Bold;
  font-weight: 800;
  font-display: swap;
  src: url("/assets/fonts/BalooPaaji2-ExtraBold.ttf");
}

ion-card {
  box-shadow: unset;
}

p {
  font-size: 14px;
  line-height: 24px;
}
a {
  font-weight: 500;
  color: $link-color;
  font-size: 16px;
}
h1 {
  font-size: 28px;
  font-weight: 400;
}
h3 {
  font-weight: 600;
  font-size: 18px;
  color: var(--ion-white-color);
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.justify-center {
  justify-content: center;
}

.invalid_msg {
  color: #ff0000;
  margin-top: 5px;
}

.input-box {
  outline: none;
  padding: 10px;
  // width: 90%;
  border-radius: 6px;
  transition: 0.4s;
  display: flex;
  justify-content: start;
  align-items: center;
  border: 2px solid black;
}

.example-viewport {
  height: 100vh;
  width: 60%;
  margin: 0 auto;
  overflow-x: hidden;
}

.filepond--item-panel {
  background-color: transparent !important;
  box-shadow: none !important;
}

.post-settings {
  &.web {
    .modal-wrapper {
      top: 62% !important;
      bottom: unset;
    }
  }
}

.post-settings {
  background: #00000040;
  .modal-wrapper {
    position: absolute;
    height: 380px !important;
    max-width: 100%;
    top: unset !important;
    bottom: 0;
  }
}
app-post-action-sheet {
  ion-card {
    padding: 0;
    margin: 0;
    .post-settings-row {
      border-bottom: 1px solid var(--ion-border-color);
      padding: 0px 15px;
      display: flex;
      justify-content: space-between;
      height: 100px;
      width: 100%;
      text-align: center;
      margin-top: 50px;
      margin-bottom: 18px;
      .post-popup {
        .post-settings-col {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 3px solid var(--ion-border-color);
        }
        p {
          color: var(--ion-white-color);
          padding: 0;
          margin: 10px 0px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .post-settings-texts {
      color: var(--ion-white-color);
      font-size: 16px;
      font-weight: 100;

      .disable_follow {
        pointer-events: none !important;
        p {
          color: #666 !important;
        }
      }
      p {
        font-size: 16px;
        line-height: 20px;
        padding: 0 0px 10px;
        margin: 20px 15px 0;
        cursor: pointer;
      }
    }
  }
}

.post-settings-col {
  display: inline-block;
}

.userProfile {
  padding-top: 10%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.user_postlist {
  // padding: 40px 60px;
  // width: 48%;
  // display: block;
  // flex-wrap: wrap;
  // margin-right: 24px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

button.vjs-fullscreen-control.vjs-control.vjs-button,
.vjs-progress-control.vjs-control,
button.vjs-play-control.vjs-control.vjs-button.vjs-paused,
button.vjs-play-control.vjs-control.vjs-button.vjs-playing {
  display: none !important;
}

.remove_cancel .action-sheet-group-cancel {
  display: none;
}
.detail-width {
  width: 30%;
}
.details {
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  width: 30% !important;

  .required-details-list {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 100%;

    position: relative;
    width: max-content;
    overflow-x: hidden;
    overflow-y: hidden;
    // transition: all 1s ease;
    transform: scale(0.98);

    .required-details {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1.5rem 1.25rem;
      position: relative;
    }
  }
}

.frame_img {
  // img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  // }
}

.button-view {
  ion-icon {
    width: 25px;
    height: 25px;
    padding-left: 15px;
  }
}
.category-group {
  color: #fff;
  text-transform: capitalize;
  // width: 100%;
  .selected {
    color: var(--ion-theme-color) !important;
  }
}

img {
  display: block;
}

.container {
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 15px;
}

/* Slider */
.circle_top {
  background: $theme-color;
  width: 200px;
  height: 200px;
  position: fixed;
  top: -15%;
  right: -25%;
  border-radius: 50%;
  z-index: 99999;
}
.slider_section {
  // position: relative;
  .top_position {
    position: absolute;
    z-index: 99999;
    margin-top: 50px;
    text-transform: capitalize;
    font-size: 18px !important;
  }
  .slide_images {
    display: block;
    width: 100%;
  }
  .bottom_content {
    position: relative;
    bottom: 0;
    width: 100%;
  }
  .slide_content {
    background: $light-grey;
    display: block;
    width: 100%;
    padding: 40px 30px;
    text-align: left;
    h2 {
      margin: 0;
    }
    h6 {
      font-size: 20px;
      font-weight: 500;
      margin: 0px 0px 15px 0px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: $intro-secondary-color;
      margin-bottom: 50px;
    }
  }
  .slide_img {
    margin-top: 0;
    margin-bottom: 15px;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .slide_button {
    // background: $light-grey;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 7px;
    z-index: 999;
    right: 0;
    ion-button {
      margin-top: 0;
      text-transform: capitalize;
      font-size: 18px !important;
      &::part(native) {
        line-height: 25px;
      }
      ion-icon {
        font-size: 16px !important;
        color: var(--ion-theme-color) !important;
      }
    }
  }
}
.ion-color-orange-text {
  color: $orange-text;
  &::part(native) {
    color: unset;
  }
}
ion-footer {
  .grey_bg {
    background: $light-grey;
  }
}

ion-searchbar {
  padding: 0px 15px !important;
  .searchbar-input-container {
    .searchbar-input {
      box-shadow: unset;
      background-color: var(--ion-item-background-black);
      color: var(--ion-text-color);
      border-radius: 5px;
      height: 40px;
      width: 100%;
    }
    .searchbar-search-icon {
      color: var(--ion-icon-color) !important;
    }
  }
}

ion-modal {
  ionic-selectable-modal {
    ion-header {
      padding: 15px 0px !important;
      ion-toolbar {
        padding: 0px 0px 15px;
        .popup_header {
          display: flex;
          align-items: center;
        }
      }
      .header {
        padding: 0 15px 15px !important;
        ion-icon {
          // margin-top: 3px;
        }
        ion-title {
          float: right;
          text-align: left;
          width: 88%;
          text-transform: capitalize;
        }
      }
    }
    ion-content {
      ion-list {
        ion-item-group {
          .ionic-selectable-item-is-selected {
            background-color: var(--ion-theme-color);
            ion-icon {
              fill: var(--ion-text-color);
            }
          }
        }
      }
    }
  }
  app-message-detail {
    ion-header {
      app-page-header {
        .header_btns {
          ion-row {
            .backButton {
              max-width: 15% !important;
            }
          }
        }
      }
    }
    ion-content {
      --padding-start: 16px;
      --padding-end: 16px;
      .mesage_detail_page_comment {
        app-user-message {
          .message-user {
            ion-row {
              height: auto;
              align-items: flex-start;
              &.comment_footer {
                align-items: center !important;
                color: #8f8f8f;
              }
              &.user_text {
                flex-direction: column;
              }
              .header_title {
                text-align: end;
              }
              .message_text {
                margin-top: 10px;
                width: 100% !important;
                max-width: 100% !important;
                padding: 0;
              }
              .cmt_options {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-direction: column;
                margin-bottom: auto;
                margin-top: auto;
              }
              ion-col {
                &.menu_icon_comment {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  padding: 3px;
                }
                &.profile_time {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  font-size: 14px;
                  padding: 0;
                  .time {
                    line-height: 20px;
                    width: 30px;
                  }
                }
                ion-icon {
                  font-size: 16px !important;
                  color: #8f8f8f;
                }
                .heart_icon {
                  position: relative;
                  transform: unset;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  height: 60px;
                  a24-button-heart {
                    .button {
                      margin-right: 0;
                      line-height: 0;
                      top: 48%;
                    }
                    .button.dead {
                      top: 50% !important;
                    }
                  }
                  p {
                    margin: 0;
                  }
                }
                .heart_like_section {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 20px;
                  flex-direction: column;
                  label {
                    height: 38px;
                  }
                }
                app-c-avatar {
                  -webkit-margin-end: unset !important;
                  margin-inline-end: unset !important;
                  width: 100% !important;
                  height: 100% !important;
                }
                .profile_comment_message {
                  width: 100%;
                  .profile_name_time {
                    p {
                      margin: 0;
                      padding: 0;
                      font-size: 13px;
                      color: var(--ion-theme-color);
                      line-height: 5px;
                    }
                    .profile_time {
                      span {
                        margin: 0;
                        padding: 0;
                        font-size: 12px;
                        color: #9d9d9d;
                      }
                    }
                  }
                  .comment_message {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 100%;
                    margin: 0;
                    .message-container {
                      background: unset !important;
                      padding: 0 !important;
                      max-width: 100% !important;
                      margin-top: 5px;
                      span {
                        display: block;
                        text-align: justify;
                        line-height: 20px;
                        &::after {
                          content: unset;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

app-image-edit {
  height: 100vh;
  ion-content {
    .edit_cancel_btn {
      position: absolute;
      z-index: 9999;
      top: 4%;
      right: 7%;
      ion-icon {
        font-size: 35px !important;
      }
    }
    .croppie-container {
      height: auto !important;
      .cr-boundary {
        width: 100% !important;
        height: 75vh !important;
        .cr-viewport.cr-vp-square {
          height: 100% !important;
          border: 2px solid #404040 !important;
        }
      }
      .cr-slider-wrap {
        width: 100% !important;
        padding: 17px 5px 20px;
        margin: 0 !important;
        text-align: center;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .action_filed {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 30px 10px;
    }
    .image_action_btn {
      ion-row {
        margin-bottom: 15px;
        padding: 0 20px;
        ion-col {
          ion-button {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            &::part(native) {
              box-shadow: none !important;
              text-transform: capitalize;
              height: 50px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

app-message-detail {
  ion-footer {
    ion-toolbar {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 5px 10px 20px !important;
      ion-textarea {
        background-color: var(--ion-background-color) !important;
        height: 50px;
        border-radius: 70px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 100% !important;
        .textarea-wrapper {
          width: 100%;
          height: 50px !important;
          textarea {
            height: 100% !important;
            color: var(--ion-color-base);
            padding: 0 60px !important;
            line-height: 50px;
          }
        }
      }
      app-c-avatar {
        position: absolute;
        z-index: 999;
        bottom: 3px;
        left: 4px;
        .user_profile {
          ion-avatar {
            width: 40px;
            height: 40px;
          }
        }
      }
      .send_icon {
        position: absolute;
        z-index: 999;
        right: 45px;
        bottom: 10px;
        font-size: 30px;
        color: var(--ion-theme-color);
        width: auto;
        height: 25px;
      }
    }
  }
}

.user_profile {
  ion-avatar {
    box-shadow: -3px 3px 6px 1px #0c0c0c17;
    width: 40px;
    height: 40px;
    position: relative;
    background: #ff9b20;
    ion-thumbnail {
      ion-img {
        &::part(image) {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          object-fit: cover;
          object-position: top center;
        }
      }
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 24px;
      font-weight: 400;
    }
  }
}

.page_content {
  padding: 25px 40px;
  height: calc(100vh - 94px);
  overflow-y: scroll;
  margin-top: 94px;
}

.page_content {
  &.admin_all_post {
    margin-left: 0 !important;
  }
}

ng2-smart-table .custom-smart-table .top-pagination .pagination-bottom {
  bottom: -75px !important;
  right: 8% !important;
}

ng2-smart-table .custom-smart-table {
  // background: #fff;
  padding: 35px 35px 10px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 2px 0 #0c0c0c14;
  z-index: 99;
  margin: auto 25px;
  table {
    thead {
      margin: 10px;
      border-width: 5px;
      border-radius: 5px;
    }
    tbody tr td {
      word-break: break-word;
      text-align: left !important;
      text-transform: capitalize !important;
    }
  }
  .top-pagination {
    .pagination-top {
      display: none;
    }
    .pagination-bottom {
      position: absolute;
      bottom: -86px;
      right: 0px;
      background: #fff;
      height: 44px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      text-align: center;
      z-index: 99999;
      ul {
        padding: 0;
        span {
          padding-right: 5px;
          padding-left: 5px;
        }
      }
      .page-item {
        display: inline-block !important;
        margin: 0 5px;
        width: 24px;
        height: 24px;
        position: relative;
        &.active {
          background: #00ca80;
          border-radius: 50%;
          .page-link {
            color: #fff;
          }
        }
      }
    }
  }
}

.theme_green_btn {
  --background: #00ca80;
  color: #f3fdf9;
  border-radius: 6px;
  border: unset;
}

.search_bar {
  position: relative;
  padding: 0px;
  ion-searchbar {
    padding: 0;
    height: 44px;
    --border-radius: 5px;
    --border-top-right-radius: 0 !important;
    --border-bottom-right-radius: 0 !important;
    --box-shadow: -1px 2px 5px 0 #0c0c0c14;
    input {
      padding-inline-start: 16px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 14px;
      width: 90%;
    }
    .searchbar-search-icon {
      position: absolute;
      right: 14px !important;
      left: auto;
      z-index: 9998;
      color: #fff;
    }
    .searchbar-clear-button {
      right: 55px !important;
    }
  }
  .button-holder {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #af0000;
    width: 48px;
    height: 44px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    --box-shadow: -1px 2px 5px 0 #0c0c0c14;
    ion-icon {
      display: none;
    }
  }
}

.user-list {
  height: 60px;
  padding: 5px;
  display: block;
}
.vid-close {
  position: relative;
  top: 40px;
  left: 12px;
  z-index: 999;
}
.vid_mute {
  position: relative;
  top: 40px;
  left: 88%;
  z-index: 999;
}

/* Gallery Section */

.gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 80px;
  justify-content: flex-start;
  .gallery-item {
    position: relative;
    width: 22%;
    margin: 11px;
    app-post-slides {
      ion-card {
        padding: 0;
        margin: 0 10px;
        .meanu_icon {
          position: absolute;
          top: 10px;
          left: 10px;
          background-color: var(--ion-post-icon-bg);
          border-radius: 50%;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 10px 20px 0px rgb(8 0 42 / 8%);
          z-index: 9999;
          ion-buttons {
            ion-button {
              ion-icon {
                font-size: 20px !important;
              }
            }
          }
        }
      }
    }

    .meanu_icon {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: var(--ion-post-icon-bg);
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 10px 20px 0px rgb(8 0 42 / 8%);
      ion-buttons {
        ion-button {
          ion-icon {
            font-size: 20px !important;
          }
        }
      }
    }
    .icons {
      position: absolute;
      left: 15px;
      top: 15px;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 30%;
      ion-icon {
        color: var(--ion-color-base) !important;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
    .media {
      width: 100%;
      height: 100%;
      app-video-play {
        width: 100%;
        .video-js {
          border-radius: 13px;
          height: 100% !important;
          .vjs-poster {
            border-radius: 13px;
          }
          video {
            border-radius: 13px;
            object-fit: cover;
          }
        }
      }
      ion-img {
        height: 100%;
        &::before {
          content: "";
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          border-radius: 13px !important;
          margin: 1px;
          background: linear-gradient(to right, #f1f0f0, #c1c0c0, #f1f0f0);
          background-size: 200%;
          background-position: left;
          @keyframes load {
            0%,
            100% {
              background-position: right;
            }
            50% {
              background-position: left;
            }
          }
        }
        &::part(image) {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 13px;
        }
      }

      .video_uploading {
        width: auto;
        height: 290px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ff9b21;
        border-radius: 10px;
        ion-icon {
          height: 55px;
          width: 55px;
        }
      }
    }
  }
}

/* Loader */

.loader {
  width: 5rem;
  height: 5rem;
  border: 0.6rem solid #999;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin: 0 auto;
  animation: loader 500ms linear infinite;
}

// backdrop

ion-action-sheet {
  .action-sheet-wrapper {
    .action-sheet-container {
      .action-sheet-group {
        background: var(--ion-item-background-black);
        button {
          span {
            color: var(--ion-text-color);
            ion-icon {
              color: var(--ion-text-color);
            }
          }
        }
      }
    }
  }
}

// toast

.toast-custom-class {
  --ion-color-base: var(--ion-theme-color) !important;
}

// vicky : add product source dropdown design
ion-action-sheet.source_list {
  .action-sheet-wrapper {
    .action-sheet-container {
      .action-sheet-group {
        background: var(--ion-item-background-black);
        button {
          span {
            color: var(--ion-text-color);
            flex-direction: row-reverse !important;
            justify-content: space-between !important;
          }
          &.flipkart_icon {
            span {
              ion-icon {
                content: url(/assets/svg/settings/flipkart.svg);
              }
            }
          }
          &.walmart_icon {
            span {
              ion-icon {
                content: url(/assets/svg/settings/walmart.svg);
              }
            }
          }
          &.is_selected {
            background-color: var(--ion-theme-color);
          }
        }
      }
    }
  }
}

ion-content {
  ion-datetime {
    --background: var(--ion-background-color) !important;
  }
}

ion-modal {
  ion-content {
    ion-list {
      ion-item {
        &::part(native) {
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  ion-footer {
    ion-button {
      &::part(native) {
        --ion-toolbar-color: var(--ion-theme-color);
        --ion-toolbar-background: var(--ion-text-color);
      }
    }
  }
}
//app post item

add-post,
app-app-settings,
app-change-password,
app-add-profile,
app-edit-profile {
  ion-item {
    margin: 15px 0 !important;
  }
}

add-post {
  .custom_time_filed {
    margin: 0 !important;
  }
}

app-add-product {
  ion-item {
    margin: 0 !important;
    &.custom_time_filed {
      --background: var(--ion-item-background-black);
      border-radius: var(--border-radius-s);
      --padding-top: 5px !important;
      --padding-start: 5px !important;
      opacity: 1 !important;
      color: var(--ion-white-color);
      z-index: 9;
      font-size: 14px !important;
      font-weight: 300;
      --min-height: 55px !important;
      cursor: pointer;
      .form_group.set_time {
        .set_time_info {
          .time_info {
            p {
              margin-top: 15px;
              margin-left: 10px;
            }
          }
          .up_text {
            ion-label {
              margin-top: -15px;
            }
          }
        }
        ion-icon {
          position: absolute;
          right: 10px;
          top: 17px;
          font-size: 15px !important;
        }
      }
    }
  }
}

/* Login & Register Page */
ion-modal {
  app-login {
    ion-grid {
      overflow: scroll;
      ion-row {
        margin: 15px 0;
        ion-col {
          .logo {
            justify-content: center !important;
          }
        }
      }
    }
  }
}

.login_page,
app-register {
  position: relative;
  ion-grid {
    --ion-margin: 0 !important;
    height: 100%;
    padding: 0px 15px !important;

    ion-row {
      height: 100%;
      display: block;
      position: relative;

      ion-col {
        padding: 0;
        .logo {
          margin: 0px auto 10px;
          width: 130px;
          ion-icon {
            font-size: 35px !important;
            height: 65px;
          }
          p {
            color: var(--ion-theme-color);
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
  ion-card {
    margin-top: 20px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-shadow: none;
    background: unset !important;
    ion-item {
      // background-color: $light-grey;
      margin: 10px 0 15px;
      border-radius: 5px;
      border-bottom: none !important;
      --border-color: $light-grey;
      --inner-border-width: 0 !important;
      &::part(native) {
        --border-color: $light-grey;
      }
      ion-icon {
        color: $link-color;
        font-size: 18px !important;
      }
      a24-input {
        width: 100%;
        ion-input {
          input {
            height: 60px;
            padding: 0;
            color: $input-text-color !important;
          }
        }
      }
    }
  }
  .forgot_pw {
    margin: 15px 0px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  ion-button {
    text-transform: capitalize;
    margin: 0px;
    font-size: 14px;
    &.sign_btns {
      height: 60px;
      margin: 20px 0px 0 !important;
      box-shadow: none;
      &::part(native) {
        box-shadow: none;
      }
    }
    &.guest_btn {
      --background: var(--ion-active-label-color);
      height: 60px;
      margin: 20px auto 0 !important;
      &::part(native) {
        box-shadow: none;
      }
    }
  }
  .social_login {
    span {
      font-size: 25px;
      display: inline-block;
      margin: 0px 10px;
      color: #d6d6d6;
    }
  }
  .or_continue {
    margin: 20px 0px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      font-size: 16px;
    }
    .sideline {
      width: 110px;
      display: block;
      .left {
        float: left;
      }
      .right {
        float: right;
      }
    }
  }
  .social_login {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 25px;
    cursor: pointer;
    span {
      display: flex;
      align-items: center;
      width: 180px;
      justify-content: center;
      padding: 8px 0px;
      border: 1px solid var(--ion-white-color);
      border-radius: 4px;
      p {
        margin: 0;
        margin-left: 15px;
      }
    }
  }
  .spacer {
    height: 25px;
    display: block;
  }
  .sign_bottom {
    padding-bottom: 30px;
    position: relative;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;

    p {
      margin: 0;
      font-size: 16px;
    }
    .transparent_txt_btn {
      color: $orange-text;
    }
  }
}

app-forgot {
  padding: 0px 15px;
  ion-header {
    ion-toolbar {
      padding: 0;
    }
  }
  ion-content {
    ion-card {
      margin: 0px !important;
    }
    ion-button {
      text-transform: capitalize;
      margin: 20px 0px !important;
      &.sign_btns {
        width: 100%;
        height: 60px;
      }
    }
  }
}

ion-buttons {
  &.back_btns {
    ion-back-button {
      border: 1px solid var(--ion-white-color);
      color: #fff;
      width: 45px;
      min-width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
}
// wep page

app-web-header {
  position: relative;
  z-index: 9999;
  top: 0;
  width: 100%;
  margin: 0 auto;
  .header_row {
    margin: 8px 0 0;
    .profile_btn {
      padding: 5px 28px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .home_icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .all_icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .logo_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      ion-icon {
        height: 40px;
        width: 100%;
      }
    }
  }
}
.header_home_post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
ion-split-pane {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 15px !important;
  &.has_header {
    margin-top: 80px !important;
  }
}
.split-pane-side {
  z-index: 999999;
  position: absolute;
  right: 0;
  left: 10%;
  width: 400px;
  border: 0;
  // NOTE: div changes
  div {
    width: 100%;
  }
  &.has_not_side_pane {
    display: none;
  }
}
ion-router-outlet {
  width: 100%;
  margin: 0 auto;
}

/* inner header */
ion-header {
  padding: 15px 15px 3px 15px;
  z-index: 99999;
  --background: var(--ion-web-background-color) !important;
  border: unset;
  &:after {
    display: none;
  }
  ion-toolbar {
    padding: 0px;
    --min-height: 40px;
    --border-style: unset !important ;
    ion-searchbar {
      --box-shadow: unset !important;
    }
    ion-icon {
      font-size: 33px !important;
    }
    &.toolbar-segment {
      display: none !important;
    }
    .input-box {
      width: 0%;
      -webkit-transition: width 0.4s ease-in-out;
      transition: width 0.4s ease-in-out;
    }
    .search_box {
      padding: 0;
      .search_extend {
        position: relative;
        .input-box {
          --padding-top: 0px;
          --padding-bottom: 0px;
          padding: 0 !important;
          border: 0px !important;
          width: 100%;
          ion-input {
            --background: var(--ion-card-background-color);
            border-radius: 6px;
            --padding-start: 40px;
            ion-icon {
              position: absolute;
              top: 8px;
              left: 5px;
            }
          }
        }
        ion-button {
          position: absolute;
          z-index: 9;
          top: 0;
          right: 3px;
          width: 33px;
          height: 33px;
          z-index: 99;
          &::part(native) {
            padding: 0 !important;
          }
        }
      }
    }
  }
}

cdk-virtual-scroll-viewport {
  .cdk-virtual-scroll-content-wrapper {
    margin: 0 auto;
    width: 54% !important;
    min-width: 54% !important;
    top: 0;
    left: 0;
    contain: content;
    right: 0;
    app-feed-post {
      ion-card {
        margin: 0 !important;
        ion-slides {
          height: 406px;
          .video-js.vjs-1-1:not(.vjs-audio-only-mode),
          .video-js.vjs-16-9:not(.vjs-audio-only-mode),
          .video-js.vjs-4-3:not(.vjs-audio-only-mode),
          .video-js.vjs-9-16:not(.vjs-audio-only-mode),
          .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
            padding-top: 0 !important;
            height: 100% !important;
          }
        }
      }
    }
  }
}

.feed_scroll {
  height: auto;
  width: 100%;
  margin: 0 auto;
}

/* Card Lists */
ion-card {
  &.card_lists {
    box-shadow: unset !important;
    position: relative;
    margin-top: 0 !important;
    padding: 0px;
    background-color: var(--ion-web-background-color) !important;
    ion-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      color: var(--ion-white-color);
      width: 100%;
      margin: 0 auto;
      --min-height: 32px;
      padding: 5px 4px 10px 16px;
      ion-buttons {
        .heart_like_section {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 5px;
          label {
            ion-icon {
              font-size: 40px !important;
              padding-top: 5px;
            }
          }
        }
      }
      ion-avatar {
        width: 42px;
        height: 42px;
        border: 1px solid var(--ion-white-color);
        color: var(--ion-white-color);
        text-align: center;
        line-height: 43px;
      }
      .post_details {
        width: 90%;
        padding-left: 14px;
        .post_title {
          font-size: 14px;
          padding: 0;
          margin: 0;
          line-height: 20px;
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .post_texts {
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .post_user {
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .views {
            margin-left: 5px;
          }
        }
      }
      ion-button {
        &::part(native) {
          color: var(--ion-white-color);
          padding: 0px 10px;
        }
      }
    }
    ion-card {
      margin: 0;
      box-shadow: unset !important;
      border-radius: unset;
      ion-slides {
        ion-slide {
          width: 100% !important;
          margin: 0 auto !important;
          ion-img {
            &::part(image) {
              height: 210px !important;
              object-fit: cover !important;
            }
          }
        }
        .swiper-pagination.swiper-pagination-fraction {
          bottom: 10px;
          left: 0;
          right: 20px;
          margin: 0 auto;
          width: 92%;
          text-align: right;
        }
      }
    }
    .bottom_btns {
      padding-bottom: 5px;
      border-bottom: 1px solid var(--ion-border-color);
      padding: 0 10px !important;
      margin-left: 0 !important;
      ion-buttons {
        &.right_side_icon {
          margin-left: 0;
        }
        .bookmark {
          ion-icon {
            font-size: 19px !important;
          }
        }
        ion-button {
          height: 50px;
          ion-icon {
            font-size: 22px;
            margin-inline-end: 0;
          }
          &.post_footer_right {
            width: 100%;
          }
        }
        .heart_icon {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          a24-button-heart {
            .button {
              position: relative;
              top: unset;
              left: unset;
              margin-right: unset;
              transform: unset;
              width: 100%;
              cursor: pointer;
              background: transparent;
              border: none;
              transition: 0.4s;
              outline: 0;
              text-align: center;
              color: #fff;
              font-weight: 100;
              letter-spacing: 2px;
              font-size: 14px;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              &.button.dead {
                .sw {
                  left: 50% !important;
                  margin-right: 50% !important;
                  top: 50% !important;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
          p {
            margin-left: 4px !important;
            width: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        p {
          font-weight: normal;
        }
        .placement {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 50px;
          width: auto;
          .heart_count {
            display: flex;
            height: 100%;
            p {
              position: absolute;
              right: 5px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
.feed_scroll_first {
  height: 150px;
  width: auto;
  ion-slide {
    height: auto;
    object-fit: contain !important;
    img {
      height: 392px !important;
      object-fit: contain !important;
    }
  }
}

.feed_scroll {
  // height: auto;
  height: 520px;
  width: auto;
  margin: 0px auto;
  app-feed-post {
    ion-card {
      margin: 0 !important;
      width: 100vw;
      ion-item {
        ion-buttons {
          ion-button {
            &::part(native) {
              padding: 0;
            }
            ion-icon {
              float: left;
              width: 24px;
            }
            p {
              margin-left: 4px;
              width: 33px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
  ion-slide {
    height: auto;
    object-fit: contain !important;
    img {
      height: 406px !important;
      object-fit: contain !important;
      cursor: pointer;
    }
  }
}

//vicky people suggestion comp
.people_suggestion {
  height: 330px;
  width: auto;
  display: flex;
  align-items: center;
}

app-feed-post {
  ion-card {
    margin: 0 !important;
    width: 100%;
    ion-item {
      ion-buttons {
        ion-button {
          &::part(native) {
            padding: 0;
          }
          ion-icon {
            float: left;
            width: 24px;
          }
          p {
            margin-left: 4px;
            width: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

// app project detail modal

app-project-detail-modal {
  ion-content {
    --offset-bottom: 0px !important;
    .project_transparent_text {
      .header {
        ion-icon {
          margin-bottom: 15px;
        }
      }
      h3 {
        margin: 15px 0;
        font-weight: 400;
        font-size: 18px;
        cursor: pointer;
      }
      .space {
        padding-bottom: 10px;
        margin-bottom: 20px;
        position: relative;
        &::after {
          content: "";
          width: 200px;
          height: 10px;
          border-bottom: 1px solid #5c5c58;
          position: absolute;
          right: 0;
        }
        h4 {
          margin: 0;
          font-weight: 400;
          font-size: 18px;
          margin-bottom: 8px;
        }
        .detail_box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p {
            margin: 0;
            line-height: 30px;
          }
          ion-icon {
            margin-left: 10px;
            font-size: 18px !important;
            pointer-events: none;
          }
        }
        .text_capital {
          font-size: 14px;
          font-weight: 100;
        }
      }
      .non-sapce {
        padding-bottom: 10px;
        margin-bottom: 20px;
        h4 {
          margin: 0;
          font-weight: 400;
          font-size: 18px;
          margin-bottom: 8px;
        }
        .detail_box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          font-weight: 100;
          cursor: pointer;
          ion-icon {
            margin-left: 10px;
            font-size: 18px !important;
          }
        }
      }
    }
  }
}

app-post-detail {
  cdk-virtual-scroll-viewport {
    .list-group-item {
      .post_detail_main_page {
        app-post-header {
          ion-item {
            padding: 5px 0px 10px 0px !important;
            --padding-start: 10px;
          }
        }
        ion-col {
          a24-slides {
            .slider_section {
              ion-slides {
                .swiper-wrapper {
                  ion-slide {
                    &:first-child {
                      // margin-left: 10px;
                    }
                    min-width: 70px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/*app-post-detail*/

.project_details_modal {
  --background: transparent !important;
  --width: 100%;
  ion-content {
    --background: transparent !important;
    .project_transparent_text {
      padding: 0;
      text-align: right;
      position: relative;
      top: 15%;
      right: 7%;
      width: 46%;
      margin: 0 auto;
    }
    .header {
      text-align: center;
      padding: 30px 0;
      position: absolute;
      bottom: 5%;
      cursor: pointer;
      width: 100%;
    }
  }
}

app-post-detail {
  ion-header {
    padding-bottom: 15px;
  }
  ion-content {
    .full_post_detail_page {
      ion-row {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0px 15px;
        align-items: flex-end;

        ion-col {
          .heart_like_section {
            label {
              svg {
                width: 50px;
                margin-top: 6px;
                margin-left: -8px;
              }
            }
            span {
              margin-left: -10px;
            }
          }
          p {
            font-size: 20px;
            line-height: 30px;
            padding: 0;
            margin: 8px 0;
          }
          a24-slides {
            .slider_section {
              ion-slides {
                .swiper-wrapper {
                  ion-slide {
                    justify-content: flex-start;
                    max-width: 35% !important;
                    width: 35% !important;
                    .slide_img {
                      margin-top: 0;
                      margin-bottom: 0;
                      img {
                        width: 75px;
                        height: 75px;
                      }
                      h4 {
                        border-radius: 3px;
                        padding-left: 5px;

                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span {
                          height: 30px;
                          width: 30px;
                          border-top-left-radius: 3px;
                          border-bottom-left-radius: 3px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 0;
                          margin: 0;
                          margin-left: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ion-footer {
    ion-toolbar {
      background-color: var(--ion-background-color);
      ion-buttons {
        .heart_like_section {
          label {
            svg {
              width: 65px;
            }
          }
        }
      }
    }
  }
}

/* Header */
.header_btns {
  .backButton {
    max-width: 100% !important;
    padding: 0;
    width: 100%;
  }
  .centerButton {
    text-align: right;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ion-text-color);
    ion-icon {
      width: 30px;
      height: 30px;
    }
    &.skip {
      color: var(--ion-theme-color);
      font-size: 18px;
      text-transform: capitalize;
      z-index: 99999;
    }
    &.options {
      ion-icon {
        transform: rotate(-90deg);
      }
    }
  }
  .rightButton {
    // max-width: 20% !important;
    text-align: right;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--ion-text-color);

    ion-icon {
      width: 35px;
      height: 35px;
      margin-left: 10px;
    }
    .fit_screen {
      width: 30px;
      height: 30px;
    }
    &.skip {
      color: var(--ion-theme-color);
      font-size: 18px;
      text-transform: capitalize;
      z-index: 99999;
    }
    &.options {
      ion-icon {
        transform: rotate(-90deg);
      }
    }
  }
}
.back_btns {
  border: 1px solid var(--ion-white-color);
  color: #fff;
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  ion-icon {
    display: block;
    margin: auto;
    width: 100%;
    position: absolute;
    font-size: 15px !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/* Footer */
ion-footer {
  z-index: 99999;
  .d-flex {
    width: 90%;
    margin: 0 auto;
    .addPost {
      ion-button {
        margin: 0;
        width: 50px;
        height: 50px;
        &::part(native) {
          --padding-start: 0;
          --padding-end: 0;
        }
      }
    }
  }
  .foo_logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ion-icon {
      font-size: 40px !important;
    }
  }
  ion-toolbar {
    padding: 5px 0px !important;
    --background: var(--ion-background-color) !important;
    box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.4);
  }
}

// gallery video icon

button.vjs-big-play-button {
  display: none !important;
}

/* Category */
app-category {
  width: 100%;
  padding: 0;
  ion-content {
    height: 80%;
    // --background: var(--ion-background-color) !important;
  }
  .header_btns {
    margin: 20px 15px;
  }
  .categories {
    padding: 0 15px;
    .categories_select {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .category_row {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 0px 15px;
      .selected {
        .category_box {
          // background-color: var(--ion-theme-color) !important;
          background-color: #212121 !important;
          border: 1px solid var(--ion-theme-color) !important;
          ion-icon {
            color: var(--ion-white-color) !important;
            display: none;
          }
          p {
            color: var(--ion-white-color) !important;
            &::before {
              content: "";
              border-left: 0.5px solid var(--ion-white-color) !important;
              padding-left: 10px !important;
              display: none;
            }
          }
        }
      }
      .category-col {
        text-align: center;
        margin: 8px 0;
        padding: 0;
        height: 50px;
        border-radius: 4px;
        cursor: pointer;
        .category_box {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-around;
          // background-color: var(--ion-item-background-black);
          border: 1px solid #5a5a5a;
          margin: 0;
          padding: 10px;
          height: 50px;
          border-radius: 4px;
          width: auto;
          ion-icon {
            font-size: 24px !important;
            margin-right: 10px;
            display: none;
          }

          .category-group {
            font-size: 14px;
            margin: 0;
            line-height: 18px;
            &::before {
              content: "";
              border-left: 0.5px solid #717171;
              padding-left: 10px;
              display: none;
            }
          }
        }
      }
    }
  }
  .category_btn {
    ion-icon {
      font-size: 16px !important;
      color: var(--ion-theme-color) !important;
    }
  }
}
ion-modal {
  --ion-safe-area-top: 0px;
  --height: 100%;
  --background: var(--ion-web-background-color) !important;
  app-category {
    .header_btns {
      margin-top: 35px;
    }
    .categories {
      padding: 0 15px;
      position: relative;
      margin-bottom: 50px;
      .categories_select {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .category_row {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 0px 15px;
        .selected {
          .category_box {
            background-color: var(--ion-theme-color) !important;
            ion-icon {
              color: var(--ion-white-color) !important;
              display: none;
            }
            p {
              color: var(--ion-white-color) !important;
              &::before {
                content: "";
                border-left: 0.5px solid var(--ion-white-color) !important;
                padding-left: 10px !important;
                display: none;
              }
            }
          }
        }
        .category-col {
          text-align: center;
          margin: 8px 0;
          padding: 0;
          height: 50px;
          border-radius: 4px;
          .category_box {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: var(--ion-item-background-black);
            margin: 0;
            padding: 10px;
            height: 50px;
            border-radius: 4px;
            width: auto;
            ion-icon {
              font-size: 24px !important;
              margin-right: 10px;
              display: none;
            }

            .category-group {
              font-size: 14px;
              margin: 0;
              line-height: 18px;
              &::before {
                content: "";
                border-left: 0.5px solid #717171;
                padding-left: 10px;
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .category_btn {
    color: var(--ion-theme-color);
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 400;
    margin-right: 20px;
  }
}

.inner_title {
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  margin: 20px 0px;
  padding: 0px 5px;
}

.transparent_txt_btn {
  --background: transparent;
  margin-left: 5px !important;
  height: auto;
  color: var(--ion-theme-color);
  text-transform: capitalize;
  font-size: 18px;
  &::part(native) {
    height: 24px !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: unset;
  }
}
.filepond--media-preview audio,
.filepond--media-preview video {
  width: 100%;
  will-change: transform;
  height: 100%;
  object-position: center;
}

app-login,
app-register,
app-forgot,
app-report-modal {
  ion-item.item-label-floating {
    a24-label {
      ion-label {
        transform: translateY(96%) !important;
      }
    }
  }
}

/* User Profile */
.profile {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  app-profile-upload {
    position: absolute;
    top: 20px;
    right: 25px;
    display: block;
    margin: auto;
    // file-pond {
    //   .filepond--wrapper {
    .filepond--root {
      .filepond--drop-label {
        label {
          .user_picture_icon {
            margin-top: 0px;
            background-color: var(--ion-white-color);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            // left: 115px;
            // bottom: 19px;
            ion-icon {
              color: #404040;
              font-size: 18px !important;
              margin-left: 2px;
            }
          }
          //   }
          // }
        }
      }
      .filepond--list-scroller {
        .filepond--list {
          .filepond--item {
            top: 28px !important ;
            left: -120px !important;
            .filepond--file-wrapper {
              legend {
                display: none;
              }
              .filepond--file {
                .filepond--media-preview-wrapper {
                  .filepond--media-preview {
                    audio,
                    video {
                      width: 100%;
                      height: calc(100vw * 4 / 20);
                      position: relative;
                    }
                  }
                }

                .filepond--file-action-button {
                  cursor: auto;
                  color: #404040;
                  border-radius: 50%;
                  background-color: #ffffff;
                  background-image: none;
                  box-shadow: 0 0 #fff0;
                  transition: box-shadow 0.25s ease-in;
                  cursor: pointer;
                }

                .filepond--load-indicator {
                  top: -35px;
                  position: absolute;
                  left: 65px;
                }
                .filepond--action-remove-item {
                  margin-left: 10px;
                  margin-top: 0px;
                }
                .filepond--file-info {
                  .filepond--file-info-sub {
                    display: none;
                  }
                  .filepond--file-info-main {
                    text-indent: 100%;
                    .filepond--action-edit-item-alt {
                      cursor: pointer;
                      color: #404040;
                      border-radius: 50%;
                      background-color: #fff;
                      background-image: none;
                      box-shadow: 0 0 #fff0;
                      transition: box-shadow 0.25s ease-in;
                      width: 25px;
                      height: 25px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      position: absolute;
                      top: 1px;
                      left: 48px;
                      svg {
                        width: 25px;
                        height: 25px;
                      }
                    }
                  }
                }
                .filepond--action-abort-item-load {
                  display: none;
                }
                .filepond--action-retry-item-load {
                  display: none;
                }
                .filepond--processing-complete-indicator {
                  display: none;
                }
                .filepond--file-status {
                  display: none;
                }
                .ilepond--load-indicator {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .profile-image {
    width: 110px;
    height: 110px;
    margin: 0 auto 10px;
    position: relative;
    ion-icon {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      border: unset;
    }

    ion-img {
      width: 110px;
      height: 110px;
      &::part(image) {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
        border-radius: 50%;
        border: unset;
      }
    }
    img {
      width: 110px;
      border-radius: 50%;
      height: 110px;
      object-fit: cover;
      object-position: top center;
    }
  }
  .profile-user-name {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: var(--ion-text-color);
    margin: 5px 0px;
  }
  .account-user-name {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: var(--ion-theme-color);
    margin: 5px 0px;
    justify-content: center;
  }
  .profile-bio {
    text-align: center;
    height: 50px;
    overflow: scroll;
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;

    &:empty {
      display: none;
    }
  }
  .profile-stats {
    background: var(--ion-card-background-color);
    padding: 10px;
    border-radius: 7px;
    margin: 20px 0px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        width: auto;
        text-align: left;
        margin: 0 auto;
        cursor: pointer;
        line-height: 18px;
        span {
          display: block;
        }
        &.follow {
          span {
            font-weight: 700;
            font-size: 18px;
            line-height: 10px;
          }
          p {
            font-size: 18px;
            line-height: 20px;
            margin: 0;
            text-align: left;
          }
        }
        &.add_follow {
          .profile-stat-count {
            background: var(--ion-background-color);
            height: 32px;
            font-size: 12px;
            line-height: 32px;
            text-align: center;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            span {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              flex-direction: row-reverse;
              width: 100%;
            }
            ion-icon {
              font-size: 20px;
              margin-left: 5px;
            }
            &.disable_follow {
              pointer-events: none !important;
              color: #666 !important;
              ion-icon {
                color: #666 !important;
              }
            }
          }
        }
        &.profile_menu_btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 10%;
        }
      }

      &.blocked_account {
        li {
          li {
            &.add_follow {
              margin: 0 auto;
            }
          }
        }
      }
      &.people_account {
        li {
          &.add_follow {
            margin-right: 0;
          }
        }
      }

      &.my_profile {
        li {
          &.add_follow,
          &.profile_menu_btn {
            display: none;
          }
        }
      }
      &.private_account {
        .add_follow {
          margin-right: 13%;
        }
      }
    }
  }
}

app-post-detail {
  ion-header {
    background: unset !important;
  }
  ion-content {
    app-post-slides {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      z-index: -9;
      cursor: pointer;
      ion-card {
        margin: 0 !important;
        border-radius: unset !important;
        ion-slides {
          .swiper-wrapper {
            ion-slide {
              position: relative;
              overflow: hidden;
              ion-img {
                &::part(image) {
                  height: 100vh !important;
                  width: 100% !important;
                  object-fit: cover !important;
                  aspect-ratio: 1 / 2;
                }
              }
            }
          }
        }
      }
    }
  }
}

.slide_img {
  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    border: 2px solid #ffffff;
    background-color: var(--ion-color-base);
  }
  h4 {
    border-radius: 10px;
    align-items: center;
    padding-left: 5px;
    background-color: #4e4e4e;
  }
}

.uppercase {
  text-transform: uppercase;
}
.trending_skeleton {
  margin-bottom: 20px;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .box ion-avatar {
      height: 55px;
      width: 55px;
      margin: 10px;
      border-radius: 50%;
    }
  }
}

// TWITTER HEART

.heart {
  height: 66px;
  width: 56px;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/66955/web_heart_animation.png");
  background-size: cover;
  background-position: left;
  margin: 0 auto;
  cursor: pointer;
  margin-left: -9px;
  &.is-active {
    animation: heart-burst 0.8s steps(28) forwards;
    margin-right: -20px;
  }
}

.is-active {
  animation: heart-burst 0.8s steps(28) forwards;
}

@keyframes heart-burst {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

a24-select-component {
  width: 100%;
}
ion-select,
ionic-selectable {
  width: 100%;
  max-width: 100%;
  --padding-start: 0;
  display: block !important;
  &::part(icon) {
    top: 50% !important;
    right: 6%;
    left: auto;
    position: absolute;
  }
  &.ionic-selectable {
    max-width: 100%;
  }
}

a.filepond--credits {
  display: none;
}

.author_name {
  background: turquoise;
  padding: 11px;
  width: 100%;
  text-align: center;
}

// Vicky ui design

.text_capital {
  text-transform: capitalize;
}

app-empty-message {
  min-width: 200px;
  margin: auto;
  color: #fff;
  color: var(--ion-white-color);
  z-index: 999;
  min-height: 300px;
  pointer-events: none;

  .empty_post_container .empty_post_content {
    margin: auto;
    padding: 10px;
    position: absolute;
    text-align: center;
    // top: 60%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  &.empty_profile {
    .empty_post_container .empty_post_content {
      top: 60%;
      left: 50%;
      transform: translate(-50%, -40%);
    }
  }
}

// app-profile {
//   .empty_post_container {
//     min-width: 200px;
//     margin: auto;

//     .empty_post_content {
//       margin: auto;
//       padding: 10px;
//       position: absolute;
//       text-align: center;
//       top: 60%;
//       left: 50%;
//       transform: translate(-50%, -40%);
//     }
//   }
// }

.custom_alert_modal {
  .alert-message {
    color: var(--ion-white-color) !important;
  }
}

.alert-message {
  color: var(--ion-white-color) !important;
}

.follower-suggestion-page {
  .slider {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    align-items: center;

    .slide.user_action_list {
      margin: auto 20px;
    }
  }
}

// skeleton

.custom_skeleton {
  .custom_skeleton_square {
    // column-count: 3;
    .skeleton_square {
      break-inside: avoid-column;
      width: 250px;
      height: 250px;
    }
  }
  .custom_skeleton_rectangle {
    // margin: 5%;
    // width: 90%;
    // height: 100px;

    .skeleton_user_dp {
      border-radius: 50%;
    }
    div {
      height: 50px;
      width: 90%;
    }
  }
  .custom_skeleton_profile {
    // margin: 5%;
    // width: 90%;
    height: 250px;

    .skeleton_user_profile_dp {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    div {
      margin-left: 100px;
      height: 150px;
      width: 50%;
    }
  }
}
.pin-container {
  .pin-item {
    margin: 4px;
    margin-bottom: 24px;
  }
}

.profile {
  .display-image {
    width: 100px;
    height: 100px;
    margin: auto;
  }

  .bio {
    height: 30px;
    border-radius: 8px;
    margin: 20px auto;
    width: 100px;
  }
  .followers {
    height: 75px;
    border-radius: 8px;
    margin: 20px auto;
    width: 300px;
  }
}

.horizontal_view {
  display: flex;
}

app-all-post,
app-sample {
  ion-header {
    ion-toolbar {
      .search_content {
        display: flex;
        height: 30px;
        align-items: center;
        margin: 10px 0;
        padding: 10px 0;
        .post_box,
        .people_box {
          text-align: center;
          width: 100%;
          cursor: pointer;
          p {
            margin: 0;
            position: relative;
            padding: 5px 0;
          }
          .is_active {
            &::after {
              content: "";
              border-bottom: 2px solid var(--ion-theme-color);
              width: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
      .category_section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // margin: 20px 0px;
        ion-col {
          margin: 5px 0 20px;
        }
        .category_explore {
          width: 30%;

          ion-card {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100px;
            height: 30px;
            box-shadow: none;
            color: var(--ion-text-color);
            padding: 0;
            ion-icon {
              --ion-color-base: #ff9b20 !important;
            }
            .bottom_btns {
              padding-bottom: unset;
              border-bottom: unset;
            }
          }
          &::after {
            border-right: 1.5px solid #fff;
            content: "";
          }
        }

        .category_slider {
          width: 70%;
          a24-slides {
            .slider_section {
              ion-slides {
                .swiper-wrapper {
                  ion-slide {
                    // max-width: 100% !important;
                    // width: auto !important;
                    &:first-child {
                      &::after {
                        content: "";
                        width: 0;
                        height: 95%;
                        position: absolute;
                        border: 1px solid var(--ion-white-color);
                        top: 0;
                        right: -10px;
                      }
                    }
                    .category-group {
                      width: 100% !important;

                      cursor: pointer;
                      ion-card {
                        margin: 0;
                        padding: 0;
                        box-shadow: unset;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;
                        background-color: var(--ion-item-background-black);
                        border-radius: 4px;
                        .explore_icon {
                          font-size: 24px !important;
                          margin-left: 6px;
                        }
                        ion-card-content {
                          height: auto;
                          width: max-content;
                          margin: 0;
                          display: flex;
                          align-items: center;
                          justify-content: flex-start;
                          border-radius: 2px;
                          color: var(--ion-text-color);
                          font-size: 15px;
                          padding: 5px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ion-row {
        align-items: center;
        line-height: 18px;
        ion-col {
          margin: 0;
          padding: 0;
          a24-search {
            ion-searchbar {
              padding: 0 !important;
            }
          }
          .center_text {
            p {
              text-align: center;
              font-size: 14px;
              line-height: 20px;
              padding: 0;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .post_page_logo {
          width: 70%;
        }
        .header_home_post {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .profile_btn {
            margin-left: 10px;
          }
        }
      }
    }
  }

  ion-content {
    .mainpage_heading {
      a24-slides {
        .slider_section {
          ion-slides {
            .swiper-wrapper {
              ion-slide {
                .slide_img {
                  ion-img {
                    position: relative;
                    background-clip: padding-box;
                    border: solid 1px transparent;
                    border-radius: 50px;
                    margin-top: 10px;
                    &::before {
                      content: "";
                      position: absolute;
                      top: 0;
                      right: 0;
                      bottom: 0;
                      left: 0;
                      z-index: -1;
                      margin: -2px; /* same as border width */
                      border-radius: inherit; /* inherit container box's radius */
                      background: linear-gradient(207deg, #ffcd1d 0%, #fc7823 25%, #da181a 100%);
                    }
                    &::part(image) {
                      border-radius: 50%;
                      width: 60px;
                      height: 60px;
                      object-fit: cover;
                      border: 2px solid var(--ion-white-color);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    app-feed-post {
      ion-card {
        padding: 0;
        margin: 0;
      }
    }
  }
}

app-guest-user-profile {
  ion-content {
    position: fixed !important;
    .user_container {
      .gallery {
        padding: 0 0px 0 8px !important;
        .gallery-item {
          width: 22% !important;
          margin: 8px !important;
        }
      }
    }
  }
}

app-profile,
app-guest-user-profile {
  user-profile {
    width: 100%;
    position: relative;
    height: 100%;
    margin: 0 auto;
    ion-header {
      padding: 15px !important;
      background: unset !important;
      width: 40%;
      margin: 0 auto;
    }
    ion-content {
      app-page-not-found {
        .pagenot_found_container {
          width: 40%;
          margin: 0 auto;
        }
      }
      .user_container {
        width: 40%;
        margin: 0 auto;
        .gallery {
          padding: 0 0px 0 10px;
          justify-content: flex-start;
          .gallery-item {
            position: relative;
            width: 23%;
            margin: 7px;
          }
        }
      }
    }
  }
}

app-guest-user-profile {
  ion-content {
    user-profile {
      ion-header {
        padding: 0;
      }
    }
  }
}

app-settings {
  ion-content {
    ion-list {
      ion-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 5px 0px;
        --min-height: unset !important;
        --padding-top: 10px;
        --padding-bottom: 10px;
        --inner-padding-end: 0 !important;
        --padding-start: 0;
        a24-label {
          width: 100%;
          margin-left: 100px;
        }
      }
    }
  }
}
app-privacy-security {
  ion-content {
    ion-card {
      margin: 0 !important;
      box-shadow: unset !important;
      background: unset !important;
      ion-card-header {
        color: var(--ion-theme-color);
        padding: 0;
      }
      ion-card-content {
        padding-bottom: 0 !important;
        padding-inline-start: 0 !important;
        padding-inline-end: 0 !important;
        ion-list {
          ion-item {
            align-items: center !important;
            margin: 0 !important ;
          }
        }
      }
    }
  }
}

app-post-settings,
app-allow-comments {
  ion-content {
    ion-card {
      ion-card-content {
        padding-bottom: 0 !important;
        ion-item {
          margin: 0 !important;
          --min-height: 48px;
        }
      }
    }
  }
}

app-followers-settings {
  ion-content {
    ion-card {
      ion-card-header {
        padding: 20px 20px;
      }
      ion-card-content {
        padding: 0 20px 0px !important;
        ion-item {
          margin: 0 !important;
          --min-height: 48px;
        }
      }
    }
  }
}

app-followers-settings,
app-post-settings,
app-privacy-security,
app-allow-comments {
  ion-card {
    background: var(--ion-card-background-color) !important;
  }
}

app-app-settings {
  ion-content {
    ion-card {
      ion-card-content {
        margin: 0;
        padding: 0 !important;
      }
    }
  }
}

ion-button {
  &::part(native) {
    box-shadow: unset;
    text-transform: capitalize;
    font-size: 16px;
  }
}

app-about {
  ion-content {
    .about_page {
      margin: 30px auto;
      text-align: center;
      width: 40%;

      .share_btn {
        ion-button {
          width: 150px;
          ion-icon {
            font-size: 24px !important;
          }
        }
      }

      ion-icon {
        width: 160px;
        margin-left: -25px;
        font-size: 67px !important;
      }
      ion-img {
        width: 160px;
      }
      h4 {
        font-size: 12px !important;
        margin: 0;
      }
      P {
        font-size: 16px;
        text-align: justify;
      }
      ion-button {
        &::part(native) {
          box-shadow: unset;
          text-transform: capitalize;
        }
        ion-icon {
          margin-left: 30px;
        }
      }
    }
  }
}

app-settings,
app-followers-settings,
app-notification,
app-privacy-security,
app-help,
app-about,
app-edit-profile,
app-app-settings,
app-post-settings,
app-comments,
app-add-product,
app-add-profile,
app-forgot,
app-blocked-user,
app-muted-user,
app-change-password,
app-allow-comments {
  ion-content {
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-start: 15px;
    --padding-end: 15px;

    ion-card {
      padding: 0;
      margin: 15px 0 20px !important;
      background: unset;
    }

    ion-title {
      padding: 0;
      color: var(--ion-text-color) !important;
      font-size: 28px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 85%;
      white-space: nowrap;
      position: relative;
      margin: 15px 0 0;
      padding: 0;
      text-align: left;
      height: auto;
    }

    ion-list {
      ion-item {
        a24-label {
          ion-label {
            font-size: 16px !important;
          }
        }
      }
    }

    ion-grid {
      padding-inline-start: unset;
      padding-inline-end: unset;
      ion-row {
        ion-col {
          padding-inline-start: unset;
          padding-inline-end: unset;
          ion-card {
            --padding-start: unset;
            --padding-end: unset;
            background: transparent;
            box-shadow: none;
            ion-card-content {
              padding: 0 !important;
              form {
                position: relative;
                ion-button {
                  // position: absolute;
                  z-index: 99999;
                  width: 100%;
                  height: 40px;
                  bottom: 0;
                  left: 0;
                  margin: 20px auto;
                  padding: 0px 15px;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  margin-bottom: unset !important;
                  margin-inline-start: unset !important;
                }
              }
            }
          }
        }
      }
    }
    ion-card-content {
      ion-row {
        h5 {
          font-size: 24px !important;
          color: var(--ion-text-color);
          margin: 20px 0 !important;
        }
      }
      ion-item {
        a24-radio {
          ion-radio-group {
            ion-item {
              &::part(native) {
                flex-direction: row-reverse;
              }
              ion-radio {
                margin: 0 36px 0px 4px !important;
              }
            }
          }
        }
        a24-label {
          ion-label {
            font-size: 16px !important;
            color: var(--ion-text-color) !important;
            display: unset !important;
          }
        }
        a24-toggle {
          margin: 0;
          padding: 0;
          ion-toggle {
            margin: 0;
            padding: 5px;
          }
        }
      }
    }
    &.app-followers-page {
      ion-card {
        padding: 0;
        margin: 15px 0 40px;
        box-shadow: unset;
      }
    }
    &.post_setting_Page {
      ion-card {
        padding: 0;
        margin: 15px 0 40px;
        box-shadow: unset !important;
      }
    }

    &.privacy_page_section {
      ion-card {
        padding: 0;
        margin: 10px 0 15px;
        box-shadow: unset;
        ion-card-content {
          ion-list {
            ion-item {
              a24-label {
                ion-label {
                  font-size: 16px !important;
                  color: var(--ion-text-color) !important;
                }
              }
              ion-icon {
                color: var(--ion-text-color);
              }
            }
          }
        }
      }
    }

    &.app_notification_section,
    &.comment_section {
      ion-card {
        padding: 0;
        margin: 10px 0 15px;
        box-shadow: unset;
        ion-card-content {
          padding: 0;
          margin: 0;
          ion-list {
            ion-item {
              &::part(native) {
                margin: 0;
                padding: 0;
                --border-style: 0 !important;
                padding-inline-end: unset !important;
                padding-inline-start: unset !important;
                --inner-padding-end: 0 !important;
                .item-inner {
                  padding-inline-end: unset !important;
                  padding-inline-start: unset !important;
                }
              }
              a24-label {
                ion-label {
                  font-size: 16px !important;
                  color: var(--ion-text-color) !important;
                }
              }
              ion-icon {
                color: var(--ion-text-color);
              }
            }
          }
        }
      }
    }
  }
}

// app-page-not-found

app-page-not-found {
  .pagenot_found_container {
    p {
      font-size: 16px;
      margin: 10px 0;
    }
    .logo_container {
      margin: 20px 15px 0;
      .foo_logo {
        ion-icon {
          height: 45px;
          width: 100%;
        }
      }
    }
    .page_not_found_box {
      margin: auto 15px;
      .page_not_found {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 5px 0 45px;
        text-align: center;
        p > span {
          color: var(--ion-theme-color);
          cursor: pointer;
        }
      }
      .page_not_foung_img {
        margin: 110px 0;
        ion-icon {
          width: 100%;
          height: 236px;
        }
      }
    }
    .not_found_footer {
      position: absolute;
      bottom: 30px;
      padding: 15px;
      background-color: var(--ion-item-background-black);
      width: 40%;
      .foo_logo {
        ion-icon {
          width: 90px;
          height: 30px;
        }
      }
      .footer_box {
        .text_box,
        .btn_box {
          width: 100%;
          text-align: center;
        }
        .btn_box {
          display: flex;
          align-items: center;
          padding: 20px;
          ion-button {
            width: 100%;
            text-transform: capitalize;
            &::part(native) {
              box-shadow: unset;
            }
            &:last-child() {
              &::part(native) {
                background-color: unset;
                color: var(--ion-theme-color);
              }
            }
          }
        }
      }
    }
  }
}

// setting page

app-settings {
  ion-header {
    background: unset !important;
  }
  ion-content {
    ion-list {
      ion-item {
        padding: 10px 0;
        ion-icon {
          color: var(--ion-color-base) !important;
          margin-inline-end: 0px !important ;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          margin-inline-start: 0;
        }
        ion-label {
          font-size: 24px !important;
          color: var(--ion-text-color) !important;
          display: unset !important;
        }
      }
    }
  }
}

// introducation page

.intro_container {
  .intro_slide {
    justify-content: flex-end !important;
    .intro_image_container {
      margin: auto 0;
      ion-img {
        &::part(image) {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 100%;
          margin: 0 auto -100px;
        }
      }
    }
  }
  .swiper-pagination-bullets {
    bottom: 15px;
    left: 30px;
    width: 100%;
    text-align: start;
  }
}

//loginpage

.login_form_container {
  .header {
    font-size: 28px;
    font-weight: 500;
    color: var(--ion-text-color);
    margin-top: 40px;
  }
  .sub_header {
    font-size: 16px;
    font-weight: 400;
    color: $intro-secondary-color;
    line-height: 10px;
  }
}

// comment model
.real_comment_model {
  --background: #00000059 !important;
  ion-header {
    background: transparent !important;
  }
  ion-content {
    --background: transparent !important ;
  }
  ion-footer {
    app-messages-keyboard {
      ion-toolbar {
        --background: none !important;
        box-shadow: none;
        .editer {
          position: absolute;
          right: 60px;
          z-index: 99;
          bottom: 10px;

          .edit_text {
            p {
              display: none;
            }
          }
          .editer_icon {
            text-align: center;
            margin-right: 15px;
            span {
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .message_box {
          ion-item {
            --border-width: 0 0 0 0 !important;
            --border-color: unset !important;
            --highlight-color-focused: unset !important;
            --highlight-color-valid: unset !important;
            --highlight-background: unset !important;
            --highlight-color-invalid: unset !important;
            --inner-border-width: 0px 0px 0px 0px !important;
            --padding-start: 8px !important;
          }
          textarea {
            color: var(--ion-white-color) !important;
            --highlight-color-focused: unset !important;
            --highlight-color-valid: unset !important;
            --highlight-background: unset !important;
          }
          .icon_img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            position: absolute;
            bottom: 2px;
            margin: 0px 3px;
          }
        }
      }
    }
  }
}

.comment_modal {
  --background: transparent !important;

  app-message-detail {
    // padding: 0px 10px;
    ion-header {
      background: transparent !important;
      app-page-header {
        .header_btns {
          ion-row {
            .header_title {
              h3 {
                text-align: end;
              }
            }
          }
        }
      }
    }
    ion-content {
      --background: transparent !important ;
      --padding-start: 5px !important;
      --padding-end: 10px !important;

      app-user-message {
        --background: transparent !important;
        .message-user {
          ion-item {
            --inner-border-width: 0 !important;
          }
          ion-row {
            .message-container {
              background: unset !important;
              position: relative;
              border-radius: unset;
              padding: 0;
              margin-left: 0;
              margin-right: 0;
              -webkit-margin-start: 0px;
              margin-inline-start: 0px;
              -webkit-margin-end: 0px;
              margin-inline-end: 0px;
              width: 95%;
              max-width: 100%;
              margin-top: 0;
              span {
                &::after {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

// help

app-help {
  ion-content {
    a24-accordion {
      ion-accordion-group {
        margin: 25px 0px;
        ion-accordion {
          background: var(--ion-card-background-color) !important;
          margin: 15px 0 20px !important;
          ion-item {
            display: block;
            align-items: center;
            &::part(native) {
              margin: 0;
              padding: 0;
              color: var(--ion-text-color);
              border: unset !important;
            }
            a24-label {
              --min-height: unset !important;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 100%;
              white-space: nowrap;
              height: 55px;
              line-height: 25px;
              padding: 15px;
              ion-label {
                display: unset !important;
                font-size: 16px !important;
                color: var(--ion-text-color);
              }
            }
            ion-icon {
              padding: 0;
              margin: 0;
            }
          }
          ion-list {
            ion-item {
              --inner-border-width: 0;
              a24-label {
                --min-height: unset !important;
                text-overflow: unset;
                overflow: unset;
                width: 100%;
                white-space: unset;
                text-align: justify;
                height: auto;
                ion-label {
                  display: unset !important;
                  font-size: 16px !important;
                  color: var(--ion-text-color);
                  white-space: unset !important;
                }
              }
            }
          }
          &.accordion-expanded {
            .header {
              a24-label {
                ion-label {
                  color: var(--ion-theme-color) !important ;
                }
              }
              ion-icon {
                color: var(--ion-theme-color) !important ;
              }
            }
          }
        }
      }
    }
  }
}

// notification

app-notifications {
  ion-header {
    app-page-header {
      .header_btns {
        ion-row {
          .backButton {
            max-width: 15% !important;
          }
        }
      }
    }
  }
  ion-content {
    ion-card {
      margin: 0 !important;
      ion-card-content {
        padding: 0 !important;
        .notification_btn {
          position: absolute;
          right: 16px;
          top: 10px;
        }
        .notification_box {
          margin-bottom: 20px;
          .head_text {
            color: var(--ion-white-color);
            font-size: 18px;
            margin: 0 0 10px;
          }
          ion-row {
            align-items: center;
            ion-col {
              ion-row {
                align-items: center;
                ion-col {
                  .text {
                    color: var(--ion-white-color);
                    line-height: 14px;
                    margin: 0;
                    .username {
                      color: var(--ion-theme-color);
                      margin-right: 3px;
                    }
                  }
                  .date_sec {
                    color: var(--ion-icon-color);
                    font-weight: 600;
                  }
                  app-c-avatar {
                    .user_profile {
                      position: relative;
                      width: 43px !important;
                      .info_icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 9;
                        font-size: 15px !important;
                      }
                      ion-avatar {
                        box-shadow: -3px 3px 6px 1px #0c0c0c17;
                        width: 39px;
                        height: 39px;
                        position: relative;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: linear-gradient(207deg, #ffcd1d 0%, #fc7823 25%, #da181a 100%);
                        ion-thumbnail {
                          --size: 35px !important;
                          ion-img {
                            border: 0.5px solid white;
                            border-radius: 50%;
                            &::part(image) {
                              width: 35px;
                              height: 35px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              &.avatar {
                padding: 5px 0 !important;
                width: 100% !important;
                max-width: 8% !important;
              }
              &.info_text {
                flex: unset !important;
                width: 100% !important;
                max-width: 75% !important;
              }
              &.date_col {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

// Report model and  app-duration

ion-modal {
  app-duration {
    ion-header {
      app-page-header {
        .header_btns {
          ion-row {
            .backButton {
              max-width: 15% !important;
            }
          }
        }
      }
    }
    overflow-y: scroll !important;
    padding: 0 15px;
    ion-grid {
      padding: 0;
      .back_btns {
        margin: 10px 0px !important;
      }
      a24-label {
        ion-label {
          padding: 0;
          color: var(--ion-text-color) !important;
          font-size: 20px !important;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 210px;
          white-space: nowrap;
        }
      }
      ion-row {
        margin: 10px auto 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        ion-col {
          margin: 0px;
          ion-card {
            text-align: center;
            color: var(--ion-white-color);
            font-size: 14px;
            padding: 0;
            margin: 0;
            height: 50px;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--ion-item-background-black);
            cursor: pointer;
            &.selected {
              background-color: var(--ion-theme-color);
              cursor: pointer;
            }
          }
        }
      }
      .duration_btn {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-start: 0px;
        &::part(native) {
          --box-shadow: unset;
          height: 40px;
          margin-top: 24px;
          width: 280px;
        }
      }
    }
  }

  app-following-list {
    ion-content {
      .following_list {
        a24-search {
          ion-searchbar {
            padding: 0 !important;
          }
        }
      }
    }
  }

  app-report-modal {
    padding: 0px 10px;
    ion-header {
      padding: 15px 0 0 !important;
      ion-title {
        padding: 0;
        margin: 0;
        color: var(--ion-color-base);
        position: relative;
        text-align: start;
        height: 30px;
      }
      ion-card-subtitle {
        color: var(--ion-color-base) !important;
        p {
          margin: 0;
          font-weight: 300;
          text-transform: capitalize;
        }
      }
    }
    ion-content {
      form {
        width: 100% !important;
        padding: 0 10px;
        ion-item {
          &.item-input {
            margin-top: 10px;
          }
        }
        ion-radio-group {
          ion-item {
            height: 38px;

            a24-label {
              line-height: 25px;
              ion-label {
                color: var(--ion-color-base) !important;
              }
            }
          }
        }
      }
      ion-button {
        margin: 15px 0px;
      }
    }
  }
}

app-follower-suggestion {
  ion-content {
    .follower_head_text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header_text {
        padding: 0;
        color: var(--ion-text-color) !important;
        font-size: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 210px;
        white-space: nowrap;
      }
      ion-button {
        &::part(native) {
          background-color: transparent;
          box-shadow: unset;
          text-transform: capitalize;
          font-size: 18px !important;
          color: var(--ion-theme-color);
        }
      }
    }
    // follower-suggestion {
    //   .follower-suggestion-page {
    //     .follower_page_input {
    //       margin: 0px 15px;
    //     }
    //   }
    // }
  }
}

// following list

app-following-list,
app-followers-list {
  ion-content {
    .following_list {
      padding: 0px 15px;
      ion-toolbar {
        a24-search {
          ion-searchbar {
            padding: 0 !important;
          }
        }
      }
      ion-card {
        margin: 4% 0 0;
      }
      .following_page {
        margin: 20px 0 0 !important;
        ion-row {
          ion-col {
            h4 {
              padding: 0;
              margin: 0;
              color: var(--ion-white-color);
            }
            p {
              padding: 0;
              margin: 0;
              color: var(--ion-white-color);
            }
            &.user_profile {
              padding: 0;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

//Blocked list

app-following-list,
app-blocked-user,
app-all-post,
app-following-list,
app-follow-request,
app-follow-request-sent,
app-no-network,
app-muted-user,
app-follower-suggestion,
app-followers-list {
  ion-content {
    .user_action_list,
    .account-list {
      padding: 0px;
      height: 65px;
      app-account-list {
        .acct-list-content {
          .card-modal {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .account_btn_type {
              ion-button {
                margin: 0;
                height: auto !important;
                --border-radius: 4px;
                &::part(native) {
                  border: 1px solid var(--ion-theme-color);
                  background: transparent;
                  font-size: 12px;
                  color: var(--ion-color-base);
                  width: auto;
                  padding: 2px 5px !important;
                  font-weight: 300 !important;
                  cursor: pointer;
                  display: block;
                  text-align: center;
                  text-overflow: ellipsis;
                  text-transform: capitalize;
                  line-height: 18px;
                  text-transform: capitalize;
                  box-shadow: none !important;
                  height: auto;
                }
              }
              .active_btn {
                margin: 0;
                height: auto !important;
                &::part(native) {
                  border: 1px solid var(--ion-theme-color);
                  background: transparent;
                  background-color: var(--ion-theme-color) !important;
                }
              }
              ion-buttons {
                ion-button {
                  &::part(native) {
                    border: unset !important;
                  }
                }
              }
            }
            .user_request_detail {
              display: flex;
              flex-direction: row !important;
              align-items: center;
              .user_detail_text {
                margin-left: 10px;
                p {
                  padding: 0;
                  margin: 0;
                  line-height: 18px;
                }
                .username_new {
                  font-size: 14px;
                  color: var(--ion-white-color);
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 160px;
                  height: 1.2em;
                  white-space: nowrap;
                }
                .username {
                  font-size: 16px;
                  color: var(--ion-white-color);
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 160px;
                  height: 1.2em;
                  white-space: nowrap;
                }
              }

              app-c-avatar {
                .user_profile {
                  ion-avatar {
                    width: 40px;
                    height: 40px;
                  }
                }
              }
            }
            .user_request_btn {
              flex-direction: row !important;
              display: flex;
              align-items: center;
              justify-content: space-between;
              ion-buttons {
                ion-button {
                  color: var(--ion-white-color);
                }
              }
            }
          }
        }
      }
    }
  }
}

// guest_login
app-guest-user-model {
  .guest_page {
    width: 365px;
    background-color: var(--ion-background-color);
    padding: 25px 20px;
    border-radius: 10px;
    .head {
      p {
        padding: 0;
        margin: 0;
        text-align: center;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        color: var(--ion-white-color);
      }
    }
    ion-button {
      margin: 0;
      padding: 0px 5px;
      a {
        text-decoration: none;
      }
    }
    .sub_text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      flex-wrap: wrap;
      ul {
        list-style: none;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          ion-icon {
            font-size: 16px !important;
            margin-right: 10px;
            color: var(--ion-item-bb-text-label) !important;
          }
          span {
            font-size: 14px;
            font-weight: 100;
            color: var(--ion-item-bb-text-label) !important;
          }
        }
      }
    }
    .login_btns {
      .explore_btn {
        &::part(native) {
          color: var(--ion-white-color);
          border: 1px solid #797979;
          background: transparent;
          padding: 15px;
          margin: 0;
          height: 45px;
          width: 120px;
          font-size: 14px;
        }
      }
      .sing_new {
        &::part(native) {
          --box-shadow: unset;
          height: 45px;
          width: 120px;
          font-size: 14px;
        }
      }
    }
  }
}
app-post-detail {
  cdk-virtual-scroll-viewport {
    .cdk-virtual-scroll-content-wrapper {
      width: 40% !important;
      min-width: 40% !important;
      margin: 0 25% 0 auto;
    }
  }
}

app-post-detail {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .video-js.vjs-1-1,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  .video-js.vjs-9-16,
  .video-js.vjs-fluid {
    overflow: hidden;
    width: 100%;
    max-width: 80%;
    height: 100% !important;
  }
  cdk-virtual-scroll-viewport {
    .cdk-virtual-scroll-content-wrapper {
      top: 0;
      left: 0;
      contain: content;
      right: 0;
      .list-group-item {
        app-post-slides {
          cursor: pointer;
          ion-card {
            ion-slides {
              ion-slide {
                img {
                  width: 80% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

app-all-post,
app-sample {
  .follower_page_input {
    padding: 0;
    .suggestion_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px 25px;
      ion-title {
        padding: 0;
        color: var(--ion-white-color);
      }
    }
    .slider {
      margin: 5px 0px;
      .user_action_list,
      .account-list {
        padding: 0px;
        background-color: var(--ion-item-background-black);
        margin: 0 0 0 10px;
        border-radius: 8px;
        &:last-child() {
          margin-right: 10px;
        }
        app-account-list {
          display: flex;
          align-items: center;
          padding: 25px 0px !important;
          height: 100% !important;
          .acct-list-content {
            margin: 0 !important;
            .card-modal {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              .account_btn_type {
                ion-button {
                  margin: 0;
                  height: auto !important;
                  &::part(native) {
                    border: 1px solid var(--ion-theme-color);
                    background: transparent;
                    font-size: 14px;
                    color: var(--ion-color-base);
                    width: auto;
                    padding: 5px 9px;
                    cursor: pointer;
                    display: block;
                    text-align: center;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                    line-height: 18px;
                    text-transform: capitalize;
                    box-shadow: none !important;
                    height: auto;
                    font-weight: 100;
                  }
                }
                ion-buttons {
                  ion-button {
                    &::part(native) {
                      border: unset !important;
                    }
                  }
                }
              }
              .user_request_detail {
                display: flex;
                flex-direction: column;
                align-items: center;
                .user_detail_text {
                  text-align: center;
                  margin: 10px;
                  p {
                    padding: 0;
                    margin: 0;
                    line-height: 18px;
                  }
                  .username_new {
                    font-size: 15px;
                    color: var(--ion-white-color);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 160px;
                    height: 1.2em;
                    white-space: nowrap;
                  }
                  .username {
                    font-size: 18px;
                    color: var(--ion-white-color);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 125px;
                    padding: 0;
                    height: 1.2em;
                    white-space: nowrap;
                  }
                }

                app-c-avatar {
                  .user_profile {
                    ion-avatar {
                      width: 40px;
                      height: 40px;
                    }
                  }
                }
              }
              .user_request_btn {
                flex-direction: row !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
                ion-buttons {
                  ion-button {
                    color: var(--ion-white-color);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
//public_policy_popup

.public_policy_popup {
  padding: 10px;
  p {
    text-align: justify;
    .read_more {
      color: var(--ion-theme-color);
      cursor: pointer;
    }
  }
}

// app-add-profile

app-add-profile {
  ion-content {
    ion-grid {
      .add_profile_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px;
      }
    }
    .category_btn {
      ion-icon {
        font-size: 16px !important;
        color: var(--ion-theme-color) !important;
      }
      float: right;
      opacity: 1;
    }
  }
}

// header style
app-add-product,
app-add-post,
app-save-posts {
  ion-header {
    padding: 0px 15px;
    app-page-header {
      .header_btns {
        .backButton {
          max-width: 8% !important;
          padding: 0;
          width: 100%;
        }
        ion-row {
          ion-col {
            h3 {
              color: var(--ion-text-color) !important;
              font-size: 17px;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 210px;
              white-space: nowrap;
              text-align: left;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

// add post
app-admin-add-post {
  ion-content {
    max-width: 964px;
    // position: absolute;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
  }
}

app-add-post {
  add-post {
    ion-content {
      ion-row {
        form {
          .add_poast_card {
            border-bottom: 1px solid var(--ion-border-color);
            padding-bottom: 20px;
          }
          .submit_btn {
            ion-button {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-inline-start: 0px;
            }
          }
        }
      }
    }
  }
}

app-add-post,
app-admin-add-post {
  add-post {
    ion-header {
      padding: 0px 15px;
      width: 40%;
      margin: 0 auto;
      background-color: var(--ion-web-background-color) !important;
      ion-toolbar {
        app-page-header {
          .header_btns {
            ion-row {
              ion-col {
                h3 {
                  color: var(--ion-text-color) !important;
                  font-size: 17px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 210px;
                  white-space: nowrap;
                  text-align: left;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }

    ion-content {
      width: 100%;
      ion-row {
        padding-bottom: 30px;
        height: 100%;
        width: 40%;
        margin: 0 auto;
        form {
          width: 100%;
          .submit_btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 16px 50px;
            ion-button {
              width: 100%;
            }
            .pub_policy {
              margin: 4px 2px;
              height: 40px;
              align-items: flex-end;
              app-public-policy {
                ion-card-content {
                  padding: 0;
                  span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
          ion-card {
            background-color: unset !important;
            margin: 20px 15px !important;
            ion-card-content {
              padding: 0 !important;
              .filepond--root {
                margin-bottom: 0px !important;
                // height: 360px !important;
                .filepond--drop-label {
                  background-color: var(--ion-item-background-black);
                  color: var(--ion-white-color);
                  border-radius: 5px;
                  min-height: 3.75em !important;
                  label {
                    width: 100%;
                    padding: 0 10px;
                    .img_upload_btn {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      .left {
                        text-align: start;
                        .tittle {
                          font-weight: 300;
                          display: block !important;
                          color: var(--ion-item-bb-text-label) !important;
                          z-index: 99;
                          position: relative;
                          transform: none !important;
                          font-size: 12px !important;
                        }
                        .sub_text {
                          font-size: 14px !important;
                        }
                      }
                      .right.upload_btn {
                        padding: 4px 35px;
                        border: 1px solid #fff;
                        border-radius: 25px;
                        font-size: 14px;
                        margin-right: 1%;
                        color: #fff;
                        font-weight: 400;
                        cursor: pointer;
                      }
                    }
                  }
                }
                .filepond--list-scroller {
                  margin-top: 60px;
                  transform: unset !important;
                  -webkit-transform: unset !important;
                  -moz-transform: unset !important;
                  -ms-transform: unset !important;
                  -o-transform: unset !important;
                  .filepond--list {
                    width: 100%;
                    left: 0 !important;
                    right: 0 !important;
                    .filepond--item {
                      margin: 15px 0 !important;
                      transform: unset !important;
                      position: relative;
                      height: 310px !important;

                      fieldset {
                        .filepond--file {
                          button {
                            cursor: pointer !important;
                          }
                        }
                      }
                    }
                  }
                }
                .filepond--panel-root {
                  background-color: transparent !important;
                }
              }

              app-video-play {
                position: relative;
                .video-js {
                  padding-top: 56.25% !important;
                }
              }
              ion-item {
                .active_sub_category {
                  ion-chip {
                    background-color: var(--ion-theme-color);
                    color: var(--ion-white-color);
                    ion-icon {
                      font-size: 20px !important;
                      color: var(--ion-white-color);
                    }
                  }
                }
                &.custom_time_filed {
                  .set_time {
                    width: 100%;
                    background: var(--ion-item-background-black);
                    border-radius: var(--border-radius-s);
                    --padding-top: 30px !important;
                    --padding-start: 10px !important;
                    --padding-end: 20px !important;
                    height: 60px;
                    opacity: 1 !important;
                    color: var(--ion-white-color);
                    z-index: 9;
                    font-size: 14px !important;
                    font-weight: 300;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    a24-label {
                      ion-label {
                        transform: none !important;
                        font-size: 12px !important;
                      }
                    }
                    .set_time_info {
                      .time_info {
                        padding-left: 10px;
                        padding-top: 3px;
                        p {
                          span {
                            margin: 0 10px 0 0;
                          }
                        }
                      }
                    }
                    ion-icon {
                      font-size: 14px !important;
                      padding-right: 6%;
                    }
                    .add_btn {
                      padding: 4px 35px;
                      border: 1px solid #fff;
                      border-radius: 25px;
                      font-size: 14px;
                      margin-right: 4%;
                      color: #fff;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
            &.required_filed {
              a24-label {
                ion-label {
                  color: var(--ion-white-color);
                  font-size: 20px !important;
                }
              }
              h5 {
                color: var(--ion-white-color);
              }
            }
          }
          .product_submit {
            --box-shadow: unset;
            &::part(native) {
              --box-shadow: unset;
              height: 40px;
              margin-top: 24px;
              width: 280px;
            }
          }
        }
      }
    }
  }
}

// ion popovwer

.popover-class {
  .popover-viewport {
    ion-list {
      ion-item {
        --border-color: unset;
        --inner-border-width: 0;
        .popover_cotainer_text {
          width: 100%;
          .popup_box,
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            color: var(--ion-white-color);
          }
        }
      }
    }
  }
}

// app-add-product

app-add-product {
  ion-content {
    ion-button {
      margin-bottom: 30px;
      margin-top: 30px;
      &::part(native) {
        box-shadow: none !important;
      }
    }
    ion-card {
      ion-card-content {
        padding: 0 !important;
        .price_input {
          justify-content: space-between;
          align-items: center;
          ion-col {
            padding: 15px 5px !important;
            position: relative;
            &:first-child() {
              padding-left: 0 !important ;
            }
            &:last-child() {
              padding-right: 0 !important;
            }
            .price_sign {
              position: absolute;
              z-index: 999;
              top: 36px;
              right: 15px;
            }
          }
        }
        .error_msg {
          .valid_msg {
            .form-con-errors {
              .hide-err {
                margin-top: -14px !important;
                padding-bottom: 5px;
              }
            }
          }
        }
        .producat_image_box {
          justify-content: space-between;
          ion-col {
            padding: 0 5px !important;
            ion-item {
              a24-select-component {
                .custom_select {
                  position: relative;
                }
              }
            }
            &:first-child() {
              padding-left: 0 !important ;
            }
            &:last-child() {
              padding-right: 0 !important;
              a24-filepond {
                file-pond {
                  .filepond--wrapper {
                    button.filepond--file-action-button.filepond--action-edit-item {
                      display: none;
                    }
                    .filepond--file-info {
                      display: none;
                    }
                    .filepond--action-remove-item {
                      width: 20px;
                      height: 20px;
                      left: 8px;
                      top: 5px;
                      margin-left: 13px;
                    }
                    .filepond--root {
                      margin-bottom: 0 !important;
                      height: auto !important;
                      .filepond--drop-label {
                        background-color: var(--ion-theme-color);
                        color: var(--ion-white-color);
                        padding: 0;
                        margin: 0;
                        border: unset;
                        width: 100%;
                        position: relative;
                        min-height: 100% !important ;
                        height: 60px;
                        border-radius: 8px;
                        font-size: 12px;
                        label {
                          .product_img_box {
                            color: var(--ion-white-color);
                            .product_icon {
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              ion-icon {
                                color: var(--ion-white-color);
                              }
                            }
                          }
                        }
                      }
                      .filepond--list-scroller {
                        margin-top: 0 !important;
                        margin-bottom: 0 !important;
                        .filepond--list {
                          .filepond--item {
                            height: 60px !important;
                            .filepond--panel {
                              .filepond--item-panel {
                                .filepond--panel-center {
                                  height: auto !important;
                                }
                              }
                            }
                          }
                        }
                      }
                      .filepond--panel-center {
                        height: 30px !important;
                        border-radius: 8px !important;
                      }
                      .filepond--panel-root {
                        background-color: var(--ion-background-color) !important;
                      }
                      .filepond--panel-root {
                        background-color: var(--ion-background-color) !important;
                        padding: 0;
                        margin: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .source_link {
          ion-col {
            padding: 0 !important;
          }
        }
        .add_remove_sec {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .add_link,
          .control_link,
          .rm_link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: var(--ion-theme-color);
            font-size: 18px;
            font-weight: 400;
            margin: 15px 0;
            float: right;
            width: 100%;
            cursor: pointer;
            ion-icon {
              margin-right: 10px;
              color: var(--ion-theme-color);
            }
          }
        }
        ion-row {
          ion-col {
            a24-label {
              ion-label {
                color: var(--ion-white-color);
              }
            }
          }
        }
      }
    }
  }
}

// app-no-network

app-no-network {
  ion-content {
    .network_container {
      width: 40%;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .no_network_icon {
        width: 100%;
        margin: 0 auto;
        svg {
          width: 80%;
          padding: 20px;
          height: 100%;
          padding-top: 200px;
          margin: 0 auto;
          display: block;
        }
      }
      .no_network_footer {
        bottom: 0;
        left: 0;
        background-color: var(--ion-item-background-black);
        width: 100%;
        margin: 0 auto;
        padding: 35px;
        .text_box {
          padding-bottom: 15px;
          h4 {
            font-size: 24px;
          }
          p {
            font-size: 18px;
            line-height: 30px;
            padding: 0;
            margin: 0;
          }
        }
        ion-button {
          width: 158px;
          height: 45px;
          font-size: 18px;
          text-transform: capitalize;
          font-weight: 400;
          &::part(native) {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.pub_policy {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pub_content {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: inherit;
  display: block;
  position: relative;
  line-height: 1.5;
  p {
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }
}

app-sample-post-detail,
app-post-detail {
  ion-header {
    position: absolute;
    background: transparent !important;
  }
  cdk-virtual-scroll-viewport {
    .cdk-virtual-scroll-content-wrapper {
      .fit {
        app-post-slides {
          cursor: pointer;
          ion-card {
            ion-slides {
              .swiper-wrapper {
                ion-slide {
                  img {
                    object-fit: contain !important;

                    object-position: 50% 40%;
                    cursor: pointer;
                  }
                  // ion-img {
                  //   &::part(image) {
                  //     object-fit: contain !important;
                  //     object-position: center center;
                  //   }

                  // }
                }
              }
            }
          }
        }
      }
      .list-group-item {
        padding: 0 !important;
        position: relative;
        &.fit {
          app-post-slides {
            ion-card {
              ion-slides {
                ion-slide {
                  app-video-play {
                    video {
                      object-fit: contain !important;
                    }
                  }
                }
              }
            }
          }
        }

        app-post-slides {
          ion-card {
            ion-slides {
              .swiper-wrapper {
                ion-slide {
                  ion-img {
                    &::part(image) {
                      //height: 100vh;
                      height: var(--height-viewport);
                      object-fit: cover;
                      object-position: center center;
                    }
                  }
                }
              }
            }
          }
        }
        .post_detail_main_page {
          .left_detail_box {
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100% !important;
            max-width: 80% !important;
            .info {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background-color: var(--ion-blur-color);
              margin: 0;
              height: 25px;
              border-radius: 50px;
              padding: 0 10px;
              width: fit-content;
              margin-left: 8px;
              cursor: pointer;
              p {
                margin: 0;
                margin-left: 5px;
                font-size: 14px;
              }
            }
            .post_product_link {
              margin-left: 5px;
              z-index: 998;
            }
            .user_details {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin: 20px 0;
              margin-left: 8px;
              app-c-avatar {
                .user_profile {
                  ion-avatar {
                    border: solid 2px transparent;
                    width: 40px;
                    height: 40px;
                    ion-thumbnail {
                      position: relative;
                      background-clip: padding-box;
                      border: solid 1px transparent;
                      border-radius: 50px;
                      margin: -5px;
                      ion-img {
                        position: relative;
                        background-clip: padding-box;
                        border: solid 1px transparent;
                        border-radius: 50px;
                        margin: 0px;
                        &::before {
                          content: "";
                          position: absolute;
                          top: 0;
                          right: 0;
                          bottom: 0;
                          left: 0;
                          z-index: -1;
                          margin: -2px; /* same as border width */
                          border-radius: inherit; /* inherit container box's radius */
                          background: linear-gradient(207deg, #ffcd1d 0%, #fc7823 25%, #da181a 100%);
                        }
                        &::part(image) {
                          border-radius: 50%;
                          object-fit: cover;
                          border: 1px solid var(--ion-white-color);
                          margin: 1px;
                        }
                      }
                    }
                  }
                }
              }
              .profile_img {
                margin-left: 10px;
                p {
                  padding: 0;
                  margin: 0;
                  font-size: 16px;
                  line-height: 20px;
                }
              }
            }
          }
          .right_detail_box {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: 5px;
            z-index: 999;
            .comment_icon,
            .share_icon {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              flex-direction: column;
              height: 58px;
              ion-icon {
                filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
                font-size: 29px !important;
              }
              p {
                padding: 0;
                margin: 0;
              }
              span {
                color: var(--ion-white-color);
                filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
              }
            }
            .arrow_icon {
              width: auto;
              height: 20px;
              ion-icon {
                filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
                font-size: 29px !important;
              }
            }
            .volume_icon {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              flex-direction: column;
              height: 45px;
              ion-icon {
                width: 25px;
              }
            }
            .post_page_heart {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              flex-direction: column;
              height: 60px;
              width: 100%;
              position: relative;
              a24-button-heart {
                height: 30px;
                .button {
                  position: relative;
                  top: unset;
                  left: unset;
                  margin-right: unset;
                  transform: unset;
                  width: 100%;
                  cursor: pointer;
                  background: transparent;
                  border: none;
                  transition: 0.4s;
                  outline: 0;
                  text-align: center;
                  color: var(--ion-white-color);
                  font-family: unset;
                  font-weight: 100;
                  letter-spacing: 2px;
                  font-size: 14px;
                  text-transform: uppercase;
                  ion-icon {
                    font-size: 29px !important;
                    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
                  }
                  &:hover {
                    transform: unset;
                  }
                }
              }
              p {
                color: var(--ion-white-color);
                padding: 0;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

app-sample-post-detail,
app-post-detail {
  ion-content {
    position: fixed;
    z-index: 99999;
    .cdk-virtual-scroll-content-wrapper {
      width: 100%;
      height: 100vh;
      // overflow-y: scroll;
      // overscroll-behavior-y: contain;
      // scroll-snap-type: y mandatory;
      .full_post_detail_page {
        //position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        scroll-snap-align: center;
        transform: translateY(calc(max(100vh)));
        app-post-slides {
          height: 100vh;
          position: fixed;
          ion-card {
            height: 100vh;
            margin: 0;
            ion-slides {
              height: 100%;
              ion-slide {
                margin: 0;
                width: 100% !important;
                margin: 0 auto !important;
                img {
                  object-fit: cover !important;
                  aspect-ratio: 9 / 18;
                  height: 100vh;
                  width: 100%;
                }
              }
            }
          }
        }
        .post_detail_main_page {
          position: absolute;
          bottom: 150px;
          width: 100%;

          ion-col {
            p {
              font-size: 16px;
            }
            a24-slides {
              .slider_section {
                ion-slides {
                  .swiper-wrapper {
                    ion-slide {
                      margin-right: 0;
                      .slide_img {
                        flex-direction: column;
                        margin-bottom: 0;
                        width: 100%;
                        height: 100%;
                        h4 {
                          border-radius: 3px;
                          padding: 0 0 0 15px;
                          background-color: #4e4e4e;
                          font-size: 18px;
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          span {
                            line-height: 0;
                            ion-icon {
                              padding: 5px 10px;
                              margin-left: 15px;
                              background-color: #e45826;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .post_detail_page_footer {
          position: fixed;
          bottom: 0;
          ion-toolbar {
            background-color: var(--ion-background-color);
          }
          &::before() {
            display: none;
          }
        }
      }
    }
  }
}

.detail-viewport {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  width: 100%;
  height: var(--height-viewport); /* updated from 100vh */
}

.list-group-item {
  height: var(--height-viewport);
  width: 100%;
  scroll-snap-align: start;
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
}

/*.cdk-virtual-scroll-content-wrapper {
  overflow-y: scroll !important; 
  scroll-snap-type: y mandatory !important; //anim
}*/
// .detail-viewport-anim {
//   overflow-y: scroll;
//   scroll-snap-type: y mandatory; //anim
// }
// .detail-viewport {
//   // overflow-y: scroll;
//   // scroll-snap-type: y mandatory; //anim
//   // width: 100%;
//   // height: 100vh;

//   // .cdk-virtual-scroll-content-wrapper {
//   //   width: 100%;
//   //   height: 100vh;
//   //   flex-basis: 100%;
//   //   max-height: 100vh;
//   //   overflow-y: scroll;
//   //   scroll-snap-type: y mandatory;
//   // }
//   // .cdk-virtual-scroll-content-wrapper > * {
//   //   width: 100%;
//   //   height: 100vh;
//   //   scroll-snap-align: start;
//   //   display: flex;
//   //   flex-direction: column;
//   //   justify-content: center;
//   // }
// }

// vicky add product unit popover
app-add-product {
  .product_box {
    border-bottom: 1px solid var(--ion-border-color);

    .requirement_sec {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .head_txt {
        width: 100%;
        p {
          color: var(--ion-white-color);
          font-size: 20px !important;
        }
      }
      .control_link.rm_link {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: var(--ion-theme-color);
        font-size: 18px;
        font-weight: 400;
        margin: 15px 0;
        float: right;
        width: 100%;
        cursor: pointer;
        ion-icon {
          margin-right: 10px;
          color: var(--ion-theme-color);
        }
      }
    }
    ion-item.custom_unit_field {
      .set_unit {
        width: 100%;
        background: var(--ion-item-background-black);
        border-radius: var(--border-radius-s);
        --padding-top: 30px !important;
        --padding-start: 10px !important;
        --padding-end: 20px !important;
        height: 60px;
        opacity: 1 !important;
        color: var(--ion-white-color);
        z-index: 9;
        font-size: 14px !important;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: space-between;
        s a24-label {
          ion-label {
            transform: none !important;
            font-size: 12px !important;
          }
        }
        .set_unit_info {
          a24-label {
            .label-floating {
              transform: translateY(0%) !important;
              -webkit-transform: translateY(0%) !important;
              -moz-transform: translateY(0%) !important;
              -ms-transform: translateY(0%) !important;
              -o-transform: translateY(0%) !important;
            }
          }
          .unit_info {
            padding-left: 10px;
            padding-top: 3px;
            p {
              span {
                margin: 0 10px 0 0;
              }
            }
          }
        }
        ion-icon {
          font-size: 14px !important;
          padding-right: 6%;
        }
        .add_btn {
          padding: 4px 35px;
          border: 1px solid #fff;
          border-radius: 25px;
          font-size: 14px;
          margin-right: 4%;
          color: #fff;
          font-weight: 400;
        }
      }
    }
  }
}
//

// .list-group-item-anim {
//   scroll-snap-align: start; //anim
// }

.list-group-item {
  // height: 100vh;
  // // scroll-snap-align: start; //anim
  // margin: 0 !important;
  // padding: 0 10px !important;

  app-post-slides {
    // height: 100vh;
    height: var(--height-viewport);
    position: fixed;
    width: 100%;
    ion-card {
      //height: 100vh;
      height: var(--height-viewport);
      margin: 0;
      border-radius: unset;
      ion-slides {
        // height: 100%;
        height: var(--height-viewport);

        ion-slide {
          margin: 0;
          width: 100% !important;
          margin: 0 auto !important;
          img {
            object-fit: cover !important;
            aspect-ratio: 9 / 18;
            // height: 100vh;
            height: var(--height-viewport) !important;
            width: 100%;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .post_detail_main_page {
    position: absolute;
    bottom: 10px;
    width: 100%;
    z-index: 99;
    // &.extended_slider {
    //   .right_detail_box {
    //     position: absolute;
    //     right: 0;
    //     bottom: 115px;
    //   }
    // }
    ion-col {
      p {
        font-size: 16px;
        color: var(--ion-white-color);
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 15px 0px 15px 15px;
      }
      a24-slides {
        .slider_section {
          ion-slides {
            .swiper-wrapper {
              margin-bottom: 5px;
              ion-slide {
                margin-right: 0;
                .slide_img {
                  flex-direction: column;
                  margin-bottom: 0;
                  width: auto;
                  height: 100%;
                  ion-thumbnail {
                    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
                    width: 60px;
                    height: 60px;
                  }
                  h4 {
                    border-radius: 3px;
                    padding: 0 0 0 4px;
                    background-color: var(--ion-blur-color);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: var(--ion-white-color);
                    font-weight: 100;
                    font-size: 12px;
                    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
                    margin-bottom: 0;
                    width: 68px;
                    height: 22px;
                    line-height: 15px;
                    span {
                      line-height: 0;
                      ion-icon {
                        padding: 5px;
                        margin-left: 5px;
                        background-color: #e45826;
                        font-size: 10px !important;
                        border-radius: 0 2px 2px 0;
                        border: 0.8px solid #e45826;
                      }
                    }
                  }
                  ion-thumbnail {
                    ion-img {
                      &::part(image) {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-position: center;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .post_detail_page_footer {
    position: fixed;
    bottom: 0;
    ion-toolbar {
      background-color: var(--ion-background-color);
    }
    &::before() {
      display: none;
    }
  }
}

app-add-profile,
app-category,
app-follower-suggestion {
  ion-footer {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
    width: 100%;
    box-shadow: 0px 8px 67px rgb(0 0 0 / 30%);
    .category_btn {
      margin-right: 20px;
    }
  }
}

app-follower-suggestion {
  ion-footer {
    width: 40%;
    margin: 0 auto;
  }
}

// vicky - clipboard copy
.link_clipboard {
  opacity: 0 !important;
  width: 100% !important;
  height: 1px !important;
  position: absolute;
}
// Responsive design

.video-item {
  app-post-slides {
    position: relative !important;
    ion-card {
      ion-slides {
        ion-slide {
          app-video-play {
            .post-video-dimensions {
              height: 100vh !important;
            }
            video {
              width: 100% !important;
              // height: 100vh !important;
              height: var(--height-viewport) !important;
              object-fit: cover;
              border-radius: 10px;
            }
            .video-js {
              border-radius: 10px;
              background: transparent !important;
            }
          }
        }
      }
    }
  }
}

app-all-post {
  .post_search_header {
    width: 40%;
    padding: 0 !important;
    margin: 0 auto;
  }
  .post_search_viewport {
    height: 100vh;
    width: 40%;
    margin: 0 auto;
    overflow-x: hidden;
    .cdk-virtual-scroll-content-wrapper {
      margin: 0 auto;
      width: 100% !important;
      min-width: 100% !important;
      top: 0;
      left: 0;
      contain: content;
      right: 0;
    }
  }
}

// app-add-post

app-add-post {
  add-post {
    width: 100%;
    margin: 0 auto;
    .add_post {
      height: 100vh;
      width: 100%;
      margin: 0 auto;
    }
  }
}

// model login

ion-modal {
  app-login,
  app-register,
  app-forgot {
    ion-header {
      width: 100%;
      margin: 0 auto;
      padding: 15px 0 20px;
    }
    ion-content {
      ion-grid {
        width: 100%;
        margin: 0 auto !important;
        padding: 0 !important;
      }
    }
  }
  app-guest-user-model {
    .guest_page {
      margin: 15px auto;
      background-color: var(--ion-item-background-black);
      .head {
        p {
          padding: 0;
          margin: 0;
          text-align: center;
          width: 100%;
          font-size: 16px;
          line-height: 20px;
        }
      }
      .sub_text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        ul {
          list-style: none;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            ion-icon {
              font-size: 16px !important;
              margin-right: 10px;
              color: var(--ion-item-bb-text-label) !important;
            }
            span {
              font-size: 14px;
              font-weight: 100;
              color: var(--ion-item-bb-text-label) !important;
            }
          }
        }
      }
    }
  }
}

// normal page login
.slider_container {
  margin: 0 auto;
  width: 40%;
  ion-slide {
    width: 100% !important;
  }
}

app-forgot {
  ion-content {
    --padding-start: 0 !important;
    --padding-end: 0 !important;
  }
}

app-login,
app-register,
app-forgot {
  ion-header {
    width: 40%;
    margin: 0 auto;
    padding: 15px 0 20px;
  }
  ion-content {
    ion-grid {
      width: 40%;
      margin: 0 auto !important;
      padding: 0 !important;
    }
  }
}

app-edit-profile,
app-add-profile {
  .profile {
    margin: 0 auto;
    position: relative;
    width: 110px !important;
    height: 110px !important;
  }
  ion-footer {
    width: 40%;
    margin: 0 auto;
  }
  ion-header {
    width: 40%;
    margin: 0 auto;
    padding: 15px 0 20px;
    background-color: var(--ion-web-background-color) !important;
  }
  ion-content {
    --padding-start: 0;
    --padding-end: 0;
    ion-title {
      width: 40%;
      margin: 0 auto;
      padding: 0 10px;
    }
    ion-list {
      width: 40%;
      margin: 0 auto !important;
    }
    ion-grid {
      width: 40%;
      margin: 0 auto !important;
      padding: 0 10px;
      ion-title {
        width: 100%;
        margin: 0 !important;
      }
      ion-row {
        ion-col {
          width: 100% !important;
          max-width: 100% !important;
          flex: unset !important;
          ion-card {
            ion-card-content {
              ion-button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-inline-start: 0px !important;
                &::part(native) {
                  --box-shadow: unset;
                  height: 40px;
                  margin-top: 24px;
                  width: 280px;
                }
              }
            }
          }
        }
      }
    }
  }
}

app-policies {
  ion-header {
    width: 40%;
    margin: 0 auto;
    padding: 15px 0 20px;
  }
  ion-content {
    .pub_content {
      width: 40%;
      margin: 25px auto !important;
    }
  }
}
app-notifications {
  ion-header {
    width: 40%;
    margin: 0 auto;
    padding: 15px 0 20px;
  }
  ion-content {
    ion-card {
      width: 40%;
      margin: 25px auto !important;
    }
  }
}
app-change-password {
  ion-header {
    width: 40%;
    margin: 0 auto;
  }
  ion-content {
    ion-title {
      width: 40%;
      margin: 15px auto 0;
    }
    ion-grid {
      width: 40%;
      margin: 0 auto !important;
    }
  }
}

app-settings,
app-followers-settings,
app-post-settings,
app-allow-comments {
  ion-header {
    width: 40%;
    margin: 0 auto;
    padding: 15px 0 20px;
  }
  ion-content {
    ion-title {
      width: 40%;
      margin: 0 auto;
    }
    ion-card {
      width: 40%;
      margin: 25px auto !important;
    }
    ion-list {
      width: 40%;
      margin: 0 auto !important;
    }
    ion-grid {
      width: 40%;
      margin: 0 auto !important;
      ion-row {
        ion-col {
          width: 100% !important;
          max-width: 100% !important;
          flex: unset !important;
        }
      }
    }
  }
}

app-blocked-user,
app-following-list,
app-followers-list,
app-muted-user,
app-about,
app-follow-request,
app-follow-request-sent,
app-no-network {
  ion-header {
    width: 40%;
    margin: 0 auto;
    padding: 15px 0 20px;
  }
  ion-content {
    ion-title {
      width: 40%;
      margin: 0 auto;
      height: auto;
      position: relative;
      padding-inline: 18px;
      text-align: start;
    }
    .user_action_list {
      width: 40%;
      margin: 0 auto !important;
    }
    .following_list {
      width: 40%;
      margin: 0 auto !important;
      ion-card {
        .account-list {
          app-account-list {
            padding: 0;
            .acct-list-content {
              .card-modal {
                .user_request_btn {
                  .account_btn_type {
                    margin-left: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

app-follower-suggestion {
  ion-content {
    .follower_head_text {
      margin: 0 auto;
      width: 40%;
    }
    follower-suggestion {
      .follower-suggestion-page {
        margin: 0 auto;
        width: 40%;
      }
    }
  }
}

app-save-posts {
  ion-header {
    width: 40%;
    margin: 0 auto;
    padding: 15px 0 20px;
  }
  .user_postlist {
    width: 40%;
    margin: 0 auto;
  }
}

app-notification,
app-privacy-security,
app-app-settings,
app-help {
  ion-header {
    width: 40%;
    margin: 0 auto;
    padding: 15px 0 20px;
  }
  ion-content {
    ion-title {
      width: 40%;
      margin: 0 auto;
    }
    a24-accordion {
      ion-accordion-group {
        width: 40%;
        margin: 0 auto;
      }
    }
    ion-card {
      width: 40%;
      margin: 0 auto !important;
    }
  }
}
// add Product

app-add-product {
  ion-content {
    .product_box {
      ion-card {
        ion-card-content {
          ion-row {
            ion-col {
              .custom_time_filed {
                .form_group {
                  .set_time_info {
                    a24-label {
                      ion-label {
                        transform: translateY(88%) !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// vicky post-people search

.post_end {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin: 45px 0px;
  color: var(--ion-white-color);
  ion-img {
    &::part(image) {
      width: 100%;
      height: 66px;
      padding-bottom: 15px;
      margin: 0 auto;
      padding-left: 6px;
    }
  }
  P {
    margin: 0;
    line-height: 30px;
  }
}

.search_content {
  display: flex;
  justify-content: space-evenly;
  .is_active {
    color: var(--ion-theme-color) !important;
    font-weight: bold;
  }
}

app-post-slides > ion-card > ion-slides > div.swiper-wrapper {
  height: var(--height-viewport) !important;
}

app-all-post {
  cdk-virtual-scroll-viewport {
    .cdk-virtual-scroll-content-wrapper {
      app-feed-post {
        ion-card {
          app-post-slides {
            ion-card {
              ion-slides {
                .swiper-wrapper {
                  height: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
app-custom-skeleton {
  width: 40%;
  margin: 0 auto;
}

app-category {
  .intro_category {
    .categories {
      width: 40%;
      margin: 0 auto;
    }
  }
  .intro_footer {
    width: 40%;
    margin: 0 auto;
  }
}

.greaterthan_4 {
  .right_detail_box {
    position: absolute;
    right: 0;
    bottom: 115px;
  }
}
.normal {
  .right_detail_box {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.normal {
  .right_detail_box {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media only screen and (max-width: 400px) {
  .normal {
    .right_detail_box {
      position: absolute;
      right: 0;
      bottom: 115px !important;
    }
  }
}

// Responsive design
// 1600
@media only screen and (max-width: 1600px) {
  .split-pane-side {
    left: 3%;
  }
  app-web-header {
    .header_row {
      .profile_btn {
        padding: 5px 20px;
      }
    }
  }
  app-profile {
    user-profile {
      ion-content {
        .user_container {
          .gallery {
            .gallery-item {
              width: 22%;
            }
          }
        }
      }
    }
  }

  app-all-post {
    ion-header {
      ion-toolbar {
        .category_section {
          .category_slider {
            a24-slides {
              .slider_section {
                ion-slides {
                  .swiper-wrapper {
                    ion-slide {
                      &:first-child {
                        &::after {
                          right: -8px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// 1280

@media only screen and (max-width: 1280px) {
  .split-pane-side {
    left: 1%;
  }

  app-follower-suggestion {
    ion-footer {
      width: 60%;
      margin: 0 auto;
    }
  }

  app-all-post {
    ion-header {
      ion-toolbar {
        .category_section {
          .category_slider {
            a24-slides {
              .slider_section {
                ion-slides {
                  .swiper-wrapper {
                    ion-slide {
                      &:first-child {
                        &::after {
                          right: -8px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  app-post-detail {
    cdk-virtual-scroll-viewport {
      .cdk-virtual-scroll-content-wrapper {
        width: 56% !important;
        min-width: 56% !important;
        margin: 0 15% 0 auto;
      }
    }
  }

  .example-viewport {
    width: 70%;
  }

  app-no-network {
    ion-content {
      .network_container {
        width: 60%;
      }
    }
  }

  app-category {
    .intro_category {
      .categories {
        width: 60%;
        margin: 0 auto;
      }
    }
    .intro_footer {
      width: 60%;
      margin: 0 auto;
    }
  }

  .project_details_modal {
    ion-content {
      --background: transparent !important;
      .project_transparent_text {
        width: 60%;
      }
    }
  }

  app-add-post,
  app-admin-add-post {
    add-post {
      ion-header {
        width: 60%;
      }
      ion-content {
        ion-row {
          width: 60%;
          form {
            .submit_btn {
              margin: 0 16px 80px;
            }
          }
        }
      }
    }
  }

  app-category {
    ion-content {
      height: 100%;
    }
  }
  app-change-password {
    ion-header {
      width: 60%;
      margin: 0 auto;
    }
    ion-content {
      ion-title {
        width: 60%;
        margin: 15px auto 0;
      }
      ion-grid {
        width: 60%;
        margin: 0 auto !important;
      }
    }
  }

  // normal page login
  .slider_container {
    margin: 0 auto;
    width: 60%;
    ion-slide {
      width: 100% !important;
    }
  }

  app-login,
  app-register,
  app-forgot {
    ion-header {
      width: 60%;
    }
    ion-content {
      ion-grid {
        width: 60%;
      }
    }
  }

  app-edit-profile,
  app-add-profile {
    .profile {
      margin: 0 auto;
      position: relative;
      width: 110px !important;
      height: 110px !important;
    }
    ion-footer {
      width: 60%;
    }
    ion-header {
      width: 60%;
    }
    ion-content {
      ion-title {
        width: 60%;
      }
      ion-list {
        width: 60%;
      }
      ion-grid {
        width: 60%;

        ion-row {
          ion-col {
            width: 100% !important;
            max-width: 100% !important;
            flex: unset !important;
          }
        }
      }
    }
  }

  app-policies {
    ion-header {
      width: 60%;
    }
    ion-content {
      .pub_content {
        width: 60%;
      }
    }
  }
  app-notifications {
    ion-header {
      width: 60%;
    }
    ion-content {
      ion-card {
        width: 60%;
      }
    }
  }

  app-settings,
  app-followers-settings,
  app-post-settings,
  app-allow-comments {
    ion-header {
      width: 60%;
    }
    ion-content {
      ion-title {
        width: 60%;
      }
      ion-card {
        width: 60%;
      }
      ion-list {
        width: 60%;
      }
      ion-grid {
        width: 60%;

        ion-row {
          ion-col {
            width: 100% !important;
            max-width: 100% !important;
            flex: unset !important;
          }
        }
      }
    }
  }

  app-blocked-user,
  app-following-list,
  app-followers-list,
  app-muted-user,
  app-about,
  app-follow-request,
  app-follow-request-sent,
  app-no-network {
    ion-header {
      width: 60%;
    }
    ion-content {
      ion-title {
        width: 60%;
      }
      .user_action_list {
        width: 60%;
      }
      .following_list {
        width: 60%;
      }
    }
  }

  app-follower-suggestion {
    ion-content {
      .follower_head_text {
        width: 60%;
      }
      follower-suggestion {
        .follower-suggestion-page {
          width: 60%;
        }
      }
    }
  }

  app-save-posts {
    ion-header {
      width: 60%;
    }
    .user_postlist {
      width: 60%;
    }
  }

  app-notification,
  app-privacy-security,
  app-app-settings,
  app-help {
    ion-header {
      width: 60%;
    }
    ion-content {
      ion-title {
        width: 60%;
      }
      a24-accordion {
        ion-accordion-group {
          width: 60%;
        }
      }
      ion-card {
        width: 60%;
      }
    }
  }

  app-all-post {
    .post_search_header {
      width: 60%;
    }
  }

  app-all-post {
    .post_search_viewport {
      width: 60%;
    }
  }

  app-all-post,
  app-sample {
    ion-content {
      follower-suggestion {
        .follower-suggestion-page {
          width: 60%;
          margin: 15px auto 15px 21%;
        }
      }
    }
  }

  app-page-not-found {
    .pagenot_found_container {
      .not_found_footer {
        width: 60%;
      }
    }
  }
  app-custom-skeleton {
    width: 60%;
    margin: 0 auto;
  }
  app-profile,
  app-guest-user-profile {
    user-profile {
      ion-header {
        width: 60%;
      }
      ion-content {
        app-page-not-found {
          .pagenot_found_container {
            width: 60%;
            margin: 0 auto;
          }
        }
        .user_container {
          width: 60%;
        }
      }
    }
  }
  app-guest-user-model {
    .guest_page {
      width: auto;
    }
  }

  .list-group-item {
    .post_detail_main_page {
      // bottom: 90px;
    }
  }
}

// 1024

@media only screen and (max-width: 1279px) {
  .split-pane-side {
    left: 1%;
  }

  app-all-post {
    ion-header {
      ion-toolbar {
        .category_section {
          .category_slider {
            a24-slides {
              .slider_section {
                ion-slides {
                  .swiper-wrapper {
                    ion-slide {
                      &:first-child {
                        &::after {
                          right: -7px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  app-post-detail {
    cdk-virtual-scroll-viewport {
      .cdk-virtual-scroll-content-wrapper {
        width: 56% !important;
        min-width: 56% !important;
        margin: 0 17% 0 auto;
      }
    }
  }

  .example-viewport {
    width: 100%;
    margin: 0 auto 0 10%;
  }

  app-category {
    ion-content {
      height: 100%;
      .categories {
        .category_row {
          .category-col {
            .category_box {
              padding: 5px;
              p {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }
  }
  app-app-settings {
    ion-item.item-ionic-selectable.item-label-floating {
      a24-select-component {
        ionic-selectable {
          .ionic-selectable-icon {
            right: 42px !important;
          }
        }
      }
    }
  }
  app-custom-skeleton {
    width: 60%;
    margin: 0 auto;
  }
  app-about {
    ion-content {
      .about_page {
        margin: 30px auto 0;
        width: 60%;
      }
    }
  }
  app-web-header {
    .header_row {
      .profile_btn {
        padding: 5px 10px;
      }
    }
  }
}

// 820

@media only screen and (max-width: 820px) {
  .example-viewport {
    margin: 0px auto 0 17%;
  }

  app-all-post {
    ion-header {
      ion-toolbar {
        .category_section {
          .category_slider {
            a24-slides {
              .slider_section {
                ion-slides {
                  .swiper-wrapper {
                    ion-slide {
                      &:first-child {
                        &::after {
                          right: -6px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  app-custom-skeleton {
    width: 70%;
  }

  app-no-network {
    ion-content {
      .network_container {
        width: 70%;
      }
    }
  }

  app-follower-suggestion {
    ion-footer {
      width: 70%;
      margin: 0 auto;
    }
  }

  app-profile {
    user-profile {
      ion-content {
        .user_container {
          .gallery {
            .gallery-item {
              width: 21%;
              margin: 8px;
            }
          }
        }
      }
    }
  }

  .list-group-item {
    .post_detail_main_page {
      // bottom: 60px;
    }
  }

  app-change-password {
    ion-header {
      width: 70%;
    }
    ion-content {
      ion-title {
        width: 70%;
      }
      ion-grid {
        width: 70%;
      }
    }
  }

  app-category {
    .intro_category {
      .categories {
        width: 70%;
        margin: 0 auto;
      }
    }
    .intro_footer {
      width: 70%;
      margin: 0 auto;
    }
  }

  app-add-post,
  app-admin-add-post {
    add-post {
      ion-header {
        width: 70%;
      }
      ion-content {
        ion-row {
          width: 70%;
          form {
            .submit_btn {
              margin: 0 16px 80px;
            }
          }
        }
      }
    }
  }

  // normal page login
  .slider_container {
    width: 70%;
  }

  app-login,
  app-register,
  app-forgot {
    ion-header {
      width: 70%;
    }
    ion-content {
      ion-grid {
        width: 70%;
      }
    }
  }

  app-edit-profile,
  app-add-profile {
    .profile {
      margin: 0 auto;
      position: relative;
      width: 110px !important;
      height: 110px !important;
    }
    ion-header {
      width: 70%;
    }
    ion-footer {
      width: 70%;
    }
    ion-content {
      ion-title {
        width: 70%;
      }
      ion-list {
        width: 70%;
      }
      ion-grid {
        width: 70%;
      }
    }
  }

  app-policies {
    ion-header {
      width: 70%;
    }
    ion-content {
      .pub_content {
        width: 70%;
      }
    }
  }
  app-notifications {
    ion-header {
      width: 70%;
    }
    ion-content {
      ion-card {
        width: 70%;
      }
    }
  }

  app-settings,
  app-followers-settings,
  app-post-settings,
  app-allow-comments {
    ion-header {
      width: 70%;
    }
    ion-content {
      ion-title {
        width: 70%;
      }
      ion-card {
        width: 70%;
      }
      ion-list {
        width: 70%;
      }
      ion-grid {
        width: 70%;
      }
    }
  }

  app-blocked-user,
  app-following-list,
  app-followers-list,
  app-muted-user,
  app-about,
  app-follow-request,
  app-follow-request-sent,
  app-no-network {
    ion-header {
      width: 70%;
    }
    ion-content {
      ion-title {
        width: 70%;
      }
      .user_action_list {
        width: 70%;
      }
      .following_list {
        width: 70%;
      }
    }
  }

  app-follower-suggestion {
    ion-content {
      .follower_head_text {
        width: 70%;
      }
      follower-suggestion {
        .follower-suggestion-page {
          width: 70%;
        }
      }
    }
  }

  app-save-posts {
    ion-header {
      width: 70%;
    }
    .user_postlist {
      width: 70%;
    }
  }

  app-notification,
  app-privacy-security,
  app-app-settings,
  app-help {
    ion-header {
      width: 70%;
    }
    ion-content {
      ion-title {
        width: 70%;
      }
      a24-accordion {
        ion-accordion-group {
          width: 70%;
        }
      }
      ion-card {
        width: 70%;
      }
    }
  }

  app-all-post {
    .post_search_viewport {
      width: 70%;
    }
  }

  app-all-post,
  app-sample {
    ion-content {
      follower-suggestion {
        .follower-suggestion-page {
          width: 70%;
        }
      }
    }
  }

  app-page-not-found {
    .pagenot_found_container {
      .not_found_footer {
        width: 70%;
      }
    }
  }

  app-profile,
  app-guest-user-profile {
    user-profile {
      ion-header {
        width: 70%;
      }
      ion-content {
        app-page-not-found {
          .pagenot_found_container {
            width: 70%;
            margin: 0 auto;
          }
        }
        .user_container {
          width: 70%;
        }
      }
    }
  }

  app-post-detail {
    cdk-virtual-scroll-viewport {
      .cdk-virtual-scroll-content-wrapper {
        width: 75% !important;
        min-width: 75% !important;
        margin: 0 8% 0 auto;
      }
    }
  }

  app-web-header {
    .header_row {
      .profile_btn {
        padding: 5px 8px;
      }
    }
  }
  app-all-post {
    .post_search_header {
      width: 70%;
    }
  }

  .gallery {
    .gallery-item {
      position: relative;
      width: 20%;
      margin: 11px;
    }
  }

  app-all-post {
    ion-content {
      follower-suggestion {
        .follower-suggestion-page {
          margin: 15px auto 15px 3%;
        }
      }
    }
  }

  app-category {
    .categories {
      .category_row {
        .category-col {
          margin: 7px 0;
          .category_box {
            height: 40px;
            p {
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}

// 767

@media only screen and (max-width: 767px) {
  ion-router-outlet {
    width: 100%;
  }
  .page_not_found_box {
    .page_not_foung_img {
      margin: 25px 0;
    }
  }

  app-all-post {
    ion-header {
      ion-toolbar {
        .category_section {
          .category_slider {
            a24-slides {
              .slider_section {
                ion-slides {
                  .swiper-wrapper {
                    ion-slide {
                      &:first-child {
                        &::after {
                          right: -8px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  app-category {
    .intro_category {
      .categories {
        width: 95%;
        margin: 0 auto;
      }
    }
    .intro_footer {
      width: 95%;
      margin: 0 auto;
    }
  }

  app-no-network {
    ion-content {
      .network_container {
        width: 100%;
      }
    }
  }

  app-follower-suggestion {
    ion-footer {
      width: 100%;
      margin: 0 auto;
    }
  }

  .cdk-virtual-scroll-content-wrapper {
    .fit {
      app-post-slides {
        cursor: pointer;
        ion-card {
          ion-slides {
            .swiper-wrapper {
              ion-slide {
                img {
                  object-position: 50% 35%;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
  app-page-not-found {
    .pagenot_found_container {
      .not_found_footer {
        width: 100%;
        left: 0;
      }
    }
  }

  ion-split-pane {
    margin-top: 0 !important;
  }
  .example-viewport {
    margin: 0;
  }
  cdk-virtual-scroll-viewport {
    .cdk-virtual-scroll-content-wrapper {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
  .project_details_modal {
    ion-content {
      --background: transparent !important;
      .project_transparent_text {
        width: 90%;
      }
    }
  }
  app-all-post {
    ion-header {
      ion-toolbar {
        .category_section {
          .category_slider {
            a24-slides {
              .slider_section {
                ion-slides {
                  .swiper-wrapper {
                    ion-slide {
                      .category-group {
                        ion-card {
                          ion-card-content {
                            font-size: 12px;
                            padding: 5px 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  app-web-header {
    margin: 15px 0 0;
    .header_row {
      ion-col {
        &:nth-child(1) {
          order: 2;
          justify-content: flex-end !important;
        }
        &:nth-child(2) {
          order: 1;
        }
        &:nth-child(3) {
          order: 3;
          flex-wrap: nowrap;
        }
        &.home_icon_detail {
          justify-content: flex-start;
        }
      }
    }
  }

  app-profile {
    user-profile {
      ion-content {
        .user_container {
          .gallery {
            .gallery-item {
              width: 45%;
              margin: 8px;
            }
          }
        }
      }
    }
  }
  app-post-detail {
    cdk-virtual-scroll-viewport {
      .cdk-virtual-scroll-content-wrapper {
        width: 100% !important;
        min-width: 100% !important;
        margin: 0;
      }
    }
  }
  app-add-post,
  app-admin-add-post {
    add-post {
      ion-header {
        width: 95%;
      }
      ion-content {
        ion-row {
          width: 95%;
          form {
            .submit_btn {
              margin: 0 16px 80px;
            }
          }
        }
      }
    }
  }

  app-post-detail {
    cdk-virtual-scroll-viewport {
      .cdk-virtual-scroll-content-wrapper {
        .list-group-item {
          app-post-slides {
            ion-card {
              ion-slides {
                ion-slide {
                  img {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  app-about {
    ion-content {
      .about_page {
        width: 100%;
      }
    }
  }

  // ion-split-pane.has_header {
  //   margin-top: 55px !important;
  // }

  app-change-password {
    ion-header {
      width: 95%;
    }
    ion-content {
      ion-title {
        width: 95%;
        margin: 15px auto 0;
      }
      ion-grid {
        width: 95%;
      }
    }
  }

  // normal page login
  .slider_container {
    width: 100%;
  }

  app-login,
  app-register,
  app-forgot {
    ion-header {
      width: 95%;
    }
    ion-content {
      ion-grid {
        width: 95%;
      }
    }
  }

  app-edit-profile,
  app-add-profile {
    ion-header {
      width: 95%;
    }
    ion-footer {
      width: 95%;
    }
    ion-content {
      ion-title {
        width: 95%;
      }
      ion-list {
        width: 95%;
      }
      ion-grid {
        width: 95%;
      }
    }
  }

  app-policies {
    ion-header {
      width: 95%;
    }
    ion-content {
      .pub_content {
        width: 95%;
      }
    }
  }
  app-notifications {
    ion-header {
      width: 95%;
    }
    ion-content {
      ion-card {
        width: 95%;
      }
    }
  }

  app-settings,
  app-followers-settings,
  app-post-settings,
  app-allow-comments {
    ion-header {
      width: 95%;
    }
    ion-content {
      ion-title {
        width: 95%;
      }
      ion-card {
        width: 95%;
      }
      ion-list {
        width: 95%;
      }
      ion-grid {
        width: 95%;
      }
    }
  }

  app-blocked-user,
  app-following-list,
  app-followers-list,
  app-muted-user,
  app-about,
  app-follow-request,
  app-follow-request-sent,
  app-no-network {
    ion-header {
      width: 95%;
    }
    ion-content {
      ion-title {
        width: 100%;
      }
      .user_action_list {
        width: 100%;
      }
      .following_list {
        width: 100%;
      }
    }
  }

  app-follower-suggestion {
    ion-content {
      .follower_head_text {
        width: 95%;
      }
      follower-suggestion {
        .follower-suggestion-page {
          width: 95%;
        }
      }
    }
  }

  app-save-posts {
    ion-header {
      width: 95%;
    }
    .user_postlist {
      width: 95%;
    }
  }

  app-notification,
  app-privacy-security,
  app-app-settings,
  app-help {
    ion-header {
      width: 95%;
    }
    ion-content {
      ion-title {
        width: 95%;
      }
      a24-accordion {
        ion-accordion-group {
          width: 95%;
        }
      }
      ion-card {
        width: 95%;
      }
    }
  }

  app-all-post {
    .post_search_header {
      width: 95%;
    }
  }

  app-all-post {
    .post_search_viewport {
      width: 95%;
    }
  }

  app-all-post,
  app-sample {
    ion-content {
      follower-suggestion {
        .follower-suggestion-page {
          width: 95%;
          margin: 15px auto 0 10px;
        }
      }
    }
  }

  app-profile,
  app-guest-user-profile {
    user-profile {
      ion-header {
        width: 100%;
      }
      ion-content {
        app-page-not-found {
          .pagenot_found_container {
            width: 95%;
            margin: 0 auto;
          }
        }
        .user_container {
          width: 95%;
        }
      }
    }
  }
  app-post-detail .video-js.vjs-1-1,
  app-post-detail .video-js.vjs-16-9,
  app-post-detail .video-js.vjs-4-3,
  app-post-detail .video-js.vjs-9-16,
  app-post-detail .video-js.vjs-fluid {
    max-width: 100% !important;
  }

  app-add-post,
  app-admin-add-post {
    add-post {
      ion-header {
        padding: 0 !important;
      }
    }
  }
  app-add-post,
  app-save-posts,
  app-add-product {
    ion-header {
      app-page-header {
        .header_btns {
          .backButton {
            max-width: 15% !important;
          }
        }
      }
    }
  }

  app-add-post,
  app-admin-add-post {
    add-post {
      ion-content {
        ion-row {
          form {
            ion-card {
              margin: 20px 5px !important;
            }
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  ::-webkit-scrollbar-track {
    background-color: var(--ion-web-background-color);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--ion-web-background-color);
    border-radius: 10px;
  }
  app-web-header {
    .header_row {
      margin: 0 !important;
    }
  }
  app-all-post,
  app-sample {
    ion-header {
      ion-toolbar {
        ion-row {
          margin: 15px 0 0;
        }
      }
    }
  }
  ion-header {
    padding: 15px 0 !important;
  }
  app-notifications {
    ion-content {
      ion-card {
        ion-card-content {
          .notification_box {
            ion-row {
              ion-col {
                &.avatar {
                  max-width: 15% !important;
                }
                &.info_text {
                  max-width: 65% !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .real_comment_model {
    ion-header {
      width: 95%;
      margin: 0 auto;
    }
  }

  app-post-detail {
    cdk-virtual-scroll-viewport {
      .cdk-virtual-scroll-content-wrapper {
        .list-group-item {
          .post_detail_main_page {
            .left_detail_box {
              width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%) !important;
              max-width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%) !important;

              .post_product_link {
                width: 100vw;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .gallery {
    .gallery-item {
      position: relative;
      width: 45%;
      margin: 8px;
    }
  }

  app-add-product {
    ion-header {
      margin: 0 auto;
      width: 95%;
    }
  }
  app-app-settings {
    ion-item.item-ionic-selectable.item-label-floating {
      a24-select-component {
        ionic-selectable {
          .ionic-selectable-icon {
            right: 18px !important;
          }
        }
      }
    }
  }
  app-custom-skeleton {
    width: 98%;
  }
  app-web-header {
    .header_row {
      .all_icon {
        justify-content: flex-end;
      }
    }
  }
  app-web-header {
    .header_row {
      .profile_btn {
        padding: 5px 8px !important;
      }
    }
  }

  app-add-product {
    ion-item.custom_time_filed {
      .form_group.set_time {
        .set_time_info {
          .time_info {
            p {
              margin-top: 22px;
              margin-left: 6px;
              width: 45px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
    a24-select-component {
      .custom_select {
        ion-select {
          &::part(icon) {
            top: 65% !important;
          }
        }
      }
    }
  }

  app-add-product {
    ion-item.custom_time_filed {
      .form_group.set_time {
        ion-icon {
          position: absolute;
          right: 5px;
          top: 28px;
          font-size: 15px !important;
        }
      }
    }
  }
}

// 375

@media only screen and (max-width: 375px) {
  // profile image page editer
  .profile {
    a24-filepond file-pond {
      .filepond--wrapper {
        .filepond--root {
          .filepond--drop-label {
            label {
              .user_picture_icon {
                left: 95px !important;
              }
            }
          }
        }
      }
    }
  }

  app-add-product {
    ion-item.custom_time_filed {
      .form_group.set_time {
        .set_time_info {
          .time_info {
            p {
              margin-top: 22px;
              margin-left: 6px;
              width: 45px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  app-web-header {
    .header_row {
      ion-col:nth-child(1) {
        order: 2;
        justify-content: flex-start !important;
      }
    }
  }

  app-profile {
    user-profile {
      ion-content {
        .user_container {
          .gallery {
            .gallery-item {
              width: 43%;
              margin: 8px;
            }
          }
        }
      }
    }
  }
  app-custom-skeleton {
    width: 98%;
  }
  app-add-post,
  app-admin-add-post {
    add-post {
      ion-header {
        width: 98%;
      }
      ion-content {
        ion-row {
          width: 98%;
        }
      }
    }
  }

  app-web-header {
    .header_row {
      .profile_btn {
        padding: 5px 6px !important;
      }
    }
  }

  .profile {
    .profile-stats {
      ul {
        li {
          font-size: 14px;
          width: auto;
          p {
            font-size: 15px;
          }
          &.add_follow {
            .profile-stat-count {
              width: 88px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  .login_page {
    .or_continue {
      p {
        font-size: 14px;
      }
      .sideline {
        width: 90px;
      }
      ion-text {
        width: 45%;
      }
    }
  }

  app-add-product {
    ion-content {
      .product_box {
        ion-card {
          ion-card-content {
            ion-row {
              ion-col {
                .custom_time_filed {
                  .form_group {
                    .set_time_info {
                      a24-label {
                        ion-label {
                          padding: 0 7px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// 240

@media only screen and (max-width: 240px) {
  .login_form_container {
    .header {
      font-size: 18px;
      margin-top: 10px;
    }
    .sub_header {
      font-size: 10px;
      line-height: 10px;
    }
  }
  .login_page,
  app-register {
    .sign_bottom {
      p {
        font-size: 12px;
      }
      ion-button {
        &::part(native) {
          font-size: 12px;
        }
      }
    }
    .social_login {
      margin-top: 20px;
    }
    .spacer {
      height: 35px;
    }
    .or_continue {
      .sideline {
        width: 50px;
      }
      p {
        font-size: 12px;
      }
    }
    ion-card {
      ion-item {
        margin: 0px 0 10px;
        ion-icon {
          color: #afafaf;
          font-size: 14px !important;
        }
        ion-avatar {
          top: -3px !important;
          right: 0 !important;
        }
        a24-label {
          ion-label {
            transform: translateY(76%) !important;
            font-size: 10px !important;
          }
        }
        a24-input {
          ion-input {
            height: 45px;
            input {
              height: 45px;
              font-size: 10px !important;
            }
          }
        }
      }
    }
    ion-button.sign_btns {
      height: 45px;
      margin: 10px 0 !important;
      &::part(native) {
        font-size: 14px !important;
      }
    }
  }
}

.login_btns {
  display: flex;
  justify-content: space-evenly;
}

.verify_tick {
  align-items: center;
  display: flex;
  ion-icon {
    font-size: 14px !important;
    margin-left: 4px;
    color: #00fff8;
    border: 0px;
    pointer-events: none;
  }
}

.toLowercase {
  text-transform: lowercase;
}

.innerHtml_desc {
  display: flex;
  justify-content: center;
  ion-icon {
    padding: 0px 5px;
  }
}

app-image-crop {
  .edit_cancel_btn {
    position: absolute;
    top: 20px;
    left: 13px;
  }
  .canvas_edit {
    height: 80%;

    image-cropper {
      img {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
  .action_filed {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    width: 100%;
    bottom: 10%;
  }
  .image_action_btn {
    z-index: 99999;
    position: absolute;
    width: 100%;
    bottom: 0;
    ion-row {
      ion-col {
        ion-button {
          width: 100%;
        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
  &.text {
    color: #c6c6c640 !important;
  }
  &.popup {
    border: 1px solid var(--ion-border-color);
  }
  span {
    color: #c6c6c640 !important;
  }
  ion-icon {
    color: #c6c6c6c2 !important;
  }
}

app-post-header {
  ion-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: var(--ion-white-color);
    width: 100%;
    margin: 0 auto;
    --min-height: 32px;
    padding: 5px 4px 10px 16px;
    ion-buttons {
      .heart_like_section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        label {
          ion-icon {
            font-size: 40px !important;
            padding-top: 5px;
          }
        }
      }
    }
    ion-avatar {
      width: 42px;
      height: 42px;
      border: 1px solid var(--ion-white-color);
      color: var(--ion-white-color);
      text-align: center;
      line-height: 43px;
    }
    .post_details {
      width: 90%;
      padding-left: 14px;
      .post_title {
        font-size: 14px;
        padding: 0;
        margin: 0;
        line-height: 20px;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .post_texts {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .post_user {
          max-width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .views {
          margin-left: 5px;
        }
      }
    }
    ion-button {
      &::part(native) {
        color: var(--ion-white-color);
        padding: 0px 10px;
      }
    }
  }
}

app-account-list {
  .acct-list-content {
    .card-modal {
      .user_request_detail {
        .user_detail_text {
          .username.verify_tick {
            width: 90% !important;
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            white-space: nowrap !important;
            display: block;
          }
        }
      }
      .account_btn_type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 0 !important;
        ion-button {
          &.follow {
           
            &::part(native) {
              border: unset !important;
              padding: 0 !important;
              margin: 0 !important;
              font-size: 12px !important;
              color: var(--ion-theme-color) !important;
              font-weight: bold !important;
            }
          }
          &.remove {
            margin-left: 10px !important;
            &::part(native) {
              padding: 2px 5px !important;
              font-weight: 300 !important;
            }
          }
          &.btn_common {
            &::part(native) {
              padding: 2px 5px !important;
              font-weight: 300 !important;
            }
          }
        }
      }
    }
  }
}
